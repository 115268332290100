import { Component, OnDestroy, OnInit } from "@angular/core";
import { IProjectInfo } from "@vierkant-software/types__api";
import { BaseSidebarItem } from "../../base.sidebar.item";
import { GeneralStep } from "src/app/steppers/projects/steps/general/general.step";
import { Globals } from "src/app/services/globals.service";

@Component({
    selector:    'gc-project-duplicate-sidebar',
    templateUrl: './project-duplicate.sidebar.component.haml',
    styleUrls:   ['./project-duplicate.sidebar.component.sass'],
})
export class ProjectDuplicateSidebarComponent extends BaseSidebarItem<GeneralStep> implements OnInit, OnDestroy{
    readonly title: string = "duplicates";
    public projects: Partial<IProjectInfo>[];

    constructor(private mainComponent: GeneralStep, public globals: Globals) { super(); }

    ngOnDestroy() {
    }
    ngOnInit() {
        console.log('P:', this.projects);
    }
    public compare(project: Partial<IProjectInfo>){
        this.mainComponent.compareDuplicate(project);
    }
}
