/*
 * Public API Surface of ngx-component
 */
//export * from './lib/wrappers/disabled.module';
export * from './lib/wrappers/gc-reactive-forms.module';
export * from './lib/validators';
export * from './lib/wrappers/container.component';
export * from './lib/wrappers/components';
export * from './lib/wrappers/directives/gcTemplate';


