export enum PageButtonTypes {
    prevStep = 1,
    prevDraft,
    cancel,
    submit,
    nextStep,
    delete,
}

//export type ButtonColors = 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'help' | 'danger';

export type BasePageButton = {
    type:      keyof typeof PageButtonTypes;
    label?:     string;
    disabled?: boolean;
    icon?: string;
    iconPos?: 'left' | 'right' | 'top' | 'bottom';
    //visible?:  boolean;
    color:    string;
    routerLink?: (string | object)[];
    click?:    (event?: Event) => void | Promise<void>;
};
export type PageButton = BasePageButton;

export type PageButtons = PageButton[];
