

import { NgModule } from '@angular/core';
import { GcTooltipContainerComponent, GcTooltipComponent, GcTooltipDirective } from './gc-tooltip.directive';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports:      [CommonModule, TranslateModule.forChild()],
    declarations: [GcTooltipDirective, GcTooltipComponent, GcTooltipContainerComponent],
    exports:      [GcTooltipDirective, GcTooltipContainerComponent]

})
export class GcTooltipModule { }
