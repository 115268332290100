import { Component, ContentChild, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ITerminalInfo } from '@vierkant-software/types__api';
import { Globals } from 'src/app/services/globals.service';
import { Config } from "src/app/components/gc-times-editor/gc-times-editor.component";

/**
* @ignore - do not show in documentation
*/
@Component({
    selector:    'gc-print-preview',
    templateUrl: './print-preview.component.haml',
    styleUrls:   ['./print-preview.component.sass'],
})
export class PrintPreviewComponent implements OnInit {
    @Input() dataSet: ITerminalInfo;
    private _showDialog: boolean = false;
    public get showDialog(): boolean {
        return this._showDialog;
    }
    @Input() public set showDialog(value: boolean) {
        if (this._showDialog === value) return;
        this._showDialog = value;
        if (!value)this.displayPrintDialog(value);
    }
    @Output() showDialogChange = new EventEmitter();

    @ViewChild('canvasPrint') canvasPrint: ElementRef<HTMLCanvasElement>;
    @ContentChild('print', { static: true})  printData: TemplateRef<unknown>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() qrLink: string = '';

    globalDataURL: string = '';


    constructor(public globals: Globals) { }

    ngOnInit() { }

  /*ngOnChanges() {
    if (this.qrLink && this.dataSet)
      setTimeout(() => this.blah(this.parent), 500); //FIXME @Rene wait for QR qrc to be drawn
  }*/

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getQrImage(parent: any) {
        const parentElement = parent.qrcElement.nativeElement.querySelector("img").src;
        if (parentElement) {
            const blobData = this.convertBase64ToBlob(parentElement);
            const blob = new Blob([blobData], { type: "image/png" });
            return window.URL.createObjectURL(blob);
        }
    }

    convertBase64ToBlob(Base64Image: string) {
        const parts = Base64Image.split(";base64,");
        const imageType = parts[0].split(":")[1];
        const decodedData = window.atob(parts[1]);
        const uInt8Array = new Uint8Array(decodedData.length);
        for (let i = 0; i < decodedData.length; ++i)
            uInt8Array[i] = decodedData.charCodeAt(i);
        return new Blob([uInt8Array], { type: imageType });
    }

    getTerminalOption(flag: number) {
        // eslint-disable-next-line no-bitwise
        return (this.dataSet.terminalFlags & flag) !== 0 ? true : false;
    }

    private getOuterHtml(node: Node): string{
        if ('outerHTML' in node) return node.outerHTML as string;
        const div = document.createElement("div");
        div.appendChild(node.cloneNode(true));
        return div.innerHTML;
    }

    private createView(data: unknown){
        const embeddedView = this.printData.createEmbeddedView({$implicit: data});
        embeddedView.detectChanges();
        return embeddedView;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onReady(data: string) {
        const textPosY = 520;
        const textPosX = 0;
        const ctx = this.canvasPrint.nativeElement.getContext("2d");
        const printData = this.createView(this.dataSet);
        const img = new Image();
        img.onload = () => {
            const ptrn = ctx.createPattern(img, "no-repeat");
            ctx.fillStyle = '#ffffff';
            ctx.fillStyle = ptrn;
            ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
            printData.detectChanges();
            const svg = `
            <svg xmlns="http://www.w3.org/2000/svg" width="${ctx.canvas.width}" height="${ctx.canvas.height}" crossorigin="anonymous">
            <foreignObject width="100%" height="100%">
                <div xmlns="http://www.w3.org/1999/xhtml">${this.getOuterHtml(printData.rootNodes[0])}</div>
            </foreignObject>
            </svg>`;
            const svgObjectUrl = 'data:image/svg+xml;charset=Latin-1;base64,' + bytesToBase64(new TextEncoder().encode(svg));
            const svgImg = new Image();
            svgImg.onload = () => {
                ctx.drawImage(svgImg, textPosX, textPosY);
            };
            svgImg.src = svgObjectUrl;
        };
        img.src = data;

        setTimeout(() => this.globalDataURL = this.canvasPrint.nativeElement.toDataURL('image/png'), 1500);
    }

    displayPrintDialog(state: boolean) {
        const ctx = this.canvasPrint.nativeElement.getContext("2d");
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        this.qrLink = '';
        this.showDialogChange.emit(state.toString());
    }

    print() {
        const win = window.open('', '');
        // eslint-disable-next-line max-len
        win.document.write(`<html><body style="height: 29.7cm; width: 21cm;"><img style="height: 100%; width: 100%; margin: 0% 0%" src="${this.globalDataURL}" onLoad="window.print()"/></body></html>`);
    }

    download() {
        const a = document.createElement('a');
        a.href = "data: " + this.globalDataURL;
        a.download = 'zeitbox-qr-code - ' + this.dataSet.title + '.png';
        a.click();
    }

    get config(): Config<"day"> {
        return {
            dayProperty: 'day',
            keys:        [
                {
                    key:        "times",
                    label:      "Aktive Zeiten",
                    type:       "add",
                    styleClass: "fc-entry-primary"
                }
            ],
            showDayTotal:  false,
            showWeekTotal: false,
        };
    }

}

function bytesToBase64(bytes: Uint8Array) {
    const binString = Array.from(bytes, (x) => String.fromCodePoint(x)).join("");
    return window.btoa(binString);
}
