/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @angular-eslint/no-host-metadata-property */
/* eslint-disable max-len */
/* eslint-disable @angular-eslint/no-inputs-metadata-property */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/no-outputs-metadata-property */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, ViewEncapsulation, OnInit, OnDestroy, ChangeDetectorRef, ContentChild, TemplateRef, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
    selector: 'gc-steps',
    template: `
        <div [ngClass]="{ 'p-steps p-component': true, 'p-readonly': readonly }" [ngStyle]="style" [class]="styleClass">
            <ul role="tablist">
                <li
                    *ngFor="let item of model; let i = index"
                    class="p-steps-item"
                    #menuitem
                    [ngStyle]="item.style"
                    [class]="item.styleClass"
                    role="tab"
                    [attr.aria-selected]="i === activeIndex"
                    [attr.aria-expanded]="i === activeIndex"
                    pTooltip
                    [tooltipOptions]="item.tooltipOptions"
                    [ngClass]="{
                        'p-highlight p-steps-current': isActive(item, i),
                        'p-disabled': item.disabled || (readonly && !isActive(item, i)),
                        'gc-invalid': item.valid === false && i < activeIndex,
                        'gc-visited': i <= activeIndex
                    }"
                >
                    <a
                        *ngIf="isClickableRouterLink(item); else elseBlock"
                        [routerLink]="item.routerLink"
                        [queryParams]="item.queryParams"
                        role="presentation"
                        [routerLinkActive]="'p-menuitem-link-active'"
                        [routerLinkActiveOptions]="item.routerLinkActiveOptions || { exact: false }"
                        class="p-menuitem-link"
                        (click)="itemClick($event, item, i)"
                        (keydown.enter)="itemClick($event, item, i)"
                        [target]="item.target"
                        [attr.id]="item.id"
                        [attr.tabindex]="item.disabled || readonly ? null : item.tabindex ? item.tabindex : '0'"
                        [fragment]="item.fragment"
                        [queryParamsHandling]="item.queryParamsHandling"
                        [preserveFragment]="item.preserveFragment"
                        [skipLocationChange]="item.skipLocationChange"
                        [replaceUrl]="item.replaceUrl"
                        [state]="item.state"
                    >
                        <span class="p-steps-number">
                            <ng-container *ngIf="stepNumberRef else defaultNumber" [ngTemplateOutlet]= "stepNumberRef" [ngTemplateOutletContext]="{$implicit: item, index: i}"></ng-container>
                            <ng-template #defaultNumber>{{ i + 1 }}</ng-template>
                        </span>

                        <ng-container *ngIf="stepLabelRef else defaultLabel" [ngTemplateOutlet]= "stepLabelRef" [ngTemplateOutletContext]="{$implicit: item}"></ng-container>
                        <ng-template #defaultLabel><span class="p-steps-title">{{ item.label }}</span></ng-template>
                    </a>
                    <ng-template #elseBlock>
                        <a
                            [attr.href]="item.url"
                            class="p-menuitem-link"
                            role="presentation"
                            (click)="itemClick($event, item, i)"
                            (keydown.enter)="itemClick($event, item, i)"
                            [target]="item.target"
                            [attr.id]="item.id"
                            [attr.tabindex]="item.disabled || (i !== activeIndex && readonly) ? null : item.tabindex ? item.tabindex : '0'"
                        >
                            <span class="p-steps-number">
                                <ng-container *ngIf="stepNumberRef else defaultNumber" [ngTemplateOutlet]= "stepNumberRef" [ngTemplateOutletContext]="{$implicit: item, index: i}"></ng-container>
                                <ng-template #defaultNumber>{{ i + 1 }}</ng-template>
                            </span>

                            <span class="p-steps-title">
                                <ng-container *ngIf="stepLabelRef else defaultLabel" [ngTemplateOutlet]= "stepLabelRef" [ngTemplateOutletContext]="{$implicit: item}"></ng-container>
                                <ng-template #defaultLabel>{{ item.label }}</ng-template>
                            </span>
                        </a>
                    </ng-template>
                </li>
            </ul>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation:   ViewEncapsulation.None,
    styleUrls:       ['./step.sass'],
    host:            {
        class: 'gc-component p-element'
    }
})
export class GcStepsComponent implements OnInit, OnDestroy {
    private _activeIndex: number = 0;
    public get activeIndex(): number {
        return this._activeIndex;
    }
    @Input() public set activeIndex(i: number) {
        this._activeIndex = i;
        this.scrollTo(i);

    }

    @Input() model: (MenuItem & {valid?: boolean})[];

    @Input() readonly: boolean = true;

    @Input() style: any;

    @Input() styleClass: string;

    @Output() activeIndexChange: EventEmitter<any> = new EventEmitter();

    @ContentChild('stepLabel', { static: false}) stepLabelRef: TemplateRef<unknown>;
    @ContentChild('stepNumber', { static: false}) stepNumberRef: TemplateRef<unknown>;
    @ViewChildren('li') childElementQuery: QueryList<unknown>;
    private childElements: unknown[] = [];

    constructor(private router: Router, private route: ActivatedRoute, private cd: ChangeDetectorRef, private el: ElementRef) {}

    subscription: Subscription;

    ngOnInit() {
        this.subscription = this.router.events.subscribe(() => this.cd.markForCheck());
    }

    itemClick(event: Event, item: MenuItem, i: number) {
        if (this.readonly || item.disabled) {
            event.preventDefault();
            return;
        }

        this.activeIndexChange.emit(i);

        if (!item.url && !item.routerLink)
            event.preventDefault();


        if (item.command) {
            item.command({
                originalEvent: event,
                item,
                index:         i
            });
        }

    }

    scrollTo(index: number) {
        const nodes: NodeList = this.el.nativeElement.querySelectorAll('.p-steps-item');
        (nodes.item(index) as HTMLElement)?.scrollIntoView?.();
    }

    isClickableRouterLink(item: MenuItem) {
        return item.routerLink && !this.readonly && !item.disabled;
    }

    isActive(item: MenuItem, index: number) {
        if (item.routerLink) {
            const routerLink = Array.isArray(item.routerLink) ? item.routerLink : [item.routerLink];

            return this.router.isActive(this.router.createUrlTree(routerLink, { relativeTo: this.route }).toString(), false);
        }

        return index === this.activeIndex;
    }

    ngOnDestroy() {
        if (this.subscription)
            this.subscription.unsubscribe();

    }
}
