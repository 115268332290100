import { Component,Input} from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { IUserEx } from '@vierkant-software/types__api';
import { Globals } from 'src/app/services/globals.service';

/**
 * Display the user's image. If the user has no image provided or it cannot be loaded, 
 * the user's color will be used to tint the default profile image. 
 * 
 * The tinted profile image will be altered depending on the user's browser color scheme.
 * For this the color's lightness (HSL format) will be altered depending on:
 * - `Globals.DARK_MODE_LIGHTNESS`
 * - `Globals.LIGHT_MODE_LIGHTNESS`
 * 
 * @name    Avatar
 * @tags    Display, User, Profile, Image
 * @todo    rename to gc-avatar as the wrapper is never used
 * 
 * @remark  Will be renamed to `gc-avatar`. Currently the selector is `gc-profile`, as `gc-avatar` is occupied by an obsolete component.
 * 
 */
@Component({
    selector:    'gc-profile', //TODO: rename to gc-avatar as the wrapper is never used
    styleUrls:   ['./gc-avatar.component.sass'],
    templateUrl: './gc-avatar.component.haml',
})
export class GcAvatarComponent{
    public color: string;
    _internalImage: string | SafeUrl;

    /**
     * User's profile image as string or SafeUrl.
     * If no image is provided a fallback will be used.
     */
    @Input() public set image(value: string | SafeUrl) {
        this._internalImage = value;
    }

    /**
     * Color values of the user. 
     * Value can either be the hue and saturation as [Hue, Saturation] or the user data object.
     */
    @Input() public set value(value: [number, number] | IUserEx) {
        if (value && !Array.isArray(value)) value = value.userColor ?? value.defaultColor;
        this._internalValue = value as [number, number] ?? [undefined, undefined];
        this.setColor();
    }

    _internalValue: [number, number] = [undefined, undefined];

    public get lightness() {
        const lightness = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ?
            Globals.DARK_MODE_LIGHTNESS :
            Globals.LIGHT_MODE_LIGHTNESS;
        return `${lightness * 100}%`;
    }

    private setColor() {
        const [h, s] = this._internalValue;
        if (!h || !s) {
            this.color = undefined;
            return;
        }
        this.color = `${h}deg, ${s * 100}%`;
    }

}