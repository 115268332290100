import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';

export interface MenuItem {
  active: boolean;
  icon: string;
  label: string;
  route: string[];
  isChild: boolean;
  children: MenuItem[];
}

/**
 * @ignore - do not show in documentation
 */
@Component({
  selector:    'app-menu-treeview',
  templateUrl: './menu-treeview.component.haml',
  styleUrls:   ['./menu-treeview.component.sass'],
})
export class MenuTreeviewComponent implements OnInit, OnChanges {
  @Input() menuItems: MenuItem[];
  @Input() sideNavOpen? = true;
  displayChildren = false;
  activeItemId: string;
  @Input() level?: number;
  constructor(private router: Router) { }

  ngOnInit() {}
  ngOnChanges() {
    if (!this.sideNavOpen)
      this.displayChildren = false;
  }

  navigate(event: Event, route: string[], hasChildren: boolean, level: number, index: number) {
    event.stopPropagation();
    if (this.sideNavOpen) {
      if (this.activeItemId)
        document.getElementById(this.activeItemId).classList.remove('active');
      this.activeItemId = 'li' + level + index;
      document.getElementById(this.activeItemId).classList.add('active');
      if (hasChildren)
        this.displayChildren = !this.displayChildren;
      else
        this.router.navigate(route).catch(e => console.log('Error: Navigate failed'));
    }

  }
}
