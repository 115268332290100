// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gc-datetime {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  color: var(--gc-color-light-contrast);
  background: var(--gc-background-color);
  border: 1px solid var(--gc-color-medium);
  padding: 0.5rem 0.75rem;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
  appearance: none;
  border-radius: 4px;
  width: calc(100% - 1.5rem);
}
.gc-datetime:disabled {
  opacity: 0.65;
}
.gc-datetime:enabled:hover {
  border-color: var(--gc-border-color);
}
.gc-datetime:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: var(--prime-input-focus);
  border-color: var(--gc-color-primary);
}
@media (prefers-color-scheme: dark) {
  .gc-datetime::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }
}

:host-context(gc-datetime.ng-invalid) input {
  border-color: var(--gc-color-danger);
}

.flex-column {
  display: flex;
  flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/app/components/gc-datetime/datetime.component.sass"],"names":[],"mappings":"AAAA;EACI,0JAAA;EACA,eAAA;EACA,qCAAA;EACA,sCAAA;EACA,wCAAA;EACA,uBAAA;EACA,wEAAA;EACA,gBAAA;EACA,kBAAA;EACA,0BAAA;AACJ;AAAI;EACI,aAAA;AAER;AADI;EACI,oCAAA;AAGR;AAFI;EACI,eAAA;EACA,iBAAA;EACA,oCAAA;EACA,qCAAA;AAIR;AAHI;EACI;IACI,iBAAA;EAKV;AACF;;AAHI;EACI,oCAAA;AAMR;;AAHA;EACI,aAAA;EACA,sBAAA;AAMJ","sourcesContent":[".gc-datetime\n    font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, Helvetica, Arial, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\" \n    font-size: 1rem \n    color: var(--gc-color-light-contrast) \n    background: var(--gc-background-color) \n    border: 1px solid var(--gc-color-medium) \n    padding: 0.5rem 0.75rem \n    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s \n    appearance: none \n    border-radius: 4px\n    width: calc(100% - 1.5rem)\n    &:disabled\n        opacity: 0.65\n    &:enabled:hover \n        border-color: var(--gc-border-color)\n    &:enabled:focus\n        outline: 0 none\n        outline-offset: 0\n        box-shadow: var(--prime-input-focus)\n        border-color: var(--gc-color-primary)\n    @media(prefers-color-scheme: dark)\n        &::-webkit-calendar-picker-indicator\n            filter: invert(1)\n\n:host-context(gc-datetime.ng-invalid)\n    input\n        border-color: var(--gc-color-danger)            \n\n    \n.flex-column\n    display: flex\n    flex-direction: column"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
