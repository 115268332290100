import { InjectionToken } from "@angular/core";
import { IAppService } from "./IAppService";

export enum DebugFlags {
    enable = 1,
    validators = 2
}

export const APP_SERVICE = new InjectionToken<IAppService>('APP_SERVICE');
export const DRAFT_DEBUG = new InjectionToken<DebugFlags>('DRAFT_DEBUG');

export const STEP_OPTIONS = new InjectionToken<{ [key: string]: unknown }>('STEP_OPTIONS');
