console.warn('DEPRECATED(RuntimePipe): Use Globals service instead of RuntimePipe.');

import { Pipe, PipeTransform } from '@angular/core';
import { GDuration } from '@vierkant-software/types__api';
import { Globals } from '../services/globals.service';

/* 
 * Usage:
 * value | timeUnit:Type
 * 
 * Example:
 * {{ unit | runtime:Type.Short}}
 */
@Pipe({ name: 'runtime' })
export class RuntimePipe implements PipeTransform {
    transform(runtime: GDuration, format: 'default' | 'adjective' = 'adjective', showOne = false): string {
        if (!runtime)
            return '';
        const pre = !showOne && runtime.value === 1 ? "" : runtime.value;
        const post = Globals.timeUnit[format][runtime.unit] + (format === 'adjective' ? "." : "");
        return `${pre} ${post}`.trim();
    }
}
