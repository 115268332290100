import { Component } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { WrapperComponent } from "./base";
import { GC_COMPONENT_CONTAINER } from "./definitions";

@Component({
    selector:  'gc-container',
    template:  `<div class="containerComponent"><ng-content></ng-content></div>`,
    providers: [
      { provide: NG_VALUE_ACCESSOR, useExisting: GcContainerComponent, multi: true },
      { provide: GC_COMPONENT_CONTAINER, useExisting: GcContainerComponent }
    ],
})
export class GcContainerComponent extends WrapperComponent {
  override init() {} // don't init on container
}

@Component({
  selector:  'gc-skip',
  template:  `<ng-content></ng-content>`,
  styles:    [`:host { display: inline; }`],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: GcContainerComponent, multi: true },
    { provide: GC_COMPONENT_CONTAINER, useExisting: SkipContainer }
  ],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class SkipContainer {
  _skipRegister = true;
}
