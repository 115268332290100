// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  height: 100%;
  display: grid;
  grid-template-rows: 1fr;
}
:host .container {
  display: grid;
  grid-template-columns: [toggle-start] min-content [toggle-end title-start] max-content [title-end center-start] 1fr [center-end action-start] auto [action-end];
  gap: 0.5rem 1rem;
}
:host .center {
  display: flex;
  justify-content: flex-start;
}
:host .actions-template {
  display: flex;
  justify-content: flex-end;
}
:host .actions-template gc-icon {
  margin: 0 0.5rem;
}
:host .actions-template gc-icon:last-child {
  margin-right: 0 !important;
}
:host h5 {
  margin-top: 0;
}

gc-overview-panel {
  grid-template-columns: subgrid;
}

gc-scroll-container {
  height: 100%;
  margin-top: -1.5rem !important;
}

.container-page > .header {
  box-shadow: 0 0.25rem 0.25rem var(--gc-background-color);
  z-index: 1;
}`, "",{"version":3,"sources":["webpack://./src/app/components/gc-overview/gc-overview.component.sass"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;EACA,uBAAA;AACJ;AAAI;EACI,aAAA;EACA,+JAAA;EACA,gBAAA;AAER;AADI;EACI,aAAA;EACA,2BAAA;AAGR;AAFI;EACI,aAAA;EACA,yBAAA;AAIR;AAHQ;EACI,gBAAA;AAKZ;AAHY;EACI,0BAAA;AAKhB;AAJI;EACI,aAAA;AAMR;;AAJA;EACI,8BAAA;AAOJ;;AALA;EACI,YAAA;EACA,8BAAA;AAQJ;;AALI;EACI,wDAAA;EACA,UAAA;AAQR","sourcesContent":[":host\n    height: 100%\n    display: grid\n    grid-template-rows: 1fr\n    .container    \n        display: grid\n        grid-template-columns: [toggle-start] min-content [toggle-end title-start] max-content [title-end center-start] 1fr [center-end action-start] auto [action-end]\n        gap: 0.5rem 1rem\n    .center\n        display: flex\n        justify-content: flex-start\n    .actions-template\n        display: flex\n        justify-content: flex-end\n        gc-icon\n            margin: 0 0.5rem\n        gc-icon\n            &:last-child\n                margin-right: 0 !important\n    h5\n        margin-top: 0\n    \ngc-overview-panel\n    grid-template-columns: subgrid\n\ngc-scroll-container\n    height: 100%\n    margin-top: -1.5rem !important\n\n.container-page > \n    .header\n        box-shadow: 0 0.25rem 0.25rem var(--gc-background-color)\n        z-index: 1\n\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
