import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { EMPTY, from, Observable } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { DraftService } from "./draft.service";

@Injectable({
    providedIn: 'root',
})
export class DraftResolver implements Resolve<unknown> {

    constructor(
        private draftService: DraftService,
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): unknown | Observable<unknown> | Promise<unknown> {
        return from(this.draftService.initDraft(route)).pipe(
            tap(x => console.log('### R tap:', x)),
            catchError(x => { console.error('### R error:', x); return EMPTY; })
        );
    }

}
