/* eslint-disable max-len */
/* eslint-disable @angular-eslint/no-inputs-metadata-property */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/no-outputs-metadata-property */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { Component, ViewEncapsulation } from "@angular/core";
import { ProgressBar } from "primeng/progressbar";
import { WrapperBase } from "../base";
import { Wrapper } from "../decorators";

@Component({
    selector:      'gc-progressBar',
    template:      '<p-progressBar #component><ng-content></ng-content></p-progressBar>',
    encapsulation: ViewEncapsulation.None,
    providers:     [{ provide: ProgressBar, useExisting: GcProgressbarComponent }],
    inputs:        ["value", "showValue", "unit", "mode"],
})
@Wrapper(ProgressBar)
export class GcProgressbarComponent extends WrapperBase {
}
export interface GcProgressbarComponent extends ProgressBar {}
