/* eslint-disable object-shorthand */
import { Injectable, Signal } from "@angular/core";
import { ClientSettingsType } from "@vierkant-software/types__api";
import { Subject, debounceTime, filter, map } from "rxjs";
import { APIStates, AppService } from "src/services/app.service";
import { toSignal } from "@angular/core/rxjs-interop";


export const DEFAULT_SETTINGS: {[key in ClientSettingsType]?: unknown } = {
    [ClientSettingsType.showMenuBar]: true,
    [ClientSettingsType.calendar]:    {
        users: [],
    },
    [ClientSettingsType.shift]: {
        showTimesWeek:  true,
        showTimesMonth: false,
        showDays:       true,
        showAmount:     false,
        display:        undefined,
    }

};

type settingType = { [key in ClientSettingsType]?: unknown };

export function GcSetting(type: ClientSettingsType, isObject = true) {
    if (ClientSettingsType[type] === undefined)
        throw new Error('Invalid setting type');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return function GcSettingDecorator(target: any, propertyKey: string) {
        Object.defineProperty(target, propertyKey, {
            get() {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const value = SettingsService.instance.settings[type] as any;
                if (typeof value === 'object' && !value.isLuxonDateTime && !value.isLuxonDuration)
                    return SettingsService.instance.getObjectProxy(type);
                else return SettingsService.instance.settings[type];
            },
            set(value) {
                if (!!Object.compare(SettingsService.instance.settings[type], value)) {
                    SettingsService.instance.settings[type] = value;
                    SettingsService.instance.updateSettings(type);
                } else {
                    console.warn('Writing the same value to a setting');
                }
            },
            enumerable:   true,
            configurable: true
        });
    };
}

@Injectable({
    providedIn: 'root',
})
export class SettingsService {

    #settings: settingType = {};
    static instance: SettingsService;

    /** @internal */
    get settings(): settingType {
        return this.#settings;
    }

    constructor(
        private appService: AppService,
    ) {
        SettingsService.instance = this;
        this.appService.$APIState.forEach(state => {
            switch(state.type) {
                case APIStates.loggedIn:
                case APIStates.externalStudio:
                    this.loadSettings().catch(console.error);
                    break;
                case APIStates.loggedOut:
                    this.#settings = {};
                    break;
            }
        }).catch(e => null);
    }

    public getObjectSignal<T>(type: ClientSettingsType): Signal<T> {
        return toSignal(this.#debounceObs.pipe(filter(x => x.type === type), map(x => x.value as T)));
    }

    /** @internal */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getObjectProxy(type: ClientSettingsType, obj: any = this.#settings[type]): unknown {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (obj.__proxy)
            return obj.__proxy;
        const proxy = new Proxy(obj, {
            get: (target, propertyKey) => {
                if (typeof target[propertyKey] === 'object' && !target[propertyKey].isLuxonDateTime && !target[propertyKey].isLuxonDuration)
                    return this.getObjectProxy(type, target[propertyKey]);
                if (typeof target[propertyKey] === 'function') {
                    // eslint-disable-next-line @typescript-eslint/space-before-function-paren
                    return function(...args: unknown[]) {
                        const result = target[propertyKey].apply(this, args);
                        return result;
                    };
                }
                return target[propertyKey];
            },
            set: (target, propertyKey, value) => {
                if (!Object.compare(target[propertyKey], value)) {
                    target[propertyKey] = value;
                    this.updateSettings(type);
                } else {
                    if (!Array.isArray(target) || propertyKey !== 'length')
                        console.warn('Writing the same value to a setting', Array.isArray(target), propertyKey, target[propertyKey], value);
                }
                return true;
            }
        });
        Object.defineProperty(obj, '__proxy', {
            enumerable:   false,
            configurable: false,
            writable:     false,
            value:        proxy
        });
        return proxy;
    }

    #changeSettings: ClientSettingsType[] = [];
    #debounceObs = new Subject<{type: ClientSettingsType, value: unknown }>();
    #debounsObsSub = this.#debounceObs
        .pipe(debounceTime(4000))
        .subscribe(() => {
            this.writeSettings();
        });

    /** @internal */
    updateSettings(type: ClientSettingsType) {
        console.log("Updating setting scheduled:", ClientSettingsType[type]);
        if (!this.#changeSettings.includes(type))
            this.#changeSettings.push(type);
        this.#debounceObs.next({ type, value: ClientSettingsType[type] });
    }

    /** @internal */
    writeSettings() {
        console.log("Writing settings");
        const settings = this.#changeSettings;
        this.#changeSettings = [];
        for (const setting of settings)
            this.appService.api.SettingsWorker.setClientSettings(this.#settings[setting], setting).catch((x: Error) => x.displayErrorToast());
    }

    private async loadSettings() {
        this.#settings = await this.appService.api.SettingsWorker.getClientSettings();
        for (const key of (Object.keys(DEFAULT_SETTINGS).map(x => +x) as ClientSettingsType[])) {
            if (this.#settings[key] === undefined)
                this.#settings[key] = DEFAULT_SETTINGS[key];
        }
    }
}
