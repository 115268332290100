// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ul {
  padding-inline-start: 0.8rem;
  transition: 0.5s;
  width: 19rem;
}
ul li {
  padding: 1rem 0 0.6rem 0.6rem;
  list-style-type: none;
  font-weight: 500;
  font-size: 1rem;
  border: 1px solid transparent;
  border-radius: 5px;
}
ul li.active {
  background-color: #e9e9e9;
}
ul li:hover {
  cursor: pointer;
  border: 1px solid #e9e9e9;
}
ul li i {
  width: 2.25rem;
}
ul li span {
  display: inline-block;
  width: calc(100% - 3.75rem);
  padding-bottom: 0.3rem;
}
ul li span.arrow {
  display: inline-block;
  width: 1.5rem;
}
ul li ul {
  border-top: 1px solid #b1b0b0;
  width: 17.5rem;
}
ul li ul li {
  padding: 1rem 0 0 0;
}
ul li ul li span {
  width: calc(100% - 3.75rem);
}
ul li ul li span.arrow {
  width: 1.5rem;
}
ul li ul li ul {
  width: 16.5rem;
}
ul li ul li ul li i {
  display: inline-block;
  width: 2.5rem;
}
ul li ul li ul li span {
  width: calc(100% - 2.5rem);
}`, "",{"version":3,"sources":["webpack://./src/app/components/menu-treeview/menu-treeview.component.sass"],"names":[],"mappings":"AAAA;EACE,4BAAA;EACA,gBAAA;EACA,YAAA;AACF;AAAE;EACE,6BAAA;EACA,qBAAA;EACA,gBAAA;EACA,eAAA;EACA,6BAAA;EACA,kBAAA;AAEJ;AADI;EACE,yBAAA;AAGN;AAFI;EACE,eAAA;EACA,yBAAA;AAIN;AAHI;EACE,cAAA;AAKN;AAJI;EACE,qBAAA;EACA,2BAAA;EACA,sBAAA;AAMN;AALM;EACE,qBAAA;EACA,aAAA;AAOR;AANI;EACE,6BAAA;EACA,cAAA;AAQN;AAPM;EACE,mBAAA;AASR;AARQ;EACE,2BAAA;AAUV;AATU;EACE,aAAA;AAWZ;AAVQ;EACE,cAAA;AAYV;AAVY;EACE,qBAAA;EACA,aAAA;AAYd;AAXY;EACE,0BAAA;AAad","sourcesContent":["ul\n  padding-inline-start: 0.8rem\n  transition: 0.5s\n  width: 19rem  \n  li\n    padding: 1rem 0 0.6rem 0.6rem\n    list-style-type: none\n    font-weight: 500\n    font-size: 1rem\n    border: 1px solid transparent\n    border-radius: 5px\n    &.active\n      background-color: #e9e9e9        \n    &:hover\n      cursor: pointer\n      border: 1px solid #e9e9e9\n    i\n      width: 2.25rem\n    span\n      display: inline-block\n      width: calc(100% - 3.75rem)\n      padding-bottom: 0.3rem\n      &.arrow\n        display: inline-block\n        width: 1.5rem\n    ul\n      border-top: 1px solid #b1b0b0\n      width: 17.5rem\n      li\n        padding: 1rem 0 0 0\n        span\n          width: calc(100% - 3.75rem)\n          &.arrow\n            width: 1.5rem\n        ul\n          width: 16.5rem\n          li\n            i\n              display: inline-block\n              width: 2.5rem\n            span\n              width: calc(100% - 2.5rem)\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
