/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/no-outputs-metadata-property */
/* eslint-disable @angular-eslint/no-inputs-metadata-property */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { Component, EventEmitter, HostBinding, Input, Output, ViewEncapsulation } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { InputSwitch, InputSwitchChangeEvent } from "primeng/inputswitch";
import { WrapperComponent } from "../base";
import { Wrapper } from "../decorators";

@Component({
    selector:      'gc-inputSwitch',
    encapsulation: ViewEncapsulation.None,
    styles:        [
        `gc-inputswitch { display: flex; align-items: center; }`,
        `gc-inputswitch.label-offset { margin-top: 1.1875rem }`,
        `gc-inputswitch p-inputswitch{ display: contents; }`,
    ],
    template: `<p-inputSwitch [formControl]="gcControl" [id]="__internal_ID" [id]="__internal_ID"><ng-content></ng-content></p-inputSwitch>
                    <label *ngIf="label"
                        [for]="__internal_ID" [for]="__internal_ID"> {{label}}
                    </label>`,
    providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: GcInputSwitchComponent, multi: true }],
    inputs:    ["style", "styleClass", "tabindex", "inputId", "name", "ariaLabelledBy", "disabled", "readonly", "trueValue", "falseValue", "ariaLabel"],
})
@Wrapper(InputSwitch)
export class GcInputSwitchComponent extends WrapperComponent {
    @Input() label: string;
    @Input() @HostBinding('class.label-offset') offset: boolean;
    @Output() onChange: EventEmitter<InputSwitchChangeEvent> = new EventEmitter<InputSwitchChangeEvent>();
}
export interface GcInputSwitchComponent extends InputSwitch {};
