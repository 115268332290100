/* eslint-disable @angular-eslint/no-inputs-metadata-property */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Avatar } from "primeng/avatar";
import { WrapperBase } from "../base";
import { Wrapper } from "../decorators";

@Component({
    selector:      'gc-avatar ',
    encapsulation: ViewEncapsulation.None,
    template:      '<p-avatar #component><ng-content></ng-content></p-avatar>',
    providers:     [{ provide: Avatar, useExisting: GcAvatarComponent }],
    inputs:        ["label", "icon", "image", "size", "shape", "style", "styleClass"]
})
@Wrapper(Avatar)
export class GcAvatarComponent extends WrapperBase implements OnInit {
}
export interface GcAvatarComponent extends Avatar {}
