import { Component } from '@angular/core';
import { InternalAppService, Language } from 'src/services/app.service';

interface LanguageOption {
  name: string;
  code: string;
}

/**
* @ignore - do not show in documentation
*/
@Component({
  selector:    'app-language-selector',
  templateUrl: './language-selector.component.haml',
  styleUrls:   ['./language-selector.component.sass'],
})
export class LanguageSelectorComponent {

  languages: LanguageOption[];
  #lang: Language;
  get selectedLanguage(): Language{
    return this.#lang;
  };
  set selectedLanguage(value: Language){
    if (value === this.#lang)
      return;
    this.#lang = value;
    if (this.service.locale !== value)
      this.service.__setLocale(value);
  };
  constructor(public service: InternalAppService) {
    this.languages = service.languages.map(x => this.localeToOption(x));
    this.selectedLanguage = service.locale;
  }

  localeToOption(value: Language){
    return {
      code: value,
      name: value,
    };
  }
}
