// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `a {
  cursor: pointer;
  text-align: center;
  margin-top: 20px;
  width: 100%;
  font-weight: 600;
  display: inline-block;
  text-decoration: underline;
}

h5 {
  font-weight: 500;
  margin-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/sidebar/components/project-duplicate/project-duplicate.sidebar.component.sass"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,WAAA;EACA,gBAAA;EACA,qBAAA;EACA,0BAAA;AACJ;;AACA;EACI,gBAAA;EACA,mBAAA;AAEJ","sourcesContent":["a\n    cursor: pointer\n    text-align: center\n    margin-top: 20px\n    width: 100%\n    font-weight: 600\n    display: inline-block\n    text-decoration: underline\n\nh5\n    font-weight: 500\n    margin-bottom: 20px\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
