/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/no-outputs-metadata-property */
/* eslint-disable @angular-eslint/no-inputs-metadata-property */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { ChangeDetectorRef, Component, ContentChildren, NgModule, OnDestroy, OnInit, QueryList, ViewEncapsulation } from "@angular/core";
import { WrapperBase } from "../base";
import { Wrapper } from "../decorators";
import { Toast, ToastModule } from "primeng/toast";
import { MessageService } from "primeng/api";
import { Subscription } from "rxjs";
import { GcTemplate } from "../directives/gcTemplate";
import { GcReactiveForms } from "../gc-reactive-forms.module";
import { CommonModule } from "@angular/common";

@Component({
    selector:      'gc-toast',
    encapsulation: ViewEncapsulation.None,
    template:      `<p-toast #component>
                        <ng-container  *ngFor="let template of templates">
                            <ng-template let-message pTemplate="{{template.getType()}}">
                                <ng-container [ngTemplateOutlet]="template.template", [ngTemplateOutletContext]="{$implicit: message}"></ng-container>
                            </ng-template>
                        </ng-container>
                    </p-toast>`,
    providers: [{ provide: Toast, useExisting: GcToastComponent }],
    // eslint-disable-next-line max-len
    inputs:    ["key", "autoZIndex", "baseZIndex", "life", "style", "styleClass", "position", "preventOpenDuplicates", "preventDuplicates", "showTransoformOptions", "hideTransformOptions", "showTransitionOptions", "hideTransitionOptions", "breakpoints"],
    outputs:   ["onClose"]
})
@Wrapper(Toast)
export class GcToastComponent extends WrapperBase implements OnInit, OnDestroy {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @ContentChildren(GcTemplate) templates: QueryList<any>;
    clearByID: Subscription;

    constructor(public messageService: MessageService, private cd2: ChangeDetectorRef) {
        super();
    }

    override ngOnInit(): void {
        super.init();
        this.clearByID = this.messageService.clearObserver.subscribe((key) => {
            if (!key) return;
            (super.component as Toast).messages = (super.component as Toast).messages?.filter(x => x.id !== key);
            this.cd2.markForCheck();
        });
    }

    ngOnDestroy(): void {
        this.clearByID?.unsubscribe();
    }
}
export interface GcToastComponent extends Toast {}
@NgModule({
    imports: [
        ToastModule,
        CommonModule,
        GcReactiveForms,
    ],
    exports: [
        GcToastComponent,
        GcReactiveForms,
    ],
    declarations: [
        GcToastComponent
    ],
})
export class GcToastModule{}
