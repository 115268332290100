import { Component } from "@angular/core";
import { faCalendarDay, faPlus } from "@fortawesome/free-solid-svg-icons";


import { BaseSidebarItem } from "../../base.sidebar.item";

@Component({
    selector:    'gc-contractAdjustmentInfo-sidebar',
    templateUrl: './contractAdjustmentInfo.sidebar.component.haml',
    styleUrls:   ['./contractAdjustmentInfo.sidebar.component.sass'],
  })
export class ContractAdjustmentInfoSidebarComponent extends BaseSidebarItem<never> {

  iconCalendar = faCalendarDay;
  iconPlus = faPlus;
  openPage(){
    //TODO
  }

}
