import { Component, OnDestroy, OnInit } from "@angular/core";
import { UserPersonStep } from "src/app/steppers/users/steps/person/person.step";
import { IUser } from "@vierkant-software/types__api";
import { BaseSidebarItem } from "../../base.sidebar.item";
import { Globals } from "src/app/services/globals.service";

@Component({
    selector:    'gc-duplicate-sidebar',
    templateUrl: './duplicate.sidebar.component.haml',
    styleUrls:   ['./duplicate.sidebar.component.sass'],
  })
export class DuplicateSidebarComponent extends BaseSidebarItem<UserPersonStep> implements OnInit, OnDestroy{
    readonly title: string = "duplicates";
    data: Partial<IUser>[];
  public users: Partial<IUser>[];
  public images: string[];

  constructor(private mainComponent: UserPersonStep, public global: Globals) { super(); }

  ngOnDestroy() {
  }
  ngOnInit() {
    console.log('U:', this.users);
  }
  public compare(person: Partial<IUser>){
    const userIndex = this.users.findIndex(x => x === person);
    const image = this.images.length > userIndex ? this.images[userIndex] : null;
    this.mainComponent.compareDuplicate(person, image);
  }
}
