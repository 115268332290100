import { Component, OnInit } from '@angular/core';
import { ClientSettingsType } from '@vierkant-software/types__api';
import { DisplayType } from 'src/app/modules/calendar/pages/shifts/util';
import { Globals } from 'src/app/services/globals.service';
import { GcSetting } from 'src/services/settings.service';
import { Option } from 'src/util/types/global';


export interface ShiftSettings {
    showProfilePicture?: boolean;
    showAmount?: boolean;
    showDays?: boolean;
    showTimesMonth?: boolean;
    showTimesWeek?: boolean;
    display?: DisplayType;
}

/**
 * @ignore - do not show in documentation
 */
@Component({
    selector:    'gc-shift-settings',
    templateUrl: './gc-shift-settings.component.haml',
    styleUrls:   ['./gc-shift-settings.component.sass'],
})
export class GCShiftSettingsComponent implements OnInit {
    @GcSetting(ClientSettingsType.shift) private settings: ShiftSettings = {};
    constructor(public global: Globals) {}

    public get showAmount(): boolean {
        return this.settings?.showAmount;
    }

    public set showAmount(value: boolean) {
        this.settings.showAmount = value;
    }

    public get showDays(): boolean {
        return this.settings?.showDays;
    }

    public set showDays(value: boolean) {
        this.settings.showDays = value;
    }

    public get showTimesMonth(): boolean {
        return this.settings?.showTimesMonth;
    }

    public set showTimesMonth(value: boolean) {
        this.settings.showTimesMonth = value;
    }

    public get showTimesWeek(): boolean {
        return this.settings?.showTimesWeek;
    }

    public set showTimesWeek(value: boolean) {
        this.settings.showTimesWeek = value;
    }

    public get display(): DisplayType {
        return this.settings?.display;
    }

    public set display(value: DisplayType) {
        this.settings.display = value;
    }
    public get showProfilePicture(): boolean {
        return this.settings?.showProfilePicture;
    }

    public set showProfilePicture(value: boolean) {
        this.settings.showProfilePicture = value;
    }

    public displayOptions: Option<DisplayType>[] = [
        {
            label: "Keine Fotos oder Personenfarben verwenden",
            value: undefined,
        },
        {
            label: "Fotos anzeigen",
            value: "avatar",
        },
        {
            label: "Personenfarben wie Foto anzeigen",
            value: "color",
        },
        {
            label: "Personenfarben als Schichtfarbe verwenden",
            value: "shift_color",
        },
    ];

    public ngOnInit(): void {
    }
}
