import { Component,Input, OnDestroy, OnInit } from '@angular/core';
import { DateTime, Duration } from 'luxon';
import { Subscription } from 'rxjs';
import { AppService } from 'src/services/app.service';

type Value = Date | DateTime | number;
type DateFormat = string | Intl.DateTimeFormatOptions | Record<string, string>;

/**
 * Most of it's features are already implemented in `gc-date`, making this component obsolete.
 * @ignore - do not show in documentation
 */
@Component({
    selector: 'gc-date-range',
    template: '<span>{{ _internvalParsedValue }}{{!_isAllday && suffix ? " " + suffix : ""}}</span>',
})
export class GcDateRangeComponent implements OnInit, OnDestroy {
    @Input() public set useLocale(value: boolean) {
        this._useLocale = value;
        this.updateValue();
    }
    @Input() public set format(value: DateFormat){
        this._internalFormat = value;
        this.updateValue();
    }
    public get format(){
        return this._internalFormat;
    }

    @Input() public suffix: string;
    @Input() public set formatLeft(value: DateFormat){
        this._internalFormatLeft = value;
        this.updateValue();
    }
    public get formatLeft(){
        return this._internalFormatLeft;
    }

    @Input() public set formatRight(value: DateFormat){
        this._internalFormatRight = value;
        this.updateValue();
    }
    public get formatRight(){
        return this._internalFormatRight;
    }

    @Input() public set value(values: [Value?, Value?]) {
        this._internalValue = values.map(value => this.toDateTime(value)) as [DateTime?, DateTime?];
        this.updateValue();
    }

    @Input() public set showToday(value: boolean){
        this._showToday = value;
        this.updateValue();
    }

    @Input() public set showAllDay(value: boolean){
        this._showAllDay = value;
        this.updateValue();
    }

    get locale(){
        return this._useLocale ? this.appSerivce.locale : this._defaultLocale;
    }

    private _internalFormat: string | Intl.DateTimeFormatOptions;
    private _internalFormatLeft: string | Intl.DateTimeFormatOptions;
    private _internalFormatRight: string | Intl.DateTimeFormatOptions;
    private _internalValue: [DateTime?, DateTime?] = [undefined, undefined];
    private _showToday: boolean = false;
    private _showAllDay: boolean = true;
    _internvalParsedValue: string;
    private _useLocale: boolean = true;
    private _defaultLocale: string = "en-US";
    private _localeSubscription: Subscription;
    private _today = DateTime.now().startOf("day");
    _isAllday = false;

    constructor(private appSerivce: AppService){}

    ngOnInit(): void {
        this._localeSubscription = this.appSerivce.$Locale.subscribe(x => {
            if (this.useLocale)
                this._internalValue = this._internalValue?.map(v => v.setLocale(x)) as [DateTime?, DateTime?];
        });

    }

    ngOnDestroy(): void {
        this._localeSubscription?.unsubscribe();
    }

    private toDateTime(input: unknown): DateTime {
        const type = typeof input;
        try {
            if (type === "string")
            return DateTime.fromISO(input as string);
            if (type === "number")
            return DateTime.fromMillis(input as number);
            if (type === "object") {
            if (DateTime.isDateTime(input))
                return input;

            else
                return DateTime.fromJSDate(input as Date);
            }
        } catch(error) {
            console.error(typeof input, input, error);
            return undefined;
        }
        return undefined;
    }

    private formatDateTime(value: DateTime, isLeft: boolean){
        if (this._showToday && value.startOf("day").valueOf() === this._today.valueOf())
            return "Heute";
        const format = isLeft ? (this.formatLeft ?? this.format) : (this.formatRight ?? this.format);
        if (typeof format === "string")
            return value.toFormat(format);
        if (typeof format === "object")
            return value.toLocaleString(format);
        return value.toLocaleString();
    }

    private updateValue() {
        if (!this._internalValue || !this._internalValue[0] || !this._internalValue[1]){
            this._internvalParsedValue = '';
            return;
        }
        const diff = this._internalValue[1].diff(this._internalValue[0]);
        if (this._showAllDay &&
            this._internalValue[0].hour === 0 && diff <= Duration.fromObject({day: 1}) &&
            diff >= Duration.fromObject({hours: 23, minutes: 59})
        ){
            this._internvalParsedValue = "ganztägig";
            this._isAllday = true;
            return;
        }

        this._isAllday = false;
        this._internalValue = this._internalValue?.map(v => v.setLocale(this.locale)) as [DateTime?, DateTime?];
        this._internvalParsedValue = [this.formatDateTime(this._internalValue[0], true), this.formatDateTime(this._internalValue[1], false)].join(' – ');
    }
}
