import { Translation } from "primeng/api";

export const locale_de: Translation = {
    firstDayOfWeek:     1,
    dayNames:           ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sonntag"],
    dayNamesShort:      [ "So", "Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"],
    dayNamesMin:        [ "So", "Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"],
    monthNames:         ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
    monthNamesShort:    ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"],
    today:              "Heute",
    clear:              "Löschen",
    dateFormat:         "dd.mm.yy",
    weekHeader:         "KW",
    weak:               "Schwach",
    medium:             "Mittel",
    strong:             "Stark",
    passwordPrompt:     "Geben Sie das Passwort ein",
    emptyFilterMessage: "Keine Ergebnisse",
    emptyMessage:       "Keine Daten verfügbar",
    accept:             "Ja",
    reject:             "Nein",
    choose:             "Auswählen",
    upload:             "Hochladen",
    cancel:             "Abbrechen",
    apply:              "Anwenden",
    addRule:            "Regel hinzufügen",
    removeRule:         "Regel entfernen",
    after:              "Nach",
    before:             "Vor",
    startsWith:         "Beginnt mit",
    endsWith:           "Endet mit",
    contains:           "Enthält",
    notContains:        "Enthält nicht",
    equals:             "Gleich",
    notEquals:          "Ungleich",
    noFilter:           "Kein Filter",
    dateAfter:          "Datum muss nach {0} sein",
    dateBefore:         "Datum muss vor {0} sein",
    dateIs:             "Datum muss {0} sein",
    dateIsNot:          "Datum darf nicht {0} sein",
    gt:                 "Wert muss größer sein als {0}",
    lt:                 "Wert muss kleiner sein als {0}",
    gte:                "Wert muss größer oder gleich sein als {0}",
    lte:                "Wert muss kleiner oder gleich sein als {0}",
    is:                 "Wert muss {0} sein",
    isNot:              "Wert darf nicht {0} sein",
    matchAll:           "Alle Bedingungen treffen zu",
    matchAny:           "Mindestens eine Bedingung trifft zu",
};
