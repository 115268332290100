import { Order, Pagination, PaginationResult, SearchKey, SearchQueryResult, WithFiles, SearchQuery as SQ } from '@vierkant-software/types__api';
import { AppService } from 'src/services/app.service';
import { DateTime } from 'luxon';
import { Observable, Subject, debounceTime, merge } from 'rxjs';
import { SearchQuery } from './person-search.util';

export class PersonSearchService {
  private static DEBOUNCE_TIME = 250;
  static DEFAULT_FIELDS: Partial<Record<SearchKey, Order>> = {
    firstname: "ASC",
    lastname:  "ASC",
  };
  static DEFAULT_PAGINATION: Pagination = {
    page: 0,
    size: 25,
  };

  private searchSubject: Subject<SearchQuery> = new Subject<SearchQuery>();
  private searchNoDebounceSubject: Subject<SearchQuery> = new Subject<SearchQuery>();
  public searchSubscription: Observable<WithFiles<PaginationResult<SearchQueryResult>>> = merge(
    this.searchSubject.pipe(debounceTime(PersonSearchService.DEBOUNCE_TIME), this._searchObservable()),
    this.searchNoDebounceSubject.pipe(this._searchObservable(),)
  );

  public search(query: SearchQuery){
    this.searchSubject.next(query);
  }

  public searchNoDebounce(query: SearchQuery) {
    this.searchNoDebounceSubject.next(query);
  }
  private _searchObservable(){
    const internalSearch = (x: SearchQuery) => this._searchInternal(x);
    return (source: Observable<SearchQuery>): Observable<WithFiles<PaginationResult<SearchQueryResult>>> =>
      new Observable(subscriber => {
        source.subscribe({
          next(value) {
            internalSearch(value)
              .then((result) => subscriber.next(result))
              .catch((error) => subscriber.error(error));
          },
          error(error) {
            subscriber.error(error);
          },
          complete() {
            subscriber.complete();
          }
        });
      });
  }

  private async _searchInternal(value: SearchQuery) {
    const worker = AppService.instance.api.UserWorker;
    const parseSearchValue = (x: string) => this.parseSearchValue(x);
    if (value === undefined || value === null) return;
      const terms = parseSearchValue(value.term);
      const fields = {...PersonSearchService.DEFAULT_FIELDS, ...value.fields};
      const pagination = value.pagination ?? PersonSearchService.DEFAULT_PAGINATION;
      const withFiles = value.withFiles ?? false;
      const query = {
        terms,
        fields,
        withFiles,
        employee:      value.employee,
        customer:      value.customer,
        workhoursType: value.workhoursType,
        visibilityFor: value.visibilityFor,
        departments:   value.departments,
      } as SQ;

      return worker.search(query, pagination);
  }

  // explode terms into their data types
  private parseSearchValue(value: string): {string: string[], number: number[], dateTime: DateTime[]} {
    const locale = AppService.instance.locale;
    return (value ?? '').split(" ").reduce((curr, val) => {
      const number = ["", null, undefined].includes(val) ? NaN : Number(val);
      const date = DateTime.fromFormat(val, DateTime.parseFormatForOpts(DateTime.DATE_SHORT, {locale}));
      if (!isNaN(number))
        curr.number.push(number);
      else if (date.isValid)
        curr.dateTime.push(date);
      else
        curr.string.push(val);
      return curr;
    }, ({
      string:   [],
      number:   [],
      dateTime: [],
    }));
  }
}
