import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';
/* 
 * Usage:
 * value | console
 * 
 * Example:
 * {{ object | console }}
 */
@Pipe({ name: 'console', standalone: true })

export class ConsolePipe implements PipeTransform {
    transform(value: unknown){
        if (!environment.production)
            console.log("[PIPE]", value);
        return value;
    }
}
