/* eslint-disable max-len */
/* eslint-disable @angular-eslint/no-inputs-metadata-property */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/no-outputs-metadata-property */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { Component, Input, ViewEncapsulation } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { MultiSelect } from "primeng/multiselect";
import { WrapperComponent } from "../base";
import { Wrapper } from "../decorators";

@Component({
    selector:      'gc-multiSelect',
    encapsulation: ViewEncapsulation.None,
    template:      `<label *ngIf="gcLabel !== undefined" [for]="__internal_ID">{{ gcLabel }}</label>
                    <p-multiSelect [formControl]="gcControl" [id]="__internal_ID">
                        <ng-content></ng-content>
                        <ng-container  *ngFor="let template of templates">
                            <ng-template pTemplate="{{template.getType()}}">
                                <ng-container *ngTemplateOutlet="template.template"></ng-container>
                            </ng-template>
                        </ng-container>
                    </p-multiSelect>`,
    providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: GcMultiSelectComponent, multi: true }],
    inputs:    ["appendTo", "ariaFilterLabel", "ariaLabelledBy", "autofocusFilter", "autoZIndex", "baseZIndex", "defaultLabel", "dataKey", "disabled", "displaySelectedLabel",
        "dropdownIcon", "emptyFilterMessage", "filter", "filterMatchMode", "filterValue", "filterLocale", "filterBy", "filterPlaceHolder", "hideTransitionOptions", "inputId",
        "maxSelectedLabels", "name", "options", "optionLabel", "optionValue", "optionDisabled", "optionGroupLabel", "optionGroupChildren", "group", "overlayVisible",
        "panelStyle", "placeholder", "readonly", "emptyMessage", "emptyFilterMessage", "resetFilterOnHide", "scrollHeight", "selectedItemsLabel", "selectionLimit",
        "showHeader", "showHeader", "showTransitionOptions", "showToggleAll", "style", "styleClass", "tabindex", "tooltip", "tooltipStyleClass", "tooltipPosition",
        "tooltipPositionStyle", "showClear", "virtualScroll", "virtualScrollItemSize", "virtualScrollOptions", "lazy"
    ],
    outputs: [
        "onClick", "onChange", "onFilter", "onFocus", "onBlur", "onPanelShow", "onPanelHide", "onClear", "onLazyLoad"
    ]
})
@Wrapper(MultiSelect)
export class GcMultiSelectComponent extends WrapperComponent {
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('label') gcLabel: string;
}
export interface GcMultiSelectComponent extends MultiSelect {};
