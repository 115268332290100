import { IPageOptions } from "src/app/page.interface";
import { Constructor } from "src/util/types/global";

export class PageOptionsRegistry {
  /** @internal */
  static pageOptions: Map<Constructor<unknown>, IPageOptions<unknown>> = new Map();

  /** @internal */
  static registerPageOptions<T>(page: Constructor<T>, options: IPageOptions<T>) {
    PageOptionsRegistry.pageOptions.set(page, options as IPageOptions<unknown>);
  }
}

export function PageOptions<T>(options: IPageOptions<T>) {
    return (target: Constructor<T>) => {
        PageOptionsRegistry.registerPageOptions<T>(target, options);
    };
}
