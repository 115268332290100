import { ChangeDetectionStrategy, Component, computed, effect, input, output, signal } from '@angular/core';
import { IGenericStatsItemInfo, IGenericStatsList, IGenericStatsResult, StatsFormatType } from '@vierkant-software/types__api';
import { TableLazyLoadEvent } from 'primeng/table';
import { Globals } from 'src/app/services/globals.service';

interface FEIGenericStatsList extends IGenericStatsList {
  footer?: IGenericStatsItemInfo[];
}
@Component({
  selector:        'gc-generic-table',
  templateUrl:     './gc-generic-table.component.haml',
  styleUrls:       ['./gc-generic-table.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GcGenericTableComponent {
  colSpan = signal<number>(undefined);
  genericStats = input(undefined, {transform: (value: IGenericStatsResult | FEIGenericStatsList) => {
      let column = 0;
      for (let i = 0; i < value.header.headers.length; i++) {
        if (value.header.headers[i].subHeaders?.length > 0) {
          for (let j = 0; j < value.header.headers[i].subHeaders.length; j++)
            value.header.headers[i].subHeaders[j].index = column++;
        }
        else {
          column++;
        }
      }
      this.colSpan.set(column);
      return value;
    }
  });
  // hasFooter = computed(() => {
  //   return this.genericStats().footer !== undefined;
  // });
  displayButton = input<boolean>(false);
  detailDialog = input<boolean>(false);
  currentDetailUser = signal<string>(undefined);
  enums = input(undefined);
  dictionaries = input(undefined);
  actions = input(undefined);
  freezeActions = input(false);
  hasSubheader = input<boolean>(false);
  confirmationText = input<string>(undefined);
  records = computed(() => this.genericStats().header.records);
  selectedData = computed(() => this.genericStats()?.stats); //IGenericStatsData
  selectedMainColumns = computed(() => {
    return this.genericStats().header.headers.map((x,i) => ({...x, index: i}));
  });
  test = effect(() => console.log('SD: ', this.selectedData()));
  //FIXME once we got angular 18.1
  pagination = computed(() => {
    const tmp = this.genericStats();
    if ('pagination' in tmp)
      return tmp.pagination;
  });

  // maybe needed later
  // contextMenuItems = computed(() => Object.values(this.actions()).map(x => Object.values(x)).flat(1));

  //TODO document it -> how to do callback with backend call
  rowClick = output<{element: IGenericStatsItemInfo, event: MouseEvent, index: number}>();
  pageChange = output<unknown>();

  constructor(public globals: Globals) { }

  getNumberFormat(value: number) {
    const germanFloat = Intl.NumberFormat('de-DE', {minimumFractionDigits: 2});
    return germanFloat.format(value);
  }

  asFormat(format: StatsFormatType) {
    const dt = Globals.DateTime;
    return Globals.DateTime[format as keyof typeof dt];
  }

  getOptions(data: string[], recordIndex: string) {
    const records = this.genericStats().header.records[recordIndex];
    const defs = this.dictionaries()[recordIndex];
    let res = data.slice(0, (defs.display)).map(element => defs.format(records[element])).join(', ');

    if (data.length > defs.display)
      res += ' und ' + (data.length - defs.display) + ' weitere';

    return res;
  }

  rowClicked(element: IGenericStatsItemInfo, event: MouseEvent, index: number) {
    console.log('rowClicked', element);
    this.rowClick.emit({element, event, index});
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  download(content: any, event: any) {
    event.stopPropagation();
    this.actions()[content.type][content.format].command(content.data[content.index]);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  delete(content: any, event: any) {
    event.stopPropagation();
    this.actions()[content.type][content.format].command(content.data[content.index]);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onLazyLoad(event: TableLazyLoadEvent){
    const page = Math.floor(event.first / event.rows);
    this.pageChange.emit({page: page, size: event.rows});
  }

}
