import { Component, ComponentRef, HostBinding, InjectionToken, Injector, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { distinctUntilChanged } from 'rxjs/operators';
import { AppService } from 'src/services/app.service';
import { Constructor } from 'src/util/types/global';
import { IPage } from '../page.interface';
import { BaseSidebarItem } from './base.sidebar.item';

export const PARENT_COMPONENT = new InjectionToken("PARENT_COMPONENT");

@Component({
  selector:    'gc-sidebar',
  templateUrl: './siderbar.component.haml',
  styleUrls:   ['./siderbar.component.sass'],
})
export class SidebarComponent {
  @HostBinding('attr.role') role = "complementary";
  @Input()
  get visible(): boolean {
    return this.appService.pageService.showRightSidebar;
  }
  set visible(value: boolean) {
    this.appService.pageService.setOption({showRightSidebar: value});
  }

  @ViewChild('host', { read: ViewContainerRef }) host!: ViewContainerRef;

  static instance: SidebarComponent;
  private mainComponent?: IPage;

  constructor(private appService: AppService, private injector: Injector) {
    SidebarComponent.instance = this;
    this.appService.$component.pipe(distinctUntilChanged()).forEach(x => {
      this.mainComponent = x;
      //this.visible = this.mainComponent?.showRightSidebar === true;
      this.clear();
    }).catch(e => null);
  }

  addItem<T extends BaseSidebarItem<IPage>>(component: Constructor<T>, data: Partial<T>): T;
  addItem<S, T extends BaseSidebarItem<S>>(component: Constructor<T>, data: Partial<T>, sourceComponent: S): T;
  addItem<S, T extends BaseSidebarItem<S | IPage>>(component: Constructor<T>, data: Partial<T> = {}, sourceComponent?: S): T {
    const src = sourceComponent || this.mainComponent;
    const childInjector = Injector.create({ providers: [
      { provide: PARENT_COMPONENT, useValue: src },
      { provide: src.constructor, useValue: src }
    ], parent: this.injector });
    const componentRef = this.host.createComponent<T & {_componentRef: ComponentRef<T>}>(component, { injector: childInjector });
    componentRef.instance._componentRef = componentRef;
    //componentRef.instance._mainComponent = sourceComponent || this.mainComponent;
    return Object.assign(componentRef.instance, data);
  }

  clear() {
    this.host?.clear();
  }
}
