import { BaseError } from "./base-error";

export class MissingResource extends BaseError {
    status = "CALENDAR:NORESOURCE";
    constructor(type: string = "Resource"){
        super();
        this.args = {
            type
        };
    }
}
