/* eslint-disable max-len */
/* eslint-disable @angular-eslint/no-inputs-metadata-property */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/no-outputs-metadata-property */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { Component, ContentChildren, QueryList, ViewEncapsulation } from "@angular/core";
import { Panel } from "primeng/panel";
import { WrapperBase } from "../base";
import { Wrapper } from "../decorators";
import { GcTemplate } from "../directives/gcTemplate";

@Component({
    selector:      'gc-panel',
    encapsulation: ViewEncapsulation.None,
    template:      `<p-panel  #component>
                    <ng-content></ng-content>
                    <ng-container  *ngFor="let template of templates">
                        <ng-template pTemplate="{{template.getType()}}">
                            <ng-container *ngTemplateOutlet="template.template"></ng-container>
                        </ng-template>
                    </ng-container>
               </p-panel >`,
    inputs:  ["header", "toggleable", "collapsed", "style", "styleClass", "expandIcon", "collapseIcon", "showHeader", "transitionOptions", "toggler", "iconPos"],
    outputs: ["onBeforeToggle", "onAfterToggle"]
})
@Wrapper(Panel)
export class GcPanelComponent extends WrapperBase {
    @ContentChildren(GcTemplate) templates: QueryList<GcTemplate>;
}
export interface GcPanelComponent extends Panel {}
