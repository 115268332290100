
import { Routes } from "@angular/router";
import { FrontendPermissions } from "@vierkant-software/types__api";
import { AuthGuard } from "src/services/auth.guard";

export const additional_routes: Routes = [
  {
    path:         'demo',
    loadChildren: () => import('./demo-playground/demo.module').then(m => m.DemoPageModule),
    canActivate:  [AuthGuard],
    data:         {
      permissions: [FrontendPermissions.loginAdmin, 42],   // FIXME proper permission
    },
  },
];
