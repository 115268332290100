/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { NgForOf, NgForOfContext } from "@angular/common";
import { Directive, EmbeddedViewRef, Input, IterableChangeRecord, IterableChanges, IterableDiffers, NgIterable,
    OnChanges, TemplateRef, TrackByFunction, ViewContainerRef } from "@angular/core";


// Testcase
/*
.col-10.col-offset-1{ *gcFor: "let x of testarray; extra: testextra; extraKey: 'y'; extraKeyMap: 'z', \
 extra as e; sort: sort; sortBy: 's', index as i, loopCounter as lc, odd as odd, even as even" }

  testarray = [
    {x: 'A', y: 0, s: 4 },
    {x: 'B', y: 1, s: 20 },
    {x: 'C', y: 2, s: 2 },
    {x: 'D', y: 3, s: 1 },
  ];
  testextra = [
    { z: 0, y: 'X', n: 3 },
    { z: 1, y: 'Y', n: 5 },
    { z: 2, y: 'Z', n: 4 }
  ];
  testextra2 = {
    0: 'X',
    1: 'Y',
    2: 'Z'
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sort(a: any, b: any, c: any, d: any, by: string) {
    console.log(a,b,c,d,by);
    return (c?.n ?? 100) - (d?.n ?? 100);
  }
*/

class GcForOfContext<T, T2, T3, U extends NgIterable<T> = NgIterable<T>> extends NgForOfContext<T, U> {
    public extra?: T2;
    public loopCounter?: number;
    constructor(
        public $implicit: T,
        public gcForOf: U,
        public index: number,
        public count: number,
        public ref: GcForOfDirective<T, T2, T3, U>
    )
    {
        super($implicit, gcForOf, index, count);
    }

    get first(): boolean { return (this.loopCounter ?? this.index) === 0; }
    get last(): boolean { return (this.loopCounter ?? this.index) === this.count - 1; }
    get even(): boolean { return (this.loopCounter ?? this.index) % 2 === 0; }
}

@Directive({ selector: '[gcFor][gcForOf]' })
export class GcForOfDirective<T, T2, T3, U extends NgIterable<T> = NgIterable<T>>
        extends NgForOf<T, U> implements OnChanges {

    @Input() gcForOf: U & NgIterable<T> | undefined | null;
    @Input() gcForTrackBy: TrackByFunction<T>;
    @Input() gcForTemplate: TemplateRef<GcForOfContext<T, T2, T3, U>>;

    @Input() gcForExtra: Record<string | number, T2> | T2[] = undefined;
    @Input() gcForExtraKey: string | null = undefined;
    @Input() gcForExtraKeyMap: string | null = undefined;  //Only for gcForExtra instaceof Array

    @Input() gcForSort: ((a: T, b: T, ae: T2, be: T2, sortBy: T3) => number) = undefined;
    @Input() gcForSortBy: T3 = undefined;

    private _extra: Record<string | number, T2> = {};
    private _forSort: string = undefined;
    private _forSortBy: unknown = undefined;

    private _mapping: number[] = undefined;


    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ngOnChanges(values: any) {
        let sort = false;
        let forceApply = true;
        if (values.gcForOf) {
            if (this.ngForOf !== this.gcForOf)
                sort = true;
            this.ngForOf = this.gcForOf;
        }
        if (values.gcForTrackBy)
            this.ngForTrackBy = this.gcForTrackBy;
        if (values.gcForTemplate)
            this.ngForTemplate = this.gcForTemplate;

        if (this.gcForExtra && this.gcForExtraKey !== undefined && (values.gcForExtra || values.gcForExtraKeyMap)) {
            if (Array.isArray(this.gcForExtra)) {
                this._extra = Object.fromEntries(
                    this.gcForExtra.map(x => [this.getObjectPathValue(x as Record<string | number, unknown>, this.gcForExtraKeyMap), x])
                                    .filter(x => x[0] !== undefined)
                );
            } else {
                console.log('gcForExtra', this.gcForExtra);
                this._extra = this.gcForExtra as Record<string | number, T2>;
            }
            sort = true;
            forceApply = true;
        }
        if (this._forSort !== values.gcForSort || this._forSortBy !== values.gcForSortBy) {
            this._forSort = values.gcForSort;
            this._forSortBy = values.gcForSortBy;
            sort = true;
        }
        if (sort)
            this.doSort();
        if (sort || forceApply)
            this.__applyChanges();
    }

    doSort() {
        if (!this.gcForSort) return;
        if (!Array.isArray(this.gcForOf)) {
            console.error('gForOf must be of type Array if sort is used');
        } else {
            const forOf = this.gcForOf;
            // console.log('forOf:', ...forOf.map((x,i) => [i, (<FormGroup>x).value, this.getExtra(x)]));
            const mapping = Array.from(this.gcForOf.keys()).sort(
                (a, b) => this.gcForSort(forOf[a], forOf[b], this.getExtra(forOf[a]), this.getExtra(forOf[b]), this.gcForSortBy)
            );
            this.ngForOf = mapping.map(x => forOf[x]) as U & NgIterable<T>;
            this._mapping = mapping;
        }
    }

    constructor(
        private viewContainer: ViewContainerRef,
        private template: TemplateRef<GcForOfContext<T,T2, T3, U>>,
        differs: IterableDiffers)
    {
        super(viewContainer, template, differs);
        // eslint-disable-next-line @typescript-eslint/dot-notation
        this['_applyChanges'] = this.__applyChanges;
    }



    // Overrides this._applyChanges
    protected __applyChanges(changes?: IterableChanges<T>) {
        const viewContainer = this.viewContainer;
        changes?.forEachOperation(
            (item: IterableChangeRecord<T>, adjustedPreviousIndex: number | null, currentIndex: number | null) => {
                if (item.previousIndex == null) {
                    // NgForOf is never "null" or "undefined" here because the differ detected
                    // that a new item needs to be inserted from the iterable. This implies that
                    // there is an iterable value for "_ngForOf".
                    viewContainer.createEmbeddedView(
                        this.template, new GcForOfContext<T, T2, T3, U>(item.item, this.ngForOf!, -1, -1, this),
                        currentIndex === null ? undefined : currentIndex);
                } else if (currentIndex == null) {
                    viewContainer.remove(
                        adjustedPreviousIndex === null ? undefined : adjustedPreviousIndex);
                } else if (adjustedPreviousIndex !== null) {
                    const view = viewContainer.get(adjustedPreviousIndex)!;
                    viewContainer.move(view, currentIndex);
                    this.applyViewChange(view as EmbeddedViewRef<GcForOfContext<T, T2, T3, U>>, item);
                }
            });

        for (let i = 0, ilen = viewContainer.length; i < ilen; i++) {
            const viewRef = <EmbeddedViewRef<GcForOfContext<T, T2, T3, U>>>viewContainer.get(i);
            const context = viewRef.context;
            context.index = this.getIndex(i);
            context.loopCounter = i;
            context.extra = this.getExtra(context.$implicit);
            context.count = ilen;
            context.ngForOf = this.ngForOf!;
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        changes?.forEachIdentityChange((record: any) => {
            const viewRef = <EmbeddedViewRef<GcForOfContext<T, T2, T3, U>>>viewContainer.get(record.currentIndex);
            this.applyViewChange(viewRef, record);
        });
    }

    private getObjectPathValue(obj: Record<string | number, unknown>, path: string | null): unknown {
        if (path === null) return obj;
        const parts = path.split('.');
        return parts.reduce((prev, curr) => prev?.[curr], obj);
    }

    private getIndex(index: number): number {
        return this._mapping?.[index] ?? index;
    }

    private getExtra(item: T): T2 {
        if (this.gcForExtraKey === undefined)
            return undefined;
        else
            return this._extra[this.getObjectPathValue(item as Record<string | number, unknown>, this.gcForExtraKey) as string] as T2;
    }

    private applyViewChange(view: EmbeddedViewRef<GcForOfContext<T, T2, T3, U>>, record: IterableChangeRecord<T>) {
        view.context.$implicit = record.item;
    }

}


