/* eslint-disable max-len */
import { Component, Input, OnInit } from "@angular/core";
import { BaseSidebarItem } from "../../base.sidebar.item";
import {
    AbsenceVerificationType,
    IAbsenceSetInfo,
    IAbsenceStandInInfo,
    IAbsenceVerification,
    IUser,
    IUserAbsenceCreditAccountInfo,
    IUserAbsenceInfo,
    UserAbsenceInfoStatus
} from "@vierkant-software/types__api";
import { DateTime } from "luxon";
import { AppService } from "src/services/app.service";
import { Calendar } from "@fullcalendar/core";
import { sortBy } from "src/util/sort";
import { dayDiff } from "src/util/dateTime";

@Component({
    selector:    'gc-absence-sidebar',
    templateUrl: './absence.sidebar.component.haml',
    styleUrls:   ['./absence.sidebar.component.sass'],
})
export class GcAbsenceSidebarComponent extends BaseSidebarItem<never> implements OnInit {
    private _absences: Record<string, IAbsenceSetInfo> = {};
    private _date: DateTime;

    @Input() public onChange: () => void | Promise<void>;
    @Input() public onAbsenceSelect: (absence: IUserAbsenceInfo) => void | Promise<void>;
    @Input() public calendar: Calendar;
    public data: [DateTime, IUserAbsenceInfo[]][] = [];
    public filterOnlyOpen: boolean;
    @Input() public name: string = "Meldungen & Anträge aus der App";
    public selectedAbsence: IUserAbsenceInfo;
    public selectedStandIn: IAbsenceStandInInfo;
    public showEditDialog: boolean;
    public user: IUser;
    public userCreditAccount: IUserAbsenceCreditAccountInfo;
    public maxDays?: number;
    public confirmedDays?: number;
    public requestDays?: number;
    public filterOnlyWithStandIn: boolean;
    isStandInRemarkShown: boolean;
    absencesInTimeRange: Record<string,IUserAbsenceInfo[]> = {};

    constructor(private readonly app: AppService){
        super();
    }

    ngOnInit(): void {
        this.loadData().catch(console.error);
    }

    @Input() public set absences(value: IAbsenceSetInfo[]) {
        this._absences = value.reduce((curr, val) => {
            curr[val.ID] = val;
            return curr;
        }, this._absences);
    }

    public get date(): DateTime {
        return this._date;
    }

    public set date(value: DateTime) {
        this._date = value;
        this.loadData().catch(console.error);
    }

    public async acceptRequestSidebar(absence: IUserAbsenceInfo){
        try {
            const id = (absence as IUserAbsenceInfo).ID;
            await this.app.api.AbsenceWorker.confirmUserAbsenceInfo(id);
            this.showEditDialog = false;
            await this.loadData();
            if (this.onChange)await this.onChange();
        } catch(error) {
            this.app.messageService.add({severity: "error", summary: "Antrag konnte nicht akzeptiert werden.", detail: error.msg});
        }
    }

    public async acceptRequest(absence: IUserAbsenceInfo){
        if (absence.requestedChanges && 'status' in absence.requestedChanges && absence.requestedChanges.status === UserAbsenceInfoStatus.requestCancel) {
            try {
                const id = (absence as IUserAbsenceInfo).ID;
                await this.app.api.AbsenceWorker.confirmUserAbsenceInfo(id);
                this.showEditDialog = false;
                await this.loadData();
                if (this.onChange)await this.onChange();
            } catch(error) {
                this.app.messageService.add({severity: "error", summary: "Antrag konnte nicht akzeptiert werden.", detail: error.msg});
            }
        }
        else {
            try {
                const data = absence as IUserAbsenceInfo;
                if (absence.requestedChanges) {
                    // the backend does not consider "requestedChanges" and we have to apply the properties manually in order to overwrite 
                    const changes = data.requestedChanges;
                    data.start = changes.start;
                    data.end = changes.end;
                    data.isHalfDayAtBegin = changes.isHalfDayAtBegin;
                    data.isHalfDayAtEnd = changes.isHalfDayAtEnd;
                    data.days = changes.days;
                }

                await this.app.api.AbsenceWorker.upsertUserAbsenceInfo(data, data.ID);
                this.showEditDialog = false;
                await this.loadData();
                if (this.onChange)await this.onChange();
            } catch(error) {
                this.app.messageService.add({severity: "error", summary: "Antrag konnte nicht akzeptiert werden.", detail: error.msg});
            }
        }
    }

    public areSameDay(absence: IUserAbsenceInfo){
        return absence.start.hasSame(absence.end, "day");
    }

    public isThereStandIn(absence: IUserAbsenceInfo){
        return !!absence.standIns?.length;
    }

    public async editRequest(absence: Partial<IUserAbsenceInfo>){
        this.selectedAbsence = await this.app.api.AbsenceWorker.getUserAbsenceInfo(absence.ID);
        this.confirmedDays = this.selectedAbsence.days;
        this.requestDays = this.selectedAbsence.requestedChanges?.days;
        this.maxDays = this.selectedAbsence.requestedChanges?.start && this.selectedAbsence.requestedChanges.end ?
            dayDiff([this.selectedAbsence.requestedChanges.start, this.selectedAbsence.requestedChanges.end]) :
            dayDiff([this.selectedAbsence.start, this.selectedAbsence.end]);
        this.user = (await this.app.api.UserWorker.getUser(absence.userId))?.data;

        const absenceSet = !absence.absenceSetID ? null : await this.app.api.AbsenceWorker.getAbsenceSet(absence.absenceSetID);
        if (absenceSet?.hasCreditAccount)
            this.userCreditAccount = await this.app.api.AbsenceWorker.getUsersAbsenceCreditAccountInfo(absence.userId, absence.start.year, absenceSet.ID);

        const userIDs = (this.selectedAbsence.standIns ?? []).filter(s => s.user?.ID).map(s => s.user.ID);
        if (userIDs.length) {
            this.absencesInTimeRange = await this.app.api.AbsenceWorker.getAbsencesInTimeRange(userIDs,
            this.selectedAbsence.start,this.selectedAbsence.end ?? this.selectedAbsence.start);
        } else {
            this.absencesInTimeRange = {};
        }

        this.showEditDialog = true;
    }

  //for the stand-ins
    isAbsenceInRangeConfirmed(absenceInRangeStatus: UserAbsenceInfoStatus){
        if ([UserAbsenceInfoStatus.confirmed, UserAbsenceInfoStatus.registered].includes(absenceInRangeStatus))
            return true;
        else
            return false;
    }

    public openStandInRemark(standIn: IAbsenceStandInInfo){
        this.selectedStandIn = standIn;
        this.isStandInRemarkShown = true;
    }

    closeStandInRemarks(){
        this.selectedStandIn = undefined;
        this.isStandInRemarkShown = false;
    }

    public getAbsence(id: string){
        return this._absences[id];
    }

    public getAbsenceHeader(absence: IUserAbsenceInfo){
        if (!absence) return "";
        const createdBy = absence.createdByName;
        const createdAt = absence.created_at?.setLocale(this.app.locale);
        const created = `Angelegt von ${createdBy} am ${createdAt.toFormat('D')} um ${createdAt.toFormat('t')} Uhr.`;

        // (Zuletzt geändert von XXX am XXX um XX:XX Uhr)
        const modifiedBy = absence.modifiedByName;
        const modifiedAt = absence.modified_at?.setLocale(this.app.locale);
        let modified = '';
        if (modifiedBy && modifiedAt)
            modified = `Zuletzt geändert von ${modifiedBy} am ${modifiedAt.toFormat('D')} um ${modifiedAt.toFormat('t')} Uhr.`;

        let deleted = '';
        const deleteAt = absence.requestedChanges?.requestedAt?.setLocale(this.app.locale);
        if (absence.requestedChanges?.status === UserAbsenceInfoStatus.requestCancel && deleteAt)
            deleted = `Stornierung beantragt am ${deleteAt.toFormat('D')} um ${deleteAt.toFormat('t')} Uhr.`;
        return `(${[created, modified, deleted].map(x => x.trim()).join(' ')})`;
    }

    public getActionText(absence: IUserAbsenceInfo){
        switch(this.getStatus(absence)) {
            case 'request':
                return "Bestätigen";
            case 'change':
                return "Ändern zu";
            case 'deletion':
                return "Stornieren";
            default:
                break;
        }
    }

    public getEndsWithHalfDay(bool: boolean){
        return bool ? "Endet mit halbem Tag" : "Endet NICHT mit halbem Tag";
    }

    public getGroupSize(group: [DateTime, IUserAbsenceInfo[]]){
        return group[1]?.filter(x => (!this.filterOnlyWithStandIn || !!x.standIns?.length) && (!this.filterOnlyOpen || !this.isConfirmed(x))).length ?? 0;
    }

    public getStartsWithHalfDay(bool: boolean){
        return bool ? "Startet mit halbem Tag" : "Startet NICHT mit halbem Tag";
    }

    private getStatus(absence: IUserAbsenceInfo) {
        if ([UserAbsenceInfoStatus.request, UserAbsenceInfoStatus.reported].includes(absence.status) && !absence.requestedChanges) return "request";
        if (absence.requestedChanges?.status === UserAbsenceInfoStatus.request &&
            [UserAbsenceInfoStatus.registered, UserAbsenceInfoStatus.confirmed, UserAbsenceInfoStatus.reported].includes(absence.status)) return "change";
        if (absence.requestedChanges?.status === UserAbsenceInfoStatus.requestCancel ||
            [UserAbsenceInfoStatus.requestCancel, UserAbsenceInfoStatus.reported, UserAbsenceInfoStatus.registered].includes(absence.status))  return "deletion";
        return undefined;
    }

    public isRequest(absence: IUserAbsenceInfo){
        const status = this.getStatus(absence);
        return  status === "request";
    }

    public isChange(absence:  IUserAbsenceInfo){
        const status = this.getStatus(absence);
        return  status === "change";
    }

    public isDeletion(absence: IUserAbsenceInfo){
        const status = this.getStatus(absence);
        return  status === "deletion";
    }

    public isConfirmed(absence: IUserAbsenceInfo) {
        return (absence.status === UserAbsenceInfoStatus.confirmed
            || absence.status === UserAbsenceInfoStatus.registered
            || absence.status === UserAbsenceInfoStatus.reported) && !absence.requestedChanges;
    }

    public getDialogHeader(absence: IUserAbsenceInfo){
        if (this.isDeletion(absence))
            return "Stornierung";
        if (absence.requestedChanges && this.isChange(absence as IUserAbsenceInfo))
            return "Abwesenheit editieren";
        return "Neuer Antrag";
    }

    public async jumpToAbsence(absence: IUserAbsenceInfo){
        await this.onAbsenceSelect?.(absence);
        this.calendar?.gotoDate(absence.start.toJSDate());
    }

    public onCloseDialog(){
        this.user = undefined;
        this.maxDays = undefined;
        this.confirmedDays = undefined;
        this.requestDays = undefined;
        this.userCreditAccount = undefined;
    }

    public async rejectRequest(absence: IUserAbsenceInfo){
        const id = (absence as IUserAbsenceInfo).ID;
        await this.app.api.AbsenceWorker.rejectUserAbsenceInfo(id, true);
        this.showEditDialog = false;
        await this.loadData();
        if (this.onChange)await this.onChange();
    }

    private setEarliestDate(){
        this._date = sortBy(this.data, x => x[0].valueOf()).shift()?.[0] ?? DateTime.now().startOf("day");
    }

    private async loadData(){
        const result = await this.app.api.AbsenceWorker.getRequestedAbsencesV2(false, this.date);
        const tmp = (result.reduce((curr,val) => {
            const day = (val.requestedChanges?.requestedAt ?? val.confirmedAt ?? val.start).startOf("day").valueOf();
            if (!curr[day])
                curr[day] = [];
            curr[day].push(val);
            return curr;
        }, <Record<number, IUserAbsenceInfo[]>>{}));
        this.data = sortBy(Object.keys(tmp).map(x => [DateTime.fromMillis(Number(x), {locale: this.app.locale}), tmp[Number(x)]]), x => x[0]);
        if (!this.date)this.setEarliestDate();
    }

    public hasVerificationRequirement(verification: IAbsenceVerification){
        return [AbsenceVerificationType.alwaysRequired, AbsenceVerificationType.requiredAfter].includes(verification?.type);
    }

}
