import { Duration, DurationLikeObject, ToHumanDurationOptions } from "luxon";
import { AppService } from "src/services/app.service";

//TODO: use Intl.DateTimeFormat-literals for correct localization
export type Format = string | ToHumanDurationOptions;

export function formatTime(value: Duration, format: Format = "h'h' m'min'", units?: (keyof DurationLikeObject)[], locale?: string, rescale?: boolean){
    if (!value)
        return '';
    value = configureValue(value, units, locale, rescale);
    if (typeof format === "string")
        return value.toFormat(format);
    if (typeof format === "object")
        return value.toHuman(format);
    return value.toLocaleString();
}

function configureValue(value: Duration, units?: (keyof DurationLikeObject)[], locale?: string, rescale: boolean = true) {
    value = value?.reconfigure({locale: locale ?? AppService.instance.locale});
    if (units) value = value.shiftTo(...units).normalize();
    if (rescale) value = value.rescale();
    return value;
}
