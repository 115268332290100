// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.department-group {
  padding: 0.5rem !important;
  font-weight: 600;
  font-size: 1rem;
  background: var(--gc-color-light);
}

.department-checkbox {
  width: 3rem;
}

.department-entry {
  padding: 0.5rem 0 !important;
}

.user-selection {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.scroll-container {
  flex-grow: 1;
  position: relative;
}
.scroll-container .scroll-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: overlay;
}`, "",{"version":3,"sources":["webpack://./src/app/components/gc-shift-settings/gc-shift-settings.component.sass"],"names":[],"mappings":"AAAA;EACI,0BAAA;EACA,gBAAA;EACA,eAAA;EACA,iCAAA;AACJ;;AACA;EACI,WAAA;AAEJ;;AADA;EACI,4BAAA;AAIJ;;AAFA;EACI,YAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AAKJ;;AAHA;EACI,YAAA;EACA,kBAAA;AAMJ;AALI;EACI,kBAAA;EACA,MAAA;EACA,QAAA;EACA,SAAA;EACA,OAAA;EACA,iBAAA;AAOR","sourcesContent":[".department-group\n    padding: 0.5rem !important\n    font-weight: 600\n    font-size: 1rem\n    background: var(--gc-color-light)\n\n.department-checkbox\n    width: 3rem\n.department-entry\n    padding: 0.5rem 0 !important\n\n.user-selection\n    height: 100%\n    display: flex\n    flex-direction: column\n    gap: 1rem\n\n.scroll-container\n    flex-grow: 1\n    position: relative\n    .scroll-content\n        position: absolute\n        top: 0\n        right: 0\n        bottom: 0\n        left: 0\n        overflow: overlay"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
