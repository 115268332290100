/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/no-outputs-metadata-property */
/* eslint-disable @angular-eslint/no-inputs-metadata-property */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Button } from "primeng/button";
import { WrapperBase } from "../base";
import { Wrapper } from "../decorators";

@Component({
    selector:      'gc-button',
    encapsulation: ViewEncapsulation.None,
    template:      '<p-button #component><ng-content></ng-content></p-button>',
    providers:     [{ provide: Button, useExisting: GcButtonComponent }],
    inputs:        [
        "label", "type", "icon", "iconPos", "badge", "badgeClass", "loading", "loadingIcon", "disabled", "style", "styleClass", "ariaLabel", "text"
    ],
    outputs: ["onClick", "onFocus", "onBlur"]
})
@Wrapper(Button)
export class GcButtonComponent extends WrapperBase implements OnInit {
}
export interface GcButtonComponent extends Button {}
