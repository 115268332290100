// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(250px, 100%), min-content));
  gap: 1rem;
}

.single-select {
  display: grid;
  gap: 0.125rem;
  cursor: pointer;
  border: 1px solid var(--gc-border-color);
  border-radius: 4px;
  background: var(--gc-color-light-tint);
  padding: 0.5rem 0.75rem;
}
.single-select.selected {
  background: var(--gc-color-primary);
  color: var(--gc-text-color-inverse);
}
.single-select.selected:hover {
  background: var(--gc-color-primary-shade);
  box-shadow: var(--gc-box-shadow);
}
.single-select:hover {
  box-shadow: var(--gc-box-shadow);
  background: var(--gc-color-light);
}
.single-select .text {
  display: block;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.single-select p-chip {
  display: block;
  justify-self: flex-end;
}
.single-select p-chip .p-chip {
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/components/gc-single-select/gc-single-select.component.sass"],"names":[],"mappings":"AACA;EACI,aAAA;EACA,8EAAA;EACA,SAAA;AAAJ;;AAEA;EACI,aAAA;EACA,aAAA;EACA,eAAA;EACA,wCAAA;EACA,kBAAA;EACA,sCAAA;EACA,uBAAA;AACJ;AAAI;EACI,mCAAA;EACA,mCAAA;AAER;AADQ;EACI,yCAAA;EACA,gCAAA;AAGZ;AAFI;EACI,gCAAA;EACA,iCAAA;AAIR;AAHI;EACI,cAAA;EACA,iBAAA;EACA,uBAAA;EACA,gBAAA;EACA,mBAAA;AAKR;AAJI;EACI,cAAA;EACA,sBAAA;AAMR;AALQ;EACI,UAAA;AAOZ","sourcesContent":["\n.list\n    display: grid\n    grid-template-columns: repeat(auto-fit, minmax(min(250px, 100%), min-content))\n    gap: 1rem\n \n.single-select\n    display: grid\n    gap: 0.125rem\n    cursor: pointer\n    border: 1px solid var(--gc-border-color)\n    border-radius: 4px\n    background:  var(--gc-color-light-tint)\n    padding: 0.5rem 0.75rem\n    &.selected\n        background: var(--gc-color-primary)\n        color: var(--gc-text-color-inverse)\n        &:hover\n            background: var(--gc-color-primary-shade)\n            box-shadow: var(--gc-box-shadow)\n    &:hover\n        box-shadow: var(--gc-box-shadow)\n        background: var(--gc-color-light)\n    .text\n        display: block\n        font-weight: bold\n        text-overflow: ellipsis\n        overflow: hidden\n        white-space: nowrap\n    p-chip\n        display: block\n        justify-self: flex-end\n        .p-chip\n            padding: 0\n\n\n\n        "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
