import {
    Component,
    ViewEncapsulation,
    forwardRef,
    OnDestroy,
    Input,
    ViewChild,
    TemplateRef,
    OnInit,
    Output,
    EventEmitter,
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { PersonSearchService } from "./../person-search.service";
import {
    IDepartmentDTO,
    ISearchQueryEmployee,
    Order,
    Pagination,
    SearchKey,
    SearchQueryResult,
    VisibilityName,
    WorkHoursType
} from "@vierkant-software/types__api";
import { DataColumnDefinitions, SearchQuery } from "./../person-search.util";
import { Subscription } from "rxjs";
import { Table, TableLazyLoadEvent } from "primeng/table";
import { SearchBaseComponent, SearchField, SelectMode } from "./gc-person-search.base.component";
import { AppService } from "src/services/app.service";
import { toDict } from "src/util/array";
import { Option } from "src/util/types/global";
import { Globals } from "src/app/services/globals.service";

/**
 * @ignore - do not show in documentation
 */
@Component({
  selector:      'gc-person-search-base',
  templateUrl:   './gc-person-search.component.haml',
  styleUrls:     ['./gc-person-search.component.sass'],
  encapsulation: ViewEncapsulation.Emulated,
  providers:     [
    {
      provide:     NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GcPersonSearchComponent),
      multi:       true
    }
  ]
})
export class GcPersonSearchComponent extends SearchBaseComponent implements OnInit, OnDestroy {
    public static readonly defaultFields: SearchField[] = [
        "birthday",
        "email", "phone", "mobile", "addresses",
        "username", "rfid",
        "formerEmployee", "activeEmployee", "futureEmployee"
    ];
    private readonly defaultActiveFields: SearchField[] = [
        // firstname and lastname are included by default
    ];
    private _fields: SearchField[] = [...GcPersonSearchComponent.defaultFields];
    private _activeFields: SearchField[] = [...this.defaultActiveFields];
    private _lockedFields: SearchField[] = [];
    private _departments?: string[] | true;
    private _pagination: Pagination;
    private _searchValue: string = '';
    private _searchSubscription: Subscription;
    private _userDepartments?: Record<string, IDepartmentDTO>;
    private _selectedDepartments: string[] = [];
    private didInit: boolean;

    protected readonly service: PersonSearchService;

    public readonly columnDefinitions: DataColumnDefinitions = {
        firstname: {
            label:    "Vorname",
            path:     (x) => x.firstname,
            template: null,
        },
        lastname: {
            label:    "Nachname",
            path:     (x) => x.lastname,
            template: null,
        },
        birthday: {
            label:    "Geburtsdatum",
            path:     (x) => x.birthday,
            template: () => this.templateDate,
        },
        email: {
            label:    "E-Mail",
            path:     (x) => x.email,
            template: () => this.templateContacts,
        },
        phone: {
            label:    "Telefon",
            path:     (x) => x.phone,
            template: () => this.templateContacts,
        },
        mobile: {
            label:    "Handy",
            path:     (x) => x.mobile,
            template: () => this.templateContacts,
        },
        addresses: {
            label:    "Adresse",
            path:     (x) => x.addresses,
            template: () => this.templateAddresses,
        },
        username: {
            label:    "Benutzername",
            path:     (x) => x.username,
            template: () => this.templateBooleanText,
        },
        rfid: {
            label:    "RFID",
            path:     (x) => x.rfid,
            template: () => this.templateBooleanText,
        },
        employee: {
            label:    "Mit Vertrag",
            path:     (x) => x.employee,
            template: () => this.templateEmployee,
        },
        customer: {
            label:    "Ohne Vertrag",
            path:     (x) => x.customer,
            template: () => this.templateCustomer,
        }
    };

    @Input() public maxSelect?: number = undefined;
    @Input() public minSelect?: number = undefined;
    @Input() public selectionMode: SelectMode = "single";
    @Input() public showAvatar = true;

    @Input() public visibility?: VisibilityName;
    @Input() public employment?: Partial<ISearchQueryEmployee>;
    @Input() public trustedWorkTimes?:  "yes" | "no" | "any";
    @Input() public workhoursType?: WorkHoursType;

    @Output() public selectedItems = new EventEmitter<(SearchQueryResult & {avatar?: string})[]>();
    @Output() public selectionChange = new EventEmitter<string[]>();

    @ViewChild('tableElement') public table: Table;
    @ViewChild('columnAddresses', {static: true}) public templateAddresses: TemplateRef<HTMLElement> = undefined;
    @ViewChild('columnBoolean', {static: true}) public templateBoolean: TemplateRef<HTMLElement> = undefined;
    @ViewChild('columnBooleanText', {static: true}) public templateBooleanText: TemplateRef<HTMLElement> = undefined;
    @ViewChild('columnContacts', {static: true}) public templateContacts: TemplateRef<HTMLElement> = undefined;
    @ViewChild('columnDate', {static: true}) public templateDate: TemplateRef<HTMLElement> = undefined;
    @ViewChild('columnEmployee', {static: true}) public templateEmployee: TemplateRef<HTMLElement> = undefined;
    @ViewChild('columnCustomer', {static: true}) public templateCustomer: TemplateRef<HTMLElement> = undefined;

    public avatars: string[];
    public defaultSort: {field: string, order: number}[] = [{field: "firstname", order: 1}];
    public isSelectable = (event: {data: string, index: number}) => {
        if (this.value?.includes(event.data))
            return true;
        if (this.maxSelect && this.value?.length >= this.maxSelect)
            return false;
        return true;
    };
    public loading = false;
    public searchResult: SearchQueryResult[] = [];
    public departmentOptions: Option<string>[] = [];
    public readonly trustedWorkTimeOptions: Option<string>[] = [
        {label: "Ja", value: "yes"},
        {label: "Nein", value: "no"},
    ];

    constructor(private app: AppService){
        super();
        this._searchSubscription = this.service.searchSubscription.subscribe(result => {
            this.searchResult = result.data.data;
            this.pagination = result.data.pagination;
            this.avatars = result.__files;
            this.loading = false;
        });
    }

    @Input() public set fields(value: SearchField[]) {
        this._fields = value ?? [...GcPersonSearchComponent.defaultFields];
    }
    public get fields() {
        return this._fields;
    }

    @Input() public set activeFields(value: SearchField[]) {
        this._activeFields = [...new Set([...(value ?? []), ...this.lockedFields])];
        this.search();
    }
    public get activeFields(): SearchField[] {
        return this._activeFields;
    }
    @Input() public set lockedFields(value: SearchField[]) {
        this._lockedFields = value ?? [];
        this.activeFields = this.activeFields;
    }
    public get lockedFields(): SearchField[] {
        return this._lockedFields;
    }

    @Input() public set departments(value: string[] | true) {
        this._departments = value;
        if (value === true) {
            this.app.api.OrgchartWorker.getDepartmentListForLoggedinUser().then(departments => {
                this._userDepartments = toDict(departments, x => x.departmentID);
                this.departmentOptions = Object.values(this._userDepartments).map(x => ({label: x.name, value: x.departmentID}) as Option<string>);
                if (this.selectedDepartments.length !== 0)this.search();
            }).catch(error => {
                error.displayErrorToast?.("Fehler beim Laden der Abteilungen");
                console.error(error);
            });
        }
    }
    public get departments(): string[] | true {
        return this._departments;
    }

    @Input() public set pagination(value: Pagination) {
        this._pagination = value;
    }

    @Input() public set searchValue(value: string) {
        if (value === this._searchValue) return;
        this._searchValue = value;
        this.pagination = {...this.pagination, page: 0};
        this.search(value);
    }

    @Input() public set value(value: string[]) {
        if (super.value === value)
            return;

        if (!value) {
            this.selectedItems.emit(this.searchResult);
        } else {
            this.selectedItems.emit(value.reduce((curr, val) => {
                const entryIndex = this.searchResult.findIndex(x => x.ID === val);
                if (entryIndex >= 0) curr.push({...this.searchResult[entryIndex], avatar: this.avatars[entryIndex]});
                return curr;
            }, []));
        }
        super.value = value;
        this.onChange?.(value);
    }

    public get selectedDepartments(): string[] {
        return this._selectedDepartments;
    }
    public set selectedDepartments(value: string[]) {
        this._selectedDepartments = value;
        this.search();
    }

    public get pagination(): Pagination {
        return this._pagination ?? { page: 0, size: 25, total_pages: 0, total_size: 0 };
    }

    public get searchValue(): string {
        return this._searchValue;
    }

    public get tableHeader(): SearchKey[] {
        return Array.from(new Set([
            ...Object.keys(PersonSearchService.DEFAULT_FIELDS),
            ...Object.keys(this.columnDefinitions).filter(x => (this.activeFields ?? []).includes(x as SearchKey)),
        ])) as SearchKey[];
    }

    public get value(): string[] {
        return super.value;
    }

    public getSelectionString() {
        const min = this.minSelect;
        const max = this.maxSelect ?? this.pagination.total_size;
        if (!max)
            return `${this.value?.length ?? this.pagination.total_size} ausgewählt`;
        const text: string[] = [`${this.value?.length ?? this.pagination.total_size} von`];
        if (this.minSelect)
            text.push(`${min}–${max}`);
         else
            text.push(`${max}`);

        return text.join(' ');
    }

    public handleEvent(event: Event) {
        event.stopPropagation();
        event.preventDefault();
    }

    public ngOnDestroy(): void {
        this._searchSubscription?.unsubscribe();
    }

    public ngOnInit(): void {
        this.didInit = true;
        this.search(this.searchValue, true);
    }

    public onLazyLoad(event: TableLazyLoadEvent){
        const page = Math.floor(event.first / event.rows);
        if (!event.multiSortMeta && this.pagination.page === page) return;
        if (!this.pagination || (page === this.pagination.page && !event.multiSortMeta)) return;
        this.pagination.page = page;

        Object.entries(this.columnDefinitions).forEach(([key, value]) => {
            const isSort = event.multiSortMeta?.find(x => x.field === key);
            value.order = isSort ? (isSort.order === 1 ? "ASC" : "DESC") : null;
        });
        this.search();
    }

    public onSelectionChange(value: string | string[]) {
        if (value === null)
            value = [];
        if (!Array.isArray(value))
            value = [value];
        this.value = value;
        if (this.minSelect && this.value.length < this.minSelect)
            return;
        this.onChange?.(this.value.filter(x => x));
    }

    public selectAll(){
        this.writeValue(null, true);
        this.table.clear();
        this.table.selection = [];
        this.onChange?.(this.value);
    }

    public selectNone(){
        this.writeValue([], true);
        this.table.clear();
        this.table.selection = this.value;
        this.onChange?.(this.value);
    }

    protected search(value: string = this.searchValue, noDebounce = false){
        if (!this.didInit) return;
        // prepare query fields
        let departments: string[] | undefined  = undefined;
        if (!!this.departments) {
            if (this.departments === true)
                // don't limit to any departments (allow search for all users) if no department was selected
                departments = this.selectedDepartments.length === 0 ? undefined : this.selectedDepartments;
            else
                // set departments to pre-defined department list
                departments = this.departments;
        }

        const fields = this.activeFields.reduce((curr, val) => {
            const definition = this.columnDefinitions[val as SearchKey];
            if (!definition) return curr;
            curr[val as SearchKey] = definition.order;
            return curr;
        }, ({
            firstname: this.columnDefinitions.firstname.order,
            lastname:  this.columnDefinitions.lastname.order,
        }) as Partial<Record<SearchKey, Order>>);

        const employee = this.isActive(["employee", "formerEmployee", "activeEmployee", "futureEmployee"]) || this.trustedWorkTimes ? {
            isEmployee:       this.activeFields.includes("employee") || !!this.trustedWorkTimes,
            formerEmployees:  this.activeFields.includes("formerEmployee"),
            activeEmployees:  this.activeFields.includes("activeEmployee"),
            futureEmployees:  this.activeFields.includes("futureEmployee"),
            trustedWorkTimes: this.trustedWorkTimes,
        } : undefined;

        const customer = this.isActive("customer") ? {
            isCustomer: true
        } : undefined;


        // query search
        const query: SearchQuery = {
            term:          value,
            pagination:    this.pagination,
            withFiles:     this.showAvatar,
            visibilityFor: this.visibility,
            workhoursType: this.workhoursType,
            fields,
            departments,
            employee,
            customer,
        };
        this.loading = true;
        if (noDebounce)this.service.searchNoDebounce(query);
        else this.service.search(query);
    }

    public has(value: SearchField) {
        return this.fields.includes(value);
    }

    public toggleField(value: SearchField) {
        const index = this.activeFields.indexOf(value);
        if (index < 0)this.activeFields.push(value);
        else this.activeFields.splice(index, 1);
        this.search();
    }

    isActive(value: SearchField[]): boolean;
    isActive(value: SearchField): boolean;
    public isActive(value: SearchField | SearchField[]) {
        const field = Array.isArray(value) ? value : [value];
        return field.some(x => this.activeFields.includes(x));
    }

    public isLocked(value: SearchField) {
        return this.lockedFields.includes(value);
    }

    public get hasContact() {
        return contactFields.some(x => this.fields.includes(x));
    }
    public get hasCredentials() {
        return credentialFields.some(x => this.fields.includes(x));
    }
    public get hasEmployment() {
        return employeeFields.some(x => this.fields.includes(x));
    }
    public get otherFields() {
        const fields = this.fields.filter(x => !(contactFields.includes(x) || credentialFields.includes(x) || employeeFields.includes(x)));
        return fields.length > 0 ? fields : undefined;
    }

    public getColor(user: SearchQueryResult) {
        const [h, s] = user.userColor ?? user.defaultColor;
        if (!h || !s) return undefined;
        const lightness = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ?
            Globals.DARK_MODE_LIGHTNESS :
            Globals.LIGHT_MODE_LIGHTNESS;
        return `hsl(
            ${h}deg,
            ${s * 100}%,
            ${lightness * 100}%
        )`;
    }
}

const contactFields: SearchField[] = ["addresses", "phone", "mobile", "email"] as const;
const credentialFields: SearchField[] = ["username", "rfid"] as const;
const employeeFields: SearchField[] = ["formerEmployee", "activeEmployee", "futureEmployee", "trustedWorkTimes"];