import {
    AfterViewChecked,
    Component, ComponentRef, DoCheck, HostListener, Inject, Injector, OnDestroy, ViewChild, ViewContainerRef
} from "@angular/core";
import { ActivatedRoute, NavigationStart, Router } from "@angular/router";
import { MessageService } from "primeng/api";
import { Subscription } from "rxjs";
import { DraftDefinition } from "../../types/draft-definition";
import { IAppService } from "../../types/IAppService";
import { stepComp } from "../../types/step-definition";
import { APP_SERVICE, STEP_OPTIONS } from "../../types/token";
import { DraftService } from "../draft.service";
import { Step } from "../../types/step";

@Component({
    selector:    'gc-drafthost',
    templateUrl: './draft-host-component.html',
    styleUrls:   ['./draft-host-component.sass']
})
export class DraftHostComponent implements AfterViewChecked, OnDestroy, DoCheck {

    public readonly isHost = true;

    #component!: stepComp;
    #allComponents: { [anchor: string]: ComponentRef<unknown>} = {};
    #currentDefinition?: DraftDefinition<unknown>;
    #newDefinition?: DraftDefinition<unknown>;
    #routerSubs: Subscription;
    #dataSubs: Subscription;
    #afterNavigate: boolean = false;

    #currentID: string;

    #data: Record<string, unknown>;

    get #resolvedData() {
        console.log(this.draftService._resolveData);
        return { ...this.#data, ...this.draftService._resolveData };
    }

    @ViewChild('body', { read: ViewContainerRef }) template!: ViewContainerRef;
    @ViewChild('bodyHidden', { read: ViewContainerRef }) template_hidden!: ViewContainerRef;

    //@HostListener('window:pagehide')  // would be a temp workaround for saFari...
    @HostListener('window:visibilitychange')
    onGehWeg() { this.draftService.save().catch(e => console.error(e)); }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    emptyArray: Step<any>[] = [];

    /*get steps() {
        const steps = this.draftService.steps;
        if(steps.length > 1) return steps;
        return this.emptyArray;
    }*/

    steps = this.draftService.steps;
    stepId = this.draftService.stepId;

    showStepperBar: boolean;

    ngDoCheck() {
        const steps = this.draftService.steps;
        if (steps.length <= 1)
            this.steps = this.emptyArray;
        else if (steps.length !== this.steps.length || steps.some((step, i) =>
            step.anchor !== this.steps[i].anchor || step.disabled !== this.steps[i].disabled || step.valid !== this.steps[i].valid
        ))
            this.steps = steps;
        const stepId = this.draftService.stepId;
        if (this.stepId !== stepId)
            this.stepId = stepId;
        const ssb = this.steps?.[stepId]?.step?.config?.showStepperBar;
        if (typeof ssb === 'function') {
            this.showStepperBar = ssb({
                form:    this.draftService.form,
                self:    this.steps[stepId],
                stepper: this.draftService._current_definition
            }) ?? (this.steps && this.steps.length > 0);
        } else {
            this.showStepperBar = ssb ?? (this.steps && this.steps.length > 0);
        }
    }

    constructor(
        private draftService: DraftService,
        private router: Router,
        private activeRoute: ActivatedRoute,
        private msgServ: MessageService,
        @Inject(APP_SERVICE) private appService: IAppService, //TODO: __activate as dom event
        private injector: Injector,
        private route: ActivatedRoute,
    ) {
        draftService._hostComponent = this;
        this.#routerSubs = this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                //console.log(event);
                this.draftService.save().catch(e => console.error(e));
            }
        });
        this.#dataSubs = this.route.data.subscribe(data => {
            this.#newDefinition = this.draftService._current_definition;
            this.#afterNavigate = true;
            this.#data = data;
            this.#currentID = this.draftService.draftId;
        });
    }

    cleanup() {
        //console.log("Cleanup called");
        this.template.clear();
        this.template_hidden.clear();
    }

    ngOnDestroy() {
        this.#component?.onDeactivate?.();
        this.#routerSubs.unsubscribe();
        this.#dataSubs.unsubscribe();
        this.draftService.clearDraft(this.#currentID).catch(e => console.error(e));
        if (this.draftService._hostComponent === this)
            this.draftService._hostComponent = undefined;
    }

    async ngAfterViewChecked(): Promise<void | boolean> {
        //console.log('draft definition: ', this.#currentDefinition?.constructor.name, this.#newDefinition?.constructor.name);
        if (this.#newDefinition && this.#currentDefinition?.constructor.name !== this.#newDefinition?.constructor.name) {
            //console.log('REINIT');
            this.#currentDefinition = this.#newDefinition;
            await null;
            this.#component?.onDeactivate?.();
            Object.values(this.#allComponents).forEach((comp) => {
                comp.destroy();
            });
            this.#allComponents = {};
            this.template_hidden.clear();
            this.template.clear();
            this.draftService._current_definition.steps.forEach((step) => {
                const injector = Injector.create({ parent: this.injector, providers: [{ provide: STEP_OPTIONS, useValue: step.config?.options }] });
                const comp = this.template_hidden.createComponent<stepComp>(step.component, { injector });
                comp.instance.ɵinjector = comp.injector;
                comp.instance.ɵAnchor = step.anchor;
                this.#allComponents[step.anchor] = comp;
            });
            this.#component = undefined;
            //console.log("done: ", this.#currentDefinition?.constructor.name);
        }
        if (this.#afterNavigate && this.draftService.anchor && this.#component?.ɵAnchor !== this.draftService.anchor) {
            this.#afterNavigate = false;
            //console.log('Switch component');
            await null;
            if (!this.#allComponents[this.draftService.anchor])
                return await this.router.navigate(['/404']); //FIXME: real error page (?)
            if (this.#component) {
                this.#component.onDeactivate?.();
                const oldview = this.#allComponents[this.#component.ɵAnchor].hostView;
                const oldindex = this.template.indexOf(oldview);
                this.template.detach(oldindex);
                this.template_hidden.insert(oldview, 0);
            }
            const newview = this.#allComponents[this.draftService.anchor].hostView;
            const newindex = this.template_hidden.indexOf(newview);
            this.template_hidden.detach(newindex);
            this.template.insert(newview, 0);
            this.#component = this.#allComponents[this.draftService.anchor].instance;
            this.#component.onResolve?.(this.#resolvedData);
            this.appService.__activate?.(this.#component, this.draftService.isInModal);
            this.#component.ngAfterViewChecked?.(true);
        } else if (this.#afterNavigate) {
            this.#afterNavigate = false;
            //console.log('AfterNavigate');
            await null;
            this.#component?.onDeactivate?.();
            this.#component?.onResolve?.(this.#resolvedData);
            this.appService.__activate?.(this.#component, this.draftService.isInModal);
        }
    }

    stepClicked(step: number) {
        const steps = this.draftService.steps;
        const valid = steps.reduce((v, s, i) => v && (s.valid || i >= step), true);
        console.log(valid, this.draftService.steps);
        if (!valid) {
            const invalid_step = steps.find(x => !x.valid);
            return this.msgServ.add({ // TODO @Chris useful message
                severity: 'info',
                summary:  'Ungültig',
                detail:   `Der Schritt '${invalid_step.label}' ist nicht gültig. Bitte überprüfen Sie alle Eingabefelder.`,
            });
        }
        if (this.draftService.draftId) {
            this.router.navigate(this.draftService.getStepLink(step)).catch(e => {
                console.log('navigation to step failed');
            });
        }
    }

    get breadcrumb() {
        return this.draftService.breadcrumb;
    }

    get buttons() {
        return this.#component?.ɵDraftButtons ?? this.draftService.buttons;
    }

    public get backToParentButton(){
        return this.draftService.backToParentButton;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    track(index: number, item: any) {
        return item.type + this.stepId + (item.disabled ?? 'false');
    }
}
