import { Component, OnInit, ViewChild } from "@angular/core";
import { DuplicateSidebarComponent } from "src/app/sidebar/components/duplicates/duplicate.sidebar.component";
import { SidebarComponent } from "src/app/sidebar/siderbar.component";
import { Gender, IUser, ContactType, ContactDomain } from '@vierkant-software/types__api';
import { AppService } from "src/services/app.service";
import { IFrontendPaymentOption, IFrontendUser } from "../../contract.interfaces";
import { FormChanged, DraftForm, DraftFormType, DraftService, DraftFormChanged } from "src/app/modules/ngx-draft";
import { DateTime } from "luxon";
import { AbstractControl, FormArray, FormBuilder } from "@angular/forms";
import { OverlayPanel } from "primeng/overlaypanel";
import { Globals } from "src/app/services/globals.service";
interface Option {
	name: string;
	id: number;
	patch?: {
		pronouns: string;
		pronouns2: string;
	};
}

export const ContactTypeDefs = {
	[ContactType.Undefined]: { label: '', inputType: 'text' },
	[ContactType.EMail]:     { label: 'E-Mail', inputType: 'email' },
	[ContactType.Phone]:     { label: 'Telefon', inputType: 'tel' },
	[ContactType.Mobile]:    { label: 'Handy', inputType: 'tel' },
	[ContactType.Fax]:       { label: 'Fax', inputType: 'tel' },
};

export const ContactDomainsDefs = {
	[ContactDomain.Undefined]: { label: '' },
	[ContactDomain.Private]:   { label: 'Privat' },
	[ContactDomain.Business]:  { label: 'Geschäftlich' },
};

@Component({
	templateUrl: './person.step.haml',
	styleUrls:   ['./person.step.sass'],
})
export class UserPersonStep implements OnInit, FormChanged {		// FIXME @Chris IPage needs a cleanup and replacement

	@DraftForm() form: DraftFormType<IFrontendUser>;

	duplicateSideBarComponent: DuplicateSidebarComponent;

	maxDate = DateTime.now();

	editable: boolean;
	selectedGender?: Option;
	gender = Gender;
	genders: Option[] = [
		{ name: 'Unbekannt/Kein', id: Gender.Undefined, patch: {pronouns: 'Unbekannt', pronouns2: 'Unbekannt'} }, //FIXME unbekannt raus
		{ name: 'Männlich', id: Gender.Male, patch: {pronouns: 'Er', pronouns2: 'Sein'} },
		{ name: 'Weiblich', id: Gender.Female, patch: {pronouns: 'Sie', pronouns2: 'Ihr'} },
		{ name: 'Divers', id: Gender.Diverse, patch: {pronouns: '', pronouns2: ''} }
	];
	isMaiden: boolean;

	userA?: Partial<IUser>;
	userB?: Partial<IUser>;
	userBImage?: string;

	selectedIndex: number = undefined;
	contactTypes = [
		{ type: ContactType.EMail, name: 'E-Mail' },
		{ type: ContactType.Phone, name: 'Telefon' },
		{ type: ContactType.Mobile, name: 'Handy' },
		{ type: ContactType.Fax, name: 'Fax' },
	];
	contactDomains = [
		{ type: ContactDomain.Private, name: 'Privat' },
		{ type: ContactDomain.Business, name: 'Geschäftlich' },
	];

	newContact = this.fb.group({
		type:   [ContactType.Undefined],
		domain: [ContactDomain.Undefined],
	});

	@ViewChild('overlayPanel') overlayPanel: OverlayPanel | undefined;

	displayCompare: boolean;

	constructor(
		private rightSideBar: SidebarComponent,
		private appSerive: AppService,
		private draftService: DraftService,
		private fb: FormBuilder,
        public readonly global: Globals
	) { }

	get contacts(): FormArray {
		return <FormArray>this.form.get('contacts');
	}
	get differentContractHolder() {
		return this.form.get('differentContractHolder')?.value;
	}

	ngOnInit() {
		this.duplicateSideBarComponent = undefined;
	}

	async onFormChanged(data: IUser) { //FIXME @Chris @Jens
		// only search if both the firstname and lastname is set, otherwise search is not meaningful
		if (data.lastname === '' || data.firstname === '' || data.ID) return;   //TODO maidenname

		// TODO only search on userinput

		try {
			const result = await this.appSerive.api.UserWorker.findDuplicates({
				lastname:  data.lastname,
				//alternativeLastname: data.maidenName,
				firstname: data.firstname,
				birthday:  data.birthday,
				gender:    data.gender,
			});
			if (result && result.data.length > 0) {
				if (!this.duplicateSideBarComponent) {
					this.duplicateSideBarComponent = this.rightSideBar.addItem(DuplicateSidebarComponent, { users: result.data, images: result.__files });
				}
				else {
					this.duplicateSideBarComponent.users = result.data;
					this.duplicateSideBarComponent.images = result.__files;
				}

				this.rightSideBar.visible = true;
			}
		} catch(e) {
			console.error('Error: findDuplicates failed', e);
		}
	}

	async createDifferentContractHolder() {
        return await this.draftService.createDraft('users', 'different-contactholder', { chain: true });
    }

	async editDifferentContractHolder(differentContractHolder: Record<string,unknown>) {
		return await this.draftService.createDraft('users', 'different-contactholder', { chain: true, data: differentContractHolder });
	}

	deleteDifferentContractHolder() {
		this.form.get('differentContractHolder').reset();
	}

	async SelectUser(user: Partial<IUser>) {
		console.log('SelectUser called:', user);	// FIXME @Chris do we miss something here?
		/*
			this.service.SetUser(user);
			if (user.addresses.length > 0)
					this.service.SetAddress(user.addresses[0]);
			if (user.ID) {
					const contacts = await this.service.FetchContacts(user.ID);
					this.service.SetContacts(contacts);
			}
			this.person.patchValue({
					firstName: user.firstname,
					familyName: user.lastname,
					gender: this.genders.find(x => x.code === user.gender),
					birthDate: new Date(user.birthday),
			});
			*/
	}

	compareDuplicate(duplicate: Partial<IUser>, image?: string) {
		console.log('compareDuplicate called:', duplicate, this.form.value);
		this.userA = this.form.value as IUser;
		this.userB = duplicate;
		// this.userBImage = image;
		this.displayCompare = true;
	}

	async setFormToDuplicate(event: IUser) {
		this.draftService.recordId = event.ID;
		const tmpUser = await this.appSerive.api.UserWorker.getUser(event.ID, true);
		const PO = await this.appSerive.api.UserWorker.getUserPaymentOptions(event.ID);
		const tmpUserPaymentOptions: IFrontendPaymentOption[] = PO.map(x => ({ ...x.value, isPrimary: x.isPrimary, ID: x.ID }));
		this.form.patchValue(tmpUser.data);
		this.form.patchValue({paymentMethod: tmpUserPaymentOptions}); //FIXME backend doesnt match frontend
		this.form.get('image').patchValue(tmpUser.__files[0]);
	}

	@DraftFormChanged(['gender'])
	genderChanged(ev: { gender: Gender }) {
		this.form.patchValue(this.genders.filter(x => x.id === ev.gender)[0]?.patch);
	}

	getLabel(contact: AbstractControl): string {
		if (!contact.value.type && !contact.value.domain)
			return 'Bitte auswählen';
		return (
			ContactTypeDefs[<ContactType>contact.value.type]?.label ??
			ContactTypeDefs[ContactType.Undefined].label
		) + ' ' + (
				ContactDomainsDefs[<ContactDomain>contact.value.domain]?.label ??
				ContactDomainsDefs[ContactDomain.Undefined].label
			) + ' *';
	}

	getType(contact: AbstractControl): string {
		return ContactTypeDefs[<ContactType>contact.value.type]?.inputType ?? ContactTypeDefs[ContactType.Undefined].inputType;
	}

	addContact() {
		this.contacts.addEntry({ domain: ContactDomain.Undefined, type: ContactType.Undefined });
	}

	triggerValidator() {
		this.form.updateValidators();
	}

	deleteContact(index: number) {
		this.contacts.removeAt(index);
	}

	toggle(event: Event, index?: number) {
		this.selectedIndex = undefined;
		this.overlayPanel?.toggle(event);
		if (index !== undefined)
			this.selectedIndex = index;
	}
}
