// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main .title {
  min-width: 8rem;
}
.main .content {
  display: flex;
}
.main .content.center {
  justify-content: center;
  text-align: center;
}
.main .content.right {
  justify-content: flex-end;
}
.main .content.left {
  justify-content: flex-start;
}

.gc-component {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.gc-component .scroll-container {
  flex-grow: 1;
  position: relative;
}
.gc-component .scroll-container .scroll-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: overlay;
}`, "",{"version":3,"sources":["webpack://./src/app/components/gc-generic-table/gc-generic-table.component.sass"],"names":[],"mappings":"AACI;EACI,eAAA;AAAR;AACI;EACI,aAAA;AACR;AAAQ;EACI,uBAAA;EACA,kBAAA;AAEZ;AADQ;EACI,yBAAA;AAGZ;AAFQ;EACI,2BAAA;AAIZ;;AAFA;EACI,YAAA;EACA,aAAA;EACA,sBAAA;EACA,WAAA;AAKJ;AAJI;EACI,YAAA;EACA,kBAAA;AAMR;AALQ;EACI,kBAAA;EACA,MAAA;EACA,QAAA;EACA,SAAA;EACA,OAAA;EACA,iBAAA;AAOZ","sourcesContent":[".main\n    .title \n        min-width: 8rem\n    .content\n        display: flex\n        &.center\n            justify-content: center\n            text-align: center\n        &.right\n            justify-content: flex-end\n        &.left\n            justify-content: flex-start\n\n.gc-component\n    height: 100%\n    display: flex\n    flex-direction: column\n    gap: 0.5rem\n    .scroll-container\n        flex-grow: 1\n        position: relative\n        .scroll-content\n            position: absolute\n            top: 0\n            right: 0\n            bottom: 0\n            left: 0\n            overflow: overlay\n    \n\n        \n    \n    \n    \n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
