import { NgModule } from "@angular/core";
import { GcIconComponent } from "./gc-icon.component";
import { CommonModule } from "@angular/common";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

@NgModule({
    imports:      [CommonModule, FontAwesomeModule],
    exports:      [GcIconComponent],
    declarations: [GcIconComponent]
})
export class GcIconModule {}