export function sortBy<T>(array: T[], ...by: ((obj: T) => unknown)[]): T[]{
    const result = [...array];
    return result.sort((a,b) => {

        for (const x of by){
            let xA;
            let xB;

            // catch for null access
            try {
                xA = x(a);
            } catch(error) {
                try {
                    xB = x(b);
                    return 1;
                } catch(errorB) {
                    continue;
                }
            }
            try {
                xB = x(b);
            } catch(error) {
                try {
                    xA = x(a);
                    return -1;
                } catch(errorA) {
                    continue;
                }
            }

            // sort missing props to the end
            if (xA === undefined){
                if (xB === undefined)
                    continue;
                return 1;
            }
            if (xB === undefined)
                return -1;

            // check props
            if (xA < xB)
                return -1;
            if (xA > xB)
                return 1;
        }
        return 0;
    });
}
