import {
  AfterViewInit,
  Component,
  ElementRef, EventEmitter,
  forwardRef, HostBinding, HostListener,
  InjectFlags,
  Injector,
  Input, Output,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { AppService } from '../../../../../services/app.service';
import { ISelectedProleosFile } from '../../../../steppers/product-management/product/product.interfaces';

type ValueType = string | ArrayBuffer;
type CallbackType = (() => void) | ((x: ValueType) => void);

@Component({
  selector:    'app-proleos-file-upload',
  templateUrl: './proleos-file-upload.component.haml',
  styleUrls:   ['./proleos-file-upload.component.sass'],
  providers:   [
    {
      provide:     NG_VALUE_ACCESSOR,
      multi:       true,
      useExisting: forwardRef(() => ProleosFileUploadComponent)
    }
  ]
})
export class ProleosFileUploadComponent implements ControlValueAccessor, AfterViewInit {
  @HostBinding('class.drag') dragOver = false;
  @Output() fileSelect = new EventEmitter<ISelectedProleosFile>();
  @Input() inputId = '';
  @Input() accept = '';
  @Input() maxFileMb = 100;
  onChange: CallbackType = (): void => { };
  onTouch: () => void = (): void => { };

  @ViewChild('input') inputElementRef: ElementRef<HTMLInputElement>;

  @HostListener('dragover', ['$event'])
  onDragOver(): void {
    this.dragOver = true;
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(): void {
    this.dragOver = false;
  }

  @HostListener('drop', ['$event'])
  onDrop(): void {
    this.dragOver = false;
  }

  value: ValueType;
  file: File;
  _originalValue?: ValueType;

  constructor(
    private injector: Injector,
    private appServive: AppService
  ) {
  }

  ngAfterViewInit(): void {
    const control: NgControl = this.injector.get(NgControl, undefined, InjectFlags.Self);
    if (!control)
      throw new Error('NgControl not found');
    control.control.GCType = 'file';
  }

  selectFile(event: Event): void {
    const input: HTMLInputElement = <HTMLInputElement>event.target;

    if (!input.files || !input.files[0])
      return;

    for (let i: number = 0; i < input.files.length; i++) {
      const file: File = input.files[i];
      if (this.accept && !this.accept.includes(file.type)) {
        console.error(`Der Filetype der Datei ${file.name} (${file.type}) ist nicht zulässig.`);
        this.appServive.messageService.add({
          severity: "error",
          summary:  "Es ist ein Fehler aufgetreten!",
          detail:   `Der Filetype der Datei ${file.name} (${file.type}) ist nicht zulässig.`,
          life:     3000,
        });
        return;
      }
      const sizeInMb: number = file.size / (1024 ** 2);
      if (sizeInMb > this.maxFileMb) {
        console.error(`Die Dateigröße der Datei ${file.name} (${sizeInMb} MB) ist nicht zulässig.`);
        this.appServive.messageService.add({
          severity: "error",
          summary:  "Es ist ein Fehler aufgetreten!",
          detail:   `Die Dateigröße der Datei ${file.name} (${sizeInMb} MB) ist nicht zulässig.`,
          life:     3000,
        });        return;
      }

      const src = URL.createObjectURL(file);
      let result: ISelectedProleosFile = {
        name: file.name,
        size: file.size,
        src,
      };
      if (file.type.includes('image')) {
        const image = new Image();
        image.onload = () => {
          result = {
            ...result,
            width:  image.naturalWidth,
            height: image.naturalHeight,
          };
          this.fileSelect.emit(result);
        };
        image.src = src;
        continue;
      }
      this.fileSelect.emit(result);
    }
  }

  onInputChange(): void {
    const val = this.inputElementRef.nativeElement.value;
    this.onChange(val);
  }

  writeValue(value: ValueType): void {
    this.value = value;
    if (!this._originalValue)
      this._originalValue = value;
  }

  registerOnChange(fn: CallbackType): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

}
