import { IUser } from "@vierkant-software/types__api";

export type Formats = "initial" | "initialNoDot" | "normal";
export type FormatObj = {firstname: Formats | "none", lastname: Formats | "none"};
export type Format = (Formats) | FormatObj;


export function formatUser(value: IUser, format: Format = "normal"){
    if (!value)
        return undefined;
    if (typeof format === "object"){
        return `${getValue(value.firstname, format.firstname)} ${getValue(value.lastname, format.lastname)}`;
    } else {
        let name = `${getValue(value.firstname, format)} ${getValue(value.lastname, format)}`;
        if (format === "initialNoDot")
            name = name.replace(" ", "");
        return name;
    }
}

function getValue(input: string, format: Formats | "none"){
    if (!input || input.trim() === "")
        return '';
    switch(format) {
        case "none":
            return '';
        case "initial":
            return input[0].toUpperCase() + '.';
        case "initialNoDot":
            return input[0].toUpperCase();
        default:
            return input;
    }
}
