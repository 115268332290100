/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable space-before-function-paren */
import { AbstractControl, AsyncValidatorFn, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";
import { FileUploadInfo } from "@vierkant-software/remoterpc";
import { unArray } from "./unArray";
import { DateTime, Duration } from 'luxon';

declare module "@angular/forms" {
    export interface FormArray<TControl> {
        gcontrol: GCFormArray<unArray<ɵFormArrayValue<TControl>>>;
        addEntry(this: FormArray<TControl>, data?: unArray<ɵFormArrayValue<TControl>>): TControl;
        updateValidators(): void;
        FA_changed: { index: number, type: 'add' | 'remove' }[];
    }
    export interface FormGroup<TControl> {
        gcontrol: GCFormGroup<ɵFormGroupValue<TControl>> | GCFormGroup<ɵFormGroupValue<TControl>>;
        addEntry(this: FormGroup<TControl>, key: string, data?: unknown): TControl;
        updateValidators(): void;
        __GCFileUpload?: {[id: string]: string};
        __GCFiles?: {[id: string]: string};
        getFilesForSubmit(): Promise<FileUploadInfo>;
        getWithoutFiles(): TControl;
    }

    export interface AbstractControl {
        getDraftValues(path?: string[]): unknown;
        setDraftValues(value: unknown, path?: string[]): void;
        setFiles(fileData: { [path: string]: string }, path?: string[]): void;
        getFiles(path?: string[]): { [path: string]: string };
        getWithoutFiles(): unknown;
        GCType?: 'file';
        noSaveInDraft?: true;
    }
}

const oldGPatchValue = FormGroup.prototype.patchValue;
FormGroup.prototype.patchValue = function (this: FormGroup, value, options = {}): void {
    if (this.gcontrol && this.gcontrol instanceof GCFormDictionary && (this as any)._isDictionaryRoot) {
        Object.entries(value).forEach(([key, val]) => {
            if (!this.controls[key])
                this.gcontrol?.addDictElement(this, key, {});
        });
    }
    oldGPatchValue.apply(this, [value, options]);
};


const oldFAPatchValue = FormArray.prototype.patchValue;
FormArray.prototype.patchValue = function (this: FormArray, value, options = {}): void {
    for (let i = this.length; this.gcontrol && i < value.length; i++)
        this.addEntry();
    while (this.gcontrol && this.length > (value?.length ?? 0))
        this.removeAt(this.length - 1);
    oldFAPatchValue.apply(this, [value, options ]);
};

FormGroup.prototype.getFilesForSubmit = async function (this: FormGroup) {
    return Object.fromEntries(
        await Promise.all(
            Object.entries(this.getFiles())
            .filter(([,url]) => url?.length > 5)
            .map(async ([path, url]) =>
                [
                    path,
                    await fetch(url)
                        .then(res => res.blob())
                        .then(async res => <unknown>{ data: await res.arrayBuffer(), ContentType: res.type })
                ]
            )
        )
    );
};

//#region FormArrayTracking
const FA_original_removeAt = FormArray.prototype.removeAt;
FormArray.prototype.removeAt = function (index: number, options: { emitEvent?: boolean | undefined } | undefined): unknown {
    if (!this.FA_changed)
        this.FA_changed = [];
    this.FA_changed.push({ index, type: 'remove' });
    return FA_original_removeAt.call(this, index, options);
};

const FA_original_push = FormArray.prototype.push;
FormArray.prototype.push = function (control: AbstractControl, options: { emitEvent?: boolean | undefined } | undefined) {
    if (!this.FA_changed)
        this.FA_changed = [];
    this.FA_changed.push({ index: this.length, type: 'add' });
    return FA_original_push.call(this, control, options);
};
//#endregion

//#region getWithoutFiles
FormGroup.prototype.getWithoutFiles = function (this: FormGroup) {
    return Object.entries(this.controls).reduce((o, [i,c ]) => Object.assign(o, { [i]: c.getWithoutFiles() }), {});
};
FormArray.prototype.getWithoutFiles = function (this: FormArray) {
    return this.controls.map((c) => c.getWithoutFiles());
};
AbstractControl.prototype.getWithoutFiles = function (this: AbstractControl) {
    if (this.GCType === 'file')
        return '';
    return this.value;
};
//#endregion

//#region setFiles
FormArray.prototype.setFiles = function (this: FormArray, fileData: { [path: string]: string }, path: string[] = []) {
    this.controls.forEach((c,i) => c.setFiles(fileData, [...path, i.toString()]));
};
FormGroup.prototype.setFiles = function (this: FormGroup, fileData: { [path: string]: string }, path: string[] = []) {
    Object.entries(this.controls).forEach(([i, c]) => c.setFiles(fileData, [...path, i.toString()]));
};
AbstractControl.prototype.setFiles = function (this: AbstractControl, fileData: { [path: string]: string }, path: string[] = []) {
    if (fileData[path.join('.')])
        this.setValue(fileData[path.join('.')]);
};
//#endregion

//#region getFiles
AbstractControl.prototype.getFiles = function (this: AbstractControl, path: string[] = []) {
    if (this.GCType !== 'file')
        return {};
    else
        return { [path.join('.')]: this.value };
};
FormGroup.prototype.getFiles = function (this: FormGroup, path: string[] = []) {
    return Object.entries(this.controls).reduce((o,[i, c]) => Object.assign(o, c.getFiles([...path, i])), {});
};
FormArray.prototype.getFiles = function (this: FormArray, path: string[] = []) {
    return this.controls.reduce((o, c, i) => Object.assign(o, c.getFiles([...path, i.toString()])), {});
};
//#endregion


FormArray.prototype.addEntry = function (this: FormArray, data?: unknown) { return this.gcontrol.addElement(this, <any>data); };
FormGroup.prototype.addEntry = function (this: FormGroup, key: string, data?: unknown) { return this.gcontrol.addDictElement(this, key, <any>data); };
FormArray.prototype.updateValidators = function (this: FormArray) { return this.gcontrol.updateArrayValidators(this); };
FormGroup.prototype.updateValidators = function (this: FormGroup) { return this.gcontrol.updateGroupValidators(this); };
//#region set/getDraftValues
AbstractControl.prototype.getDraftValues = function (this: AbstractControl, path: string[] = []): unknown {
    if (this.GCType === 'file') {
        if ((<FormGroup>this.root).__GCFileUpload) {
            if (this.dirty) {
                if ((<FormGroup>this.root)?.__GCFiles?.[path.join('.')] !== this.value)
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    (<FormGroup>this.root).__GCFileUpload![path.join('.')] = this.value;
                return ['', this.dirty];
            }
        }
    }
    return [this.value, this.dirty];
};
AbstractControl.prototype.setDraftValues = function (this: AbstractControl, value: [unknown, boolean], path: string[] = []) {
    if (value === undefined)
        return;
    this.setValue(value[0]);
    if (value[1])
        this.markAsDirty();
    else
        this.markAsPristine();
};
FormGroup.prototype.getDraftValues = function (this: FormGroup, path: string[] = []): unknown {
    if (this === this.root) {
        this.__GCFileUpload = {};
        if (this.noSaveInDraft)
            return {};
    }
    const p: Record<string, unknown> = {};
    <unknown>Object.entries(this.controls).map(([key, control]) => {
        if (!control.noSaveInDraft)
            p[key] = control.getDraftValues([...path, key]);
    });
    return p;
};
FormGroup.prototype.setDraftValues = function (this: FormGroup, value: unknown, path: string[] = []) {
    if (value === undefined)
        return;
    Object.keys(<any>value).forEach((key) => {
        if (this.gcontrol instanceof GCFormDictionary && !this.controls[key])
            this.gcontrol.addDictElement(this, key, {});
        if (this.controls[key])
            this.controls[key].setDraftValues((<Record<string, unknown[]>>value)[key], [...path, key]);
    });
};
FormArray.prototype.getDraftValues = function (this: FormArray, path: string[] = []): unknown[] {
    return <unknown[]>this.controls.map((v, i) => v.getDraftValues([...path, i.toString()]));
};
FormArray.prototype.setDraftValues = function (this: FormArray, value: unknown[], path: string[] = []) {
    if (value === undefined)
        return;
    const elements = Math.max(value.length, this.controls.length);
    for (let i = 0; i < elements; i++) {
        if (i >= value.length)
            this.removeAt(i);
        if (i >= this.controls.length)
            this.addEntry();
        (<FormGroup>this.controls[i]).setDraftValues(<unknown[]>value[i], [...path, i.toString()]);
    }
};
//#endregion

export type CrossFormValidator<T> = (form: T) => { [controlPath: string]: string | false };

export function crossFieldValidator(validator: CrossFormValidator<any>) {
    if (!validator)
        return undefined;
    return (control: AbstractControl): ValidationErrors | null => {
        const result = validator(control);
        Object.entries(result).forEach(([k,v]) => {
            const formControl = control.get(k.split('.'));
            if (!formControl)
                return console.error('Invalid control path: ' + k);
            if (v === false) {
                const formErrors = Object.fromEntries(Object.entries(formControl.errors || {}).filter(([ke]) => ke !== 'crossForm'));
                formControl.setErrors(
                    Object.keys(formErrors).length > 0 ? formErrors : null
                );
            } else {
                formControl.setErrors({ ...formControl.errors, ...{ crossForm: v }});
            }
        });
        const filteredResult = Object.fromEntries(Object.entries(result || {}).filter(([k,v]) => v !== false));
        return Object.keys(filteredResult).length > 0 ? filteredResult : null;
    };
}

export class GCAbstractForm<T> {
    public current?: FormGroup;
    public fb?: FormBuilder;

    constructor(public definition: GCFormDefinition<T>, private groupValidators?: CrossFormValidator<FC_G<T>>, protected dontSave?: typeof DONT_SAVE) { }

    getFormGroup(fb: FormBuilder, data: Partial<T>, isChild = false): FormGroup {
        this.fb = fb;
        this.current = fb.group(Object.fromEntries(Object.entries(this.definition).map(([key, def]) => {
            if (def instanceof GCFormDictionary)
                return [key, def.getFormDictionary(fb, (<Record<string, any>><unknown>data)?.[key], true)];
            else if (def instanceof GCFormArray)
                return [key, def.getFormArray(fb, (<Record<string, any[]>><unknown>data)?.[key], true)];
            else if (def instanceof GCFormGroup)
                return [key, def.getFormGroup(fb, (<Record<string, any>><unknown>data)?.[key], true)];
            else
                return [key, def];
        })), { validators: crossFieldValidator(this.groupValidators) });
        if (this.dontSave === DONT_SAVE) {
            this.current.noSaveInDraft = true;
        } else {
            Object.entries(this.definition).forEach(([key,def]) => {
                if (Array.isArray(def) && def.length > 3 && def[3] === DONT_SAVE)
                    this.current!.controls[key].noSaveInDraft = true;
            });
        }
        this.current.gcontrol = this as any;
        if (!isChild && data) {
            this.current.patchValue(data, { emitEvent: false });
            this.updateGroupValidators(this.current);
            this.current.updateValueAndValidity({ emitEvent: false, onlySelf: false});
        }
        return this.current;
    }

    updateGroupValidators(element: FormGroup): void {
        Object.entries(this.definition).forEach(([key, def]) => {
            if (def instanceof GCFormArray)
                def.updateArrayValidators(element.controls[key] as FormArray);
            else if (def instanceof GCFormGroup)
                def.updateGroupValidators(element.controls[key] as FormGroup);
        });
        element.updateValueAndValidity({ emitEvent: false, onlySelf: false});
    }

    /* WHY typescript... WHY???? */
    getFormArray(fb: FormBuilder, data: Partial<T>[], isChild = false): FormArray {
        throw new Error('Not imeplemented');
    }
    getFormDictionary(fb: FormBuilder, data: Record<string, Partial<T>>, isChild = false): FormGroup {
        throw new Error('Not imeplemented');
    }
    addElement(fa: FormArray, data: Partial<T>, isChild = false): FormGroup {
        throw new Error('Not imeplemented');
    }
    addDictElement(fg: FormGroup, key: string, data: Partial<T>, isChild = false): FormGroup {
        throw new Error('Not imeplemented');
    }
    updateArrayValidator(f: FormGroup) {
        throw new Error('Not imeplemented');
    }
    updateArrayValidators(element: FormArray): void {
        throw new Error('Not imeplemented');
    }
    updateDictionaryValidator(f: FormGroup) {
        throw new Error('Not imeplemented');
    }


}

export class GCFormGroup<T> extends GCAbstractForm<T> {}

export class GCFormDictionary<T extends TBase, TBase = T> extends GCAbstractForm<T> {
    public dictionary?: FormGroup;

    constructor(
        definition: GCFormDefinition<T>,
        public discriminator?: keyof TBase,
        public validators?: { [key: string]: { [field in keyof T]?: ValidatorFn[] } },
        groupValidators?: CrossFormValidator<FC_G<T>>,
        private dictionaryValidators?: CrossFormValidator<FC_G<T>>,
        dontSave?: typeof DONT_SAVE
    ) {
        super(definition, groupValidators, dontSave);
    }

    override getFormDictionary(fb: FormBuilder, data: Record<string, Partial<T>>, isChild = false): FormGroup {
        this.fb = fb;
        this.dictionary = fb.group({}, { validators: crossFieldValidator(this.dictionaryValidators) });
        Object.entries(data ?? {}).forEach(([key, value]) => {
            this.dictionary?.addControl(key, this.getFormGroup(<FormBuilder>this.fb, value as Partial<T>, true));
        });
        if (this.dontSave === DONT_SAVE)
            this.dictionary.noSaveInDraft = true;
        this.dictionary.gcontrol = this as GCFormDictionary<unknown>;
        (this.dictionary as any)._isDictionaryRoot = true;
        return this.dictionary;
    }

    override addDictElement(fg: FormGroup, key: string, data: Partial<T>, isChild = false): FormGroup {
        const f = this.getFormGroup(<FormBuilder>this.fb, data, true);
        if (!isChild && data) {
            f.patchValue(data, { emitEvent: false });
            this.updateDictionaryValidator(f);
        }
        fg.addControl(key as string, f);
        return f;
    }

    override updateDictionaryValidator(f: FormGroup) {
        if (!this.discriminator) return;
        if (this.validators?.[f.controls[<string>this.discriminator]?.value]) {
            Object.entries(this.definition).forEach(([key, def]) => {
                f.controls[key].setValidators(this.validators?.[f.controls[<string>this.discriminator]?.value][<keyof T>key] ?? null);
            });
        }
    }

    override updateGroupValidators(element: FormGroup): void {
        Object.entries(element.controls).forEach(([key, control]) => {
            this.updateDictionaryValidator(control as FormGroup);
            this.updateGroupValidators(control as FormGroup);
        });
    }

}

export class GCFormArray<T extends TBase, TBase = T> extends GCAbstractForm<T> {
    public array?: FormArray;

    constructor(
        definition: GCFormDefinition<T>,
        public discriminator?: keyof TBase,
        public validators?: { [key: string]: { [field in keyof T]?: ValidatorFn[] } },
        groupValidators?: CrossFormValidator<FC_G<T>>,
        private arrayValidators?: CrossFormValidator<FC_A<T>>,
        dontSave?: typeof DONT_SAVE
    ) {
        super(definition, groupValidators, dontSave);
    }

    override getFormArray(fb: FormBuilder, data: Partial<T>[], isChild = false): FormArray {
        this.fb = fb;
        this.array = fb.array([], { validators: crossFieldValidator(this.arrayValidators) });
        for (let i = 0; i < data?.length; i++)
            this.addElement(this.array, data[i], isChild);
        if (this.dontSave === DONT_SAVE)
            this.array.noSaveInDraft = true;
        this.array.gcontrol = this;
        return this.array;
    }

    override addElement(fa: FormArray, data: Partial<T>, isChild = false): FormGroup {
        const fg = this.getFormGroup(<FormBuilder>this.fb, data, true);
        if (!isChild && data) {
            fg.patchValue(data, { emitEvent: false });
            this.updateArrayValidator(fg);
        }
        fa.push(fg);
        return fg;
    }

    override updateArrayValidator(f: FormGroup) {
        if (!this.discriminator) return;
        if (this.validators?.[f.controls[<string>this.discriminator]?.value]) {
            Object.entries(this.definition).forEach(([key, def]) => {
                f.controls[key].setValidators(this.validators?.[f.controls[<string>this.discriminator]?.value][<keyof T>key] ?? null);
            });
        }
    }

    override updateArrayValidators(element: FormArray): void {
        for (const control of element.controls) {
            this.updateArrayValidator(control as FormGroup);
            this.updateGroupValidators(control as FormGroup);
        }
    }

}

export const DONT_SAVE = Symbol('DONT_SAVE');

export type GCFormField<T> = [ T | { value: T, disabled: boolean },
    (ValidatorFn | ValidatorFn[])?,
    (AsyncValidatorFn | AsyncValidatorFn[] | null)?,
    typeof DONT_SAVE?
];

export type GCFormDefinition<T> = {
    //Object TKey = keyname, T = Object -> Object literal aus T[TKey]
    [TKey in keyof T]?:
            (T[TKey] extends (infer N)[] ? (
                N extends DateTime | Duration | Uint8Array | string | number ? GCFormField<T[TKey]> : GCFormArray<N>
            ) :
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (T[TKey] extends {[P in string | number]?: any} ? (
                T[TKey] extends DateTime | Duration | Uint8Array ? GCFormField<T[TKey]> : (
                    T[TKey] extends Record<string, infer N> ? (
                        GCFormGroup<T[TKey]> | GCFormDictionary<N>
                    ) : GCFormGroup<T[TKey]>

                )
            ) :
            GCFormField<T[TKey]>))
};

export type FC_G<T> = FormGroup<{ [TKey in keyof T]?: FC<T[TKey]> }>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FC_A<N> = FormArray<FC<N> extends AbstractControl<any,any> ? FC<N> : AbstractControl<any,any>>;
export type FC<T> =
            (T extends (infer N)[] ? (
                N extends DateTime | Duration | Uint8Array | string | number ? FormControl<T> : FC_A<N>
            ) :
            (T extends {[P in string | number]: unknown} ? (
                T extends DateTime | Duration | Uint8Array ? FormControl<T> : FC_G<T>
            ) :
            FormControl<T>))
;

export type DraftFormType<T> = FC_G<T>;
