/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/no-outputs-metadata-property */
/* eslint-disable @angular-eslint/no-inputs-metadata-property */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { Chips } from "primeng/chips";
import { Wrapper } from "../decorators";
import { WrapperComponent } from "../base";

@Component({
    selector:      'gc-chip',
    encapsulation: ViewEncapsulation.None,
    template:      '<p-chip [formControl]="gcControl"><ng-content></ng-content></p-chip>',
    providers:     [{ provide: NG_VALUE_ACCESSOR, useExisting: GcChipComponent, multi: true }],
    inputs:        ["label", "icon", "image", "removable", "style", "styleClass", "removeIcon"],
    outputs:       ["onRemove", "onImageError"]
})
@Wrapper(Chips)
export class GcChipComponent extends WrapperComponent implements OnInit {
}
export interface GcChipComponent extends Chips {}
