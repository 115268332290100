// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host > * {
  margin-right: 1rem;
}
:host :last-child {
  margin-right: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/gc-user-list/gc-user-list.component.sass"],"names":[],"mappings":"AACI;EACI,kBAAA;AAAR;AACI;EACI,0BAAA;AACR","sourcesContent":[":host \n    > *\n        margin-right: 1rem\n    :last-child\n        margin-right: 0 !important"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
