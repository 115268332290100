import { Component } from "@angular/core";
import { environment } from "src/environments/environment";
import { HelpSidebarComponent } from "../help/help.sidebar.component";

@Component({
  selector:    'gc-law-sidebar',
  templateUrl: './law.sidebar.component.haml',
  styleUrls:   ['./law.sidebar.component.sass'],
})
export class LawSidebarComponent extends HelpSidebarComponent {
  readonly title: string = "Warnung";

  protected override get baseUrl(): string {
    return `${environment.helpBaseUrl}/legal`;
  }
}
