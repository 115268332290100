/* eslint-disable max-len */
/* eslint-disable @angular-eslint/no-inputs-metadata-property */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/no-outputs-metadata-property */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { Component, ElementRef, Input, ViewEncapsulation } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { Password } from "primeng/password";
import { WrapperComponent } from "../base";
import { Wrapper } from "../decorators";
import { ViewChild } from "@angular/core";

@Component({
    selector: 'gc-password',
    template: `<label *ngIf="label !== undefined" [for]="__internal_ID">{{ label }}</label>
                    <p-password #el [formControl]="gcControl" [id]="__internal_ID"><ng-content></ng-content></p-password>`,
    encapsulation: ViewEncapsulation.None,
    providers:     [{ provide: NG_VALUE_ACCESSOR, useExisting: GcPasswordComponent, multi: true }],
    inputs:        [
        "promptLabel", "weakLabel", "mediumLabel", "strongLabel", "feedback", "toggleMask", "appendTo", "inputStyle", "inputStyleClass", "inputId", "style",
        "styleClass", "placeholder", "label", "ariaLabel", "arialLabelledBy", "showClear"
    ],
    outputs: ["onBlur", "onFocus", "onClear"],
})
@Wrapper(Password)
export class GcPasswordComponent extends WrapperComponent {
    @Input() label: string;
    @ViewChild('el') inputEl: ElementRef<HTMLInputElement>;

    @Input() strongCheckRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
    @Input() mediumCheckRegExp = /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/;

    focus(options?: FocusOptions) {
        this.inputEl.nativeElement.focus(options);
    }
}
export interface GcPasswordComponent extends Password {};
