import { ComponentRef } from "@angular/core";
import { IPage } from "../page.interface";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export class BaseSidebarItem<T = IPage> {
    _componentRef!: ComponentRef<this>;
    destroy() {
        this._componentRef.destroy();
    }
};
