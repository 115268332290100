import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { ValidatorFlags, GCValidatorFn } from "src/app/modules/ngx-components";
import { DraftService } from "src/app/modules/ngx-draft";
import { DateTime } from "luxon";

export class GCValidators {
    static required: ValidatorFn;

    static {
        const required = (control: AbstractControl): ValidationErrors | null => Validators.required(control);
        required.flags = ValidatorFlags.required;
        this.required = required;
    }

    static conditional(condition: (form: FormGroup) => boolean, validators: ValidatorFn | ValidatorFn[]): ValidatorFn {
        const val = (control: AbstractControl): ValidationErrors | null => {
            if (condition(<FormGroup>control.root)) {
                if (Array.isArray(validators))
                    return Validators.compose(validators)(control);
                return validators(control);
            }
            return null;
        };
        Object.defineProperty(val, 'flags', {
            enumerable: true,
            get() {
                if (!condition(<FormGroup<unknown>><unknown>DraftService.instance.form))
                    return ValidatorFlags.none;
                if (!Array.isArray(validators))
                    return (<GCValidatorFn>validators).flags ?? ValidatorFlags.none;
                // eslint-disable-next-line no-bitwise
                return (<GCValidatorFn[]>validators).reduce((f,v) => f | v.flags ,ValidatorFlags.none);
            }
        });
        return <GCValidatorFn>val;
    }

    static byValue(path: string[], value: unknown, validators: ValidatorFn | ValidatorFn[]): ValidatorFn {
        const val = (control: AbstractControl): ValidationErrors | null => {
            if (control.root.get(path).value === value) {
                if (Array.isArray(validators))
                    return Validators.compose(validators)(control);
                return validators(control);
            }
            return null;
        };
        Object.defineProperty(val, 'flags', {
            enumerable: true,
            get() {
                if (DraftService.instance.form.root.get(path).value !== value)
                    return ValidatorFlags.none;
                if (!Array.isArray(validators))
                    return (<GCValidatorFn>validators).flags ?? ValidatorFlags.none;
                // eslint-disable-next-line no-bitwise
                return (<GCValidatorFn[]>validators).reduce((f,v) => f | v.flags ,ValidatorFlags.none);
            }
        });
        return <GCValidatorFn>val;
    }

    static notByValue(path: string[], value: unknown, validators: ValidatorFn | ValidatorFn[]): ValidatorFn {
        const val = (control: AbstractControl): ValidationErrors | null => {
            if (control.root.get(path).value !== value) {
                if (Array.isArray(validators))
                    return Validators.compose(validators)(control);
                return validators(control);
            }
            return null;
        };
        Object.defineProperty(val, 'flags', {
            enumerable: true,
            get() {
                if (DraftService.instance.form.root.get(path).value === value)
                    return ValidatorFlags.none;
                if (!Array.isArray(validators))
                    return (<GCValidatorFn>validators).flags ?? ValidatorFlags.none;
                // eslint-disable-next-line no-bitwise
                return (<GCValidatorFn[]>validators).reduce((f,v) => f | v.flags ,ValidatorFlags.none);
            }
        });
        return <GCValidatorFn>val;
    }

    static arrayLength(max?: number, min?: number): ValidatorFn {
        const res = (control: AbstractControl): ValidationErrors | null => {
            if (min && control.value.length < min)
                return { arrayLength: 'min' };
            if (max && control.value.length > max)
                return { arrayLength: 'max' };
            return null;
        };
        Object.defineProperty(res, 'flags', {enumerable: false, writable: false, value: ValidatorFlags.none});
        return <GCValidatorFn>res;
    }

    static beforeDateTime(before: DateTime): ValidatorFn {
        const res = (control: AbstractControl): ValidationErrors | null => {
            console.log("beforeDateTime:", control.value, !control.value || before.diff(control.value).valueOf());
            if (!control.value || before.diff(control.value).valueOf() < 0)
                return { dateTimeBefore: true };
            return null;
        };
        Object.defineProperty(res, 'flags', {enumerable: false, writable: false, value: ValidatorFlags.none});
        return <GCValidatorFn>res;
    }

    /**
     * checks for a number to be an exact fraction
     * 
     * @param fraction 
     * 
     * @example
     *  2 will allow 10; 10,5; 11 ...
     * 10 will allow one 10,1; 10,2 ...
     */
    static fractional(fraction: number): ValidatorFn {
        const res = (control: AbstractControl): ValidationErrors | null => {
            if (Math.abs(control.value - Math.floor(control.value * fraction) / fraction) > Number.EPSILON)
                return { wrongNumber: true };
            return null;
        };
        Object.defineProperty(res, 'flags', {enumerable: false, writable: false, value: ValidatorFlags.none});
        return <GCValidatorFn>res;
    }
}
