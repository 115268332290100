/* eslint-disable no-bitwise */
import { Permission } from "@vierkant-software/types__api";

export class Permissions {
    static readonly arraySize = 10;

    static getDefault() {
        return new Uint8Array(Permissions.arraySize);
    }
    static getAll() {
        const perms = this.getDefault();
        this.setPerms([...<Permission[]>Object.values(Permission).filter(x => !isNaN(+x))], perms);
        return perms;
    }

    static hasSomePerms(perms: Permission[], permissions?: Uint8Array) {
        if (!permissions)
            return false;
        return perms.some(perm => {
            const offset = Math.floor(perm / 8);
            return (permissions[offset] & (1 << (perm % 8))) !== 0;
        });
    }

    static hasAllPerms(perms: Permission[], permissions?: Uint8Array) {
        if (!permissions)
            return false;
        return perms.every(perm => {
            const offset = Math.floor(perm / 8);
            return (permissions[offset] & (1 << (perm % 8))) !== 0;
        });
    }

    static setPerms(perms: Permission[], permissions: Uint8Array = this.getDefault()) {
        perms.forEach(perm => {
            const offset = Math.floor(perm / 8);
            permissions[offset] |= (1 << (perm % 8));
        });
        return permissions;
    }


    static unsetPerms(perms: Permission[], permissions: Uint8Array) {
        perms.forEach(perm => {
            const offset = Math.floor(perm / 8);
            permissions[offset] &= ~(1 << (perm % 8)) & 0xff;
        });
        return permissions;
    }

    static mergePerms(...permissions: Uint8Array[]) {
        const len = Math.max(this.arraySize, ...permissions.map(x => x.byteLength));
        return permissions.reduce((prev, next) => {
            next.forEach((v,k) => prev[k] |= v);
            return prev;
        }, new Uint8Array(len));
    }

    static getPerms(permission: Uint8Array): Permission[] {
        const len = permission.byteLength;
        const out = [];
        let bit = 0;
        for (let i = 0; i < len; i++) {
            for (let j = 0; j < 8; j++) {
                if (permission[i] & 1 << j) out.push(bit);
                bit++;
            }
        }
        return out;
    }
}
