/* eslint-disable @angular-eslint/no-inputs-metadata-property */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { Component, ContentChildren, QueryList, ViewEncapsulation } from "@angular/core";
import { Card } from "primeng/card";
import { WrapperBase } from "../base";
import { Wrapper } from "../decorators";
import { GcTemplate } from "../directives/gcTemplate";

@Component({
    selector:      'gc-card',
    encapsulation: ViewEncapsulation.None,
    template:      `<p-card #component>
                        <ng-content></ng-content>
                        <ng-container  *ngFor="let template of templates">
                            <ng-template pTemplate="{{template.getType()}}">
                                <ng-container *ngTemplateOutlet="template.template"></ng-container>
                            </ng-template>
                        </ng-container>
                        <p-header>
                            <ng-content select="gc-header"></ng-content>
                        </p-header>
                        <p-footer>
                            <ng-content select="gc-footer"></ng-content>
                        </p-footer>
                </p-card>`,
    inputs: ["header", "subheader", "style", "styleClass"],
})
@Wrapper(Card)
export class GcCardComponent extends WrapperBase {
    @ContentChildren(GcTemplate) templates: QueryList<GcTemplate>;
}
export interface GcCardComponent extends Card {}
