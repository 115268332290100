/* eslint-disable max-len */
/* eslint-disable @angular-eslint/no-inputs-metadata-property */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { AfterViewInit, Component, ElementRef, Input, ViewChild, ViewEncapsulation, forwardRef } from "@angular/core";
import { FormControl, NG_VALUE_ACCESSOR } from "@angular/forms";
import { InputText } from "primeng/inputtext";
import { WrapperComponent } from "../base";
import { Wrapper } from "../decorators";

@Component({
    selector: 'gc-input-text',
    styles:   [
        `.p-inputtext{ width: 100% }`,
        `.length{
            position: absolute;
            top: 1px;
            right: 0.5rem;
            font-size: 0.75rem;
            color: rgba(var(--gc-color-light-contrast-rgb), 0.5);
        }`,
        `.gc-maxLength { position:relative; }`,
    ],
    template: `<label *ngIf="label !== undefined" [for]="__internal_ID">{{ label }}</label>
                <div [ngClass]="{'gc-maxLength': maxLength !== undefined}">
                    <input #el type="text" [id]="__internal_ID" pInputText pAutoFocus [autofocus]="autoFocus" [formControl]="gcControl" [placeholder]="placeholder ?? ''" [autocomplete]="autocomplete"/>
                    <span class="length" *ngIf="maxLength !== undefined">{{el.value.length}} / {{maxLength}} </span>
                </div>
                `,
    providers:     [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => GcInputTextComponent), multi: true }],
    inputs:        ["disabled"],
    encapsulation: ViewEncapsulation.None,
})
@Wrapper(InputText)
export class GcInputTextComponent extends WrapperComponent implements AfterViewInit {
    @Input() label: string;
    @Input() autoFocus: boolean;
    @Input() autocomplete?: string;
    @Input() placeholder?: string;
    @ViewChild('el') inputEl: ElementRef<HTMLInputElement>;
    maxLength?: number;

    focus(options?: FocusOptions) {
        this.inputEl.nativeElement.focus(options);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    override writeValue(obj: any): void {
        if (this.maxLength !== undefined && typeof obj === "string")
            obj = obj.slice(0, this.maxLength);

        super.writeValue(obj);
    }

    async ngAfterViewInit(){
        await null;
        this.setMaxLength();
    }

    setMaxLength(): void {
        const validator = this.formControlDirective?.form?.validator;
        if (!validator) return;
        const errors = validator(new FormControl({length: Infinity}));
        const length = errors?.maxlength?.requiredLength;
        if (length)this.maxLength = length;
    }
}
export interface GcInputTextComponent extends InputText {};
