// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.preview {
  float: left;
  background-color: #fff;
  border: 1px solid #000;
  width: 10.5cm;
  height: 14.85cm;
  overflow: hidden;
  display: flex;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/app/components/print-preview/print-preview.component.sass"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,sBAAA;EACA,sBAAA;EACA,aAAA;EACA,eAAA;EACA,gBAAA;EACA,aAAA;EACA,uBAAA;AACJ","sourcesContent":[".preview\n    float: left\n    background-color: #fff\n    border: 1px solid #000\n    width: 10.5cm\n    height: 14.85cm\n    overflow: hidden\n    display: flex\n    justify-content: center\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
