// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icons.p-button {
  pointer-events: none;
  background: var(--gc-color-secondary) !important;
  border: 1px solid var(--gc-color-secondary) !important;
  color: black !important;
  width: 2.357rem;
}`, "",{"version":3,"sources":["webpack://./src/app/sidebar/components/contractAdjustmentInfo/contractAdjustmentInfo.sidebar.component.sass"],"names":[],"mappings":"AAAA;EACI,oBAAA;EACA,gDAAA;EACA,sDAAA;EACA,uBAAA;EACA,eAAA;AACJ","sourcesContent":[".icons.p-button\n    pointer-events: none\n    background: var(--gc-color-secondary) !important\n    border: 1px solid var(--gc-color-secondary) !important\n    color: black !important\n    width: 2.357rem\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
