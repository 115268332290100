import { DateTime } from "luxon";
import { AppService } from "src/services/app.service";

export type Format = string | Intl.DateTimeFormatOptions;
export function formatDateTime(value: DateTime, format: Format, locale?: string){
    if (!value)
        return '';
    value = configureValue(value, locale);
    if (typeof format === "string")
        return value.toFormat(format);
    if (typeof format === "object")
        return value.toLocaleString(format);
    return value.toLocaleString();
}

function configureValue(value: DateTime, locale?: string) {
    value = value.setLocale(locale ?? AppService.instance.locale);
    return value;
}
