/* eslint-disable max-len */
/* eslint-disable @angular-eslint/no-inputs-metadata-property */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/no-outputs-metadata-property */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { Component, ViewEncapsulation } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { Slider } from "primeng/slider";
import { WrapperComponent } from "../base";
import { Wrapper } from "../decorators";

@Component({
    selector:      'gc-slider',
    encapsulation: ViewEncapsulation.None,
    template:      '<p-slider [formControl]="gcControl"><ng-content></ng-content></p-slider>',
    providers:     [{ provide: NG_VALUE_ACCESSOR, useExisting: GcSliderComponent, multi: true }],
    inputs:        ["animate", "disabled", "min", "max", "orientation", "step", "range", "style", "styleClass", "tabindex", "ariaLabelledBy"],
    outputs:       ["onChange", "onSlideEnd"]
})
@Wrapper(Slider)
export class GcSliderComponent extends WrapperComponent {
}
export interface GcSliderComponent extends Slider {};
