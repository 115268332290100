import { Injectable } from '@angular/core';
import { IPage, IPageOptions } from 'src/app/page.interface';
import { noUndefined } from 'src/util/object';
import { Constructor, methodsOf } from 'src/util/types/global';
import { PageOptionsRegistry } from './pageOptions.decorator';
import { AppService } from './app.service';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
const DEFAULTS: IPageOptions<unknown> = {
  showWrapper:       false,
  showInnerWrapper:  true,
  showBackground:    true,
  showRightSidebar:  false,
  useInnerAddButton: false,
  addButtonMethod:   '' as never,
};

@Injectable({
  providedIn: 'root'
})
export class PageService {
  currentOption: IPageOptions<unknown> = {};

  #currentComponent: unknown;
  #currentBaseComponent: unknown;

  get showLeftSideNavigation() {
    return !!this.currentOption.showLeftSideNavigation ?? false;
  }

  get showTopBar() {
    return this.currentOption.showTopBar ?? false;
  }

  get showWrapper() {
    return this.currentOption.showWrapper ?? false;
  }

  get showInnerWrapper() {
    return this.currentOption.showInnerWrapper ?? true;
  }

  get showBackground() {
    return this.currentOption.showBackground ?? true;
  }

  get baseIsHost() {
    return (this.#currentBaseComponent as { isHost?: boolean })?.isHost ?? false;
  }

  get showRightSidebar() {
    return this.currentOption.showRightSidebar ?? false;
  }

  get addSplitButtonMethods() {
    return Object.keys(this.currentOption.addButtonAdditionalMethods ?? {});
  }

  get title() {
    if (typeof this.currentOption.title === 'function')
      return this.currentOption.title({ app: AppService.instance, comp: this.#currentComponent });
    return this.currentOption.title ?? '';
  }

  get subTitle() {
    if (typeof this.currentOption.subTitle === 'function')
      return this.currentOption.subTitle({ app: AppService.instance, comp: this.#currentComponent });
    return this.currentOption.subTitle ?? '';
  }

  async __activate(target: IPage) {
    this.#currentComponent = target;
    const targetPageOptions = PageOptionsRegistry.pageOptions.get(target.constructor as Constructor) ?? {};
    const baseOptions = this.baseIsHost && PageOptionsRegistry.pageOptions.get(this.#currentBaseComponent.constructor as Constructor);
    this.currentOption = { ... this.currentOption, ... DEFAULTS, ... baseOptions || {}, ... noUndefined(targetPageOptions)};
    // console.log("PageActivate:", this.baseIsHost, targetPageOptions.showWrapper !== undefined);
    if (this.baseIsHost && !baseOptions && targetPageOptions.showWrapper === undefined)
      this.currentOption.showWrapper = true;
    if (baseOptions?.showWrapper !== undefined)
      this.currentOption.showWrapper = baseOptions.showWrapper;
    await target.componentActivated?.(this.currentOption);
    this.updateTitle();
    // console.log('PageOptions', this.currentOption);
  }

  updateTitle() {
    if (this.title || this.subTitle)
      this._title.setTitle(environment.APP_TITLE + ' - ' + this.title + ' - ' + this.subTitle);
    else if (this.title)
      this._title.setTitle(environment.APP_TITLE + ' - ' + this.title);
    else
      this._title.setTitle(environment.APP_TITLE);
  }

  __baseComponentActivate(target: IPage) {
    this.#currentBaseComponent = target;
  }

  setOption(option: IPageOptions) {
    this.currentOption = { ... this.currentOption, ... noUndefined(option) };
  }

  get #addButtonMethod() {
    if (typeof this.currentOption.addButtonMethod === 'function')
      return this.currentOption.addButtonMethod({ app: AppService.instance, comp: this.#currentComponent });
    return this.currentOption.addButtonMethod ?? '';
  }

  get hasAddButton() {
    return !this.currentOption.useInnerAddButton && this.#addButtonMethod;
  }

  get hasInnerAddButton() {
    return this.currentOption.useInnerAddButton && this.#addButtonMethod;
  }

  callAddButtonMethod() {
    if ((this.#currentComponent as Record<string,() => void>)[this.#addButtonMethod as methodsOf<unknown>])
      return (this.#currentComponent as Record<string,() => void>)[this.#addButtonMethod as methodsOf<unknown>]?.();
    else
      return (this.#currentBaseComponent as Record<string,() => void>)[this.#addButtonMethod as methodsOf<unknown>]?.();
  }

  callSplitButtonMethod(key: string) {
    if ((this.#currentComponent as Record<string,() => void>)[this.currentOption.addButtonAdditionalMethods?.[key]])
      return (this.#currentComponent as Record<string,() => void>)[this.currentOption.addButtonAdditionalMethods?.[key]]?.();
    else
      return (this.#currentBaseComponent as Record<string,() => void>)[this.currentOption.addButtonAdditionalMethods?.[key]]?.();
  }

  constructor(private _title: Title) {}
}
