// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  font-weight: bold;
  text-align: left;
}

h1 {
  margin: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/gc-price/gc-price.component.sass"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,gBAAA;AACJ;;AAAA;EACI,oBAAA;AAGJ","sourcesContent":["*\n    font-weight: bold\n    text-align: left\nh1\n    margin: 0 !important"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
