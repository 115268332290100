// "use asm";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BitField = void 0;

function BitField(buff, padTo) {
    if(padTo === undefined)
        padTo = 0;
    if (typeof buff === 'string') {
        var paddedBuffer = buff.padEnd(padTo, '0');
        this.buffer = new Uint32Array((paddedBuffer.length / 32) + ((paddedBuffer.length % 32) !== 0 ? 1 : 0));
        for (var i = 0; i < paddedBuffer.length; i++)
            if (paddedBuffer[i] === '1')
                this.set(i);
    } else if (typeof buffer === 'number') {
        this.buffer = new Uint32Array((buff / 32) + ((buff % 32) !== 0 ? 1 : 0));
    } else if (buff) {
        this.buffer = new Uint32Array(buff);
    } else {
        this.buffer = new Uint32Array(Math.ceil(padTo / 32));
    }
    return this;
}
Object.defineProperties(BitField.prototype, {
    size: { get: function() { return this.buffer.length * 32; }},
    value: { get: function() { return this.buffer; }},
    extend: { value: function(size) {
        var buffer = new Uint32Array((size / 32) + ((size % 32) !== 0 ? 1 : 0));
        buffer.set(this.buffer);
        this.buffer = buffer;
    }},
    clone: { value: function() {
        return new BitField(this.buffer);
    }},
    isSet: { value: function(index) {
        var byte = index / 32 | 0;
        var bit = index % 32 | 0;
        return (this.buffer[byte] & (1 << bit)) !== 0;
    }},
    set: { value: function(index) {
        var byte = index / 32 | 0;
        var bit = index % 32 | 0;
        this.buffer[byte] |= 1 << bit;
        return this;
    }},
    clear: { value: function(index) {
        var byte = index / 32 | 0;
        var bit = index % 32 | 0;
        this.buffer[byte] &= ~(1 << bit);
        return this;
    }},
    not: { value: function() {
        for (var i = 0; i < this.buffer.length; i++)
            this.buffer[i] = ~this.buffer[i];
        return this;
    }},
    and: { value: function(other) {
        for (var i = 0; i < this.buffer.length; i++)
            this.buffer[i] &= (other.buffer[i] !== undefined ? other.buffer[i] : 0);
        return this;
    }},
    or: { value: function(other) {
        for (var i = 0; i < this.buffer.length; i++)
            this.buffer[i] |= (other.buffer[i] !== undefined ? other.buffer[i] : 0);
        return this;
    }},
    xor: { value: function(other) {
        for (var i = 0; i < this.buffer.length; i++)
            this.buffer[i] ^= (other.buffer[i] !== undefined ? other.buffer[i] : 0);
        return this;
    }},
    nxor: { value: function(other) {
        for (var i = 0; i < this.buffer.length; i++)
            this.buffer[i] = ~(this.buffer[i] ^ (other.buffer[i] !== undefined ? other.buffer[i] : 0));
        return this;
    }},
    nor: { value: function(other) {
        for (var i = 0; i < this.buffer.length; i++)
            this.buffer[i] = ~(this.buffer[i] | (other.buffer[i] !== undefined ? other.buffer[i] : 0));
        return this;
    }},
    nand: { value: function(other) {
        for (var i = 0; i < this.buffer.length; i++)
            this.buffer[i] = ~(this.buffer[i] & (other.buffer[i] !== undefined ? other.buffer[i] : 0));
        return this;
    }},
    toString: { value: function() {
        var str = '';
        for (var i = 0; i < this.buffer.length; i++) {
            var byte = this.buffer[i];
            for (var j = 0; j < 32; j++) {
                var bit = byte & (1 << j);
                str += bit ? '1' : '0';
            }
        }
        return str;
    }}


});

exports.BitField = BitField;

