// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lightGrayBg {
  background: var(--gc-content-background);
  padding: 10px;
}

.whiteBg {
  background: var(--gc-background-color);
}

.min-height {
  min-height: 150px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/compare-dialog/compare-dialog.component.sass"],"names":[],"mappings":"AAAA;EACI,wCAAA;EACA,aAAA;AACJ;;AACA;EACI,sCAAA;AAEJ;;AAAA;EACI,iBAAA;AAGJ","sourcesContent":[".lightGrayBg\n    background: var(--gc-content-background)\n    padding: 10px\n\n.whiteBg\n    background: var(--gc-background-color)\n\n.min-height\n    min-height: 150px\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
