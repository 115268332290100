import { AfterViewInit, Component, HostBinding, Inject, OnInit, effect } from '@angular/core';
// eslint-disable-next-line max-len
import { ActivatedRoute, ChildActivationStart, NavigationCancel, NavigationCancellationCode, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { MenuItem, Message, PrimeNGConfig } from 'primeng/api';
import { AppService, InternalAppService } from 'src/services/app.service';
import { IPage } from './page.interface';
import { DOCUMENT } from '@angular/common';
import { MessageService } from 'primeng/api';
import { locale_de } from 'src/environments/locale.de';
// import { StudioSwitchSidebarComponent } from './components/gc-studio-switch-sidebar/gc-studio-switch-sidebar.component';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { EventType, IEventTaskStatus, ScheduledTaskStatus, ScheduledTaskType } from '@vierkant-software/types__api';
import { GcIconPack } from 'src/icons/gcIconPack';
import { IDraftConflict, DraftConflictResolution } from 'src/app/modules/ngx-draft/types/IAppService';
import { DraftService } from './modules/ngx-draft';
import { ShiftAIService } from 'src/services/shiftAI.service';

export enum StudioSwitchVisible {
    none,
    sidebar,
    fab,
}

@Component({
    selector:    'app-root',
    templateUrl: 'app.component.haml',
    styleUrls:   ['app.component.sass']
})
export class AppComponent implements OnInit, AfterViewInit {
    static instance: AppComponent;

    get modal_show() {
        return this.appService.modal_show;
    }

    set modal_show(value: boolean) {
        if (!value)
            this.appService.navigateModal(null).catch(x => null);
    }

    get modal_options() {
        return this.appService.modal_options;
    }

    #component: IPage & { breadcrumb?: MenuItem[] };  // TODO cleanup/define base component
    loading: boolean = false;

    get breadcrumb() {
        return this.#component?.breadcrumb || [];
    }

    @HostBinding('class.hmm') get isHmm() {
        return this.appService.theme() === 'hmm';
    }

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private primengConfig: PrimeNGConfig,
        private router: Router,
        private messageService: MessageService,
        private draftService: DraftService,
        private shiftAI: ShiftAIService,
        public appService: InternalAppService,
        activatedRoute: ActivatedRoute,
        faLibrary: FaIconLibrary,
        ) {
        AppComponent.instance = this;
        this.appService.rootActivatedRoute = activatedRoute;
        this.router.events.forEach(event => {
            if (event instanceof NavigationStart) {
                this.loading = true;
            } else if (
                event instanceof ChildActivationStart
            ) {
                appService.__clearResolverException(event.snapshot.outlet);
            } else if (
                event instanceof NavigationEnd
            ) {
                this.loading = false;
                this.appService.pageService.__baseComponentActivate(this.#component);
                if (!this.#component?.isHost)
                    this.appService.__activate(this.#component);
            } else if (
                event instanceof NavigationCancel ||
                event instanceof NavigationError
            ) {
                this.loading = false;
                // console.error(event);    
                // displays a shiny toast, if [].length > 0, 
                // otherwise display an generic nav error when app.component is the host (no draft)
                if (event instanceof NavigationCancel && event.code === NavigationCancellationCode.NoDataFromResolver)
                    this.letsGetToasty(appService.__getResolverExceptions());
            }
        }).catch(() => null);

        // TODO remove later - debug thingy
        appService.$Locale.subscribe(lang => this.document.documentElement.lang = lang);

        faLibrary.addIconPacks(fas);
        faLibrary.addIconPacks(GcIconPack);
        effect(() => {
            if (!this.appService.clientIsValid())
                this.router.navigate(['/version-to-old'], { skipLocationChange: true }).catch(console.error);
        });
    }

    ngOnInit() {
        this.primengConfig.ripple = true;
        this.primengConfig.setTranslation(locale_de);
    }

    ngAfterViewInit() {
        this.appService.$events.subscribe(ev => {
            console.log('events:', ev);
            if (ev?.data?.type === EventType.taskCompleted){
                if (ev?.data?.status?.type === ScheduledTaskType.WorkingTimeAnalysesSummary && ev?.data?.status?.taskID)
                    this.onAnalysesSummaryCompleted(ev.data.status as IEventTaskStatus);
                else if (ev?.data?.status?.type === ScheduledTaskType.TakeoverAbsenceEntitlements && ev?.data?.status?.taskID)
                    this.onAbsenceTransferCompleted(ev.data.status as IEventTaskStatus);
                else if (ev?.data?.status?.type === ScheduledTaskType.ExpiryAbsenceEntitlements && ev?.data?.status?.taskID)
                    this.onAbsenceExpirationCompleted(ev.data.status as IEventTaskStatus);
                else if (ev?.data?.status?.type === ScheduledTaskType.AbsenceEmployeeStats && ev?.data?.status?.taskID)
                    this.absenceEmployeeStats(ev.data.status as IEventTaskStatus);
                else if (ev?.data?.status?.type === ScheduledTaskType.SalaryStats && ev?.data?.status?.taskID)
                    this.salaryStats(ev.data.status as IEventTaskStatus);
                }
            });
        this.shiftAI.draftResultSubject.subscribe((x) => {
            // don't do anything if the relevant draft is open, as this scenario is handled there instead.
            if (!x || this.draftService.draftId === x) return;
            this.router.navigate(['$', 'calendar/shifts/planning', x, 'edit']).catch(err => err.displayErrorToast?.() ?? console.error(err));
        });
    }

    public onActivate(event: IPage) {
        this.#component = event;
    }

    public raiseLockIssue(_conflicts: IDraftConflict[]): Promise<DraftConflictResolution> {
        // TODO: add readonly button in new design @Sandra
        return new Promise((resolve, reject) => {
            AppService.instance.confirmationService.confirm({
                header:        "Entwurf-Konflikt",
                // eslint-disable-next-line max-len
                message:       "Es gab einen Konflikt mit einem offenen Entwurf. Sie können einen neuen Entwurf öffnen, wodurch die bisherigen Änderungen überschrieben werden.",
                acceptLabel:   "Offenen Entwurf überschreiben",
                rejectLabel:   "Abbrechen",
                accept:        () => resolve("overwrite"),
                reject:        () => resolve("cancel"),
                closeOnEscape: true,
            });
        });
    }

    // FIXME @Chris
    public items: MenuItem[] = [];

    letsGetToasty(arr: string[] = []) {
        if (arr.length > 0) {
            arr.forEach(err => {
                this.messageService.add({
                    severity: "error",
                    summary:  "Es ist ein Fehler aufgetreten!",
                    detail:   err,
                    life:     3000,
                });
            });
        } else {
            this.messageService.add({
                severity: "error",
                detail:   "Es ist ein Fehler aufgetreten!",
                life:     3000,
            });
        }
    }

  //#region StudioSwitch Logic // FIXME on/off logic
  // #studioSwitchVisible: StudioSwitchVisible = StudioSwitchVisible.none;
  // @ViewChild(StudioSwitchSidebarComponent, { static: true})studioSwitch: StudioSwitchSidebarComponent;
  // @ViewChild("content", { static: true})content: ElementRef;

  // set studioSwitchVisible(val: StudioSwitchVisible) {
  //   this.#studioSwitchVisible = val;
  //   if (val === StudioSwitchVisible.sidebar)
  //     this.studioSwitch.display = true;
  // }

  // get studioSwitchFABvisible() {
  //   return this.#studioSwitchVisible === StudioSwitchVisible.fab;
  // }

  // studioSwitchFABclicked() {
  //   this.studioSwitchVisible = StudioSwitchVisible.sidebar;
  // }
  //#endregion

    private sharedCompletedTask(taskStatus: IEventTaskStatus, taskType: ScheduledTaskType, severity: string, summary: string, detail: string, data?: unknown){
        if (!taskStatus?.taskID || taskStatus.type !== taskType) return;
        this.messageService.add({
            severity: severity,
            summary:  summary,
            detail:   detail,
            sticky:   true,
            id:       taskStatus.taskID,
            data
        });
    }

    private onAbsenceTransferCompleted(taskStatus: IEventTaskStatus){
        if (taskStatus.status !== ScheduledTaskStatus.completed) return;
        this.sharedCompletedTask(taskStatus, ScheduledTaskType.TakeoverAbsenceEntitlements,
            'success',
            'Guthaben übertragen',
            'Die Übertragung der Guthabenkonten wurde erfolgreich abgeschlossen.'
        );
    }

    private absenceEmployeeStats(taskStatus: IEventTaskStatus){
        if (taskStatus.status !== ScheduledTaskStatus.completed) return;
        const data = (message: Message) => this.openTaskResult(message, ['absence-selection', 'absence-details', message.id]);
        this.sharedCompletedTask(taskStatus, ScheduledTaskType.AbsenceEmployeeStats,
            'success',
            'Berechnung der Abwesenheiten abgeschlossen',
            'Die Berechnung der angeforderten Zusammenfassung ist abgeschlossen.',
            data
        );
    }

    private onAbsenceExpirationCompleted(taskStatus: IEventTaskStatus){
        if (taskStatus.status !== ScheduledTaskStatus.completed) return;
        const summary = 'Guthabenverfall';
        const detail = 'Der Verfall der Guthaben wurde erfolgreich gebucht.';
        this.sharedCompletedTask(taskStatus, ScheduledTaskType.ExpiryAbsenceEntitlements, 'success', summary, detail);
    }

    private onAnalysesSummaryCompleted(taskStatus: IEventTaskStatus){
        let detail: string | undefined;
        let data: (message: Message) => void;
        switch(taskStatus.status){
            case ScheduledTaskStatus.completed:
                detail = "Die Berechnung der Arbeitszeiten Zusammenfassung";
                if (taskStatus.createdAt)
                    detail += ` von ${taskStatus.createdAt.toFormat('dd.MM.yyyy HH:mm')} Uhr`;
                detail += ' ist abgeschlossen';
                data = (message: Message) => this.openTaskResult(message, ['analyses','working-time','working-time-summary',message.id]);
                this.sharedCompletedTask(taskStatus, ScheduledTaskType.WorkingTimeAnalysesSummary,
                    "success",
                    "Berechnung der Arbeitszeiten abgeschlossen",
                    detail,
                    data
                );
                break;
            case ScheduledTaskStatus.failure:
                if (taskStatus.errorMessage) {
                    detail = taskStatus.errorMessage;
                } else {
                    detail = "Bei der Berechnung der Arbeitszeiten Zusammenfassung";
                    if (taskStatus.createdAt)
                        detail += ` von ${taskStatus.createdAt.toFormat('dd.MM.yyyy HH:mm')} Uhr`;
                    detail += ' trat ein Fehler auf';
                }
                this.sharedCompletedTask(taskStatus, ScheduledTaskType.WorkingTimeAnalysesSummary,
                    "error",
                    "Fehler bei der Berechnung der Arbeitszeiten",
                    detail
                );
                break;
        }
	}

    private salaryStats(taskStatus: IEventTaskStatus){
        if (taskStatus.status !== ScheduledTaskStatus.completed) return;
        const data = (message: Message) => this.openTaskResult(message, ['salaries', 'salaries-detail', message.id]);
        this.sharedCompletedTask(taskStatus, ScheduledTaskType.SalaryStats,
            'success',
            'Berechnung der Stundensätze abgeschlossen',
            'Die Berechnung der angeforderten Zusammenfassung ist abgeschlossen.',
            data
        );
    }

    private openTaskResult(message: Message, nav: string[]){
        this.messageService.clear(message.key);
        if (message?.id)
            this.router.navigate(nav).catch(console.error);
    }

    public isFunction(value: unknown) {
        return typeof value === "function";
    }
    public isObject(value: unknown) {
        return typeof value === "object";
    }
}
