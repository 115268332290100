// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.circle-icon, .grayBg, .purpleBg, .yellowBg, .greenBg, .blueBg {
  border-radius: 50%;
  height: 120px;
  width: 120px;
  font-size: 25px;
  text-align: center;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  cursor: pointer;
}

.blueBg {
  background: rgb(58, 134, 180);
  background: linear-gradient(45deg, rgb(0, 116, 173) 0%, rgb(0, 197, 210) 45%, rgb(0, 116, 173) 100%);
}

.greenBg {
  height: 140px !important;
  width: 140px !important;
  font-size: 1.4rem !important;
  background: rgb(180, 255, 218);
  background: linear-gradient(45deg, rgb(10, 139, 76) 0%, rgb(76, 188, 134) 45%, rgb(10, 139, 76) 100%);
}

.yellowBg {
  background: rgb(224, 242, 112);
  background: linear-gradient(45deg, #e1f270 0%, rgb(255, 207, 35) 45%, rgb(255, 214, 153) 100%);
}

.purpleBg {
  background: rgb(159, 152, 203);
  background: linear-gradient(45deg, rgb(38, 40, 43) 0%, rgb(60, 19, 149) 45%, rgb(159, 152, 203) 100%);
}

.grayBg {
  background: rgb(242, 242, 242);
  background: linear-gradient(45deg, rgb(73, 80, 87) 0%, rgb(114, 130, 146) 45%, rgb(73, 80, 87) 100%);
}`, "",{"version":3,"sources":["webpack://./src/app/components/gradient-button/gradient-button.component.sass"],"names":[],"mappings":"AAGA;EACI,kBAAA;EACA,aAAA;EACA,YAAA;EACA,eAAA;EACA,kBAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,iBAAA;EACA,eAAA;AAFJ;;AAIA;EACI,6BAAA;EACA,oGAAA;AADJ;;AAIA;EACI,wBAAA;EACA,uBAAA;EACA,4BAAA;EACA,8BAAA;EACA,qGAAA;AADJ;;AAIA;EACI,8BAAA;EACA,8FAAA;AADJ;;AAIA;EACI,8BAAA;EACA,qGAAA;AADJ;;AAIA;EACI,8BAAA;EACA,oGAAA;AADJ","sourcesContent":["$height: 100px\n$width: 100px\n$fontSize: 1rem\n.circle-icon\n    border-radius: 50%\n    height: calc($height * 1.2)\n    width: calc($width * 1.2)\n    font-size: 25px\n    text-align: center\n    color: white\n    display: flex\n    justify-content: center\n    align-items: center\n    font-size: calc($fontSize * 1.2)\n    cursor: pointer\n    \n.blueBg\n    background: rgb(58,134,180)\n    background: linear-gradient(45deg, rgba(0,116,173,1) 0%, rgba(0,197,210,1) 45%, rgba(0,116,173,1) 100%)\n    @extend .circle-icon\n\n.greenBg\n    height: calc($height * 1.4)   !important  // set active class\n    width: calc($width * 1.4)   !important  // set active class\n    font-size: calc($fontSize * 1.4) !important  // set active class\n    background: rgb(180,255,218)\n    background: linear-gradient(45deg, rgba(10,139,76,1) 0%, rgba(76,188,134,1) 45%, rgba(10,139,76,1) 100%)\n    @extend .circle-icon\n\n.yellowBg\n    background: rgb(224,242,112)\n    background: linear-gradient(45deg, #e1f270 0%, rgba(255,207,35,1) 45%, rgba(255,214,153,1) 100%)\n    @extend .circle-icon\n\n.purpleBg\n    background: rgb(159,152,203)\n    background: linear-gradient(45deg, rgba(38,40,43,1) 0%, rgba(60,19,149,1) 45%, rgba(159,152,203,1) 100%)\n    @extend .circle-icon\n\n.grayBg\n    background: rgb(242,242,242)\n    background: linear-gradient(45deg, rgba(73,80,87,1) 0%, rgba(114,130,146,1) 45%, rgba(73,80,87,1) 100%)\n    @extend .circle-icon"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
