/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/no-outputs-metadata-property */
/* eslint-disable max-len */
/* eslint-disable @angular-eslint/no-inputs-metadata-property */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { Component, Input, ViewEncapsulation } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { ColorPicker } from "primeng/colorpicker";
import { WrapperComponent } from "../base";
import { Wrapper } from "../decorators";

@Component({
    selector:      'gc-colorPicker',
    styles:        ['p-colorPicker.block .p-colorpicker{ display: block }'],
    encapsulation: ViewEncapsulation.None,
    template:      `<label *ngIf="label !== undefined" [for]="__internal_ID">{{ label }}</label>
                    <p-colorPicker [ngClass]="{block: !!label}" [formControl]="gcControl" [id]="__internal_ID"><ng-content></ng-content></p-colorPicker >`,
    providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: GcColorPickerComponent, multi: true }],
    inputs:    ["style", "styleClass", "inline", "format", "appendTo", "tabindex", "disabled", "inputId", "baseZIndex", "autoZIndex", "showTransitionOptions", "hideTransitionOptions"],
    outputs:   ["onChange", "onShow", "onHide"]
})
@Wrapper(ColorPicker)
export class GcColorPickerComponent extends WrapperComponent {
    @Input() label: string;
}
export interface GcColorPickerComponent extends ColorPicker {};
