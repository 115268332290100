// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  position: relative;
}
:host > .content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}

:host.full-page {
  margin: calc(-1 * var(--wrapper-gap));
}
:host.full-page > .content {
  padding: var(--wrapper-gap);
  top: var(--wrapper-gap);
}`, "",{"version":3,"sources":["webpack://./src/app/components/layout/scroll-container/scroll-container.component.sass"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;AAAI;EACI,kBAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,cAAA;AAER;;AAAA;EACI,qCAAA;AAGJ;AAFI;EACI,2BAAA;EACA,uBAAA;AAIR","sourcesContent":[":host\n    position: relative\n    > .content\n        position: absolute\n        top: 0\n        left: 0\n        right: 0\n        bottom: 0\n        overflow: auto\n\n:host.full-page\n    margin: calc(-1*var(--wrapper-gap))\n    > .content\n        padding: var(--wrapper-gap)\n        top: var(--wrapper-gap)"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
