/* eslint-disable @typescript-eslint/space-before-function-paren */
import { ViewChildren } from "@angular/core";
import { AbstractControl, FormGroup, FormGroupDirective } from "@angular/forms";
import { Subscription, timer } from "rxjs";
import { debounce, tap } from "rxjs/operators";
import equal from 'fast-deep-equal/es6';
import { stepComp } from "../types/step-definition";
import { DraftService } from "./draft.service";

export function DraftFormChanged(watch: string[], x: {debounce?: number, direct: true}): (target: stepComp, propertyKey: string) => void;
export function DraftFormChanged(watch: string[][] | string[], x?: {debounce?: number, direct?: false}): (target: stepComp, propertyKey: string) => void;
export function DraftFormChanged(watch: string[][] | string[], {debounce: debounceTime, direct}: {debounce?: number, direct?: boolean} = {}) {
    return (target: stepComp, propertyKey: string) => {
        const oldOnResolve = target.onResolve;
        const oldOnDeactivate = target.onDeactivate;
        let subs: Subscription;
        if (watch.length < 1)
            return;
        if (!Array.isArray(watch[0]))
            watch = [watch as string[]];
        let values: Record<string | number | symbol, unknown>;
        let calling = false;
        target.onResolve = function(data: unknown) {
            values = Object.fromEntries((<string[][]>watch).map((w) => [w.join('.'), DraftService.instance.form.get(w)?.value]));
            let form = DraftService.instance.form as AbstractControl;
            if (direct)
                form = form.get(watch[0]);
            let vc = form.valueChanges;
            if (debounceTime)
                vc = vc.pipe(debounce(() => timer(debounceTime)));
            subs = vc.subscribe((ev) => {
                const nv =
                    (<string[][]>watch).map((w) => [w.join('.'), DraftService.instance.form.get(w)?.value])
                         .filter(([key, newValue]) => !equal(values[key], newValue));
                if ((direct || nv.length > 0) && !calling) {
                    calling = true;
                    (<Record<string, (event: unknown) => void>>target)[propertyKey].apply(this, [ev]);
                }
                calling = false;
                values = Object.fromEntries((<string[][]>watch).map((w) => [w.join('.'), DraftService.instance.form.get(w)?.value]));
            });
            oldOnResolve?.apply(this, [data]);
        };
        target.onDeactivate = function() {
            subs.unsubscribe();
            return oldOnDeactivate?.call(this);
        };
    };
}

export function DraftForm({debounce: debounceTime}: {debounce?: number} = {}) {
    return (target: stepComp, propertyName: string) => {
        Object.defineProperty(target, propertyName, {
                enumerable: true,
                get(this: stepComp) {
                    return DraftService.instance.form;
                }
        });
        ViewChildren(FormGroupDirective)(target, 'ɵControlContainer');
        if ((<{ propDecorators: { ɵControlContainer: {args: unknown[], type: unknown }[]}}><unknown>target.constructor).propDecorators) {
            (<{ propDecorators: { ɵControlContainer: {args: unknown[], type: unknown }[]}}><unknown>target.constructor).propDecorators.ɵControlContainer = [{
                args: [ FormGroupDirective ],
                type: ViewChildren  //TODO: CHECK THIS IN PRODUCTION!!!
            }];
            //console.log("PD: ", (target.constructor.__prop__metadata__), (target.constructor.propDecorators));
        }
        const originlAfterViewChecked = target.ngAfterViewChecked;
        // eslint-disable-next-line @typescript-eslint/space-before-function-paren
        target.ngAfterViewChecked = <(revalidate?: boolean) => void> async function(revalidate: boolean = false) {
            const draftService = DraftService.instance;
            if (!this.ɵControlContainer)
                return;
            if (!this.constructor.disableWarning?.includes('multiForm') && this.ɵControlContainer.length > 1) {
                console.warn(target.constructor.name, "has more than 1 FormGroup ", this.ɵControlContainer);
                if (this.constructor.disableWarning)
                    this.constructor.disableWarning.push('multiForm');
                else this.constructor.disableWarning = ['multiForm'];
            }
            const valid = (this.ɵControlContainer.length === 0) ||
                !this.ɵControlContainer?.some((fg: FormGroupDirective) => !fg.directives.every((d) => d.valid || d.disabled));
            // eslint-disable-next-line no-bitwise
            if (draftService.debug > 1 && this.ɵControlContainer.length > 0)
                console.log('DEBUG draft_validator step: ', this.constructor.name, 'valid : ', valid, 'containers :', this.ɵControlContainer);
            //console.log('VALIDATE: ', this.ɵAnchor, valid, this.ɵControlContainer);
            if (typeof valid === 'boolean' && (revalidate || draftService.isStepValid[this.ɵAnchor] !== valid)) {
                //console.log("REVALIDATE: ", this.constructor.name, this.ɵControlContainer?.first?.directives);
                await null;
                //console.log('cont: ', (<Injector>this.ɵinjector).get(ControlContainer));
                draftService.isStepValid[this.ɵAnchor] = valid;
            }
            originlAfterViewChecked?.apply(this);
        };
        if (target.onFormChanged || target.onFormChangedImmed) {
            const oldOnResolve = target.onResolve;
            const oldOnDeactivate = target.onDeactivate;
            let unsubs: Subscription;
            target.onResolve = function(data: unknown) {
                unsubs = (<FormGroup>this[propertyName]).valueChanges
                    .pipe(
                        tap((values) => this.onFormChangedImmed?.(values)),
                        debounce(
                            () => timer(debounceTime ?? 1000)
                        ))
                    .subscribe((values) => this.onFormChanged?.(values));
                return oldOnResolve?.call(this, data);
            };
            target.onDeactivate = function() {
                unsubs.unsubscribe();
                return oldOnDeactivate?.call(this);
            };

        }
    };
}

export function DraftButtons() {
    return (target: stepComp, propertyName: string) => {
        Object.defineProperty(target, 'ɵDraftButtons', {
            enumerable:   true,
            configurable: false,
            get(this: stepComp) {
                return this[<keyof stepComp>propertyName];
            }
        });
    };
}
