import { Component, EventEmitter, forwardRef, HostListener, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export type IDualSelectOptions<T = string> = {
  label: string;
  id: T;
  selected?: boolean;
  R_highlighted?: boolean;
  R_hide?: boolean;
  L_highlighted?: boolean;
  L_hide?: boolean;
};

export interface DualSelectEvent<T = string> extends Event {
  selected: T[];
}

/**
* @ignore - do not show in documentation
*/
@Component({
  selector:    'gc-dualselect',
  templateUrl: './dual-multi-select.component.haml',
  styleUrls:   ['./dual-multi-select.component.sass'],
  providers:   [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DualMultiSelectComponent), multi: true }
  ]
})
export class DualMultiSelectComponent<T = string> implements ControlValueAccessor {

  @Input() leftHeader: string = "Verfügbar";
  @Input() rightHeader: string = "Ausgewählt";
  @Input() set options(value: IDualSelectOptions<T>[]) {
    this.#options = value ?? [];
    this.#aoCache = this.#naoCache = this.lastIdx = undefined;
    const ids = this.#options.map(x => x.id);
    this.value = this.value.filter(x => ids.includes(x));
  };

  #options: IDualSelectOptions<T>[];

  @Output() changed: EventEmitter<DualSelectEvent<T>> = new EventEmitter();

  onChange = (value: T[]) => {};
  onTouch = () => {};

  value: T[] = [];

  shift: boolean = false;
  ctrl: boolean = false;
  lastIdx: number;
  lastField: 'left' | 'right' = 'left';

  #naoCache: IDualSelectOptions<T>[];
  get nonActiveOptions() {
    return this.#naoCache || (this.#naoCache = this.#options.filter(entry => !this.value.includes(entry.id)));
  }
  #aoCache: IDualSelectOptions<T>[];
  get activeOptions() {
    return this.#aoCache || (this.#aoCache = this.#options.filter(entry => this.value.includes(entry.id)));
  }

  constructor() { }

  writeValue(obj: T[]): void {
    this.value = obj ?? [];
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  moveRight(event: MouseEvent) {
    const ids = this.#options
      .filter(entry => entry.selected)
      .filter(entry => !this.value.includes(entry.id))
      .map(x => x.id);
    this.value.push(...ids);
    this.#aoCache = this.#naoCache = undefined;
    this.onChange(this.value);
    (<DualSelectEvent<T>><unknown>event).selected = this.value;
    this.changed.emit(<DualSelectEvent<T>><unknown>event);
  }

  moveLeft(event: MouseEvent) {
    const ids = this.#options
      .filter(entry => entry.selected)
      .filter(entry => this.value.includes(entry.id))
      .map(x => x.id);
    this.value = this.value.filter(x => !ids.includes(x));
    this.#aoCache = this.#naoCache = undefined;
    this.onChange(this.value);
    (<DualSelectEvent<T>><unknown>event).selected = this.value;
    this.changed.emit(<DualSelectEvent<T>><unknown>event);
  }

  doubleClick(entry: IDualSelectOptions<T>) {
    const active = this.value.includes(entry.id);
    if (active)
      this.value = this.value.filter(x => x !== entry.id);
    else
      this.value.push(entry.id);
    entry.selected = false;
    this.#aoCache = this.#naoCache = undefined;
    this.onChange(this.value);
    (<DualSelectEvent<T>><unknown>event).selected = this.value;
    this.changed.emit(<DualSelectEvent<T>><unknown>event);
  }

  unselect(active: boolean) {
    if (active)
      this.activeOptions.forEach(x => x.selected = false);
    else
      this.nonActiveOptions.forEach(x => x.selected = false);
    this.lastField = active ? 'right' : 'left';
  }

  touch(entry: IDualSelectOptions<T>, event: Event) {
    const oldCtrl = this.ctrl;
    this.ctrl = true;
    this.select(entry, event);
    this.ctrl = oldCtrl;
    event.stopPropagation();
    event.preventDefault();
  }

  select(entry: IDualSelectOptions<T>, event: Event) {
    const active = this.value.includes(entry.id);
    if (active)
      this.lastField = 'right';
    else
      this.lastField = 'left';
    this.#options.forEach(x => this.value.includes(x.id) === active || (x.selected = false));
    if (this.lastIdx !== undefined && this.value.includes(this.#options[this.lastIdx].id) !== active)
      this.lastIdx = undefined;
    if (!this.ctrl && (!this.shift || this.lastIdx === undefined)) {
      this.#options.forEach(x => this.value.includes(x.id) === active && (x.selected = false));
      entry.selected = true;
      this.lastIdx = this.#options.indexOf(entry);
    } else if (this.ctrl && (!this.shift || this.lastIdx === undefined)) {
      entry.selected = !entry.selected;
      if (entry.selected)
        this.lastIdx = this.#options.indexOf(entry);
      else
        this.lastIdx = undefined;
    } else if (this.shift && this.ctrl) {
      const idx = this.#options.indexOf(entry);
      if (idx > (this.lastIdx)) {
        for (let i = this.lastIdx + 1; i <= idx; i++) {
          if (this.value.includes(this.#options[i].id) === active && ((active && !this.#options[i].R_hide) || (!active && !this.#options[i].L_hide)))
            this.#options[i].selected = !this.#options[i].selected;
        }
      } else {
        for (let i = idx + 1; i <= this.lastIdx; i++) {
          if (this.value.includes(this.#options[i].id) === active && ((active && !this.#options[i].R_hide) || (!active && !this.#options[i].L_hide)))
            this.#options[i].selected = !this.#options[i].selected;
        }
      }
      this.lastIdx = idx;
    } else if (this.shift && !this.ctrl) {
      const idx = this.#options.indexOf(entry);
      this.#options.forEach(
        (x, i) =>
          this.value.includes(x.id) === active &&
          ((active && !x.R_hide) || (!active && !x.L_hide)) &&
          (x.selected = i >= Math.min(idx, this.lastIdx) && i <= Math.max(idx, this.lastIdx))
      );
    }
    event.stopPropagation();
  }

  @HostListener('document:keyup', ['$event', 'true'])
  @HostListener('document:keydown', ['$event', 'false'])
  key(event: KeyboardEvent, down: boolean) {
    if (event.repeat)
      return;
    this.shift = event.shiftKey;
    this.ctrl = event.ctrlKey;
    if (this.ctrl && event.key === 'a' && down) {
      if (this.lastField === 'left') {
        this.nonActiveOptions.forEach(x => x.selected = true);
        this.activeOptions.forEach(x => x.selected = false);
      } else {
        this.activeOptions.forEach(x => x.selected = true);
        this.nonActiveOptions.forEach(x => x.selected = false);
      }
      event.preventDefault();
    }
  }

}
