import { h24Icon } from "./h24";
import { h24NoIcon } from "./h24-no";
import { RfidIcon } from "./rfid.icon";
import { ThumbTackIcon } from "./thumbtack.icon";
import { NoTrashIcon } from "./no-trash.icon";
import { IconPack } from "@fortawesome/fontawesome-svg-core";
import { NoHouseIcon } from "./no-house";

export const GcIconPack: IconPack = {
    thumbtack: ThumbTackIcon,
    h24:       h24Icon,
    h24No:     h24NoIcon,
    rfid:      RfidIcon,
    noTrash:   NoTrashIcon,
    noHouse:   NoHouseIcon,
} as IconPack;
