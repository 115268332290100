import { HttpClient, HttpXhrBackend } from "@angular/common/http";
import { Component, Input, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import { BaseSidebarItem } from "../../base.sidebar.item";

@Component({
  selector:    'gc-help-sidebar',
  templateUrl: './help.sidebar.component.haml',
  styleUrls:   ['./help.sidebar.component.sass'],
})
export class HelpSidebarComponent extends BaseSidebarItem<never> implements OnInit {
  @Input() public name: string;
  content: string;

  http = new HttpClient(new HttpXhrBackend({
    build: () => new XMLHttpRequest()
  }));


  get displayTitle() {
    return this.name.replace('-', ' ');
  }

  protected get baseUrl() {
    return environment.helpBaseUrl;
  }

  ngOnInit() {
    this.fetchHelpPage(this.name).catch(console.error);
  }

  async fetchHelpPage(name: string){
    //TODO: temp till deployment works. will throw cors
    this.http.get(`${this.baseUrl}/${encodeURIComponent(name)}.md`)
      .subscribe(x => this.content = x.toString());
  }
}
