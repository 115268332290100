import { Component,Input } from '@angular/core';
import { ContactDomain, ContactType, IContact } from '@vierkant-software/types__api';

const ContactTypeDefs = {
	[ContactType.Undefined]: { label: '',icon: 'address-book',inputType: 'text' },
	[ContactType.EMail]:     { label: 'E-Mail', icon: 'at', inputType: 'email' },
	[ContactType.Phone]:     { label: 'Telefon', icon: 'phone', inputType: 'tel' },
	[ContactType.Mobile]:    { label: 'Mobil', icon: 'mobile-screen-button', inputType: 'tel' },
	[ContactType.Fax]:       { label: 'Fax', icon: 'fax', inputType: 'tel' },
};

const ContactDomainDefs: Record<ContactDomain, string> = {
	[ContactDomain.Undefined]: undefined,
	[ContactDomain.Private]:   "Privat",
	[ContactDomain.Business]:  "Geschäftlich",
};


/**
 * Display contact information of various types. Accepts an `IContact` object.
 * 
 * @name    Contact
 * @tags    Display, User, Data
 * 
 * @example Phone
 * ```
 * %gc-contact{[value]: "demo.phone"}
 * ```
 * 
 * @example Mobile
 * ```
 * %gc-contact{[value]: "demo.mobile"}
 * ```
 * 
 * @example E-Mail
 * ```
 * %gc-contact{[value]: "demo.eMail"}
 * ```
 * 
 * @example Fax
 * ```
 * %gc-contact{[value]: "demo.fax"}
 * ```
 */
@Component({
    selector: 'gc-contact',
    template: `
<div *ngIf="_parsedValue" class="gc-contact flex flex-wrap" [style.flex-direction]="orientation">
    <div class="gc-contact-key" [class.expand]="showKey">
        <gc-icon *ngIf="hasIcon" [icon]="_parsedValue.icon" mode="fa"></gc-icon>
        <label *ngIf="showKey">
            {{_parsedValue.key}}
            <span *ngIf="_parsedValue.domain" class="gc-contact-type">{{_parsedValue.domain}}</span>
        </label>
    </div>
    <div class="gc-contact-value">{{_parsedValue.value}}</div>
</div>
`,
    styles: [
        ".gc-contact .gc-contact-key { max-width: 175px; font-size: 1rem; }",
        ".gc-contact .gc-contact-key gc-icon{ display: inline-block; width: 16px; height: 16px; margin-right: 0.25rem }",
        ".gc-contact .gc-contact-key .gc-contact-type{ font-size: 0.75rem; font-style: italic; opacity: 0.70; }",
        ".gc-contact .gc-contact-value { flex-grow: 1; }",
        ".expand { width: 100% }"
    ]
})
export class GcContactComponent {

    /**
     * Contact information to display.
     * 
     * @example
     * ```
     * %gc-contact{[value]: "{type: ContactType.Phone , value: '+123 45679', isPrimary: true, domain: ContactDomain.Business, }"}
     * ```
     */
    @Input() public set value(value: IContact) {
        this._internalValue = value;
        this.updateValue();
    }

    @Input() hasIcon = true;
    /**
     * 
     * @example Key
     * ```
     * %gc-contact{[value]: "{type: ContactType.Phone , value: '+123 45679', domain: ContactDomain.Business, isPrimary: true}", [showKey]: "true"}
     * ```
     * @example No key
     * ```
     * %gc-contact{[value]: "{type: ContactType.Phone , value: '+123 45679', domain: ContactDomain.Business, isPrimary: true}", [showKey]: "false"}
     * ```
     * @default true
     */
    @Input() showKey = true;

    /**
     * Displays contact as block or inline. Setting the orientation to row will display it in one line,
     * while setting it to column will display it as a block.
     * 
     * @example Row
     * ```
     * %gc-contact{[value]: "{type: ContactType.Phone , value: '+123 45679', isPrimary: true, domain: ContactDomain.Business, }", orientation: "row"}
     * ```
     * @example Column
     * ```
     * %gc-contact{[value]: "{type: ContactType.Phone , value: '+123 45679', isPrimary: true, domain: ContactDomain.Business, }", orientation: "column"}
     * ```
     * @default "row"
     */
    @Input() orientation: "row" | "column" = "row";


    _internalValue: IContact;
    _parsedValue: {key: string, value: string, domain: string, icon: string};

    updateValue(){
        this._parsedValue = {
            key:    this.getLabel(),
            icon:   this.getIcon(),
            value:  this.getValue(),
            domain: this.getDomain(),
        };
    }

    getLabel(): string {
        return (
            ContactTypeDefs[this._internalValue.type]?.label ??
            ContactTypeDefs[ContactType.Undefined].label
        );
    }

    getIcon(): string {
        return (
            ContactTypeDefs[this._internalValue.type]?.icon ??
            'address-book'
        );
    }

    getValue() {
        return this._internalValue.value;
    }

    getDomain() {
        return (
            ContactDomainDefs[this._internalValue.domain] ??
            ContactDomainDefs[ContactType.Undefined]
        );
    }
}
