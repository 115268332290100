import { Component } from "@angular/core";
import { PersonOverviewPage } from "src/app/pages/person/id/overview/overview.page";
import { BaseSidebarItem } from "../../base.sidebar.item";

@Component({
    selector:    'gc-personShortcut-sidebar',
    templateUrl: './person-shortcut.sidebar.component.haml',
    styleUrls:   ['./person-shortcut.sidebar.component.sass'],
  })
export class PersonShortcutSidebarComponent extends BaseSidebarItem<PersonOverviewPage>{
    readonly title: string = "Einstellungen";
    selected: string[] = [];
    shortcuts: string[] = [];

    constructor(private mainComponent: PersonOverviewPage){
      super();
      // FIXME filter these things regardig permissions
      // service.getShortcuts('').then(x => this.shortcuts = x).catch(x => { throw x; });
    }
//called in haml to set the shortcut buttons on select/unselect from sidebar list
    setShortcuts(){
      (this.mainComponent).selectedShortcuts = this.selected;
    }

    async resetShortcuts(){
      // FIXME same as above
      // this.selected = await this.service.getDefaultShortcuts('');
      this.setShortcuts();
    }
}
