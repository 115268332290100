// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.summary {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.summary-box, .scale {
  padding: 0.5rem;
  flex: 100%;
}

.color {
  width: 85px;
  height: 55px;
  border-radius: 3px;
}

@media screen and (min-width: 768px) {
  .scale {
    flex: 1 0 0 !important;
  }
}
.department-chips {
  display: flex;
  gap: 0.5rem;
}
.department-chips p-chip .color {
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 10px;
}
.department-chips p-chip .text {
  display: flex;
  flex-direction: column;
  padding: 0.2rem 0.3rem;
}
.department-chips p-chip .text span:nth-child(1) {
  font-weight: 500;
}
.department-chips p-chip .text span:nth-child(2) {
  font-size: 0.8rem;
  color: var(--gc-text-color);
}`, "",{"version":3,"sources":["webpack://./src/app/components/gc-event-template-summary/gc-event-template-summary.component.sass"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,SAAA;AACJ;;AACA;EACI,eAAA;EACA,UAAA;AAEJ;;AAAA;EACI,WAAA;EACA,YAAA;EACA,kBAAA;AAGJ;;AADA;EACI;IACI,sBAAA;EAIN;AACF;AAHA;EACI,aAAA;EACA,WAAA;AAKJ;AAHQ;EACI,cAAA;EACA,eAAA;EACA,mBAAA;AAKZ;AAJQ;EACI,aAAA;EACA,sBAAA;EACA,sBAAA;AAMZ;AALY;EACI,gBAAA;AAOhB;AANY;EACI,iBAAA;EACA,2BAAA;AAQhB","sourcesContent":[".summary\n    display: flex\n    flex-direction: column\n    gap: 1rem\n\n.summary-box, .scale\n    padding: 0.5rem\n    flex: 100%\n\n.color\n    width: 85px\n    height: 55px\n    border-radius: 3px\n\n@media screen and ( min-width: 768px )\n    .scale\n        flex: 1 0 0 !important\n\n.department-chips\n    display: flex\n    gap: 0.5rem\n    p-chip\n        .color\n            width: 0.75rem\n            height: 0.75rem\n            border-radius: 10px\n        .text\n            display: flex\n            flex-direction: column\n            padding: 0.2rem 0.3rem\n            span:nth-child(1)\n                font-weight: 500\n            span:nth-child(2)\n                font-size: 0.8rem\n                color: var(--gc-text-color)"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
