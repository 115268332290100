import { DateTime } from "luxon";

export { };

export function toDateTime(input: unknown): DateTime {
  const type = typeof input;
  try {
    if (type === "string")
      return DateTime.fromISO(input as string);
    if (type === "number")
      return DateTime.fromMillis(input as number);
    if (type === "object") {
      if (DateTime.isDateTime(input))
        return input;

      else
        return DateTime.fromJSDate(input as Date);
    }
  } catch(error) {
    console.error(typeof input, input);
    console.error(error);
    return undefined;
  }
  return undefined;

}

function toDateTimeProto(this: number | string | Date) {
  return toDateTime(this);
}

declare global {
  export interface String {
      toDateTime(): DateTime ;
    }
  export interface Number {
    toDateTime(): DateTime ;
  }
  export interface Date {
    toDateTime(): DateTime ;
  }
}
String.prototype.toDateTime = toDateTimeProto;
Number.prototype.toDateTime = toDateTimeProto;
Date.prototype.toDateTime =   toDateTimeProto;
