// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.entries > * {
  width: 100%;
  max-width: 340px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/gc-settings-group/gc-settings-group.component.sass"],"names":[],"mappings":"AACI;EACI,WAAA;EACA,gBAAA;AAAR","sourcesContent":[".entries\n    > * \n        width: 100%\n        max-width: 340px"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
