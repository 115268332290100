/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-bitwise */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/no-inputs-metadata-property */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, Optional, Output, SkipSelf, ViewEncapsulation } from "@angular/core";
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from "@angular/forms";
import { WrapperComponent } from "../base";
import { GcContainerComponent } from "../container.component";
import { GC_COMPONENT_CONTAINER } from "../definitions";
@Component({
    selector:      'gc-flackbox',
    encapsulation: ViewEncapsulation.None,
    templateUrl:   `flackbox.html`,
    providers:     [{ provide: NG_VALUE_ACCESSOR, useExisting: GcFlackboxComponent, multi: true }],
})
export class GcFlackboxComponent implements ControlValueAccessor, OnChanges, OnDestroy {
    fc = new FormControl();

    useTristate = false;

    @Input() flag: number;
    @Input() get mask(): number {
        return this._mask;
    };
    set mask(val: number) {
        this._mask = val;
        this.useTristate = val !== undefined;
    }
    @Input()
    get value(): number {
        return this._value;
    }
    set value(val: number) {
        this.writeValue(val);
    }
    @Input() label: string;
    @Input()
    get disabled(): boolean {
        return this.fc.disabled;
    }
    set disabled(val: boolean) {
        this.setDisabledState(val);
    }
    @Input() tabindex: number;
    @Input() inputId: string;
    @Input() styleClass: string;
    @Input() labelStyleClass: string;
    @Input() checkboxIcon: string;
    @Input() readonly: boolean;
    @Input() tricheckboxFullIcon: string;
    @Input() tricheckboxMixedIcon: string = 'pi-minus';

    @Output() onChange: EventEmitter<number> = new EventEmitter<number>();

    _value: number;
    _mask: number;

    constructor(@Inject(GC_COMPONENT_CONTAINER) @Optional() @SkipSelf() protected parent: GcContainerComponent) {
        if (parent?._skipRegister)
            parent = undefined;
        if (parent)
            parent.registerChild(this as unknown as WrapperComponent);
    }

    ngOnChanges(): void {
        this.writeValue(this._value);
    }

    ngOnDestroy(): void {
        if (this.parent)
            this.parent.unregisterChild(this as unknown as WrapperComponent);
    }

    writeValue(obj: any): void {
        this._value = obj;
        if (this.useTristate)
            this.fc.setValue((obj & this.mask) === this.mask ? true : ((obj & this.mask) === 0 ? null : false), { emitEvent: false });
        else
            this.fc.setValue(!!(obj & this.flag), { emitEvent: false });
    }
    registerOnChange(fn: any): void {
        this.fc.valueChanges.subscribe((val) => {
            if (this.useTristate) {
                if (val === null) {
                    this._value &= ~this.mask;
                    this.parent.broadcastValue(this as unknown as WrapperComponent, this._value);
                    fn(this._value);
                    this.onChange.emit(this._value);
                } else if (val) {
                    this._value |= this.mask;
                    this.parent.broadcastValue(this as unknown as WrapperComponent, this._value);
                    fn(this._value);
                    this.onChange.emit(this._value);
                } else {
                    this.fc.setValue(null);
                }
            } else {
                if (val)
                    this._value |= this.flag;
                else
                    this._value &= ~this.flag;
                this.parent.broadcastValue(this as unknown as WrapperComponent, this._value);
                fn(this._value);
                this.onChange.emit(this._value);
            }
        });
    }
    registerOnTouched(fn: any): void { }
    setDisabledState?(isDisabled: boolean): void {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        isDisabled ? this.fc.disable() : this.fc.enable();
    }
}
