// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
                .input-number.p-inputtext {
                    width: 100%;
                }`, "",{"version":3,"sources":["webpack://./src/app/modules/ngx-components/lib/wrappers/components/input-number.ts"],"names":[],"mappings":";gBACgB;oBACI,WAAW;gBACf","sourcesContent":["\n                .input-number.p-inputtext {\n                    width: 100%;\n                }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
