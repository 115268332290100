/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/no-outputs-metadata-property */
/* eslint-disable max-len */
/* eslint-disable @angular-eslint/no-inputs-metadata-property */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { Component, Input, ViewEncapsulation } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { InputNumber } from "primeng/inputnumber";
import { WrapperComponent } from "../base";
import { Wrapper } from "../decorators";

@Component({
    selector: 'gc-inputNumber',
    styles:   [`
                .input-number.p-inputtext {
                    width: 100%;
                }`,
                `.p-inputnumber-input {
                    width: 100% !important;
                }`,
                `p-inputnumber {
                    width: 100%;
                }`],
    encapsulation: ViewEncapsulation.None,
    template:      `<label *ngIf="label !== undefined" [for]="__internal_ID">{{ label }}</label>
                    <p-inputNumber class="input-number" [formControl]="gcControl" [name]="__internal_ID" [inputId]="__internal_ID"><ng-content></ng-content></p-inputNumber>`,
    providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: GcInputNumberComponent, multi: true }],
    inputs:    ["value", "format", "showButtons", "buttonLayout", "incrementButtonClass", "decrementButtonClass", "incrementButtonIcon", "decrementButtonIcon", "locale",
        "localeMatcher", "mode", "prefix", "suffix", "currency", "currencyDisplay", "useGrouping", "minFractionDigits", "maxFractionDigits", "min", "max", "step", "allowEmpty",
        "style", "allowEmpty", "style", "styleClass", "inputId", "inputStyle", "inputStyleClass", "placeholder", "size", "maxLength", "tabindex", "disabled", "readonly", "title",
        "ariaLabel", "ariaRequired", "name", "autocomplete", "showClear"
    ],
    outputs: ["onFocus", "onBlur", "onInput", "onClear"]
})
@Wrapper(InputNumber)
export class GcInputNumberComponent extends WrapperComponent {
    @Input() label: string;
}
export interface GcInputNumberComponent extends InputNumber {};
