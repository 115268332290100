/* eslint-disable max-len */
/* eslint-disable @angular-eslint/no-inputs-metadata-property */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/no-outputs-metadata-property */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { SplitButton } from "primeng/splitbutton";
import { WrapperBase } from "../base";
import { Wrapper } from "../decorators";

@Component({
    selector:      'gc-splitButton',
    encapsulation: ViewEncapsulation.None,
    template:      '<p-splitButton #component><ng-content></ng-content></p-splitButton >',
    providers:     [{ provide: SplitButton, useExisting: GcSplitButtonComponent }],
    inputs:        ["label", "icon", "iconPos", "style", "styleClass", "menuStyle", "menuStyleClass", "appendTo", "disabled", "tabindex", "dir",
        "showTransitionOptions", "hideTransitionOptions", "expandAriaLabel"
    ],
    outputs: [
        "onClick", "onDropdownClick"
    ]
})
@Wrapper(SplitButton)
export class GcSplitButtonComponent extends WrapperBase implements OnInit {
}
export interface GcSplitButtonComponent extends SplitButton {}
