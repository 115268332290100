/* eslint-disable @typescript-eslint/space-before-function-paren */
import { OrgChart as D3OrgChart, Layout } from 'd3-org-chart';
import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    OnChanges,
    SimpleChanges,
    TemplateRef,
    ViewChild,
    forwardRef,
    Optional,
    Self,
    OnDestroy,
    HostListener,
    Output,
    EventEmitter,
    NgZone,
} from '@angular/core';
import * as d3 from 'd3';
import {HierarchyNode} from 'd3';
import { IOrgChartDepartmentInfo, IOrgChartEntry, IUser } from '@vierkant-software/types__api';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject, Subscription, debounceTime } from 'rxjs';

/**
* @ignore - do not show in documentation
*/
@Component({
    selector:    'gc-org-chart',
    styleUrls:   [`./gc-org-chart.component.sass`],
    templateUrl: `./gc-org-chart.component.haml`,
    providers:   [
        {
        provide:     NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => GcOrgChartComponent),
        multi:       true
        },
    ],
})
export class GcOrgChartComponent implements OnChanges, AfterViewInit, ControlValueAccessor, OnDestroy {
    public expand(nodeID: string) {
        const state = this.chart?.getChartState();
        const node = state.allNodes.find(x => x.id === nodeID);
        const childNodes = state.allNodes.filter(x => x.parent?.id === nodeID);
        [...childNodes, node, node.parent].forEach(x => x && this.chart.setExpanded(x.id, true));
        this.chart.render();
    }
    public rotate() {
        this.chart.layout(this.orientations[this.orientationIndex++ % 4] as Layout).render().fit();
    }

    public toggleCompact() {
        this.compact = !this.compact;
        this.chart.compact(this.compact).render().fit();
    }

    private orientations = ["left", "bottom", "right", "top"];
    private orientationIndex = 0;
    private isCompact: boolean;
    private static readonly DEFAULT_CONFIG: OrgChartConfiguration = {
        zoom:        0.7,
        nodeWidth:   250,
        nodeHeight:  172,
        margin:      80,
        childMargin: 40,
    };

    private resizeSubject: Subject<void> = new Subject<void>();
    private resizeSubscription: Subscription = this.resizeSubject.pipe(debounceTime(250)).subscribe(x => {
        this.update();
    });
    private _centerOnClick: boolean = true;
    private _config: OrgChartConfiguration = GcOrgChartComponent.DEFAULT_CONFIG;
    private _selectedNode: IOrgChartEntry;
    private _rootNode: IOrgChartEntry;
    @ViewChild('expandButton', {static: true}) private buttonTemplateRef: TemplateRef<unknown>;
    private chart: D3OrgChart<IOrgChartEntry>;
    private containerSubscription: Subscription;
    @ViewChild('department', {static: true}) private departmentTemplateRef: TemplateRef<unknown>;
    @ViewChild('staff', {static: true}) private staffTemplateRef: TemplateRef<unknown>;
    @ViewChild('user', {static: true}) private userTemplateRef: TemplateRef<unknown>;

    @Input() public data: IOrgChartEntry[];
    @Input() public images: string[] = [null, null, null, null, null];
    @Input() public compact: boolean = false;
    public onModelChange = (value: IOrgChartEntry[]) => {};
    public onModelTouched: () => void = () => {};
    @Input() public highlightedIDs: string[] = [];
    @Input() public userInfo: Record<string, Partial<IUser> & {image: number}> = {};
    @Output() public nodeSelected: EventEmitter<IOrgChartEntry> = new EventEmitter();

    constructor(
        private el: ElementRef,
        @Self() @Optional() private container: ControlContainer,
        private ngZone: NgZone,
    ) {}

    public get centerOnClick(): boolean {
        return this._centerOnClick;
    }

    @Input() public set centerOnClick(value: boolean) {
        this._centerOnClick = value;
        if (!value){
            this.chart.clearHighlighting();
            this.chart.updateNodesState();
        }
    }

    public get config(): OrgChartConfiguration {
        return this._config;
    }

    @Input() public set config(value: OrgChartConfiguration) {
        this._config = {...GcOrgChartComponent.DEFAULT_CONFIG, ...value};
    }

    public get selectedNode(): IOrgChartEntry{
        return this._selectedNode;
    }

    public set selectedNode(value: IOrgChartEntry) {
        this._selectedNode = value;
        this.nodeSelected.emit(value);
    }

    public updateSelectedNode(){
        this.selectedNode = this.data.find(x => x.ID === this.selectedNode?.ID);
    }

    public unselectNode(){
        this.selectedNode = undefined;
    }

    public getDepartment(entry: IOrgChartEntry): IOrgChartDepartmentInfo{
        if (entry?.type === "department") return entry.departmentInfo;
        else if (entry?.parentID) return this.getDepartment(this.data.find(x => x.ID === entry.parentID));
        return undefined;
    }

    public getDepartmentColor(entry: IOrgChartEntry){
        const department = this.getDepartment(entry);
        return department?.color;
    }

    public getUser(entry: IOrgChartEntry){
        const user = entry?.userInfo.length > 0 ? entry.userInfo[0] : null;
        if (!user) return null;
        return this.userInfo?.[user.userID];
    }

    public getUserImage(entry: IOrgChartEntry){
        const user = this.getUser(entry);
        if (!user) return null;
        return this.images[user.image];
    }

    public getUserInfo(entry: IOrgChartEntry){
        return entry?.userInfo.length > 0 ? entry.userInfo[0] : null;
    }

    public markChanged() {
        this.onModelChange?.(this.data);
    }

    public ngAfterViewInit(): void {
        if (!this.chart)this.chart = new D3OrgChart<IOrgChartEntry>().initialZoom(this.config.zoom);
        if (this.container){
            this.writeValue(this.container.control.value);
            this.containerSubscription = this.container.control.valueChanges.subscribe(x => {
                this.writeValue(x);
            });
        }
        this.center();
    }

    public ngOnChanges(_changes: SimpleChanges): void {
        this.update();
    }

    public ngOnDestroy(): void {
        this.containerSubscription?.unsubscribe();
        this.resizeSubscription?.unsubscribe();
    }

    @HostListener('window:resize', ['$event'])
    public onResize(_event: Event) {
        /**
         * svg container of chart is absolutely sized from d3.
         * need to update the chart when the container (or window) resized,
         * so that it will fill the new space, otherwise it will be cropped
         * to the original size, leaving the new area unusable
         */
        this.resizeSubject.next();
    }

    public registerOnChange(fn: (_: IOrgChartEntry[]) => void): void {
        this.onModelChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.onModelTouched = fn;
    }

    public update() {
        if (!this.chart || !this.data) return;
        this._rootNode = this.data.find(x => !x.parentID);
        const self = this;
        this.ngZone.runOutsideAngular(() => {
            this.chart
                .nodeId(this.getNodeId)
                .parentNodeId(this.getNodeParentId)
                .container(this.el.nativeElement)
                .svgWidth(this.el.nativeElement.parentElement.offsetWidth)
                .svgHeight(this.el.nativeElement.parentElement.offsetHeight)
                .data(this.data)
                .compact(this.compact)
                .nodeWidth((node) => resolve(node, this.config.nodeWidth))
                .nodeHeight((node) => resolve(node, this.config.nodeHeight))
                .childrenMargin((node) => resolve(node, this.config.childMargin))
                .compactMarginBetween((node) => resolve(node, this.config.margin))
                .compactMarginPair((node) => resolve(node, this.config.margin))
                .nodeContent(this.renderItem.bind(this))
                .nodeUpdate(function(node) {
                    d3.select(this).select('.node-foreign-object-div').on('click', () => {
                        self.selectedNode = node.data;
                    });
                })
                .buttonContent(({ node, state }) => {
                    const template = this.buttonTemplateRef;
                    const embeddedView = template.createEmbeddedView({
                        $implicit: node.data,
                        node,
                    });
                    embeddedView.detectChanges();
                    const html = this.getOuterHtml(embeddedView.rootNodes[0]);
                    embeddedView.destroy();
                    return html;
                })
                .linkUpdate(function(node, i, arr) {
                    d3.select(this)
                    .attr('stroke-width', (d: HNode) =>
                        d.id === self.selectedNode?.ID ? 5 : 1
                    );
                })
                .render();
        });
    }

    public center() {
        if (!this._rootNode) return;
        this.chart.setCentered(this._rootNode.ID);
    }

    public writeValue(obj: IOrgChartEntry[]): void {
        if (obj?.filter(x => !x.ID).length > 0 || obj === this.data) return;
        this.data = obj;
        this.update();
    }

    public zoomIn() {
        this.chart.zoomIn();
    }

    public zoomOut() {
        this.chart.zoomOut();
    }

    private getNodeId(node: IOrgChartEntry | d3.HierarchyNode<IOrgChartEntry>){
        if ("ID" in node) return node.ID;
        if (node.data && "ID" in node.data) return node.data.ID;
        if ("parentId" in node) return node.id as string;
        return (node.data as unknown as {id: string}).id;
    }

    private getNodeParentId(node: IOrgChartEntry | d3.HierarchyNode<IOrgChartEntry>){
        if ("ID" in node) return node.parentID;
        if (node.data && "ID" in node.data) return node.data.parentID;
        if ("parentId" in node) return node.parentId as string;
        return (node.data as unknown as {parentId: string}).parentId;
    }

    private getOuterHtml(node: Node): string{
        if ('outerHTML' in node) return node.outerHTML as string;
        const div = document.createElement("div");
        div.appendChild(node.cloneNode(true));
        return div.innerHTML;
    }

    private renderItem(node: HNode, index: number, children: NodeList, state: unknown) {
        let template: TemplateRef<unknown>;
        if (node.data.type === "department") template = this.departmentTemplateRef;
        else if (node.data.type === "staff_unit") template = this.staffTemplateRef;
        else template = this.userTemplateRef;

        const embeddedView = template.createEmbeddedView({
            $implicit: node.data,
            node,
            index,
            children
        });
        embeddedView.detectChanges();
        const html = this.getOuterHtml(embeddedView.rootNodes[0]);
        embeddedView.destroy();
        return html;
    }
}

export type OrgChartEntry = IOrgChartEntry & {
    notePermission?: string;
};

export type OrgChartPublishType = 'never' | 'now' | 'automaticallyAt';
export type OrgChartEntryType = 'user' | 'department' | 'staff_unit';

export type D3Node<T> = {
        _centered?: boolean;
        _centeredWithDescendants?: boolean;
        _directSubordinates?: number;
        _expanded?: boolean;
        _highlighted?: boolean;
        _totalSubordinates?: number;
    } & T;

export type HNode = HierarchyNode<IOrgChartEntry> & {
    data: D3Node<IOrgChartEntry>;
};

type NodeSizeFn = (node: HNode) => number;

export type OrgChartConfiguration = {
    zoom?: number;
    nodeWidth?: number | NodeSizeFn;
    nodeHeight?: number | NodeSizeFn;
    margin?: number | NodeSizeFn;
    childMargin?: number | NodeSizeFn;
};

function resolve(node: HNode, value: number | NodeSizeFn){
    if (typeof value === "function") return value(node);
    return value;
}
