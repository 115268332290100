import { Component,Input,OnInit } from '@angular/core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export type Color = 'gray' | 'blue' | 'teal' | 'green' | 'yellow' | 'purple' | 'red';

/**
 * It's a similar component to the PrimeNG "card" component but with modified styling.
 * In the middle of the card, there's a colored circle where an icon can reside in.
 * Below the circle there is a title.
 * @name Card-item
 * @ignore - do not show in documentation
*/
@Component({
    selector:    'app-card-item',
    templateUrl: './card-item.component.haml',
    styleUrls:   ['./card-item.component.sass'],
})
export class CardItemComponent implements OnInit {
    /**
     * @values 'gray' | 'blue' | 'teal' | 'green' | 'yellow' | 'purple' | 'red'
     * @default "gray"
     */
    @Input() color: Color = 'gray';
    //"checked" used to be used for when the color was blue (and for trying to implement terms & conditions card in the past)
    /**
     * Adds a green checkmark icon on the top right corner of the card.
     * @default false
     * @example 
     * ```
     * %app-card-item{[checked]: "true", title: "Checked", color: "green"}
     * ```
     */
    @Input() checked: boolean = false;
    /**
     * The title is located under the circle.
     * @default "Title"
     */
    @Input() title: string = "Title";
    /**
     * Define font-awesome icons here.
     * 
     * @example faIcon: "clock"
     * ```
     * %app-card-item{title: "Clock", faIcon: "clock", color: "teal"}
     * ```
     */
    @Input() faIcon?: IconProp; //define font-awesome icons here
    /**
     * Define prime icons here.
     * @example primeIcon: "pi-star"
     * ```
     * %app-card-item{title: "Star", primeIcon: "pi-star", color: "yellow"}
     * ```
     */
    @Input() primeIcon?: string;

    constructor(
        private library: FaIconLibrary,
    ){
		library.addIconPacks(fas);
    }
    ngOnInit(): void {
    }
}
