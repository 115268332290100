import {
  ApplicationRef,
  Component,
  ComponentRef,
  ElementRef,
  EnvironmentInjector,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  ViewEncapsulation,
  createComponent,
  forwardRef
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { OverlayPanel } from "primeng/overlaypanel";
import { ISearchQueryEmployee, Pagination, SearchQueryResult, VisibilityName, WorkHoursType } from "@vierkant-software/types__api";
import { GcPersonSearchComponent } from "../component/gc-person-search.component";
import { SearchBaseComponent, SearchField, SelectMode } from "../component/gc-person-search.base.component";

/**
 * Person and user search as dropdown
 * 
 * @name    Person Search Dropdown
 * @tags    User, Input, ControlValueAccessor
 * @remark  Is included in the gc-person-search-module
 * @returns string[] User IDs
 * @example 
 * ```
 * %gc-person-search{[visibility]: "VisibilityName.person_search"}
 * ```
 */
@Component({
  selector:      'gc-person-search-dropdown',
  templateUrl:   './gc-person-search-dropdown.component.haml',
  styleUrls:     ['./../component/gc-person-search.component.sass', './gc-person-search-dropdown.component.sass'],
  encapsulation: ViewEncapsulation.Emulated,
  providers:     [
    {
      provide:     NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GcPersonSearchDropdownComponent),
      multi:       true
    }
  ]
})
export class GcPersonSearchDropdownComponent extends SearchBaseComponent implements OnDestroy {
  private static instances: GcPersonSearchDropdownComponent[] = [];
  public static close(){
    GcPersonSearchDropdownComponent.instances.forEach(x => x.overlay.hide());
  }

  @Output() public selectionChange = new EventEmitter<string[]>();
  @Output() public selectedItems = new EventEmitter<SearchQueryResult[]>();
  /**
   * Filter by employment
   * @values  ISearchQueryEmployee
   * @example 
   * ```ts
   *  interface ISearchQueryEmployee {
   *    isEmployee: boolean;
   *    formerEmployees: boolean;
   *    activeEmployees: boolean;
   *    futureEmployees: boolean;
   *  }
   * ```
   */
  @Input() public  employment?: Partial<ISearchQueryEmployee>;
  /**
   * Allow department filter.
   * @remark  Pass department IDs as string array, to limit the available filter. 
   *          Otherwise all visible departments of the current users will be listed
   * @values  string[] | true
   */
  @Input() public  departments?: string[] | true;
  /**
   * Available search fields
   */
  @Input() public  fields?: SearchField[];
  /**
   * Active search fields
   */
  @Input() public  activeFields?: SearchField[];
  /**
   * Locked search fields
   */
  @Input() public  lockedFields?: SearchField[];
  /**
   * Filter by trusted work times
   * @values "yes" | "no" | "any"
   */
  @Input() public trustedWorkTimes?:  "yes" | "no" | "any";
  /**
   * Filter by workhoursType
   * @values WorkHoursType
   */
  @Input() public workhoursType?: WorkHoursType;
  /**
   * Control loading state
   */
  @Input() public  loading: boolean;
  /**
   * Visibility of the search.
   * @values VisibilityName
   */
  @Input() public  visibility?: VisibilityName;
  /**
   * Limit max selectable amount.
   */
  @Input() public  maxSelect?: number;
  /**
   * Require min selectable amount.
   */
  @Input() public  minSelect?: number;
  @ViewChild('op', {static: true}) public overlay: OverlayPanel;
  /**
   * Pagination object
   * 
   * @example
   * ```ts
   *  const pagination = {
   *    page: number;
   *    size: number;
   *  }
   * ```
   */
  @Input() public  pagination: Pagination;
  @ViewChild('popup', {static: true}) public searchElement: ElementRef<HTMLElement>;
  private _searchValue: string;
  public get searchValue(): string {
    return this._searchValue;
  }
  /**
   * Search term
   */
  @Input() public set searchValue(value: string) {
    this._searchValue = value;
    this.searchComponent.setInput("searchValue", this.searchValue);
  }
  /**
   * Control selection of one or multiple people.
   * 
   * @values  "single" | "multiple"
   * @default "single"
   */
  @Input() public  selectionMode: SelectMode = "single";
  /**
   * Show avatar of people
   */
  @Input() public  showAvatar: boolean;

  public showMask: boolean;

  public get searchResult(){
    return this._searchComponent?.instance.searchResult;
  }
  public get avatars(){
    return this._searchComponent?.instance.avatars;
  }
  private _searchComponent: ComponentRef<GcPersonSearchComponent>;
  public get searchComponent(): ComponentRef<GcPersonSearchComponent> {
    if (!this._searchComponent){
      this._searchComponent = createComponent(GcPersonSearchComponent, {
        environmentInjector: this.injector,
      });
      this._searchComponent.instance.onChange = (x: string[]) => this.value = x;
      this.appRef.attachView(this._searchComponent.hostView);
    }
    return this._searchComponent;
  }
  constructor(
    private appRef: ApplicationRef,
    private injector: EnvironmentInjector
  ){
    super();
    GcPersonSearchDropdownComponent.instances.push(this);
  }

  public ngOnDestroy(): void {
    const index = GcPersonSearchDropdownComponent.instances.findIndex(x => x === this);
    if (index >= 0) GcPersonSearchDropdownComponent.instances.splice(index, 1);
  }

  public showOverlay(event: FocusEvent){
    this.showMask = true;
    this.overlay.show(event);
  }

  onPopupShow(event: unknown){
    this.searchComponent.setInput("value", this.value);
    this.searchComponent.setInput("searchValue", this.searchValue);
    this.searchComponent.setInput("departments", this.departments);
    this.searchComponent.setInput("employment", this.employment);
    this.searchComponent.setInput("fields", this.fields);
    this.searchComponent.setInput("activeFields", this.activeFields);
    this.searchComponent.setInput("lockedFields", this.lockedFields);
    this.searchComponent.setInput("trustedWorkTimes", this.trustedWorkTimes);
    this.searchComponent.setInput("workhoursType", this.workhoursType);
    this.searchComponent.setInput("pagination", this.pagination);
    this.searchComponent.setInput("visibility", this.visibility);
    this.searchComponent.setInput("minSelect", this.minSelect);
    this.searchComponent.setInput("maxSelect", this.maxSelect);
    this.searchComponent.setInput("selectionMode", this.selectionMode);
    this.searchComponent.setInput("showAvatar", this.showAvatar);
    this.searchComponent.instance.selectionChange = this.selectionChange;
    this.searchComponent.instance.selectedItems = this.selectedItems;
    this.overlay.container.firstChild.appendChild(this.searchComponent.location.nativeElement);
  }

  onPopupHide(event: unknown) {
    this.showMask = false;
  }

  public clear(): void {
    super.clear();
    this.searchComponent.setInput("searchValue", this.searchValue);
  }
}
