// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mask {
  position: fixed;
  pointer-events: fill;
  z-index: 100;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.search-bar {
  position: relative;
  z-index: 101;
}

.close {
  position: absolute;
  right: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/gc-person-search/dropdown/gc-person-search-dropdown.component.sass"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,oBAAA;EACA,YAAA;EACA,MAAA;EACA,OAAA;EACA,SAAA;EACA,QAAA;AACJ;;AACA;EACI,kBAAA;EACA,YAAA;AAEJ;;AAAA;EACI,kBAAA;EACA,WAAA;AAGJ","sourcesContent":[".mask\n    position:  fixed\n    pointer-events: fill\n    z-index: 100\n    top: 0\n    left: 0\n    bottom: 0\n    right: 0\n\n.search-bar\n    position: relative\n    z-index: 101\n\n.close\n    position: absolute\n    right: 20px"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
