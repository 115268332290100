import { Component, HostBinding, Input } from "@angular/core";
import { Gap, getGap } from "../util";

/**
 * Structure elements in a column like structure.
 * This uses `display: flex` to layout the elements inside the container.
 * 
 * 
 * @name Column
 * @tags Layout, Structure
 * @remark A distinction from classic flex is that elements are systematically spaced.
 */
@Component({
    selector:  'gc-column',
    template:  `<ng-content></ng-content>`,
    styleUrls: ['column.component.sass']
})
export class GcColumnContainerComponent {

    /**
     * Defines the gap between each element
     * 
     * @default "default"
     * @values  "text" | "default" | "large" | "small" | "none"
     * @remark  While possible to also provide regular css units, those are largely discouraged to enforce an uniform look.
     */
    @Input() gap: Gap | string = "default";
    @HostBinding("style.--layout-column-gap") get gapVar() {
        return getGap(this.gap);
    };
}
