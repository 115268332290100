// TODO: min/max should move to limit
/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable @angular-eslint/no-output-on-prefix */
import { animate, AnimationEvent, state, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import {
    AfterContentInit,
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChildren,
    ElementRef,
    EventEmitter,
    forwardRef,
    HostBinding,
    Input,
    NgModule,
    NgZone,
    OnDestroy,
    OnInit,
    Output,
    Provider,
    QueryList,
    Renderer2,
    TemplateRef,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateTime, Duration, DurationLikeObject } from 'luxon';
import { OverlayService, PrimeNGConfig, PrimeTemplate, SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ConnectedOverlayScrollHandler, DomHandler } from 'primeng/dom';
import { RippleModule } from 'primeng/ripple';
import { UniqueComponentId, ZIndexUtils } from 'primeng/utils';
import { Subscription } from 'rxjs';
import { InternalAppService } from 'src/services/app.service';
import { uses24Hour } from 'src/util/locale';

type ResponsiveOptions = {
    numMonths: number;
    breakpoint: string;
};

type Value = Duration;
type DurationMeta = DurationLikeObject & {selectable?: boolean};
type Func = () => void;

export const CALENDAR_VALUE_ACCESSOR: Provider = {
    provide:     NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => GcTimepickerComponent),
    multi:       true
};

export interface LocaleSettings {
    firstDayOfWeek?: number;
    dayNames?: string[];
    dayNamesShort?: string[];
    dayNamesMin?: string[];
    monthNames?: string[];
    monthNamesShort?: string[];
    today?: string;
    clear?: string;
    dateFormat?: string;
    weekHeader?: string;
}

export type CalendarTypeView = 'date' | 'month' | 'year';
/**
 * This time picker is an input component to select a time.
 * It is derived from PrimeNG's calendar with several modifications.
 * 
 * @link    https://primeng.org/calendar
 * @remark  This component uses luxon Duration, rather than JS date.
 * 
 * @name    TimePicker
 * @tags    Input, Time, ControlValueAccessor
 */
@Component({
    selector: 'gc-timepicker',
    template: `
        <span #container
            [ngClass]="{
                'p-calendar': true,
                'p-calendar-w-btn': showIcon,
                'p-calendar-timeonly': true,
                'p-calendar-disabled': disabled,
                'p-focus': focus
            }"
            [ngStyle]="style"
            [class]="styleClass">
            <ng-template [ngIf]="!inline">
                <input
                    #inputfield
                    type="text"
                    [attr.id]="inputId"
                    [attr.name]="name"
                    [attr.required]="required"
                    [attr.aria-required]="required"
                    [value]="inputFieldValue"
                    (focus)="onInputFocus($event)"
                    (keydown)="onInputKeydown($event)"
                    (click)="onInputClick()"
                    (blur)="onInputBlur($event)"
                    [readonly]="readonlyInput"
                    (input)="onUserInput($event)"
                    [ngStyle]="inputStyle"
                    [class]="inputStyleClass"
                    [placeholder]="placeholder || ''"
                    [disabled]="disabled"
                    [attr.tabindex]="tabindex"
                    [attr.inputmode]="touchUI ? 'off' : null"
                    [ngClass]="{'p-inputtext p-component': true, 'clearable': showClear && !disabled && value !== null}"
                    autocomplete="off"
                    [attr.aria-labelledby]="ariaLabelledBy"
                />
                <i *ngIf="showClear && !disabled && value !== null" class="p-calendar-clear-icon pi pi-times" (click)="clear()"></i>
                <button
                type="button"
                [attr.aria-label]="iconAriaLabel"
                [icon]="icon"
                pButton pRipple
                *ngIf="showIcon"
                (click)="onButtonClick($event, inputfield)"
                class="p-datepicker-trigger"
                [disabled]="disabled"
                tabindex="0"></button>
            </ng-template>
            <div
                #contentWrapper
                [class]="panelStyleClass"
                [ngStyle]="panelStyle"
                [ngClass]="{
                    'p-datepicker p-component': true,
                    'p-datepicker-inline': inline,
                    'p-disabled': disabled,
                    'p-datepicker-timeonly': true
                }"
                [@overlayAnimation]="
                    touchUI
                        ? { value: 'visibleTouchUI', params: { showTransitionParams: showTransitionOptions, hideTransitionParams: hideTransitionOptions } }
                        : { value: 'visible', params: { showTransitionParams: showTransitionOptions, hideTransitionParams: hideTransitionOptions } }
                "
                [@.disabled]="inline === true"
                (@overlayAnimation.start)="onOverlayAnimationStart($event)"
                (@overlayAnimation.done)="onOverlayAnimationDone($event)"
                (click)="onOverlayClick($event)"
                *ngIf="inline || overlayVisible"
            >
                <ng-content select="p-header"></ng-content>
                <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
                <div class="p-timepicker">
                    <div class="p-hour-picker">
                        <button
                            class="p-link"
                            type="button"
                            (keydown)="onContainerButtonKeydown($event)"
                            (keydown.enter)="incrementHour($event)"
                            (keydown.space)="incrementHour($event)"
                            (mousedown)="onTimePickerElementMouseDown($event, 0, 1)"
                            (mouseup)="onTimePickerElementMouseUp($event)"
                            (keyup.enter)="onTimePickerElementMouseUp($event)"
                            (keyup.space)="onTimePickerElementMouseUp($event)"
                            (mouseleave)="onTimePickerElementMouseLeave()"
                            pRipple
                        >
                            <span class="pi pi-chevron-up"></span>
                        </button>
                        <span><ng-container *ngIf="currentHour < 10">0</ng-container>{{ currentHour }}</span>
                        <button
                            class="p-link"
                            type="button"
                            (keydown)="onContainerButtonKeydown($event)"
                            (keydown.enter)="decrementHour($event)"
                            (keydown.space)="decrementHour($event)"
                            (mousedown)="onTimePickerElementMouseDown($event, 0, -1)"
                            (mouseup)="onTimePickerElementMouseUp($event)"
                            (keyup.enter)="onTimePickerElementMouseUp($event)"
                            (keyup.space)="onTimePickerElementMouseUp($event)"
                            (mouseleave)="onTimePickerElementMouseLeave()"
                            pRipple
                        >
                            <span class="pi pi-chevron-down"></span>
                        </button>
                    </div>
                    <div class="p-separator">
                        <span>{{ timeSeparator }}</span>
                    </div>
                    <div class="p-minute-picker">
                        <button
                            class="p-link"
                            type="button"
                            (keydown)="onContainerButtonKeydown($event)"
                            (keydown.enter)="incrementMinute($event)"
                            (keydown.space)="incrementMinute($event)"
                            (mousedown)="onTimePickerElementMouseDown($event, 1, 1)"
                            (mouseup)="onTimePickerElementMouseUp($event)"
                            (keyup.enter)="onTimePickerElementMouseUp($event)"
                            (keyup.space)="onTimePickerElementMouseUp($event)"
                            (mouseleave)="onTimePickerElementMouseLeave()"
                            pRipple
                        >
                            <span class="pi pi-chevron-up"></span>
                        </button>
                        <span><ng-container *ngIf="currentMinute < 10">0</ng-container>{{ currentMinute }}</span>
                        <button
                            class="p-link"
                            type="button"
                            (keydown)="onContainerButtonKeydown($event)"
                            (keydown.enter)="decrementMinute($event)"
                            (keydown.space)="decrementMinute($event)"
                            (mousedown)="onTimePickerElementMouseDown($event, 1, -1)"
                            (mouseup)="onTimePickerElementMouseUp($event)"
                            (keyup.enter)="onTimePickerElementMouseUp($event)"
                            (keyup.space)="onTimePickerElementMouseUp($event)"
                            (mouseleave)="onTimePickerElementMouseLeave()"
                            pRipple
                        >
                            <span class="pi pi-chevron-down"></span>
                        </button>
                    </div>
                    <div class="p-separator" *ngIf="showSeconds">
                        <span>{{ timeSeparator }}</span>
                    </div>
                    <div class="p-second-picker" *ngIf="showSeconds">
                        <button
                            class="p-link"
                            type="button"
                            (keydown)="onContainerButtonKeydown($event)"
                            (keydown.enter)="incrementSecond($event)"
                            (keydown.space)="incrementSecond($event)"
                            (mousedown)="onTimePickerElementMouseDown($event, 2, 1)"
                            (mouseup)="onTimePickerElementMouseUp($event)"
                            (keyup.enter)="onTimePickerElementMouseUp($event)"
                            (keyup.space)="onTimePickerElementMouseUp($event)"
                            (mouseleave)="onTimePickerElementMouseLeave()"
                            pRipple
                        >
                            <span class="pi pi-chevron-up"></span>
                        </button>
                        <span><ng-container *ngIf="currentSecond < 10">0</ng-container>{{ currentSecond }}</span>
                        <button
                            class="p-link"
                            type="button"
                            (keydown)="onContainerButtonKeydown($event)"
                            (keydown.enter)="decrementSecond($event)"
                            (keydown.space)="decrementSecond($event)"
                            (mousedown)="onTimePickerElementMouseDown($event, 2, -1)"
                            (mouseup)="onTimePickerElementMouseUp($event)"
                            (keyup.enter)="onTimePickerElementMouseUp($event)"
                            (keyup.space)="onTimePickerElementMouseUp($event)"
                            (mouseleave)="onTimePickerElementMouseLeave()"
                            pRipple
                        >
                            <span class="pi pi-chevron-down"></span>
                        </button>
                    </div>
                    <div class="p-ampm-picker" *ngIf="hourFormat === '12'">
                        <button
                            class="p-link"
                            type="button"
                            (keydown)="onContainerButtonKeydown($event)"
                            (click)="toggleAMPM($event)"
                            (keydown.enter)="toggleAMPM($event)"
                            pRipple
                        >
                            <span class="pi pi-chevron-up"></span>
                        </button>
                        <span>{{ pm ? 'PM' : 'AM' }}</span>
                        <button
                            class="p-link"
                            type="button"
                            (keydown)="onContainerButtonKeydown($event)"
                            (click)="toggleAMPM($event)"
                            (keydown.enter)="toggleAMPM($event)"
                            pRipple
                        >
                            <span class="pi pi-chevron-down"></span>
                        </button>
                    </div>
                </div>
                <div class="p-datepicker-buttonbar" *ngIf="showButtonBar">
                    <button
                        type="button"
                        [label]="getTranslation('today')"
                        (keydown)="onContainerButtonKeydown($event)"
                        (click)="onTodayButtonClick($event)"
                        pButton
                        pRipple
                        [ngClass]="[todayButtonStyleClass]"
                    ></button>
                    <button
                        type="button"
                        [label]="getTranslation('clear')"
                        (keydown)="onContainerButtonKeydown($event)"
                        (click)="onClearButtonClick($event)"
                        pButton
                        pRipple
                        [ngClass]="[clearButtonStyleClass]"
                    ></button>
                </div>
                <ng-content select="p-footer"></ng-content>
                <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
            </div>
        </span>
    `,
    animations: [
        trigger('overlayAnimation', [
            state(
                'visibleTouchUI',
                style({
                    transform: 'translate(-50%,-50%)',
                    opacity:   1
                })
            ),
            transition(
                'void => visible',
                [
                    style({ opacity: 0, transform: 'scaleY(0.8)' }),
                    animate('{{showTransitionParams}}', style({ opacity: 1, transform: '*' }))
                ]
            ),
            transition(
                'visible => void',
                [
                    animate('{{hideTransitionParams}}', style({ opacity: 0 }))
                ]
            ),
            transition(
                'void => visibleTouchUI',
                [
                    style({ opacity: 0, transform: 'translate3d(-50%, -40%, 0) scale(0.9)' }), animate('{{showTransitionParams}}')
                ]
            ),
            transition(
                'visibleTouchUI => void',
                [
                    animate('{{hideTransitionParams}}', style({ opacity: 0, transform: 'translate3d(-50%, -40%, 0) scale(0.9)'}))
                ]
            ),
        ])
    ],
    providers:       [CALENDAR_VALUE_ACCESSOR],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation:   ViewEncapsulation.Emulated,
    styleUrls:       ['./timepicker.component.sass']
})
export class GcTimepickerComponent implements OnInit, OnDestroy, AfterContentInit, AfterViewInit,  ControlValueAccessor {
    @HostBinding("class") class = 'p-element p-inputwrapper gc-timepicker';
    @HostBinding("class.p-inputwrapper-filled") classInputwrapperFilled = "filled";
    @HostBinding("class.p-inputwrapper-focus") classInputwrapperFocus = "focus";
    @HostBinding("class.p-calendar-clearable") classPCalendarClearable = "'showClear && !disabled'";
    @Input() style: Record<string, unknown>;

    @Input() styleClass: string;

    @Input() inputStyle: Record<string, unknown>;

    @Input() inputId: string;

    @Input() name: string;

    @Input() inputStyleClass: string;

    @Input() placeholder: string;

    @Input() ariaLabelledBy: string;

    @Input() iconAriaLabel: string;

    @Input() disabled?: boolean;


    /**
     * The time format of the time picker.
     * @values  string 
     */
    @Input() format: string;

    /**
     * Display the timepicker inline, rather than an input element.
     */
    @Input() inline: boolean = false;

    @Input() showIcon: boolean;

    @Input() icon: string = 'pi pi-calendar';

    /**
     * Append popup calendar to another element.
     * 
     * @remark Use `body` to not be bound by its parent's bounding box.
     */
    @Input() appendTo?: string | HTMLElement;

    @Input() readonlyInput: boolean;

    @Input() hourFormat: string = undefined;

    @Input() stepHour: number = 1;

    @Input() stepMinute: number = 1;

    @Input() stepSecond: number = 1;

    /**
     * Show seconds on time picker.
     */
    @Input() showSeconds: boolean = false;

    @Input() required: boolean;

    @Input() showOnFocus: boolean = true;

    @Input() showClear: boolean = false;

    @Input() selectionMode: string = 'single';

    @Input() showButtonBar: boolean;

    @Input() todayButtonStyleClass: string = 'p-button-text';

    @Input() clearButtonStyleClass: string = 'p-button-text';

    @Input() autoZIndex: boolean = true;

    @Input() baseZIndex: number = 0;

    @Input() panelStyleClass: string;

    @Input() panelStyle: Record<string, unknown>;

    @Input() keepInvalid: boolean = false;

    @Input() hideOnDateTimeSelect: boolean = true;

    @Input() touchUI: boolean;

    @Input() timeSeparator: string = ':';

    @Input() focusTrap: boolean = true;

    @Input() showTransitionOptions: string = '.12s cubic-bezier(0, 0, 0.2, 1)';

    @Input() hideTransitionOptions: string = '.1s linear';

    @Output() onFocus: EventEmitter<unknown> = new EventEmitter();

    @Output() onBlur: EventEmitter<unknown> = new EventEmitter();

    @Output() onClose: EventEmitter<unknown> = new EventEmitter();

    @Output() onSelect: EventEmitter<unknown> = new EventEmitter();

    @Output() onClear: EventEmitter<unknown> = new EventEmitter();

    @Output() onInput: EventEmitter<unknown> = new EventEmitter();

    @Output() onNowClick: EventEmitter<unknown> = new EventEmitter();

    @Output() onClearClick: EventEmitter<unknown> = new EventEmitter();

    @Output() onClickOutside: EventEmitter<unknown> = new EventEmitter();

    @Output() onShow: EventEmitter<unknown> = new EventEmitter();

    @ContentChildren(PrimeTemplate) templates: QueryList<PrimeTemplate>;

    @Input() tabindex: number;

    @ViewChild('container', { static: false }) containerViewChild: ElementRef;

    @ViewChild('inputfield', { static: false }) inputfieldViewChild: ElementRef;

    @ViewChild('contentWrapper', { static: false }) set content(content: ElementRef) {
        this.contentViewChild = content;

        if (this.contentViewChild && !this.focus)
          this.initFocusableCell();

    }

    contentViewChild: ElementRef;

    value: Value;

    currentHour: number;

    currentMinute: number;

    currentSecond: number;

    pm: boolean;

    mask: HTMLDivElement;

    maskClickListener: Func;

    overlay: HTMLDivElement;

    responsiveStyleElement: HTMLStyleElement;

    overlayVisible: boolean;

    onModelChange = (value: Value) => {};

    onModelTouched: Func = () => {};

    timePickerTimer: number;

    documentClickListener: Func;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    animationEndListener: (this: HTMLDivElement, ev: any) => unknown;

    focus: boolean;

    isKeydown: boolean;

    filled: boolean | number;

    inputFieldValue: string = null;

    _min: Duration;

    _max: Duration;

    _showTime: boolean;

    _yearRange: string;

    preventDocumentListener: boolean;

    dateTemplate: TemplateRef<unknown>;

    headerTemplate: TemplateRef<unknown>;

    footerTemplate: TemplateRef<unknown>;

    disabledDateTemplate: TemplateRef<unknown>;

    decadeTemplate: TemplateRef<unknown>;

    _disabledDates: Array<DateTime>;

    _disabledDays: Array<number>;

    selectElement: unknown;

    todayElement: unknown;

    focusElement: unknown;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    scrollHandler: any;

    documentResizeListener: (this: Window, ev: UIEvent) => unknown;

    navigationState: { backward?: boolean, button?: boolean } = null;

    initialized: boolean;

    translationSubscription: Subscription;

    _locale: LocaleSettings;

    _responsiveOptions: ResponsiveOptions[];

    currentView: string;

    attributeSelector: string;

    preventFocus: boolean;

    @Input() get defaultTime(): Duration {
        return this._defaultDate;
    }

    set defaultTime(defaultTime: Duration) {
        this._defaultDate = defaultTime;

        if (this.initialized)
            this.initTime(this.getCurrentTime());

    }

    _defaultDate: Duration;

    @Input() get min(): Duration {
        return this._min;
    }

    set min(duration: Duration) {
        this._min = duration.shiftTo("hours", "minutes", "seconds");
    }

    @Input() get max(): Duration {
        return this._max;
    }

    set max(duration: Duration) {
        this._max = duration.shiftTo("hours", "minutes", "seconds");
    }

    constructor(
        public service: InternalAppService,
        public el: ElementRef,
        public renderer: Renderer2,
        public cd: ChangeDetectorRef,
        private zone: NgZone,
        private config: PrimeNGConfig,
        public overlayService: OverlayService
    ) {
        this.hourFormat = this.hourFormat ?? uses24Hour(this.service.locale) ? "24" : "12";
    }

    ngOnInit() {
        this.attributeSelector = UniqueComponentId();
        this.value = this.defaultTime ?? this.getCurrentTime();

        this.translationSubscription = this.config.translationObserver.subscribe(() => {
            this.cd.markForCheck();
        });

        this.initialized = true;
    }

    ngAfterContentInit() {
        this.templates.forEach((item) => {
            switch(item.getType()) {
                case 'disabledDate':
                    this.disabledDateTemplate = item.template;
                    break;

                case 'header':
                    this.headerTemplate = item.template;
                    break;

                case 'footer':
                    this.footerTemplate = item.template;
                    break;

                default:
                    this.dateTemplate = item.template;
                    break;
            }
        });
    }

    ngAfterViewInit() {
        if (this.inline && this.contentViewChild) {
            this.contentViewChild.nativeElement.setAttribute(this.attributeSelector, '');

            if (!this.disabled)
                this.initFocusableCell();

        }
    }

    getTranslation(option: string) {
        return this.config.getTranslation(option);
    }


    initTime(date: Duration) {
        this.pm = date.hours > 11;

        this.currentMinute = date.minutes;
        this.currentSecond = date.seconds;
        this.setCurrentHourPM(date.hours);
    }

    onDateSelect(event: Event, dateMeta: DurationMeta) {
        if (this.disabled || !dateMeta.selectable) {
            event.preventDefault();
            return;
        }

        if (this.shouldSelectDate(dateMeta))
                this.selectDate(dateMeta);

        if (this.hideOnDateTimeSelect) {
            setTimeout(() => {
                event.preventDefault();
                this.hideOverlay();

                if (this.mask)
                    this.disableModality();


                this.cd.markForCheck();
            }, 150);
        }

        this.updateInputfield();
        event.preventDefault();
    }

    shouldSelectDate(dateMeta: DurationMeta) {
      return true;
    }


    updateInputfield() {
        let formattedValue = '';

        if (this.value)
          formattedValue = this.formatDateTime(this.value);


        this.inputFieldValue = formattedValue;
        this.updateFilledState();
        if (this.inputfieldViewChild && this.inputfieldViewChild.nativeElement)
            this.inputfieldViewChild.nativeElement.value = this.inputFieldValue;

    }

    formatDateTime(date: Duration) {
        let formattedValue = this.keepInvalid ? date.toLocaleString() : null;

        if (this.isValidTime(date))
          formattedValue = this.formatTime(date);

        return formattedValue;
    }

    setCurrentHourPM(hours: number) {
        if (this.hourFormat === '12') {
            this.pm = hours > 11;
            if (hours >= 12)
                this.currentHour = hours === 12 ? 12 : hours - 12;
             else
                this.currentHour = hours === 0 ? 12 : hours;

        } else {
            this.currentHour = hours;
        }
    }

    selectDate(durationMeta: DurationMeta) {
        let duration = Duration.fromObject({
          ...durationMeta,
        });

        duration.set({
          hour:   this.currentHour,
          minute: this.currentMinute,
          second: this.currentSecond,
        });

        if (this.min && this.min > duration) {
            duration = this.min;
            this.setCurrentHourPM(duration.hours);
            this.currentMinute = duration.minutes;
            this.currentSecond = duration.seconds;
        }

        if (this.max && this.max < duration) {
            duration = this.max;
            this.setCurrentHourPM(duration.hours);
            this.currentMinute = duration.minutes;
            this.currentSecond = duration.seconds;
        }

        this.updateModel(duration);

        this.onSelect.emit(duration);
    }

    updateModel(value: Duration) {
        this.value = value;
        this.onModelChange(this.value);
    }

    onInputFocus(event: Event) {
        this.focus = true;
        if (this.showOnFocus)
            this.showOverlay();

        this.onFocus.emit(event);
    }

    onInputClick() {
        if (this.showOnFocus && !this.overlayVisible)
            this.showOverlay();

    }

    onInputBlur(event: Event) {
        this.focus = false;
        this.onBlur.emit(event);
        if (!this.keepInvalid)
            this.updateInputfield();

        this.onModelTouched();
    }

    onButtonClick(event: Event, inputfield: HTMLInputElement) {
        if (!this.overlayVisible) {
            inputfield.focus();
            this.showOverlay();
        } else {
            this.hideOverlay();
        }
    }

    clear() {
        this.inputFieldValue = null;
        this.value = null;
        this.onModelChange(this.value);
        this.onClear.emit();
    }

    onOverlayClick(event: Event) {
        this.overlayService.add({
            originalEvent: event,
            target:        this.el.nativeElement
        });
    }

    onContainerButtonKeydown(event: KeyboardEvent) {
        switch(event.which) {
            //tab
            case 9:
                if (!this.inline)
                    this.trapFocus(event);

                break;

            //escape
            case 27:
                this.overlayVisible = false;
                event.preventDefault();
                break;

            default:
                //Noop
                break;
        }
    }

    onInputKeydown(event: KeyboardEvent) {
        this.isKeydown = true;
        if (event.keyCode === 40 && this.contentViewChild) {
            this.trapFocus(event);
        } else if (event.keyCode === 27) {
            if (this.overlayVisible) {
                this.overlayVisible = false;
                event.preventDefault();
            }
        } else if (event.keyCode === 13) {
            if (this.overlayVisible) {
                this.overlayVisible = false;
                event.preventDefault();
            }
        } else if (event.keyCode === 9 && this.contentViewChild) {
            DomHandler.getFocusableElements(this.contentViewChild.nativeElement).forEach((el) => (el.tabIndex = -1));
            if (this.overlayVisible)
                this.overlayVisible = false;

        }
    }

    updateFocus() {
        let cell;

        if (this.navigationState) {
            if (this.navigationState.button) {
                this.initFocusableCell();

                if (this.navigationState.backward) DomHandler.findSingle(this.contentViewChild.nativeElement, '.p-datepicker-prev').focus();
                else DomHandler.findSingle(this.contentViewChild.nativeElement, '.p-datepicker-next').focus();
            } else {
                if (this.navigationState.backward) {
                    let cells;

                    if (this.currentView === 'month')
                        cells = DomHandler.find(this.contentViewChild.nativeElement, '.p-monthpicker .p-monthpicker-month:not(.p-disabled)');
                     else if (this.currentView === 'year')
                        cells = DomHandler.find(this.contentViewChild.nativeElement, '.p-yearpicker .p-yearpicker-year:not(.p-disabled)');
                     else
                        cells = DomHandler.find(this.contentViewChild.nativeElement, '.p-datepicker-calendar td span:not(.p-disabled):not(.p-ink)');


                    if (cells && cells.length > 0)
                        cell = cells[cells.length - 1];

                } else {
                    if (this.currentView === 'month')
                        cell = DomHandler.findSingle(this.contentViewChild.nativeElement, '.p-monthpicker .p-monthpicker-month:not(.p-disabled)');
                     else if (this.currentView === 'year')
                        cell = DomHandler.findSingle(this.contentViewChild.nativeElement, '.p-yearpicker .p-yearpicker-year:not(.p-disabled)');
                     else
                        cell = DomHandler.findSingle(this.contentViewChild.nativeElement, '.p-datepicker-calendar td span:not(.p-disabled):not(.p-ink)');

                }

                if (cell) {
                    cell.tabIndex = 0;
                    cell.focus();
                }
            }

            this.navigationState = null;
        } else {
            this.initFocusableCell();
        }
    }

    initFocusableCell() {
        const contentEl = this.contentViewChild?.nativeElement;
        let cell: HTMLElement;

        if (this.currentView === 'month') {
            const cells: HTMLElement[] = DomHandler.find(contentEl, '.p-monthpicker .p-monthpicker-month:not(.p-disabled)');
            const selectedCell = DomHandler.findSingle(contentEl, '.p-monthpicker .p-monthpicker-month.p-highlight');
            cells.forEach((c) => (c.tabIndex = -1));
            cell = selectedCell || cells[0];

            if (cells.length === 0) {
                const disabledCells = DomHandler.find(contentEl, '.p-monthpicker .p-monthpicker-month.p-disabled[tabindex = "0"]');
                disabledCells.forEach((c) => (c.tabIndex = -1));
            }
        } else if (this.currentView === 'year') {
            const cells = DomHandler.find(contentEl, '.p-yearpicker .p-yearpicker-year:not(.p-disabled)');
            const selectedCell = DomHandler.findSingle(contentEl, '.p-yearpicker .p-yearpicker-year.p-highlight');
            cells.forEach((c) => (c.tabIndex = -1));
            cell = selectedCell || cells[0];

            if (cells.length === 0) {
                const disabledCells = DomHandler.find(contentEl, '.p-yearpicker .p-yearpicker-year.p-disabled[tabindex = "0"]');
                disabledCells.forEach((c) => (c.tabIndex = -1));
            }
        } else {
            cell = DomHandler.findSingle(contentEl, 'span.p-highlight');
            if (!cell) {
                const todayCell = DomHandler.findSingle(contentEl, 'td.p-datepicker-today span:not(.p-disabled):not(.p-ink)');
                if (todayCell) cell = todayCell;
                else cell = DomHandler.findSingle(contentEl, '.p-datepicker-calendar td span:not(.p-disabled):not(.p-ink)');
            }
        }

        if (cell) {
            cell.tabIndex = 0;

            if (!this.preventFocus && (!this.navigationState || !this.navigationState.button)) {
                setTimeout(() => {
                    if (!this.disabled)
                        cell.focus();

                }, 1);
            }

            this.preventFocus = false;
        }
    }

    trapFocus(event: KeyboardEvent) {
        const focusableElements = DomHandler.getFocusableElements(this.contentViewChild.nativeElement);

        if (focusableElements && focusableElements.length > 0) {
            if (!focusableElements[0].ownerDocument.activeElement) {
                focusableElements[0].focus();
            } else {
                const focusedIndex = focusableElements.indexOf(focusableElements[0].ownerDocument.activeElement);

                if (event.shiftKey) {
                    if (focusedIndex === -1 || focusedIndex === 0) {
                        if (this.focusTrap) {
                            focusableElements[focusableElements.length - 1].focus();
                        } else {
                            if (focusedIndex === -1) return this.hideOverlay();
                            else if (focusedIndex === 0) return;
                        }
                    } else {
                        focusableElements[focusedIndex - 1].focus();
                    }
                } else {
                    if (focusedIndex === -1 || focusedIndex === focusableElements.length - 1) {
                        if (!this.focusTrap && focusedIndex !== -1) return this.hideOverlay();
                        else focusableElements[0].focus();
                    } else {
                        focusableElements[focusedIndex + 1].focus();
                    }
                }
            }
        }

        event.preventDefault();
    }

    convertTo24Hour(hours: number, pm: boolean) {
        if (this.hourFormat === '12') {
            if (hours === 12)
                return pm ? 12 : 0;
             else
                return pm ? hours + 12 : hours;

        }
        return hours;
    };

    validateTime(hour: number, minute: number, second: number, pm: boolean) {
        const convertedHour = this.convertTo24Hour(hour, pm);
        if (this.min){
            if (this.min.hours > convertedHour)
                    return false;

            if (this.min.hours === convertedHour) {
                if (this.min.minutes > minute)
                    return false;

                if (this.min.minutes === minute) {
                    if (this.min.seconds > second)
                        return false;
                }
            }
        }

        if (this.max){
            if (this.max.hours < convertedHour)
                    return false;

            if (this.max.hours === convertedHour) {
                if (this.max.minutes < minute)
                    return false;

                if (this.max.minutes === minute) {
                    if (this.max.seconds < second)
                        return false;
                }
            }
        }
        return true;
    }

    onTimePickerElementMouseDown(event: Event, type: number, direction: number) {
        if (!this.disabled) {
            this.repeat(event, null, type, direction);
            event.preventDefault();
        }
    }

    onTimePickerElementMouseUp(event: Event) {
        if (!this.disabled) {
            this.clearTimePickerTimer();
            this.updateTime();
        }
    }

    onTimePickerElementMouseLeave() {
        if (!this.disabled && this.timePickerTimer) {
            this.clearTimePickerTimer();
            this.updateTime();
        }
    }

    repeat(event: Event, interval: number, type: number, direction: number) {
        const i = interval || 500;

        this.clearTimePickerTimer();
        this.timePickerTimer = setTimeout(() => {
            this.repeat(event, 100, type, direction);
            this.cd.markForCheck();
        }, i) as unknown as number;

        switch(type) {
            case 0:
                if (direction === 1)this.incrementHour(event);
                else this.decrementHour(event);
                break;

            case 1:
                if (direction === 1)this.incrementMinute(event);
                else this.decrementMinute(event);
                break;

            case 2:
                if (direction === 1)this.incrementSecond(event);
                else this.decrementSecond(event);
                break;
        }

        this.updateInputfield();
    }

    clearTimePickerTimer() {
        if (this.timePickerTimer) {
            clearTimeout(this.timePickerTimer);
            this.timePickerTimer = null;
        }
    }

    incrementHour(event: Event) {
        const prevHour = this.currentHour;
        let newHour = this.currentHour + this.stepHour;
        let newPM = this.pm;

        if (this.hourFormat === '24') { newHour = newHour >= 24 ? newHour - 24 : newHour; }
        else if (this.hourFormat === '12') {
            // Before the AM/PM break, now after
            if (prevHour < 12 && newHour > 11)
                newPM = !this.pm;

            newHour = newHour >= 13 ? newHour - 12 : newHour;
        }

        if (this.validateTime(newHour, this.currentMinute, this.currentSecond, newPM)) {
            this.currentHour = newHour;
            this.pm = newPM;
            if (!this.value)
              this.value = this.getCurrentTime();
            this.updateModel((this.value ?? this.getCurrentTime()).set({hour: this.convertTo24Hour(this.currentHour, this.pm)}));
        }
        event.preventDefault();
    }

    decrementHour(event: Event) {
        let newHour = this.currentHour - this.stepHour;
        let newPM = this.pm;

        if (this.hourFormat === '24') { newHour = newHour < 0 ? 24 + newHour : newHour; }
        else if (this.hourFormat === '12') {
            // If we were at noon/midnight, then switch
            if (this.currentHour === 12)
                newPM = !this.pm;

            newHour = newHour <= 0 ? 12 + newHour : newHour;
        }

        if (this.validateTime(newHour, this.currentMinute, this.currentSecond, newPM)) {
            this.currentHour = newHour;
            this.pm = newPM;
            if (!this.value)
              this.value = this.getCurrentTime();
            this.updateModel((this.value ?? this.getCurrentTime()).set({hour: this.convertTo24Hour(this.currentHour, this.pm)}));
        }

        event.preventDefault();
    }

    incrementMinute(event: Event) {
        let newMinute = this.currentMinute + this.stepMinute;
        newMinute = newMinute > 59 ? newMinute - 60 : newMinute;
        if (this.validateTime(this.currentHour, newMinute, this.currentSecond, this.pm)){
            this.currentMinute = newMinute;
            if (!this.value)
              this.value = this.getCurrentTime();
            this.updateModel((this.value ?? this.getCurrentTime()).set({minute: this.currentMinute}));
        }


        event.preventDefault();
    }

    decrementMinute(event: Event) {
        let newMinute = this.currentMinute - this.stepMinute;
        newMinute = newMinute < 0 ? 60 + newMinute : newMinute;
        if (this.validateTime(this.currentHour, newMinute, this.currentSecond, this.pm)){
            this.currentMinute = newMinute;
            if (!this.value)
              this.value = this.getCurrentTime();
            this.updateModel((this.value ?? this.getCurrentTime()).set({minute: this.currentMinute}));
        }


        event.preventDefault();
    }

    incrementSecond(event: Event) {
        let newSecond = this.currentSecond + this.stepSecond;
        newSecond = newSecond > 59 ? newSecond - 60 : newSecond;
        if (this.validateTime(this.currentHour, this.currentMinute, newSecond, this.pm)){
            this.currentSecond = newSecond;
            if (!this.value)
              this.value = this.getCurrentTime();
            this.updateModel((this.value ?? this.getCurrentTime()).set({second: this.currentSecond}));
        }


        event.preventDefault();
    }

    decrementSecond(event: Event) {
        let newSecond = this.currentSecond - this.stepSecond;
        newSecond = newSecond < 0 ? 60 + newSecond : newSecond;
        if (this.validateTime(this.currentHour, this.currentMinute, newSecond, this.pm)){
            this.currentSecond = newSecond;
            if (!this.value)
              this.value = this.getCurrentTime();
            this.updateModel((this.value ?? this.getCurrentTime()).set({second: this.currentSecond}));
        }


        event.preventDefault();
    }

    updateTime() {
        let value = this.value ?? this.getCurrentTime();
        value = value.set({
          hours:   this.convertTo24Hour(this.currentHour, this.pm),
          minutes: this.currentMinute,
          seconds: this.currentSecond
        });
        this.updateModel(value);
        this.onSelect.emit(value);
        this.updateInputfield();
    }

    toggleAMPM(event: Event) {
        const newPM = !this.pm;
        if (this.validateTime(this.currentHour, this.currentMinute, this.currentSecond, newPM)) {
            this.pm = newPM;
            this.updateTime();
        }
        event.preventDefault();
    }

    onUserInput(event: Event) {
        // IE 11 Workaround for input placeholder : https://github.com/primefaces/primeng/issues/2026
        if (!this.isKeydown)
            return;

        this.isKeydown = false;

        const val = (event.target as HTMLInputElement).value;
        try {
            const value = this.parseValueFromString(val);
            this.updateModel(value);
            this.updateUI();
        } catch(err) {
            //invalid date
            // const value = this.keepInvalid ? val : null;
            // this.updateModel(value);
        }

        this.filled = val !== null && val.length;
        this.onInput.emit(event);
    }


    parseValueFromString(text: string): Value {
        if (!text || text.trim().length === 0)
            return null;
        return this.parseDateTime(text);
    }

    parseDateTime(text: string): Value {
      let date;
      const parts = text.split(" ");
      if (parts.length === 1)
        date = DateTime.fromFormat(text, "H:mm");
      else
        date = DateTime.fromFormat(text, 'h:mm a');

      let time = date.diff(date.startOf("day")).shiftTo("hours", "minutes", "seconds");
      if (!this.showSeconds)
        time = time.set({seconds: 0});

      return time;
    }

    isValidTime(duration: Duration) {
        return Duration.isDuration(duration) && duration.isValid;
    }

    getCurrentTime(){
      const today = DateTime.now();
      let time = today.diff(today.startOf("day")).shiftTo("hours", "minutes", "seconds");
      if (!this.showSeconds)
        time = time.set({seconds: 0});
      return time;
    }

    getNextAllowedTime(time: Duration){
        let minDistance;
        let maxDistance;
        if (this.validateTime(time.hours, time.minutes, time.seconds, this.pm))
            return time;
        const today = DateTime.now().startOf("day");
        if (this.min)
            minDistance = today.plus(time).diff(today.plus(this.min));
        if (this.max)
            maxDistance = today.plus(this.max).diff(today.plus(time));
        if (maxDistance){
            if (minDistance && maxDistance.as("seconds") > minDistance.as("seconds"))
                return this.max;
        } else if (minDistance){
            return this.min;
        } else {
            return time;
        }
    }

    updateUI() {
        const val = this.defaultTime
            && this.isValidTime(this.defaultTime)
            && !this.value ? this.defaultTime : this.value
            && this.isValidTime(this.value) ? this.value : this.getCurrentTime();

        this.setCurrentHourPM(val.hours);
        this.currentMinute = val.minutes;
        this.currentSecond = val.seconds;
    }

    showOverlay() {
        if (!this.overlayVisible) {
            this.updateUI();

            if (!this.touchUI)
                this.preventFocus = true;


            this.overlayVisible = true;
        }
    }

    hideOverlay() {
        this.overlayVisible = false;
        this.clearTimePickerTimer();

        if (this.touchUI)
            this.disableModality();


        this.cd.markForCheck();
    }


    toggle() {
        if (!this.inline) {
            if (!this.overlayVisible) {
                this.showOverlay();
                this.inputfieldViewChild.nativeElement.focus();
            } else {
                this.hideOverlay();
            }
        }
    }

    onOverlayAnimationStart(event: AnimationEvent) {
        switch(event.toState) {
            case 'visible':
            case 'visibleTouchUI':
                if (!this.inline) {
                    this.overlay = event.element;
                    this.overlay.setAttribute(this.attributeSelector, '');
                    this.appendOverlay();
                    this.updateFocus();
                    if (this.autoZIndex) {
                        if (this.touchUI) ZIndexUtils.set('modal', this.overlay, this.baseZIndex || this.config.zIndex.modal);
                        else ZIndexUtils.set('overlay', this.overlay, this.baseZIndex || this.config.zIndex.overlay);
                    }

                    this.alignOverlay();
                    this.onShow.emit(event);
                }
                break;

            case 'void':
                this.onOverlayHide();
                this.onClose.emit(event);
                break;
        }
    }

    onOverlayAnimationDone(event: AnimationEvent) {
        switch(event.toState) {
            case 'visible':
            case 'visibleTouchUI':
                if (!this.inline) {
                    this.bindDocumentClickListener();
                    this.bindDocumentResizeListener();
                    this.bindScrollListener();
                }
                break;

            case 'void':
                if (this.autoZIndex)
                    ZIndexUtils.clear(event.element);

                break;
        }
    }

    appendOverlay() {
        if (this.appendTo) {
            if (this.appendTo === 'body') document.body.appendChild(this.overlay);
            else DomHandler.appendChild(this.overlay, this.appendTo);
        }
    }

    restoreOverlayAppend() {
        if (this.overlay && this.appendTo)
            this.el.nativeElement.appendChild(this.overlay);

    }

    alignOverlay() {
        if (this.touchUI) {
            this.enableModality(this.overlay);
        } else if (this.overlay) {
            if (this.appendTo) {
                  this.overlay.style.width = DomHandler.getOuterWidth(this.inputfieldViewChild.nativeElement) + 'px';
                DomHandler.absolutePosition(this.overlay, this.inputfieldViewChild.nativeElement);
            } else {
                DomHandler.relativePosition(this.overlay, this.inputfieldViewChild.nativeElement);
            }
        }
    }

    enableModality(element: HTMLElement) {
        if (!this.mask && !this.touchUI) {
            this.mask = document.createElement('div');
            this.mask.style.zIndex = String(parseInt(element.style.zIndex, 10) - 1);
            const maskStyleClass = 'p-component-overlay p-datepicker-mask p-datepicker-mask-scrollblocker p-component-overlay p-component-overlay-enter';
            DomHandler.addMultipleClasses(this.mask, maskStyleClass);

            this.maskClickListener = this.renderer.listen(this.mask, 'click', () => {
                this.disableModality();
            });
            document.body.appendChild(this.mask);
            DomHandler.addClass(document.body, 'p-overflow-hidden');
        }
    }

    disableModality() {
        if (this.mask) {
            DomHandler.addClass(this.mask, 'p-component-overlay-leave');
            this.animationEndListener = this.destroyMask.bind(this);
            this.mask.addEventListener('animationend', this.animationEndListener);
        }
    }

    destroyMask() {
        if (!this.mask)
            return;


        document.body.removeChild(this.mask);
        const bodyChildren = document.body.children;
        let hasBlockerMasks: boolean;
        for (let i = 0; i < bodyChildren.length; i++) {
            const bodyChild = bodyChildren[i];
            if (DomHandler.hasClass(bodyChild, 'p-datepicker-mask-scrollblocker')) {
                hasBlockerMasks = true;
                break;
            }
        }

        if (!hasBlockerMasks)
            DomHandler.removeClass(document.body, 'p-overflow-hidden');


        this.unbindAnimationEndListener();
        this.unbindMaskClickListener();
        this.mask = null;
    }

    unbindMaskClickListener() {
        if (this.maskClickListener) {
            this.maskClickListener();
            this.maskClickListener = null;
        }
    }

    unbindAnimationEndListener() {
        if (this.animationEndListener && this.mask) {
            this.mask.removeEventListener('animationend', this.animationEndListener);
            this.animationEndListener = null;
        }
    }

    writeValue(value: Value): void {
        this.value = value;
        if (this.value && typeof this.value === 'string') {
            try {
                this.value = this.parseValueFromString(this.value);
            } catch{
                if (this.keepInvalid)
                    this.value = value;

            }
        }

        this.updateInputfield();
        this.updateUI();
        this.cd.markForCheck();
    }

    registerOnChange(fn: Func): void {
        this.onModelChange = fn;
    }

    registerOnTouched(fn: Func): void {
        this.onModelTouched = fn;
    }

    setDisabledState(val: boolean): void {
        this.disabled = val;
        this.cd.markForCheck();
    }

    formatTime(date: Duration) {
        if (!date)
            return '';


        let output = '';
        let hours = date.hours;
        const minutes = date.minutes;
        const seconds = date.seconds;

        if (this.hourFormat === '12' && hours > 11 && hours !== 12)
            hours -= 12;


        if (this.hourFormat === '12')
            output += hours === 0 ? 12 : hours < 10 ? 0 + hours : hours;
         else
            output += hours < 10 ? '0' + hours : hours;

        output += ':';
        output += minutes < 10 ? '0' + minutes : minutes;

        if (this.showSeconds) {
            output += ':';
            output += seconds < 10 ? '0' + seconds : seconds;
        }

        if (this.hourFormat === '12')
            output += date.hours > 11 ? ' PM' : ' AM';


        return output;
    }

    parseTime(value: string) {
        const tokens: string[] = value.split(':');
        const validTokenLength = this.showSeconds ? 3 : 2;

        if (tokens.length !== validTokenLength)
            throw new Error('Invalid time');


        let h = parseInt(tokens[0], 10);
        const m = parseInt(tokens[1], 10);
        const s = this.showSeconds ? parseInt(tokens[2], 10) : null;

        if (isNaN(h) || isNaN(m) || h > 23 || m > 59 || (this.hourFormat === '12' && h > 12) || (this.showSeconds && (isNaN(s) || s > 59))) {
            throw new Error('Invalid time');
        } else {
            if (this.hourFormat === '12') {
                if (h !== 12 && this.pm)
                    h += 12;
                 else if (!this.pm && h === 12)
                    h -= 12;

            }

            return { hour: h, minute: m, second: s };
        }
    }

    // Ported from jquery-ui datepicker parseDate
    parseDate(value: string, format: string | Intl.DateTimeFormatOptions) {
        if (format === null || value === null)
            throw new Error('Invalid time');


        value = typeof value === 'object' ? (value as object).toString() : value + '';
        if (value === '')
            return null;
        if (typeof format === "string")
            return DateTime.fromFormat(value, format);
        const formatString = new Intl.DateTimeFormat(undefined, format).formatToParts().map(part => part.value).join('');
        return DateTime.fromFormat(value, formatString);
    }

    updateFilledState() {
        this.filled = this.inputFieldValue && this.inputFieldValue !== '';
    }

    onTodayButtonClick(event: Event) {
        const date = this.getCurrentTime();
        const dateMeta: DurationLikeObject = {
          hours:   date.hours,
          minutes: date.minutes,
          seconds: date.seconds,
        };

        this.onDateSelect(event, dateMeta);
        this.onNowClick.emit(event);
    }

    onClearButtonClick(event: Event) {
        this.updateModel(null);
        this.updateInputfield();
        this.hideOverlay();
        this.onClearClick.emit(event);
    }

    destroyResponsiveStyleElement() {
        if (this.responsiveStyleElement) {
            this.responsiveStyleElement.remove();
            this.responsiveStyleElement = null;
        }
    }

    bindDocumentClickListener() {
        if (!this.documentClickListener) {
            this.zone.runOutsideAngular(() => {
                const documentTarget: string = this.el ? this.el.nativeElement.ownerDocument : 'document';

                this.documentClickListener = this.renderer.listen(documentTarget, 'mousedown', (event) => {
                    if (this.isOutsideClicked(event) && this.overlayVisible) {
                        this.zone.run(() => {
                            this.hideOverlay();
                            this.onClickOutside.emit(event);

                            this.cd.markForCheck();
                        });
                    }
                });
            });
        }
    }

    unbindDocumentClickListener() {
        if (this.documentClickListener) {
            this.documentClickListener();
            this.documentClickListener = null;
        }
    }

    bindDocumentResizeListener() {
        if (!this.documentResizeListener && !this.touchUI) {
            this.documentResizeListener = this.onWindowResize.bind(this);
            window.addEventListener('resize', this.documentResizeListener);
        }
    }

    unbindDocumentResizeListener() {
        if (this.documentResizeListener) {
            window.removeEventListener('resize', this.documentResizeListener);
            this.documentResizeListener = null;
        }
    }

    bindScrollListener() {
        if (!this.scrollHandler) {
            this.scrollHandler = new ConnectedOverlayScrollHandler(this.containerViewChild.nativeElement, () => {
                if (this.overlayVisible)
                    this.hideOverlay();

            });
        }

        this.scrollHandler.bindScrollListener();
    }

    unbindScrollListener() {
        if (this.scrollHandler)
            this.scrollHandler.unbindScrollListener();

    }

    isOutsideClicked(event: Event) {
        return !(this.el.nativeElement.isSameNode(event.target) ||
        this.isNavIconClicked(event) ||
        this.el.nativeElement.contains(event.target) ||
        (this.overlay && this.overlay.contains(<Node>event.target)));
    }

    isNavIconClicked(event: Event) {
        return (
            DomHandler.hasClass(event.target, 'p-datepicker-prev') ||
            DomHandler.hasClass(event.target, 'p-datepicker-prev-icon') ||
            DomHandler.hasClass(event.target, 'p-datepicker-next') ||
            DomHandler.hasClass(event.target, 'p-datepicker-next-icon')
        );
    }

    onWindowResize() {
        if (this.overlayVisible && !DomHandler.isTouchDevice())
            this.hideOverlay();

    }

    onOverlayHide() {

        if (this.mask)
            this.destroyMask();


        this.unbindDocumentClickListener();
        this.unbindDocumentResizeListener();
        this.unbindScrollListener();
        this.overlay = null;
    }

    ngOnDestroy() {
        if (this.scrollHandler) {
            this.scrollHandler.destroy();
            this.scrollHandler = null;
        }

        if (this.translationSubscription)
            this.translationSubscription.unsubscribe();


        if (this.overlay && this.autoZIndex)
            ZIndexUtils.clear(this.overlay);


        this.destroyResponsiveStyleElement();
        this.clearTimePickerTimer();
        this.restoreOverlayAppend();
        this.onOverlayHide();
    }
}

/**
* @ignore - do not show in documentation
*/
@NgModule({
    imports:      [CommonModule, ButtonModule, SharedModule, RippleModule],
    exports:      [GcTimepickerComponent, ButtonModule, SharedModule],
    declarations: [GcTimepickerComponent]
})
export class TimepickerModule {}
