export type Size = "KB" | "MB" | "GB";

//TODO: Auto mode
export function formatBytes(value: number, size?: Size): string {
    switch(size) {
        case "KB":
            return value + " KB";
        case "MB":
            return Math.round(((value / 1000) + Number.EPSILON) * 100) / 100 + " MB";
        case "GB":
            return Math.round(((value / 1000000) + Number.EPSILON) * 100) / 100 + " GB";
        default:
            if (value === 1)
                return value + " byte";
            return value + " bytes";
    }
}
