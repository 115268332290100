import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { AppService } from "./app.service";
import { DebugService } from "./debug.service";
import { FeatureFlags } from "@vierkant-software/types__api";

@Injectable()
export class AuthGuard  {

    constructor(
        private service: AppService,
        private router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree>
    {
        // console.log('required permissions:', route.data?.permissions);
        const required: [] = route.data?.permissions;
        const feature: FeatureFlags = route.data?.feature;
        if ((!required || required.length === 0) && !feature) {
            console.log('Error: AuthGuard used but no permissions set.');
            return false;
        }
        if (required && !this.service.getFrontendPermissions(required)) {
            DebugService.logUnauthorized(this.service.getMissingFrontendPermissions(required));
            this.router.navigate(['/login']).catch(x => console.log('Error: Navigate to /login failed'));
            return false;
        }
        if (feature && !this.service.getFeature(feature)) {
            DebugService.logUnauthorized('Feature - ' + FeatureFlags[feature]);
            this.router.navigate(['/404']).catch(x => console.log('Error: Navigate to /404 failed?!!'));
            return false;
        }
        return true;
    }

    canLoad(route: Route, segments: UrlSegment[]): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return true;
    }
}
