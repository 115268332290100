// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-short {
  width: 80px !important;
}

.workflow {
  align-items: flex-start;
}
.workflow .inheritance {
  width: 270px;
  background: var(--gc-color-light);
  border: 1px solid var(--gc-border-color);
  padding: 0.5rem;
}
.workflow .settings {
  flex-grow: 1;
}`, "",{"version":3,"sources":["webpack://./src/app/components/workflow/workflow.component.sass"],"names":[],"mappings":"AAAA;EACI,sBAAA;AACJ;;AAAA;EACI,uBAAA;AAGJ;AAFI;EACI,YAAA;EACA,iCAAA;EACA,wCAAA;EACA,eAAA;AAIR;AAHI;EACI,YAAA;AAKR","sourcesContent":[".input-short\n    width: 80px !important\n.workflow\n    align-items: flex-start\n    .inheritance\n        width: 270px\n        background: var(--gc-color-light)\n        border: 1px solid var(--gc-border-color)\n        padding: 0.5rem\n    .settings\n        flex-grow: 1"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
