// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { animate, style, transition, trigger, AnimationTriggerMetadata } from '@angular/animations';

/**
 * Animate the expansion of a DOM element, using CSS-grid. The `grid-template-row` property is used to set the height to `0fr` (equals `0` height)
 * and then animates it to `1fr` (equals the height the element needs when fully expanded)
 * 
 * @param name Name of the animation. Defaults to `toggleAnimation`
 * @param timing Animation timing for the animation duration. Use allowed CSS values like `500ms linear`. Has default value.
 * @remark Child element of the container must have the css property `overflow: hidden` set. 
 * @returns Returns an {@link AnimationTriggerMetadata} for expanding vertical DOM elements.
 */
export const toggleAnimation = (name: string = "toggleAnimation", timing: string = '400ms cubic-bezier(0.86, 0, 0.07, 1)') => trigger(
    name,
    [
        transition(
        'void => *',
        [
            style({ 'grid-template-rows': '0fr'}),
            animate(timing,
                style({ 'grid-template-rows': '1fr'}))
        ]
        ),
        transition(
        '* => void',
        [
            style({ 'grid-template-rows': '1fr' }),
            animate(timing,
                style({ 'grid-template-rows': '0fr'}))
        ]
        )
    ]
);

/**
 * export the default {@link AnimationTriggerMetadata} for toggling/expanding vertical elements in the DOM.
 * 
 * @remark Child element of the container must have the css property `overflow: hidden` set. 
 * @remark animation name is `toggleAnimation`. Can be assigned with `@toggleAnimation` in the container element containing the `*ngIf`-Directive
 */
export const defaultToggleAnimation = toggleAnimation();

