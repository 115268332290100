import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { GcContainerComponent, SkipContainer } from "./container.component";

// External modules
import { AccordionModule } from "primeng/accordion";
import { AutoCompleteModule } from "primeng/autocomplete";
import { ButtonModule } from "primeng/button";
import { CalendarModule } from "primeng/calendar";
import { CheckboxModule } from "primeng/checkbox";
import { ColorPickerModule } from "primeng/colorpicker";
import { DropdownModule } from "primeng/dropdown";
import { InputNumberModule } from "primeng/inputnumber";
import { InputTextModule } from "primeng/inputtext";
import { InputTextareaModule } from "primeng/inputtextarea";
import { ListboxModule } from "primeng/listbox";
import { MultiSelectModule } from "primeng/multiselect";
import { PasswordModule } from "primeng/password";
import { RadioButtonModule } from "primeng/radiobutton";
import { SelectButtonModule } from "primeng/selectbutton";
import { SliderModule } from "primeng/slider";
import { ToggleButtonModule } from "primeng/togglebutton";
import { TreeSelectModule } from "primeng/treeselect";
import { DividerModule } from "primeng/divider";
import { AvatarGroupModule } from "primeng/avatargroup";
import { ProgressBarModule } from "primeng/progressbar";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { SidebarModule } from "primeng/sidebar";
import { TagModule } from "primeng/tag";
import { CardModule } from "primeng/card";
import { SharedModule } from "primeng/api";
import { AvatarModule } from "primeng/avatar";
import { SplitButtonModule } from "primeng/splitbutton";
import { ChipsModule } from "primeng/chips";
import { PanelModule } from "primeng/panel";
import { InputSwitchModule } from "primeng/inputswitch";
import { TriStateCheckboxModule } from "primeng/tristatecheckbox";

// Modules to export
import { GcAutoCompleteComponent } from "./components/auto-complete";
import { GcAvatarComponent } from "./components/avatar";
import { GcButtonComponent } from "./components/button";
import { GcCheckboxComponent } from "./components/checkbox";
import { GcColorPickerComponent } from "./components/color-picker";
import { GcDividerComponent } from "./components/divider";
import { GcDropdownComponent, GcDropdownItem } from "./components/dropdown";
import { GcInputTextComponent } from "./components/input-text";
import { GcInputTextareaComponent } from "./components/input-text-area";
import { GcTemplate } from "./directives/gcTemplate";
import { GcFooter, GcHeader } from "./components/shared";
import { GcAccordionComponent, GcAccordionTabComponent } from "./components/accordion";
import { GcCardComponent } from "./components/card";
import { GcInputNumberComponent } from "./components/input-number";
import { GcListboxComponent } from "./components/listbox";
import { GcMultiSelectComponent } from "./components/multiselect";
import { GcPasswordComponent } from "./components/password";
import { GcProgressbarComponent } from "./components/progressbar";
import { GcOverlayPanelComponent } from "./components/overlay-panel";
import { GcRadiobuttonComponent } from "./components/radio-button";
import { GcSelectButtonComponent } from "./components/select-button";
import { GcSidebarComponent } from "./components/sidebar";
import { GcSliderComponent } from "./components/slider";
import { GcTagComponent } from "./components/tag";
import { GcToggleButtonComponent } from "./components/toggle-button";
import { GcTreeSelectComponent } from "./components/tree-select";
import { GcChipComponent } from "./components/chip";
import { GcPanelComponent } from "./components/panel";
import { GcSplitButtonComponent } from "./components/split-button";
import { GcInputSwitchComponent } from "./components/input-switch";
import { GcFlackboxComponent, GcStepsComponent } from "./components";
import { GcBitButton } from "./components/bitbutton/bitbutton";
import { ToastModule } from "primeng/toast";



const components = [
    GcAccordionTabComponent,
    GcAccordionComponent,
    GcAutoCompleteComponent,
    GcAvatarComponent,
    GcButtonComponent,
    GcCheckboxComponent,
    GcFlackboxComponent,
    GcChipComponent,
    GcColorPickerComponent,
    GcDividerComponent,
    GcDropdownComponent,
    GcDropdownItem,
    GcCardComponent,
    GcInputNumberComponent,
    GcInputTextComponent,
    GcInputTextareaComponent,
    GcListboxComponent,
    GcMultiSelectComponent,
    GcPasswordComponent,
    GcProgressbarComponent,
    GcOverlayPanelComponent,
    GcRadiobuttonComponent,
    GcSelectButtonComponent,
    GcSidebarComponent,
    GcSliderComponent,
    GcTagComponent,
    GcToggleButtonComponent,
    GcTreeSelectComponent,
    GcSplitButtonComponent,
    GcBitButton,
    GcStepsComponent,

    GcPanelComponent,
    GcInputSwitchComponent,

    GcHeader,
    GcFooter,
    GcTemplate,

    SkipContainer,
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode'}),
        ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode'}),

        //external modules
        AccordionModule,
        AutoCompleteModule,
        AvatarGroupModule,
        ButtonModule,
        CalendarModule,
        CheckboxModule,
        ChipsModule,
        ColorPickerModule,
        DividerModule,
        DropdownModule,
        CardModule,
        SharedModule,
        InputNumberModule,
        InputTextModule,
        InputTextareaModule,
        ListboxModule,
        MultiSelectModule,
        PasswordModule,
        ProgressBarModule,
        OverlayPanelModule,
        RadioButtonModule,
        SelectButtonModule,
        SidebarModule,
        SliderModule,
        TagModule,
        ToastModule,
        ToggleButtonModule,
        TreeSelectModule,
        TriStateCheckboxModule,
        AvatarModule,
        SplitButtonModule,

        PanelModule,
        InputSwitchModule,
    ],
    exports: [
        ...components,
        GcContainerComponent
    ],
    declarations: [
        ...components,
        GcContainerComponent
    ],
})
export class GcReactiveForms {}
