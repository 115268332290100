// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.topbar {
  overflow-x: hidden;
  padding-bottom: 10px;
  margin-bottom: -10px;
  width: 100%;
}

.item {
  padding: 0.5rem 0;
  border-bottom: 1px solid var(--gc-border-color);
  min-width: 10rem;
}

.error-message {
  color: var(--gc-color-danger);
  padding: 0.5rem 0;
  font-size: 0.9rem;
}

.info {
  background: var(--gc-color-info);
  border: 1px solid var(--gc-color-info-shade);
  border-radius: 3px;
  width: 100%;
  max-width: 300px;
  padding: 0.45rem 0.75rem;
  display: flex;
  gap: 0.5rem;
  cursor: pointer;
  color: var(--gc-color-info-contrast);
}

@media screen and (max-width: 850px) {
  .info {
    max-width: 42px;
  }
  .info .text {
    display: none;
    color: var(--gc-color-info-contrast);
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/top-bar/top-bar.component.sass"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,oBAAA;EACA,oBAAA;EACA,WAAA;AACJ;;AACA;EACI,iBAAA;EACA,+CAAA;EACA,gBAAA;AAEJ;;AAAA;EACI,6BAAA;EACA,iBAAA;EACA,iBAAA;AAGJ;;AADA;EACI,gCAAA;EACA,4CAAA;EACA,kBAAA;EACA,WAAA;EACA,gBAAA;EACA,wBAAA;EACA,aAAA;EACA,WAAA;EACA,eAAA;EACA,oCAAA;AAIJ;;AADA;EACI;IAII,eAAA;EACN;EAJM;IACI,aAAA;IACA,oCAAA;EAMV;AACF","sourcesContent":[".topbar\n    overflow-x: hidden\n    padding-bottom: 10px\n    margin-bottom: -10px\n    width: 100%\n\n.item\n    padding: 0.5rem 0\n    border-bottom: 1px solid var(--gc-border-color)\n    min-width: 10rem\n\n.error-message\n    color: var(--gc-color-danger)\n    padding: 0.5rem 0 \n    font-size: 0.9rem\n\n.info\n    background: var(--gc-color-info)\n    border: 1px solid var(--gc-color-info-shade)\n    border-radius: 3px\n    width: 100%\n    max-width: 300px\n    padding: 0.45rem 0.75rem \n    display: flex\n    gap: 0.5rem\n    cursor: pointer\n    color: var(--gc-color-info-contrast)\n\n\n@media screen and (max-width: 850px)\n    .info\n        .text\n            display: none\n            color: var(--gc-color-info-contrast)\n        max-width: 42px"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
