import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ClientSettingsType, IUser, WithFiles } from '@vierkant-software/types__api';
import { Globals } from 'src/app/services/globals.service';
import { GcSetting } from 'src/services/settings.service';
import { AppService } from 'src/services/app.service';

/**
* @ignore - do not show in documentation
*/
@Component({
    selector:    'gc-calendar-settings',
    templateUrl: './gc-calendar-settings.component.haml',
    styleUrls:   ['./gc-calendar-settings.component.sass'],
})
export class GCCalendarSettingsComponent implements OnInit {
    @GcSetting(ClientSettingsType.calendar) public setting: CalendarSettings;
    @Output() public usersSelected: EventEmitter<WithFiles<IUser[]>> = new EventEmitter();

    public _selectedUsers: string[] = [];
    public userImages: Record<string, string> = {};
    public users: WithFiles<IUser[]>;

    constructor(private app: AppService, public global: Globals) {}

    public get selectedUsers() {
        return this._selectedUsers;
    }

    public set selectedUsers(value: string[]) {
        if (value && value.length === this.setting.users?.length && value.every(x => x && this.setting.users.includes(x))) return;
        this.setting.users = this._selectedUsers = value;
        this.app.api.UserWorker.getUsers(this._selectedUsers).then(x => {
            this.users = x;
            this.usersSelected.emit(x);
        }).catch(err => err.displayErrorToast?.());
    }

    public ngOnInit(): void {
        this._selectedUsers = this.setting.users;
        this.app.api.UserWorker.getUsers(this._selectedUsers).then(x => {
            this.users = x;
        }).catch(err => err.displayErrorToast?.());
    }
}

export interface CalendarSettings {
    users: string[];
}
