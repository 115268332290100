// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  gap: var(--layout-row-gap, 1rem);
}`, "",{"version":3,"sources":["webpack://./src/app/components/layout/row/row.component.sass"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,gCAAA;AACJ","sourcesContent":[":host\n    display: flex\n    gap: var(--layout-row-gap, 1rem)\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
