import { UrlTree } from "@angular/router";
import { UrlSerializer, DefaultUrlSerializer } from '@angular/router';

declare module "@angular/router" {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    export interface UrlTree extends Array<string> {}
}


export class DraftUrlSerializer implements UrlSerializer {
    parse(url: string): UrlTree {
        if (url.startsWith('$/')) return url.split('/') as UrlTree;
        if (url.startsWith('&/')) return url.split('/') as UrlTree;
        if (url.startsWith('%/')) return url.split('/') as UrlTree;
        if (url.startsWith('§/')) return url.split('/') as UrlTree;
        return DefaultUrlSerializer.prototype.parse.apply(this, [url]);
    }

    serialize(tree: UrlTree): string {
        if (Array.isArray(tree) && ['$', '%', '&', '§'].includes(tree[0]))
            return tree.join('/');
        return DefaultUrlSerializer.prototype.serialize.apply(this, [tree]);
    }
}
