// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.p-inputnumber-input {
                    width: 100% !important;
                }`, "",{"version":3,"sources":["webpack://./src/app/modules/ngx-components/lib/wrappers/components/input-number.ts"],"names":[],"mappings":"AAAA;oBACoB,sBAAsB;gBAC1B","sourcesContent":[".p-inputnumber-input {\n                    width: 100% !important;\n                }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
