import { GDuration, WFType, WorkflowParam } from "@vierkant-software/types__api";

/**
 * Determine what kind of entity is allowed for the property. 
 * @note - flags, increment accordingly
 */
enum EntityType {
    None = 0,
    Person = 1,
    Department = 2,
    Superior = 4,
    All = Person | Department | Superior,
}

/**
 * definition of all workflow {@link Category|categories}. 
 */
export const categories: Category[] = [
    {
        name:          "Schichtbörse",
        workflowTypes: [{
            name:        WFType.shiftExchange,
            information: {
                // TODO
                label: "Verwaltung gibt unbesetzte Schichten an Schichtbörse",
            },
            configs: ["ceb326ec-b620-449c-83aa-13929da40b36", "f07a62af-5136-4a4f-8bac-7c7de8af50f9"],
        // }, {
        //     name:        "shiftexchange-employee",
        //     information: {
        //         // TODO
        //         label: "Mitarbeitende geben Schichten an Schichtbörse"
        //     },
        //     configs: ["07922fe1-3b8f-45c7-b583-b2c1bac7f365", "51358283-dd16-4b98-bc75-77966361fca0"],
        // }, {
        //     name:        "shiftswap",
        //     information: {
        //         // TODO
        //         label: "Schichttausch",
        //     },
        //     configs: ["31f0f59f-7654-462a-aad5-033b18571787", "8d1d547f-0e92-49c7-afd4-edf4404ad392"],
        }]
    }
];

export const SHIFT_PARAM_CONFIG = [{
    ID:       "5ce268e2-dac9-44f4-a10c-7a9afad4c185",
    label:    "Warnen wenn Schicht bald startet aber noch unbesetzt ist. Zeitpunkt vor Schichtstart ab welchem gewarnt wird, Angabe in Tagen:",
    type:     "number",
    min:      0,
    default:  7,
    required: true,
}, {
    ID:      "b38c2b96-ea97-49dd-9b77-9f02b055001f",
    label:   "Wer ist zuständig?",
    type:    "entity",
    kind:    EntityType.All,
    default: {
        origin: true,
    },
    required: true,
}] as ParamConfig[];

export const SHIFT_EXCHANGE_PARAM_CONFIG = [{
    ID:       "82b28ef8-19ef-4c30-9343-57abd1d28168",
    label:    "Spätester Zeitpunkt vor Schichtstart (Angaben in Tagen)",
    type:     "number",
    min:      0,
    default:  1,
    required: true,
}, {
    ID:       "c20a86b6-99fb-4626-8f7f-9e40054e4daa",
    label:    "Frühester Zeitpunkt vor Schichtstart (Angaben in Tagen)",
    type:     "number",
    min:      0,
    default:  30,
    required: true,
}, {
    ID:      "b38c2b96-ea97-49dd-9b77-9f02b055001f",
    label:   "Wer ist zuständig?",
    type:    "entity",
    kind:    EntityType.All,
    default: {
        origin: true,
    },
    required: true,
}] as ParamConfig[];

export const SHIFT_SWAP_PARAM_CONFIG = [{
    ID:       "82b28ef8-19ef-4c30-9343-57abd1d28168",
    label:    "Spätester Zeitpunkt vor Schichtstart (Angaben in Tagen)",
    type:     "number",
    min:      0,
    default:  1,
    required: true,
}, {
    ID:       "c20a86b6-99fb-4626-8f7f-9e40054e4daa",
    label:    "Frühester Zeitpunkt vor Schichtstart (Angaben in Tagen)",
    type:     "number",
    min:      0,
    default:  30,
    required: true,
}, {
    ID:      "b38c2b96-ea97-49dd-9b77-9f02b055001f",
    label:   "Wer ist zuständig?",
    type:    "entity",
    kind:    EntityType.All,
    default: {
        origin: true,
    },
    required: true,
}] as ParamConfig[];

/**
 * definition of all workflows throug their {@link WFConfig|configuration}.
 * They are identified by a {@link WorkflowID}, which must be **uniquely created** when adding a new workflow.
 */
export const configurations: {[key: string]: WFConfig} = {
    "ceb326ec-b620-449c-83aa-13929da40b36": {
        name:   "Mitarbeitende können sich in Schichten direkt eintragen.",
        params: SHIFT_PARAM_CONFIG,
    },
    "f07a62af-5136-4a4f-8bac-7c7de8af50f9": {
        name:   "Mitarbeitende bekunden Interesse an Schichten und auf Verwaltungsseite wird die Schicht final zugewiesen.",
        params: SHIFT_PARAM_CONFIG,
    },
    "07922fe1-3b8f-45c7-b583-b2c1bac7f365": {
        name:   "Mitarbeitenden dürfen die ihnen zugewiesenen Schichten an der Schichtbörse anbieten. - OHNE Bestätigung",
        params: SHIFT_EXCHANGE_PARAM_CONFIG,
        info:   "Die Mitarbeitenden bleiben eingetragen bis jemand anders übernimmt. Die Schicht ist zu keiner Zeit unbesetzt.",
    },
    "51358283-dd16-4b98-bc75-77966361fca0": {
        name:   "Mitarbeitenden dürfen die ihnen zugewiesenen Schichten an der Schichtbörse anbieten. - MIT Bestätigung",
        params: SHIFT_EXCHANGE_PARAM_CONFIG,
        info:   "Die Mitarbeitenden bleiben eingetragen bis jemand anders übernimmt. Die Schicht ist zu keiner Zeit unbesetzt.",
    },
    "31f0f59f-7654-462a-aad5-033b18571787": {
        name:   "Mitarbeitenden gestatten untereinander Schichten zu tauschen - OHNE Bestätigung des Tausches",
        params: SHIFT_SWAP_PARAM_CONFIG,
    },
    "8d1d547f-0e92-49c7-afd4-edf4404ad392": {
        name:   "Mitarbeitenden gestatten untereinander Schichten zu tauschen - MIT Bestätigung des Tausches",
        params: SHIFT_SWAP_PARAM_CONFIG,
    },
} as const;

export interface WorkflowData {
    workflowID: UUID;
    inheritance: InheritanceType;
    params: Record<UUID, WorkflowParam>;
}
export type DepartmentWorkflows = {[workflowType: string]: WorkflowData};
type UUID = string;
type WorkflowID = string;
export type ParamType = "number" | "duration" | "boolean" | "entity";

/**
 * Grouped category of {@link WorkflowType|workflow types}. This is the outer most group.
 * Inside this group {@link WorkflowType|workflow types} are defined, which each contain one selectable workflow.
 */
interface Category {
    name: string;
    workflowTypes: WorkflowType[];
}

/**
 * The type of the workflow. Is grouped by a parent {@link Category}.
 * Each `WorkflowType` has a list of {@link WorkflowID} which are defined through their {@link WFConfig} in the exported {@link configurations}.
*/
export interface WorkflowType {
    name: WFType;
    information?: Information;
    configs: WorkflowID[];
}

/**
 * Container for all misc. and meta data attached to a workflow type.
 * @remark Will be extended in the future by more descriptive text and icons.
 */
interface Information {
    label: string;
    description?: string;
    // 
}

/**
 * Definition of the Workflow.
 * Contains a description of its parameters and their restrictions
 */
export interface WFConfig {
    name: string;
    icon?: string;
    info?: string;
    params: ParamConfig[];
}
export type ParamConfig =
    { type: "boolean",  default: boolean                                     } & ParamConfigType |
    { type: "number",   default: number,    min?: number,    max?: number    } & ParamConfigType |
    { type: "duration", default: GDuration, min?: GDuration, max?: GDuration } & ParamConfigType |
    { type: "entity",   kind: EntityType                                     } & ParamConfigType |
    never;

/**
 * The base type of all configurations
 */
type ParamConfigType = {
    ID: UUID;
    type: ParamType ;
    label: string;
    required: boolean;
} & {[key: string]: unknown};


export interface Inheritance {

    type?: InheritanceType;
    /**
     * @note workaround for draft dictionary not being able to deal with primitives...
     */
    custom?: Record<string, {type: InheritanceType}>;
}

/**
 * Allows three states:
 * - undefined/null     - Use default value defined in company settings. Settings will become read-only 
 * - 'inherit'          - Inherit from a parent, will default to default value if none parent data is set. Settings will become read-only 
 * - 'custom'           - Allow adjusting of settings. Also allows customizing to which departments the data is inherited too.
 */
export type InheritanceType = "inherit" | "custom" | undefined;