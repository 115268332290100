// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: unset;
}

.gc-tooltip {
  position: absolute;
  z-index: 9999;
  opacity: 1;
  max-width: min(300px, 100vw - 200px);
  box-shadow: var(--gc-box-shadow);
  background: var(--gc-text-color);
  color: var(--gc-background-color);
  border-radius: 4px;
  animation-name: pointer-events;
  animation-duration: 0.25s;
}
.gc-tooltip-content {
  position: relative;
  padding: 0.5rem 0.75rem;
  background: inherit;
  border-radius: inherit;
}
.gc-tooltip-arrow {
  background-color: inherit;
  border: inherit;
  height: 12px;
  position: absolute;
  transform: rotate(45deg);
  width: 12px;
}

@keyframes tooltip-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes pointer-events {
  from {
    pointer-events: none;
  }
  to {
    pointer-events: all;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/gc-tooltip/gc-tooltip.sass"],"names":[],"mappings":"AAAA;EACI,cAAA;AACJ;;AACA;EACI,kBAAA;EACA,aAAA;EACA,UAAA;EACA,oCAAA;EACA,gCAAA;EACA,gCAAA;EACA,iCAAA;EACA,kBAAA;EAEA,8BAAA;EACA,yBAAA;AACJ;AAAI;EACI,kBAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;AAER;AADI;EACI,yBAAA;EACA,eAAA;EACA,YAAA;EACA,kBAAA;EACA,wBAAA;EACA,WAAA;AAGR;;AADA;EACE;IACE,UAAA;EAIF;EAHA;IACE,UAAA;EAKF;AACF;AAAA;EACE;IACE,oBAAA;EAEF;EADA;IACE,mBAAA;EAGF;AACF","sourcesContent":[":host\n    display: unset\n\n.gc-tooltip\n    position: absolute\n    z-index: 9999\n    opacity: 1\n    max-width: min(300px, calc(100vw - 200px))\n    box-shadow: var(--gc-box-shadow)\n    background: var(--gc-text-color)\n    color: var(--gc-background-color)\n    border-radius: 4px\n    // animation-name: tooltip-fade-in\n    animation-name: pointer-events\n    animation-duration: 0.25s\n    &-content\n        position: relative\n        padding: 0.5rem 0.75rem\n        background: inherit\n        border-radius: inherit\n    &-arrow \n        background-color: inherit\n        border: inherit\n        height: 12px\n        position: absolute\n        transform: rotate(45deg)\n        width: 12px\n\n@keyframes tooltip-fade-in \n  from \n    opacity: 0\n  to \n    opacity: 1\n\n// prevent flickering.\n// flicker happens as the tooltip is created on top of the hovered element (At (0,0) relative to it).\n// this causes a hover-entry on the element but also an hover-exit on the parent element.\n// by disabling pointer-events for a short period, the hover-exit on the parent is not called when creating the tooltip element.\n@keyframes pointer-events\n  from \n    pointer-events: none\n  to \n    pointer-events: all"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
