export { };

declare global {
    export interface Array<T> {
        distinct(): T[];
        uniq(): this;
    }
}

Array.prototype.distinct = function () {
    return [...new Set(this)];
};

Array.prototype.uniq = function () {
    return uniq(this);
};

export function groupBy<T>(array: T[], key: keyof T): Record<string | number, T[]> {
    return array.reduce((curr, val) => {
        (curr[<string | number><unknown>val[key]] = curr[<string | number><unknown>val[key]] ?? []).push(val);
        return curr;
    }, <Record<string | number, T[]>>{});
};

export function groupByProp<T>(array: T[], by: ((obj: T) => string | number)): Record<string | number, T[]> {
    return array.reduce((curr, val) => {
        (curr[<string | number><unknown>by(val)] = curr[<string | number><unknown>by(val)] ?? []).push(val);
        return curr;
    }, <Record<string | number, T[]>>{});
};

export function toDict<T, TKey extends string | number | symbol>(array: T[], by: ((obj: T) => TKey)) {
    return array.reduce((curr, val) => {
        curr[by(val)] = val;
        return curr;
    }, {} as Record<TKey, T>);
};

export function uniq<T>(array: T[]) {
    return array.filter((v,i,a) => a.indexOf(v) === i);
}
