import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { IProjectInfoSimple, ProjectStatus } from '@vierkant-software/types__api';
import { Globals } from 'src/app/services/globals.service';

@Component({
  selector:    'gc-single-select',
  templateUrl: './gc-single-select.component.haml',
  styleUrls:   ['./gc-single-select.component.sass'],
})
export class GcSingleSelectComponent {
  @Input() options: IProjectInfoSimple[];
  @Input() selected: IProjectInfoSimple;
  @Output() selectedChange = new EventEmitter();
  form = this.fb.group({
    status: [
        [
            ProjectStatus.planning,
            ProjectStatus.active,
            ProjectStatus.pause,
            ProjectStatus.inactive,
            ProjectStatus.completed,
            ProjectStatus.completedAutomatically,
        ]
    ],
});

statusOptions: ProjectStatus[] = [
    ProjectStatus.planning,
    ProjectStatus.active,
    ProjectStatus.pause,
    ProjectStatus.inactive,
    ProjectStatus.completed,
    ProjectStatus.completedAutomatically,
];

  constructor(private fb: FormBuilder, public globals: Globals) { }

  getStatusLabel(val: ProjectStatus){
    if (val === ProjectStatus.none) return '';
    return this.globals.projectStatusLabels[val] ?? '';
}

getStatusClass(val: ProjectStatus){
    if (val === ProjectStatus.active)
        return 'chip-green';
    if (val === ProjectStatus.inactive)
        return 'chip-red';
    if (val === ProjectStatus.completed)
        return 'chip-gray';
    if (val === ProjectStatus.completedAutomatically)
        return 'chip-gray';
    if (val === ProjectStatus.planning)
        return 'chip-cyan';
    if (val === ProjectStatus.pause)
        return 'chip-yellow';
    return 'chip';
}

  onItemClick(option: IProjectInfoSimple) {
    if (this.selected?.ID === option?.ID)
      this.selected = undefined;
    else
      this.selected = option;
    this.selectedChange.emit(this.selected);
  }
}
