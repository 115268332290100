/* eslint-disable @angular-eslint/no-inputs-metadata-property */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { Component, ViewEncapsulation } from "@angular/core";
import { Tag } from "primeng/tag";
import { WrapperBase } from "../base";
import { Wrapper } from "../decorators";

@Component({
    selector:      'gc-tag',
    encapsulation: ViewEncapsulation.None,
    template:      '<p-tag #component><ng-content></ng-content></p-tag>',
    providers:     [{ provide: Tag, useExisting: GcTagComponent }],
    inputs:        ["value", "severity", "rounded", "icon", "style", "styleClass"]
})
@Wrapper(Tag)
export class GcTagComponent extends WrapperBase {
}
export interface GcTagComponent extends Tag {}
