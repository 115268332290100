/* eslint-disable max-len */
import { FAIcon } from "./icon";

export const ThumbTackIcon: FAIcon = {
    prefix:   "gc",
    iconName: "thumbtack",
    icon:     [
        18, 24,
        [],
        null,
        "M11.5344 2H6.46566L5.75905 11.186L4.80179 11.705C3.67081 12.3183 2.75237 13.2916 2.21919 14.5H15.7808C15.2477 13.2916 14.3292 12.3183 13.1982 11.705L12.241 11.186L11.5344 2ZM1.50002 16.5C1.01721 16.5 0.567206 16.2703 0.281269 15.8766C-0.00466862 15.4828 -0.0796686 14.9812 0.0750188 14.5266L0.121894 14.3859C0.768769 12.4406 2.12814 10.8797 3.84846 9.94687L4.38283 3H3.00002C2.78149 3 2.57402 2.9535 2.38694 2.86983C1.86373 2.63584 1.50002 2.11116 1.50002 1.5C1.50002 0.670312 2.17033 0 3.00002 0H15C15.8297 0 16.5 0.670312 16.5 1.5C16.5 2.11116 16.1363 2.63584 15.6131 2.86983C15.426 2.9535 15.2185 3 15 3H13.6172L14.1516 9.94687C15.8719 10.8797 17.2313 12.4406 17.8781 14.3859L17.925 14.5266C18.0797 14.9859 18 15.4875 17.7188 15.8766C17.4375 16.2656 16.9828 16.5 16.5 16.5H1.50002ZM10.5 18H7.50002V22.5C7.50002 23.3297 8.17033 24 9.00002 24C9.82971 24 10.5 23.3297 10.5 22.5V18Z"
    ]
};
