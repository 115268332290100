/* eslint-disable max-len */
/* eslint-disable @angular-eslint/no-inputs-metadata-property */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/no-outputs-metadata-property */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { Component, ViewEncapsulation } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { ToggleButton } from "primeng/togglebutton";
import { WrapperComponent } from "../base";
import { Wrapper } from "../decorators";

@Component({
    selector:      'gc-toggleButton',
    encapsulation: ViewEncapsulation.None,
    template:      '<p-toggleButton [formControl]="gcControl"><ng-content></ng-content></p-toggleButton>',
    providers:     [{ provide: NG_VALUE_ACCESSOR, useExisting: GcToggleButtonComponent, multi: true }],
    inputs:        ["onLabel", "offLabel", "onIcon", "offIcon", "iconPos", "style", "styleClass", "disabled", "tabindex", "inputId", "ariaLabelledBy"],
    outputs:       ["onChange"]
})
@Wrapper(ToggleButton)
export class GcToggleButtonComponent extends WrapperComponent {
}
export interface GcToggleButtonComponent extends ToggleButton {};
