import { Component,Input } from '@angular/core';
import { IAddress, ILocation } from '@vierkant-software/types__api';
type Format = "short" | "long";

/**
 * Display address information in varying formats. Accepts an `IAddress` object.
 * 
 * @name    Address
 * @tags    Display, Data, User
 * 
 * @example `long` - Use this to display an address as a block element.
 * ```
 * %gc-address{[value]: "demo.address", format: "long"}
 * ```
 * 
 * @example `short` - Use this to display an address inline.
 * ```
 * %gc-address{[value]: "demo.address"}
 * ```
 */
@Component({
    selector: 'gc-address',
    template: '<span [ngClass]="{pre: isLong}">{{ _internvalParsedValue }}</span>',
    styles:   ['.pre { white-space:pre; display: block; }']
})
export class GcAddressComponent {
    /**
     * Address to display
     * @example
     * ```ts
     * const address: IAddress = {postcode: '47877', city: 'Willich', street: 'Moosheide', houseNumber: '120'};
     * ```
     */
    @Input() public set value(value: IAddress) {
        this._internalValue = value;
        this.updateValue();
    }

    /**
     * Fallback if address object is empty
     * @default "-"
     */
    @Input() public set nullValue(value: string) {
        this._internalNullValue = value;
        this.updateValue();
    }

    /**
     * The format to display the address in.
     * Accepts `long` and `short` as value.
     * @default "short"
     */
    @Input() public set format(value: Format){
        this._internalFormat = value;
        this.updateValue();
    }


    public get format(){
        return this._internalFormat;
    }

    get isLong(){
        return this.format === "long";
    }

    _internalValue: IAddress;
    _internalFormat: Format = "short";
    _internalNullValue: string = '-';
    _internvalParsedValue: string;

    constructor(){
        this.updateValue();
    }

    private updateValue(){
        this._internvalParsedValue = this._internalValue ? this.formatAddress(this._internalValue, this.format) : this._internalNullValue;
    }

    private formatAddress(value: IAddress, format: Format): string {
        if (format === "short")
            return this.shortFormat(value);
        return this.longFormat(value);
    }

    private longFormat(value: IAddress){
        if (!value) return '';
        const result = [
            [value.street, value.houseNumber].filter(x => x).join(' '),
            [value.postcode, value.city].filter(x => x).join(' '),
            value.country,
            value.description,
        ].filter(x => x).join('\n');
        return result.trim();
    }

    private shortFormat(value: ILocation): string {
        if (!value) return '';
        const result = [
            [value.street, value.houseNumber].filter(x => x).join(' '),
            [value.postcode, value.city, value.country].filter(x => x).join(' '),
        ].filter(x => x).join(', ');
        return result.trim();
    }

}
