// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.visibility {
  align-items: flex-start;
}
.visibility .inheritance {
  flex-shrink: 0;
  width: 270px;
  background: var(--gc-color-light);
  border: 1px solid var(--gc-border-color);
  padding: 0.5rem;
}
.visibility .settings {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/app/components/visibility/visibility.component.sass"],"names":[],"mappings":"AAAA;EACI,uBAAA;AACJ;AAAI;EACI,cAAA;EACA,YAAA;EACA,iCAAA;EACA,wCAAA;EACA,eAAA;AAER;AADI;EACI,YAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AAGR","sourcesContent":[".visibility\n    align-items: flex-start\n    .inheritance\n        flex-shrink: 0\n        width: 270px\n        background: var(--gc-color-light)\n        border: 1px solid var(--gc-border-color)\n        padding: 0.5rem\n    .settings\n        flex-grow: 1\n        display: flex\n        flex-direction: column\n        gap: 1rem"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
