/* eslint-disable max-len */
/* eslint-disable @angular-eslint/no-inputs-metadata-property */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/no-outputs-metadata-property */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { Component, ViewEncapsulation } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { RadioButton } from "primeng/radiobutton";
import { WrapperComponent } from "../base";
import { Wrapper } from "../decorators";

@Component({
    selector: 'gc-radioButton',
    template: `<div class="p-field-radiobutton">
                    <p-radioButton [formControl]="gcControl"><ng-content></ng-content></p-radioButton>
                </div>`,
    encapsulation: ViewEncapsulation.None,
    providers:     [{ provide: NG_VALUE_ACCESSOR, useExisting: GcRadiobuttonComponent, multi: true }],
    inputs:        ["name", "value", "label", "disabled", "tabindex", "inputId", "ariaLabelledBy", "ariaLabel", "style", "styleClass", "labelStyleclass"],
    outputs:       ["onClick", "onFocus", "onBlur"]
})
@Wrapper(RadioButton)
export class GcRadiobuttonComponent extends WrapperComponent {
}
export interface GcRadiobuttonComponent extends RadioButton {}
