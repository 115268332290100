import { Component, forwardRef, Input, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateTime } from 'luxon';

/**
 * @ignore - do not show in documentation
 */
@Component({
  selector: 'gc-datetime',
  template: `
    <div class="flex-column">
      <label *ngIf="label !== undefined">{{ label }}</label>
      <input
          class="gc-datetime"
          [type]="_type"
          [value]="_iValue"
          (blur)="onBlur()"
          (focus))="onFocus()"
          (input)="_handleInput($any($event).target.value)"
          [disabled]=disabled
          [class.noIcon]="hideIcon"
          [min]="_min"
          [max]="_max"
          [step]="step"
          part="native"
      />
    </div>
  `,
  styleUrls:     ['./datetime.component.sass'],
  encapsulation: ViewEncapsulation.ShadowDom,
  providers:     [
    {
      provide:     NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatetimeComponent),
      multi:       true
    }
  ]
})
export class DatetimeComponent implements ControlValueAccessor {

  onChanged = (value: DateTime) => {};
  onTouched = () => {};
  _type: 'datetime-local' | 'time' | 'date' | 'week' | 'month' = 'datetime-local';

  _value = DateTime.now();

  hasFocus: boolean;

  @Input() label: string;
  @Input() disabled = false;

  @Input() min: DateTime;
  get _min(): string {
    // if (this._type === "time")
    //   return this.min?.toFormat("HH:mm");
    // return this.min ? this._getMatchingIso(this.min) : undefined;
    return this.min && this._getMatchingIso(this.min);
  }

  @Input() max: DateTime;
  get _max(): string{
    // if (this._type === "time")
    //   return this.max?.toFormat("HH:mm");
    // return this.min ? this._getMatchingIso(this.min) : undefined;
    return this.max && this._getMatchingIso(this.max);
  }
  @Input() step: number;

  @Input()
  get type() { return this._type === 'datetime-local' ? 'datetime' : this.type; }
  set type(value: 'datetime' | 'time' | 'date' | 'week' | 'month') {
    this._type = value === 'datetime' ? 'datetime-local' : value;
  }

  @Input()
  get value() { return this._value; }
  set value(value: DateTime) {
    this._value = value;
  }

  @Input() include_seconds: boolean = false;

  get _iValue() {
    return this._getMatchingIso(this._value);
  }

  @Input() hideIcon = false;

  _handleInput(value: string) {
    this._value = DateTime.fromISO(value);
    if (this._type === 'time')
      this._value = this._value.set({ year: 1970, month: 1, day: 1 });
    this.onChanged(this._value);
  }

  _getMatchingIso(value: DateTime) {
    if (!value)
      return '';
    const v = value.toLocal().set({ millisecond: 0, ...(this.include_seconds ? {} : { seconds: 0}) });
    switch(this._type) {
      case 'datetime-local':
        return v.toISO({suppressMilliseconds: true, includeOffset: false, suppressSeconds: !this.include_seconds});
      case 'date':
        return v.toISODate();
      case 'time':
        return v.toISOTime({suppressMilliseconds: true, includeOffset: false, suppressSeconds: !this.include_seconds});
      case 'week':
        return v.toISOWeekDate().substring(0, 8);
      case 'month':
        return v.toISODate()?.substring(0, 7);
    }
  }

  onFocus() {
    this.hasFocus = true;
  }

  onBlur() {
    this.hasFocus = false;
    this.onTouched();
  }

  writeValue(obj: DateTime): void {
    this._value = obj;
  }

  setDisabledState(isDisabled: boolean): void { this.disabled = isDisabled; }
  registerOnChange(fn: (value: DateTime) => void): void { this.onChanged = fn; }
  registerOnTouched(fn: () => void): void { this.onTouched = fn; }
}

