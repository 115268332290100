import { NgModule, Type } from "@angular/core";
import { GcScrollContainerComponent } from "./scroll-container/scroll-container.component";
import { GcRowContainerComponent } from "./row/row.component";
import { GcColumnContainerComponent } from "./column/column.component";

const components: Type<unknown>[] = [
    GcScrollContainerComponent,
    GcRowContainerComponent,
    GcColumnContainerComponent,
];

@NgModule({
    imports:      [],
    declarations: components,
    exports:      components,
})
export class GcLayoutModule {}
