/* eslint-disable max-len */
/* eslint-disable @angular-eslint/no-inputs-metadata-property */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/no-outputs-metadata-property */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { Component, ContentChildren, QueryList, ViewEncapsulation } from "@angular/core";
import { Sidebar } from "primeng/sidebar";
import { WrapperBase } from "../base";
import { Wrapper } from "../decorators";
import { GcTemplate } from "../directives/gcTemplate";

@Component({
    selector: 'gc-sidebar2',
    template: `
        <p-sidebar #component>
            <ng-content></ng-content>
            <ng-container  *ngFor="let template of templates">
                <ng-template pTemplate="{{template.getType()}}">
                    <ng-container *ngTemplateOutlet="template.template"></ng-container>
                </ng-template>
            </ng-container>
        </p-sidebar>`,
    encapsulation: ViewEncapsulation.None,
    providers:     [{ provide: Sidebar, useExisting: GcSidebarComponent }],
    inputs:        ["visible", "position", "fullScreen", "appendTo", "style", "styleClass", "blockScroll", "baseZIndex", "autoZIndex", "modal", "dismissable", "showCloseIcon",
        "transitionOptions", "ariaCloseLabel", "closeOnEscape"
    ],
    outputs: [
        "onShow", "onHide", "visibleChange"
    ]
})
@Wrapper(Sidebar)
export class GcSidebarComponent extends WrapperBase {
    @ContentChildren(GcTemplate) templates: QueryList<GcTemplate>;
}
export interface GcSidebarComponent extends Sidebar {}
