import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { ObjectUtils } from 'primeng/utils';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import "./util/toDateTime";

const { merge } = ObjectUtils;
ObjectUtils.merge = (obj1, obj2) => {
  if (!obj1 && !obj2) return null;
  merge.apply(ObjectUtils, [obj1, obj2]);
};

if (environment.production)
  enableProdMode();

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
