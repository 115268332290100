// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `p-card {
  border: 1px solid var(--gc-border-color);
}

.card-title {
  padding: 1rem;
  border-bottom: 1px solid var(--gc-border-color);
}

.dashboard-title {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.dashboard-title .profile {
  flex-shrink: 0;
  margin-right: 2rem;
  width: 150px;
}
.dashboard-title .data {
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
}
.dashboard-title .data > * {
  flex: 1 1 auto;
  max-width: 200px;
  min-width: 200px;
}
.dashboard-title .data > * > * {
  margin-bottom: 0.5rem;
}

.card-item {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

@media screen and (max-width: 1200px) {
  .card-item {
    flex: 0 0 auto;
    width: 50%;
  }
}
@media screen and (max-width: 992px) {
  .card-item {
    flex: 0 0 auto;
    width: 100%;
  }
}
.contract-list {
  gap: 1px;
  background: var(--gc-border-color);
}
.contract-list .contract {
  padding: 0.5rem 0;
  background: var(--gc-background-color);
}`, "",{"version":3,"sources":["webpack://./src/app/pages/person/id/overview/overview.page.sass"],"names":[],"mappings":"AAEA;EACI,wCAAA;AADJ;;AAGA;EACI,aAAA;EACA,+CAAA;AAAJ;;AAEA;EACI,aAAA;EACA,eAAA;EACA,uBAAA;AACJ;AAAI;EACI,cAAA;EACA,kBAAA;EACA,YAhBK;AAkBb;AADI;EACI,YAAA;EACA,aAAA;EACA,eAAA;AAGR;AAFQ;EACI,cAAA;EACA,gBAAA;EACA,gBAAA;AAIZ;AAHY;EACI,qBAAA;AAKhB;;AAHA;EACQ,cAAA;EACA,qBAAA;AAMR;;AALA;EACI;IACI,cAAA;IACA,UAAA;EAQN;AACF;AARA;EACI;IACI,cAAA;IACA,WAAA;EAUN;AACF;AATA;EACI,QAAA;EACA,kCAAA;AAWJ;AAVI;EACI,iBAAA;EACA,sCAAA;AAYR","sourcesContent":["$image_size: 150px\n\np-card\n    border: 1px solid var(--gc-border-color)\n\n.card-title\n    padding: 1rem\n    border-bottom: 1px solid var(--gc-border-color)\n\n.dashboard-title\n    display: flex\n    flex-wrap: wrap\n    justify-content: center\n    .profile\n        flex-shrink: 0\n        margin-right: 2rem\n        width: $image_size    \n    .data\n        flex-grow: 1\n        display: flex\n        flex-wrap: wrap\n        > *\n            flex: 1 1 auto\n            max-width: 200px\n            min-width: 200px\n            > * \n                margin-bottom: 0.5rem\n\n.card-item\n        flex: 0 0 auto\n        width: calc(100% / 3)\n@media screen and (max-width: 1200px) \n    .card-item\n        flex: 0 0 auto\n        width: 50%\n@media screen and (max-width: 992px) \n    .card-item\n        flex: 0 0 auto\n        width: 100%\n\n.contract-list\n    gap: 1px\n    background: var(--gc-border-color)\n    .contract\n        padding: 0.5rem 0\n        background: var(--gc-background-color)"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
