import {
    Component,
    ComponentRef,
    ContentChild,
    Directive,
    ElementRef,
    HostBinding,
    HostListener,
    Input,
    TemplateRef,
    ViewChild,
    OnInit,
    OnDestroy,
    ViewContainerRef} from "@angular/core";
import { arrow, computePosition, flip, autoPlacement, offset, platform, shift, Placement, autoUpdate } from "@floating-ui/dom";
import { offsetParent } from 'composed-offset-position';
import { Subject, Subscription } from 'rxjs';

/**
 * Fix for Safari on Mac throwing 'Can't find variable: TouchEvent'
 */
const isTouchEvent = (event: Event) => 'touches' in event;
type Mode = "hover" | "click";

export interface TooltipOption {
    /**
     * Trigger mode for the tooltip.
     * Option are "hover" and "click".
     * Defaults to "hover"
     */
    mode?: Mode;
    /**
     * Determine position of the tooltip. 
     * 
     * @remarks Position might be overwritten if there is no space
     * 
     */
    position?: Placement;
    /**
     * Delay to show the tooltip.
     * Defaults to 250ms. Should be lower than hideTimeout to prevent flickering.
     */
    showDelay?: number;
    /**
     * Timeout on when to hide/remove the tooltip.
     * Defaults to 300ms. Should be higher than showDelay to prevent flickering.
     */
    hideTimeout?: number;
    /**
     * Add a css class to the tooltip
     */
    tooltipClass?: string;
    /**
     * Determines if the tooltip position is updated when the element moves.
     * Defaults to true
     */
    updatePosition?: boolean;
}

/**
 * Container component where the tooltips attach to.
 * Place this ONCE near the body (e.g. app.component.ts) to prevent issues with overflow
 */
@Component({
  selector: 'gc-tooltip',
  template: '<ng-container #gcTooltipContainer></ng-container>'
})
export class GcTooltipContainerComponent {
    public static INSTANCE: GcTooltipContainerComponent;
    public static get EVENT_OBSERVABLE(){
     return GcTooltipContainerComponent.INSTANCE.eventObservable;
    }
    private eventObservable: Subject<Event> = new Subject();

    @HostBinding('class') public hostClass = "gc-tooltip-container";
    @ViewChild('gcTooltipContainer', {read: ViewContainerRef, static: true}) public viewRef: ViewContainerRef;

    constructor(){
        if (GcTooltipContainerComponent.INSTANCE)
            throw Error("Only one instance of 'GcTooltipContainerComponent' can exist.");
        GcTooltipContainerComponent.INSTANCE = this;
    }

    @HostListener('body:mousedown', ['$event'])
    @HostListener('body:touchstart', ['$event'])
    @HostListener('body:keydown.Escape', ['$event'])
    public onBodyClick(event: Event){
        this.eventObservable.next(event);
    }
}

/**
 * Tooltip component. Attaches to container and is placed along target element.
 * Cannot be manually added through a selector, but rather is automatically added through the [gcTooltip] directive
 */
@Component({
  template: `
    <div #el
        [ngClass]="tooltipClass"
        class="gc-tooltip"
        (mouseenter)="onEnterTooltip($event)"
        (mouseleave)="onMouseLeave($event)"
        (mousedown)="onEnterTooltip($event)"
        (touchstart)="onEnterTooltip($event)">
        <div #gcTooltipArrow class="gc-tooltip-arrow"></div>
        <div class="gc-tooltip-content">
            <ng-container *ngTemplateOutlet="template; context: { $implicit: value }"></ng-container>
            <span *ngIf="!template">{{value}}</span>
        </div>
    </div>`,
  styleUrls: ['./gc-tooltip.sass'],
})
export class GcTooltipComponent {
    @ViewChild('gcTooltipArrow', {static: true, read: ElementRef<HTMLElement>}) public arrow: ElementRef<HTMLElement>;
    @ViewChild('el', {static: true, read: ElementRef<HTMLElement>}) public el: ElementRef<HTMLElement>;
    @Input() hideAction?: (event: Event) => void;
    @Input() hideTimeout?: number;
    @HostBinding('class') public hostClass = "gc-tooltip";
    @HostBinding('attr.role') public role = "tooltip";
    @Input() public template: TemplateRef<unknown>;
    @Input() public tooltipClass: string;
    @Input() public value: unknown;

    public onEnterTooltip(event: Event) {
        event.stopPropagation();
        event.preventDefault();
        if (!this.hideTimeout) return;
        window.clearTimeout(this.hideTimeout);
        this.hideTimeout = undefined;
    }

    public onMouseLeave(event: Event) {
        this.hideAction?.(event);
    }
}

@Directive({
    selector: '[gcTooltip]',
})
export class GcTooltipDirective implements OnInit, OnDestroy {
    private static clickTooltips: GcTooltipDirective[] = [];
    private _tooltipOption: TooltipOption;
    private _tooltipTemplate: TemplateRef<unknown>;
    private container = GcTooltipContainerComponent.INSTANCE.viewRef;
    private element: ComponentRef<GcTooltipComponent>;
    private value: unknown;
    private bodySubscription: Subscription;
    private hideTimeout?: number;
    private showTimeout?: number;

    public cleanup: () => void;
    /**
     * Allows tooltip to be disabled/not-shown through a binding
     * 
     */
    @Input() public tooltipDisabled = false;

    constructor(
        private el: ElementRef<HTMLElement>,
    ) {
    }

    public static hideAll() {
        GcTooltipDirective.clickTooltips.forEach(tooltip => tooltip.hide());
    }

    public ngOnInit(): void {
        this.bodySubscription = GcTooltipContainerComponent.EVENT_OBSERVABLE.subscribe(x => {
            if (!["touchstart", "mousedown", "click", "keydown"].includes(x.type)) return;
            this.hide();
        });
    }

    public ngOnDestroy(): void {
        this.bodySubscription?.unsubscribe();
    }

    @Input() public set gcTooltip(value: unknown) {
        this.value = value;
        if (this.element)this.applyValue();
    }

    public get mode(): Mode {
        return this.tooltipOption?.mode ?? "hover";
    }

    public get toolTipHideTimeout() {
        return this.tooltipOption?.hideTimeout ?? 300;
    }
    public get toolTipShowDelay() {
        return this.tooltipOption?.showDelay ?? 250;
    }

    public get tooltipClass(){
        return this.tooltipOption?.tooltipClass;
    }

    public get tooltipOption(): TooltipOption {
        return this._tooltipOption;
    }

    @Input() public set tooltipOption(value: TooltipOption) {
        this._tooltipOption = value;
        this.applyValue();
    }

    public get tooltipPosition(): Placement {
        return this.tooltipOption?.position;
    }

    public get tooltipTemplate() {
        return this._tooltipTemplate;
    }

    @ContentChild('tooltip', { static: false })
    public set tooltipTemplate(value: TemplateRef<unknown>) {
        this._tooltipTemplate = value;
        this.applyValue();
    }

    // @HostListener('body:mousedown', ['$event'])
    // @HostListener('body:touchstart', ['$event'])
    // public onBodyClick(event: Event){
    //     this.hide(event);
    // }

    @HostListener('mousedown', ['$event'])
    @HostListener('touchstart', ['$event'])
    public onClick(event: Event){
        if (this.mode === "hover")
            return;
        GcTooltipDirective.clickTooltips.forEach(tooltip => tooltip.hide());
        GcTooltipDirective.clickTooltips = [this];
        this.show(event);
    }

    @HostListener('mouseenter', ['$event'])
    @HostListener('touchstart', ['$event'])
    public onMouseEnter(event: Event){
        if (this.mode === "click")
            return;
        this.showTimeout = window.setTimeout(() => this.show(event), this.toolTipShowDelay);
    }

    @HostListener('mouseleave', ['$event'])
    @HostListener('touchend', ['$event'])
    public onMouseLeave(event: Event){
        if (this.showTimeout) window.clearTimeout(this.showTimeout);
        if (this.mode === "click")
            return;
        if (!(isTouchEvent(event))){
            event.preventDefault();
            event.stopPropagation();
        }
        this.hide(event);
    }

    private applyValue(){
        if (!this.element?.instance) return;
        this.element.instance.value = this.value;
        this.element.instance.template = this.tooltipTemplate;
        this.element.instance.tooltipClass = this.tooltipClass;
    }

    private hide(event?: Event): void {
        if (!this.element) return;
        const internalHide = () => {
            this.cleanup?.();
            if (!this.element) return;
            const index = this.container.indexOf(this.element.hostView);
            if (index < 0) return;
            this.container.remove(index);
            this.element = undefined;
        };
        this.hideTimeout = this.element.instance.hideTimeout = window.setTimeout(() => internalHide(), this.toolTipHideTimeout);
    }

    private roundByDPR(value: number) {
        const dpr = window.devicePixelRatio || 1;
        return Math.round(value * dpr) / dpr;
    }

    private show(event: Event): void {
        if (this.tooltipDisabled) return;
        if (this.hideTimeout)
            window.clearTimeout(this.hideTimeout);
        if (!(isTouchEvent(event)))
            event.preventDefault();
        event.stopPropagation();

        if (!this.element){
            this.element = this.container.createComponent(GcTooltipComponent);
            if (this.mode === "hover"){
                this.element.instance.hideAction = () => {
                    event.preventDefault();
                    event.stopPropagation();
                    this.hide(event);
                };
            }
        }
        this.applyValue();
        this.container.insert(this.element.hostView);
        if (!this.element.instance.el)
            return;

        if (!this.tooltipOption || this.tooltipOption.updatePosition !== false){
            this.cleanup = autoUpdate(
                this.el.nativeElement,
                this.element.instance.el.nativeElement,
                this.updatePosition.bind(this)
            );
        } else {
            this.updatePosition();
        }
    }

    private isElementNotVisible(){
        return this.el.nativeElement.offsetParent === null;
    }

    /**
     * calculate the position of the floating tooltip in relation to the parent element.
     * 
     */
    private updatePosition() {
        if (this.isElementNotVisible()) return this.hide();
        if (!this.element?.instance) return this.hide();
        computePosition(this.el.nativeElement, this.element.instance.el.nativeElement, {
            platform: {
                ...platform,
                getOffsetParent: (element) => platform.getOffsetParent(element, offsetParent),
            },
            placement:  this.tooltipPosition,
            middleware: [
                !!this.tooltipPosition ? flip() : autoPlacement(),
                offset(7),
                shift({ padding: 5 }),
                arrow({ element: this.element.instance.arrow.nativeElement })
            ]
        }).then(({ x, y, placement, middlewareData }) => {
            Object.assign(this.element.instance.el.nativeElement.style, {
                top:       '0',
                left:      '0',
                // using translate should give better performance (?)
                // https://floating-ui.com/docs/misc#subpixel-and-accelerated-positioning
                transform: `translate(${this.roundByDPR(x)}px,${this.roundByDPR(y)}px)`,
            });
            if (middlewareData.arrow) {
                const {x: arrowX, y: arrowY} = middlewareData.arrow;

                const staticSide = {
                    top:    "bottom",
                    right:  "left",
                    bottom: "top",
                    left:   "right"
                }[placement.split("-")[0]];
                Object.assign(this.element.instance.arrow.nativeElement.style, {
                    left:         x != null ? `${arrowX}px` : '',
                    top:          y != null ? `${arrowY}px` : '',
                    [staticSide]: '-4px'
                });
            }
        }).catch(console.error);
    }
}
