import { InjectionToken } from "@angular/core";

export const GC_COMPONENT_CONTAINER = new InjectionToken('GC_COMPONENT_CONTAINER');

export const FILTERED_PROPS = [
    "formControl",
    "formControlName",
    "formControlDirective"
];

export const FILTERED_METHODS = [
    "clearInput",
    "registerOnTouched",
    "registerOnChange",
    "writeValue",
    "setDisabledState",
    "ngAfterContentInit",
    "ngAfterContentCheck",
    "ngOnInit",
    "ngOnChanges",
    "ngDoCheck",
    "ngAfterViewInit",
    "ngAfterViewChecked",
    "ngOnDestroy",
    "constructor",
];
