import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, BaseRouteReuseStrategy, DetachedRouteHandle } from "@angular/router";

@Injectable()
export class GCRouteReuseStrategy extends BaseRouteReuseStrategy {
    private routeStore = new Map<string, DetachedRouteHandle>();

    public override shouldDetach(route: ActivatedRouteSnapshot): boolean {
        return !!route.routeConfig?.data?.useDetachAttachKey;
    }

    public override store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void {
        this.routeStore.set(route.routeConfig?.data?.useDetachAttachKey ?? '', detachedTree);
    }

    public override shouldAttach(route: ActivatedRouteSnapshot): boolean {
        return !!route.routeConfig?.data?.useDetachAttachKey && this.routeStore.has(route.routeConfig?.data?.useDetachAttachKey);
    }

    public override retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
        return this.routeStore.get(route.routeConfig?.data?.useDetachAttachKey ?? '') as DetachedRouteHandle;
    }

    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        const currCompName = typeof curr.component === 'string' ? curr.component : curr.component?.name;
        const futureCompName = typeof future.component === 'string' ? future.component : future.component?.name;
        if (currCompName === 'DraftHostComponent' && futureCompName === 'DraftHostComponent')
            return future.data?.Stepper?.constructor === curr.data?.Stepper?.constructor && future.params?.draft === curr.params?.draft;


        if (future.routeConfig !== curr.routeConfig)
            return false;

        if (currCompName === futureCompName && future.routeConfig?.data?.reuseRoute)
            return true;

        // checking router params
        const futureParams = future.params;
        const currentParams = curr.params;
        const keysA = Object.keys(futureParams);
        const keysB = Object.keys(currentParams);


        if (keysA.length !== keysB.length)
            return false;

        // Test for A's keys different from B.
        for (const key of keysA) {
            if (currentParams[key] !== futureParams[key])
                return false;
        }
        return true;
    }
}
