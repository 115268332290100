import { Component } from "@angular/core";

/**
 * Component which is used as a fallback to scroll inside an element instead of the whole page.
 * For example it's used when we want to scroll in the table element.
 * @name Scroll-Container
 * @remark  use `.full-page` on component to ignore the wrapper padding of the page
 * @example
 * ```
 *  %gc-scroll-container
 *      %div{*ngFor: "let i of numbersArray; let index = index;", style: "height: 300px;"} {{index}}
 * ```
 */
@Component({
    selector: 'gc-scroll-container',
    template: `
<div class="content">
    <ng-content></ng-content>
</div>
`,
    styleUrls: ['scroll-container.component.sass']
})
export class GcScrollContainerComponent {}
