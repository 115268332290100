import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ISelectedProleosFile } from '../../../../steppers/product-management/product/product.interfaces';

@Component({
  selector:    'app-proleos-selected-files-list',
  templateUrl: './proleos-selected-files-list.component.haml',
  styleUrls:   ['./proleos-selected-files-list.component.sass'],
})
export class ProleosSelectedFilesListComponent {
  @Input() selectedFiles: Array<ISelectedProleosFile> = [];
  @Output() selectedFilesChange = new EventEmitter<Array<ISelectedProleosFile>>();

  getImageDisplaySize(size: number): string {
    return (size / (1024 * 1024)).toFixed(2).replace('.', ',') + ' MB';
  }

  removeFileAt(index: number): void {
    this.selectedFiles.splice(index, 1);
    this.emitSelectedFileChange();
  }

  moveItem(currentIndex: number, newIndex: number): void {
    moveItemInArray(this.selectedFiles, currentIndex, newIndex);
    this.emitSelectedFileChange();
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.selectedFiles, event.previousIndex, event.currentIndex);
    this.emitSelectedFileChange();
  }

  emitSelectedFileChange(): void {
    this.selectedFilesChange.emit(this.selectedFiles);
  }

}
