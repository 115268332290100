import { ISearchQueryEmployee, Pagination, SearchKey, SearchQueryResult, VisibilityName, WorkHoursType } from "@vierkant-software/types__api";
import { ControlValueAccessor } from "@angular/forms";
import { PersonSearchService } from "../person-search.service";
import { EventEmitter } from "@angular/core";
export type SelectMode = "single" | "multiple";
export type SearchField = SearchKey | "customer" | "employee" | "formerEmployee" | "activeEmployee" | "futureEmployee" | "trustedWorkTimes";

/**
* @ignore - do not show in documentation
*/
export abstract class SearchBaseComponent implements ControlValueAccessor, IPersonSearch {
    public abstract selectionChange: EventEmitter<string[]>;
    public abstract selectedItems: EventEmitter<SearchQueryResult[]>;
    protected _value: string[] = null;
    protected service: PersonSearchService;

    public abstract loading: boolean;
    public abstract visibility?: VisibilityName;
    public abstract departments?: string[] | true;
    public abstract employment?: Partial<ISearchQueryEmployee>;
    public abstract maxSelect?: number;
    public abstract minSelect?: number;
    public abstract fields?: SearchField[];
    public abstract activeFields?: SearchField[];
    public abstract lockedFields?: SearchField[];
    public abstract trustedWorkTimes?: "yes" | "no" | "any";
    public abstract workhoursType?: WorkHoursType;
    public onChange: (value: string[] | null) => void;
    public onTouch: () => void;
    public abstract pagination: Pagination;
    public abstract selectionMode: SelectMode;
    public abstract showAvatar: boolean;
    public abstract searchResult: SearchQueryResult[];
    public abstract avatars: string[];

    constructor() {
        this.service = new PersonSearchService();
    }

    public get value(): string[] {
        return this._value;
    }

    public set value(value: string[]) {
        this._value = value;
        this.onChange?.(value);
        this.selectionChange.emit(value);
    }

    public abstract set searchValue(value: string);

    public clear(){
        this.searchValue = '';
    }

    public onBlur() {
        this.onTouch();
    }

    public registerOnChange(fn: (value: string[]) => void): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    public writeValue(value: string[], allowEmpty = false): void {
        if (!allowEmpty &&  [null, undefined, []].includes(value))
            return;
        if (this.value === value)
            return;
        this.value = value;
        this.onChange?.(value);
    }
}

export interface IPersonSearch {
    visibility?: VisibilityName;
    departments?: string[] | true;
    fields?: SearchField[];
    activeFields?: SearchField[];
    lockedFields?: SearchField[];
    trustedWorkTimes?: "yes" | "no" | "any";
}