// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact .type {
  position: relative;
}
.contact .type .hover-icon {
  position: absolute;
  right: 0.75rem;
  display: none;
}
.contact .type:hover .hover-icon {
  display: initial;
}`, "",{"version":3,"sources":["webpack://./src/app/steppers/users/steps/person/person.step.sass"],"names":[],"mappings":"AACI;EACI,kBAAA;AAAR;AACQ;EACI,kBAAA;EACA,cAAA;EACA,aAAA;AACZ;AACY;EACI,gBAAA;AAChB","sourcesContent":[".contact\n    .type\n        position: relative\n        .hover-icon\n            position: absolute\n            right: 0.75rem\n            display: none\n        &:hover\n            .hover-icon\n                display: initial"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
