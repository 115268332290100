/* eslint-disable max-len */
/* eslint-disable @angular-eslint/no-inputs-metadata-property */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/no-outputs-metadata-property */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { Component, ContentChildren, EventEmitter, Input, Output, QueryList, ViewEncapsulation } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { SelectButton, SelectButtonChangeEvent } from "primeng/selectbutton";
import { WrapperComponent } from "../base";
import { Wrapper } from "../decorators";
import { GcTemplate } from "../directives/gcTemplate";

@Component({
    selector: 'gc-selectButton',
    template: `<p-selectButton [formControl]="gcControl">
                    <ng-content></ng-content>
                    <ng-container  *ngFor="let template of templates">
                        <ng-template pTemplate="{{template.getType()}}" let-item let-i="index">
                            <ng-container *ngTemplateOutlet="template.template; context: {$implicit: item, index: i}"></ng-container>
                        </ng-template>
                    </ng-container>
                </p-selectButton>`,
    encapsulation: ViewEncapsulation.None,
    providers:     [{ provide: NG_VALUE_ACCESSOR, useExisting: GcSelectButtonComponent, multi: true }],
    inputs:        ["options", "optionLabel", "optionValue", "optionDisabled", "multiple", "tabindex", "style", "styleClass", "ariaLabelledBy", "disabled", "dataKey"],
    outputs:       ["onOptionClick"]
})
@Wrapper(SelectButton)
export class GcSelectButtonComponent extends WrapperComponent {

    @ContentChildren(GcTemplate) templates: QueryList<GcTemplate>;
    @Input() allowEmpty: boolean = false;
    @Output() onChange: EventEmitter<SelectButtonChangeEvent> = new EventEmitter<SelectButtonChangeEvent>();
}
export interface GcSelectButtonComponent extends SelectButton {}
