import { Pipe, PipeTransform } from '@angular/core';
import { formatBytes, Size } from 'src/util/formatting/byteSize';

/* 
 * Usage:
 * value | byteSize:Size
 * 
 * Example:
 * {{ value | byteSize:"KB"}}
 */
@Pipe({ name: 'byteSize' })

export class ByteSizePipe implements PipeTransform {
    transform(value: number, size?: Size): string {
        return formatBytes(value, size);
    }
}
