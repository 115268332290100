import { Duration } from "@fullcalendar/core";
import { BaseError } from "./base-error";

export class ShiftExportRangeError extends BaseError {
    status = "SHIFT:EXPORT_RANGE";
    constructor(maxRange?: Duration){
        super();
        this.args = {
            maxRange
        };
    }
}
