console.warn('DEPRECATED(Enum2StringPipe): Use Globals service instead of Enum2StringPipe.');
import { Pipe, PipeTransform } from '@angular/core';
import { formatEnum } from 'src/util/formatting/enum';
/* 
 * Usage:
 * value | enum2string:Enum
 * 
 * Example:
 * {{ contactEnum | enum2string:ContactType }}
 */

/**
 * @deprecated - Passing enums into templates is bad practice. Use Globals service instead.
 */

@Pipe({ name: 'enum2string' })

export class Enum2StringPipe implements PipeTransform {
    transform(value: number, enumType: unknown): string {
        console.warn('DEPRECATED: passing enums into templates is bad practice.');
        return formatEnum(value, enumType);
    }
}
