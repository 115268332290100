import { Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation, forwardRef } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { SearchBaseComponent, SearchField, SelectMode } from "../component/gc-person-search.base.component";
import { ISearchQueryEmployee, Pagination, SearchQueryResult, VisibilityName, WorkHoursType } from "@vierkant-software/types__api";
import { GcPersonSearchComponent } from "../component/gc-person-search.component";

/**
 * Person and user search
 * 
 * @name    Person Search
 * @tags    User, Input, ControlValueAccessor
 * @remark  Is included in the gc-person-search-module
 * @returns string[] User IDs
 * @example Test
 * ```
 * %gc-person-search{[visibility]: "VisibilityName.person_search"}
 * ```
 */
@Component({
  selector:      'gc-person-search',
  templateUrl:   './gc-person-search-input.component.haml',
  styleUrls:     ['./../component/gc-person-search.component.sass'],
  encapsulation: ViewEncapsulation.Emulated,
  providers:     [
    {
      provide:     NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GcPersonSearchInputComponent),
      multi:       true
    }
  ],
})
export class GcPersonSearchInputComponent extends SearchBaseComponent {
  @Output() public selectionChange = new EventEmitter<string[]>();
  @Output() public selectedItems = new EventEmitter<SearchQueryResult[]>();

  /**
   * Available search fields
   */
  @Input() public fields?: SearchField[];
  /**
   * Active search fields
   */
  @Input() public activeFields?: SearchField[];
  /**
   * Locked search fields
   */
  @Input() public lockedFields?: SearchField[];
  /**
   * Filter by trusted work times
   * @values "yes" | "no" | "any"
   */
  @Input() public trustedWorkTimes?:  "yes" | "no" | "any";
  /**
   * Filter by workhoursType
   * @values WorkHoursType
   */
  @Input() public workhoursType?: WorkHoursType;
  /**
   * Allow department filter.
   * @remark  Pass department IDs as string array, to limit the available filter. 
   *          Otherwise all visible departments of the current users will be listed
   * @values  string[] | true
   */
  @Input() public departments?: string[] | true;
  /**
   * Filter by employment
   * @values  ISearchQueryEmployee
   * @example 
   * ```ts
   *  interface ISearchQueryEmployee {
   *    isEmployee: boolean;
   *    formerEmployees: boolean;
   *    activeEmployees: boolean;
   *    futureEmployees: boolean;
   *  }
   * ```
   */
  @Input() public employment?: Partial<ISearchQueryEmployee>;
  /**
   * Control loading state
   */
  @Input() public loading: boolean;
  /**
   * Limit max selectable amount.
   */
  @Input() public maxSelect?: number;
  /**
   * Require min selectable amount.
   */
  @Input() public minSelect?: number;
  /**
   * Pagination object
   * 
   * @example
   * ```ts
   *  const pagination = {
   *    page: number;
   *    size: number;
   *  }
   * ```
   */
  @Input() public pagination: Pagination;
  /**
   * Search term
   */
  @Input() public searchValue: string;
  /**
   * Control selection of one or multiple people.
   * 
   * @values  "single" | "multiple"
   * @default "single"
   */
  @Input() public selectionMode: SelectMode = "single";
  /**
   * Show avatar of people
   */
  @Input() public showAvatar: boolean;
  /**
   * Visibility of the search.
   * @values VisibilityName
   */
  @Input() public visibility?: VisibilityName;

  /**
   * Selected user IDs
   */
  @Input() public set value(value: string[]){
    super.value = value;
  }

  public get value(){
    return super.value;
  }

  public get searchResult() {
    return this.search.searchResult;
  }

  public get avatars() {
    return this.search.avatars;
  }

  @ViewChild("search", {static: true}) private search: GcPersonSearchComponent;
}
