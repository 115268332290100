/* eslint-disable max-len */
/* eslint-disable @angular-eslint/no-inputs-metadata-property */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/no-outputs-metadata-property */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { Component, ContentChildren, OnInit, QueryList, ViewEncapsulation } from "@angular/core";
import { OverlayPanel } from "primeng/overlaypanel";
import { WrapperBase } from "../base";
import { Wrapper } from "../decorators";
import { GcTemplate } from "../directives/gcTemplate";

// FIXME toggle is not a function

@Component({
    selector:      'gc-overlayPanel  ',
    encapsulation: ViewEncapsulation.None,
    template:      `<p-overlayPanel #component>
                    <ng-content></ng-content>
                    <ng-container  *ngFor="let template of templates">
                        <ng-template pTemplate="{{template.getType()}}">
                            <ng-container *ngTemplateOutlet="template.template"></ng-container>
                        </ng-template>
                    </ng-container>
                </p-overlayPanel>`,
    providers: [{ provide: OverlayPanel, useExisting: GcOverlayPanelComponent }],
    inputs:    ["dismissable", "showCloseIcon", "ariaCloseLabel", "style", "styleClass", "appendTo", "baseZIndex", "autoZIndex", "showTransitionOptions", "hideTransitionOptions", "focusOnShow"],
    outputs:   ["onShow", "onHide"]
})
@Wrapper(OverlayPanel)
export class GcOverlayPanelComponent extends WrapperBase implements OnInit {
    @ContentChildren(GcTemplate) templates: QueryList<GcTemplate>;
}
export interface GcOverlayPanelComponent extends OverlayPanel {}
