import { Injectable } from "@angular/core";
import { AppService } from "./app.service";
import { Subject } from "rxjs";
import { EventType, IEventTaskStatus, IShift, ScheduledTaskStatus, ScheduledTaskType, ShiftAIResult } from "@vierkant-software/types__api";
import { MessageService } from "primeng/api";
import { DraftService } from "src/app/modules/ngx-draft";

@Injectable({
  providedIn: 'root'
})
export class ShiftAIService {

    private data: Record<string, ShiftAIResult> = {};
    /**
     * Usually has the draftID as the subject, indicating that the service received a result for the given draft.
     * 
     * @note Uses `undefined` as subject to indicate an error. Subscribers should cleanup when receiving it.
     */
    public readonly draftResultSubject: Subject<string> = new Subject();

    constructor(
        private service: AppService,
        private messageService: MessageService,
        private draftService: DraftService,
    ) {
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        service.$events.subscribe(async (ev) => {
            if (ev?.data?.type === EventType.taskCompleted){
                if (ev?.data?.status?.type !== ScheduledTaskType.AI_Shift)
                    return;
                try {
                    await this.onResult(ev.data.status);
                } catch(error) {
                    console.error(error);
                }
            }
        });

    }

    async request(shifts: IShift[], draftID: string) {
        console.log("SHIFT_AI", `requesting for '${draftID}'`);
        await this.service.api.ShiftAIWorker.AI_optimizeShifts(shifts, draftID);
    }

    async onResult(status: IEventTaskStatus) {
        if (status.type !== ScheduledTaskType.AI_Shift) return;
        const task = await this.service.api.DataProcessingWorker.getShiftplanAIResult(status.taskID);
        if (status.status === ScheduledTaskStatus.failure) return this.handleFailure(task);

        this.clearWorkingToast();
        const draftID = task.result.draftID;
        console.log("SHIFT_AI", `received for '${draftID}'`);
        this.data[draftID] = task;
        this.messageService.add({
            id:       `AI_${task.result.draftID}`,
            severity: 'success',
            summary:  `Wir haben einen Vorschlag für ${task.params.start.toFormat("'KW'W")} erstellt`,
            detail:   'Achtung: manuelle Änderungen, die bereits vorgenommen wurden, werden ggf. überschrieben.',
            sticky:   true,
            data:     {
                "Anzeigen": () => this.draftResultSubject.next(draftID),
            }
        });
    }
    private clearWorkingToast() {
        this.messageService.clear("ai_shift_running");
    }

    private handleFailure(task: ShiftAIResult) {
        this.clearWorkingToast();
        this.draftResultSubject.next(undefined);
        const message = {
                id:       `AI_${task.result.draftID}`,
                severity: 'error',
        };

        if (['ECONNREFUSED', 'TIMEOUT'].includes(task.result.failureReason as string)) {
            this.messageService.add({
                ...message, summary: `Verbindung zur KI konnte nicht aufgebaut werden`
            });
        } else if (task.result.failureReason === 'NO_RESPONSE') {
            this.messageService.add({
                ...message, summary: `Keine Antwort der KI`, detail: `Anfrage konnte nicht bearbeitet werden`
            });
        } else {
            this.messageService.add({
                ...message, summary: `Es gab ein Problem beim Optimieren der Schichten`, detail: `Folgendes wurde gemeldet: '${task.result.failureReason}'`
            });
        }
    }

    get() {
        const draftID = this.draftService.draftId;
        if (!draftID) return undefined;
        const result = this.data[draftID];
        console.log("SHIFT_AI", `getting for '${draftID}'`, result);
        return result;
    }

    remove() {
        const draftID = this.draftService.draftId;
        if (!draftID) return undefined;
        console.log("SHIFT_AI", `deleting for '${draftID}'`);
        const result = this.data[draftID];
        delete this.data[draftID];
        // we don't need to await the result
        this.service.api.DataProcessingWorker.deleteShiftplanAIResultByDraft(draftID).catch(console.error);
        return result;
    }
}