/* eslint-disable max-len */
import { FAIcon } from "./icon";

export const NoTrashIcon: FAIcon = {
    prefix:   "gc",
    iconName: "no-trash",
    icon:     [
        16, 16,
        [],
        null,
        "M1.70709 15.7071L15.7071 1.70711C16.0976 1.31658 16.0976 0.683417 15.7071 0.292893C15.3166 -0.0976311 14.6834 -0.0976311 14.2929 0.292893L0.292878 14.2929C-0.0976463 14.6834 -0.0976463 15.3166 0.292878 15.7071C0.683402 16.0976 1.31657 16.0976 1.70709 15.7071ZM10.5714 1H12.9357L10.9165 3H2.85713C2.38302 3 1.99998 2.55312 1.99998 2C1.99998 1.44687 2.38302 1 2.85713 1H5.42856L5.62141 0.553125C5.76606 0.2125 6.06338 1.49012e-08 6.38749 1.49012e-08H9.61248C9.93659 1.49012e-08 10.2339 0.2125 10.3786 0.553125L10.5714 1ZM2.85713 4H9.90689L7.85974 6.02766C7.75862 6.06883 7.67445 6.15316 7.62366 6.26149L5.85713 8.0112V6.5C5.85713 6.225 5.66427 6 5.42856 6C5.19284 6 4.99998 6.225 4.99998 6.5V8.86018L2.85713 10.9826V4ZM7.57141 13.5V10.5357L5.85713 12.2337V13.5C5.85713 13.775 5.66427 14 5.42856 14C5.19284 14 4.99998 13.775 4.99998 13.5V13.0827L3.07958 14.9848C3.37444 15.5906 3.93258 16 4.57141 16H11.4286C12.3741 16 13.1428 15.1031 13.1428 14V5.01736L11 7.1398V13.5C11 13.775 10.8071 14 10.5714 14C10.3357 14 10.1428 13.775 10.1428 13.5V7.98878L8.42856 9.68674V13.5C8.42856 13.775 8.2357 14 7.99998 14C7.76427 14 7.57141 13.775 7.57141 13.5Z"
    ]
};
