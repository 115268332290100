/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/no-outputs-metadata-property */
/* eslint-disable @angular-eslint/no-inputs-metadata-property */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { Component, ViewEncapsulation } from "@angular/core";
import { Accordion, AccordionTab } from "primeng/accordion";
import { WrapperBase } from "../base";
import { Wrapper } from "../decorators";

//-------------
// AccordionTab

@Component({
    selector:      'gc-accordionTab',
    encapsulation: ViewEncapsulation.None,
    template:      `<p-accordionTab #component><ng-content></ng-content></p-accordionTab>`,
    providers:     [{ provide: AccordionTab, useExisting: GcAccordionTabComponent }],
    inputs:        ["header", "selected", "disabled", "transitionOptions", "cache"],
})
@Wrapper(AccordionTab)
export class GcAccordionTabComponent extends WrapperBase {
}
export interface GcAccordionTabComponent extends AccordionTab {}

//-------------
// Accordion

@Component({
    selector: 'gc-accordion',
    template: `<p-accordion #component><ng-content></ng-content></p-accordion>`,
    inputs:   ["multiple", "style", "styleClass", "activeIndex", "expandIcon", "collapseIcon"],
    outputs:  ["onClose", "onOpen"],
})
@Wrapper(Accordion)
export class GcAccordionComponent extends WrapperBase {
}
export interface GcAccordionComponent extends Accordion {}
