import { Routes } from "@angular/router";
import { DraftHostComponent } from "../lib/components/draft-host-component";
import { DraftDeactivateGuard } from "../lib/draft.deactivate-guard";
import { DraftResolver } from "../lib/draft.resolver";
import { DraftDefinition } from "../types/draft-definition";

export function getStepperRoutes<S, T extends DraftDefinition<S>>(stepper: new () => T): Routes {
    const definition: T = new stepper();
    const routes = [];
    for (const step of definition.steps) {
        routes.push({
            path:          ':draft/' + step.anchor,
            component:     DraftHostComponent,
            resolve:       { step: DraftResolver, ...step.config?.resolver },
            canDeactivate: [DraftDeactivateGuard],
            data:          {
                component: step.component,
                Stepper:   definition,
                anchor:    step.anchor,
                ...step.config?.routeData,
            }
        });
    }
    return routes;
}
