import { Component, ElementRef, Input, OnChanges, Output, ViewChild } from "@angular/core";
import { EventEmitter } from "@angular/core";
import QRCode from "easyqrcodejs";

/**
* @ignore - do not show in documentation
*/
@Component({
    selector: 'gc-qr',
    template: '<div #container></div>'
})
export class GcQrComponent implements OnChanges {
    @ViewChild('container', { static: true, read: ElementRef }) container: ElementRef;
    @Output() ready: EventEmitter<string> = new EventEmitter();
    @Input() value: string;
    @Input()
    set options(opt: { [key in keyof typeof this._options]?: (typeof this._options)[key] }) {
        this._options = Object.assign(this._options, opt);
    };
    get options() {
        return this._options;
    }

    _options = {
        width:                     256,
        height:                    256,
        colorDark:                 "#393939",
        colorLight:                "#57ddc0",
        correctLevel:              QRCode.CorrectLevel.H,
        quietZone:                 10,
        quietZoneColor:            '#57ddc0',
        dotScale:                  0.6,
        dotScaleTiming:            0.6,
        logo:                      '', //'/assets/img/zeitbox_app_icon.png',
        logoBackgroundTransparent: true,
        dotScaleA:                 0.6,
        dotScaleAO:                0.6,
        dotScaleAI:                0.6,
        drawer:                    'svg',
        logoWidth:                 70,
        logoHeight:                70,
    };

    logo: Promise<string> = fetch('/assets/img/zeitbox_app_icon.png').then(res => res.blob()).then(blob => new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            resolve(reader.result as string);
        };
        reader.readAsDataURL(blob);
    }));

    async ngOnChanges() {
        if (!this.value || !this.container) return;
        const element = document.createElement('div');
        const elementProxy = new Proxy(element, {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          get: (target: any, prop: any) => target[prop],
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          set: (target: any, prop: any, value: any) => {
            if (prop === 'innerHTML') {
              const val = value.replace(/width="([34][^"]*)" height="([34][^"]*)" shape-rendering="crispEdges"/g, 'width="$1" height="$2" rx="2" ry="2"');
              const svg = 'data:image/svg+xml;base64,' + btoa(val);
              this.ready.emit(svg);
              //console.log(svg);
              this.container.nativeElement.innerHTML = val;
            //   console.log(val);
            }
            return target[prop] = value;
          }
        });
        new QRCode(elementProxy, { ...this.options, text: this.value, logo: await this.logo });
    }
}
