import { Component,OnInit } from '@angular/core';

/**
* @ignore - do not show in documentation
*/
@Component({
    selector:    'gc-announcement',
    templateUrl: './gc-announcement.component.haml',
    styleUrls:   ['./gc-announcement.component.sass'],
})
export class GcAnnouncementComponent implements OnInit {

    public infoVisible = false;

    constructor(){
    }
    ngOnInit(): void {
    }
}
