// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.length{
            position: absolute;
            top: 1px;
            right: 0.5rem;
            font-size: 0.75rem;
            color: rgba(var(--gc-color-light-contrast-rgb), 0.5);
        }`, "",{"version":3,"sources":["webpack://./src/app/modules/ngx-components/lib/wrappers/components/input-text.ts"],"names":[],"mappings":"AAAA;YACY,kBAAkB;YAClB,QAAQ;YACR,aAAa;YACb,kBAAkB;YAClB,oDAAoD;QACxD","sourcesContent":[".length{\n            position: absolute;\n            top: 1px;\n            right: 0.5rem;\n            font-size: 0.75rem;\n            color: rgba(var(--gc-color-light-contrast-rgb), 0.5);\n        }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
