/* eslint-disable max-len */
import { FAIcon } from "./icon";

export const NoHouseIcon: FAIcon = {
    prefix:   "gc",
    iconName: "no-house",
    icon:     [
        29, 25,
        [],
        null,
        "M26.8531 3.11589C26.4968 2.69392 25.8659 2.64069 25.4439 2.99701L21.7472 6.11851L3.84547 21.2349L2.06481 22.7385C1.64284 23.0948 1.58961 23.7258 1.94593 24.1477C2.30224 24.5697 2.93317 24.6229 3.35514 24.2666L26.7342 4.5251C27.1562 4.16878 27.2094 3.53786 26.8531 3.11589ZM15.4358 0.523453L20.9911 5.44819L3.85099 19.9214L3.86319 17.0218C3.86337 16.9796 3.86357 16.9327 3.86843 16.8906L3.88216 13.6281L2.37748 13.6218C1.53374 13.6182 0.880256 12.9592 0.883825 12.1108C0.8856 11.6889 1.0278 11.3145 1.3573 10.9878L13.42 0.561848C13.7495 0.235106 14.1247 0.189809 14.4528 0.191189C14.781 0.19257 15.1556 0.287897 15.4358 0.523453ZM11.3557 18.8197L5.14318 24.0656C5.32134 24.1226 5.51114 24.1538 5.7082 24.1546L6.45819 24.1578C6.51443 24.158 6.57069 24.1536 6.62694 24.1491L6.62698 24.1491C6.69727 24.1541 6.76757 24.1591 6.83788 24.1594L8.33318 24.1657L9.45817 24.1704C10.4941 24.1747 11.3367 23.3392 11.341 22.3033L11.3458 21.1783L11.3557 18.8197ZM13.8791 16.6889L24.0157 8.12948L27.3633 11.0972C27.7369 11.4269 27.9229 11.8026 27.8742 12.2243C27.8707 13.0681 27.1648 13.726 26.3679 13.7227L24.8679 13.7164L24.8691 21.2258C24.8686 21.3524 24.8587 21.4789 24.8441 21.6054L24.8409 22.3601C24.8366 23.396 23.994 24.2315 22.9581 24.2272L22.2081 24.224C22.1565 24.2238 22.1049 24.2236 22.0534 24.2187C21.9878 24.2231 21.9221 24.2228 21.8565 24.2225L21.8565 24.2225L20.3331 24.2161L19.2081 24.2114C18.1722 24.207 17.3366 23.3645 17.341 22.3285L17.3457 21.2035L17.3583 18.2036C17.3618 17.3739 16.6943 16.7008 15.8647 16.6973L13.8791 16.6889Z"
    ]
};
