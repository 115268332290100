import { isSet } from "src/util/string";

const GAP_NONE = "0rem";
const GAP_TEXT = "0.25rem";
const GAP_SMALL = "0.5rem";
const GAP_DEFAULT = "1rem";
const GAP_LARGE = "2rem";

export type Gap = "text" | "default" | "large" | "small" | "none";

export function getGap(gap?: Gap | string) {
    if (!isSet(gap)) return undefined;
    if (gap === "default") return GAP_DEFAULT;
    if (gap === "text") return GAP_TEXT;
    if (gap === "large") return GAP_LARGE;
    if (gap === "small") return GAP_SMALL;
    if (gap === "none") return GAP_NONE;
    return gap;
}
