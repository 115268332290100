/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/no-outputs-metadata-property */
/* eslint-disable max-len */
/* eslint-disable @angular-eslint/no-inputs-metadata-property */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { Component, EventEmitter, Output, ViewEncapsulation } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { Checkbox, CheckboxChangeEvent } from "primeng/checkbox";
import { WrapperComponent } from "../base";
import { Wrapper } from "../decorators";

@Component({
    selector:      'gc-checkbox',
    encapsulation: ViewEncapsulation.None,
    template:      `<p-checkbox [formControl]="gcControl"><ng-content></ng-content></p-checkbox>`,
    providers:     [{ provide: NG_VALUE_ACCESSOR, useExisting: GcCheckboxComponent, multi: true }],
    inputs:        [ "name", "value", "label", "disabled", "binary", "tabindex", "inputId", "ariaLabelledBy", "ariaLabel", "style", "styleClass", "labelStyleClass", "checkboxIcon",
        "readonly", "required", "trueValue", "falseValue" ],
})
@Wrapper(Checkbox)
export class GcCheckboxComponent extends WrapperComponent {
    @Output() onChange: EventEmitter<CheckboxChangeEvent> = new EventEmitter<CheckboxChangeEvent>();
}
export interface GcCheckboxComponent extends Checkbox {}
