// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-width: 2px;
  border-style: solid;
  width: 100%;
  padding: 25px;
  min-height: 167px;
  border-color: var(--gc-border-color);
  background-color: var(--ion-color-light-tint);
  transition: border-color 0.2s;
}
:host:hover, :host.drag {
  border-color: var(--gc-color-primary-tint);
}
:host .generic-file-select-input {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
}
:host .generic-file-select-preview-image {
  width: 100%;
  object-fit: contain;
  object-position: center;
}
:host .generic-file-select-label {
  font-weight: bold;
  color: var(--gc-color-primary-tint);
  transition: color 0.2s;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/product-management/components/proleos-file-upload/proleos-file-upload.component.sass"],"names":[],"mappings":"AAAC;EACC,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,iBAAA;EACA,mBAAA;EACA,WAAA;EACA,aAAA;EACA,iBAAA;EACA,oCAAA;EACA,6CAAA;EACA,6BAAA;AACF;AACE;EACE,0CAAA;AACJ;AACE;EACE,kBAAA;EACA,UAAA;EACA,WAAA;EACA,YAAA;EACA,MAAA;EACA,OAAA;EACA,eAAA;AACJ;AACE;EACE,WAAA;EACA,mBAAA;EACA,uBAAA;AACJ;AACE;EACE,iBAAA;EACA,mCAAA;EACA,sBAAA;AACJ","sourcesContent":["\\:host\n  position: relative\n  display: flex\n  flex-direction: column\n  justify-content: center\n  align-items: center\n  border-width: 2px\n  border-style: solid\n  width: 100%\n  padding: 25px\n  min-height: 167px\n  border-color: var(--gc-border-color)\n  background-color: var(--ion-color-light-tint)\n  transition: border-color 0.2s\n\n  &:hover, &.drag\n    border-color: var(--gc-color-primary-tint)\n\n  .generic-file-select-input\n    position: absolute\n    opacity: 0\n    width: 100%\n    height: 100%\n    top: 0\n    left: 0\n    cursor: pointer\n\n  .generic-file-select-preview-image\n    width: 100%\n    object-fit: contain\n    object-position: center\n\n  .generic-file-select-label\n    font-weight: bold\n    color: var(--gc-color-primary-tint)\n    transition: color 0.2s\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
