/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/no-outputs-metadata-property */
/* eslint-disable @angular-eslint/no-inputs-metadata-property */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { Component, Input, ViewEncapsulation } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { InputTextarea } from "primeng/inputtextarea";
import { WrapperComponent } from "../base";
import { Wrapper } from "../decorators";

@Component({
    selector:      'gc-input-textarea',
    styles:        ['textarea {width: 100%}'],
    encapsulation: ViewEncapsulation.None,
    template:      `<label *ngIf="label !== undefined" [for]="__internal_ID">{{ label }}</label>
                    <textarea pInputTextarea [formControl]="gcControl" [id]="__internal_ID"><ng-content></ng-content></textarea>`,
    providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: GcInputTextareaComponent, multi: true }],
    inputs:    ["autoResize", "disabled"],
    outputs:   ["onResize"]
})
@Wrapper(InputTextarea)
export class GcInputTextareaComponent extends WrapperComponent {
    @Input() label: string;
}
export interface GcInputTextareaComponent extends InputTextarea {};
