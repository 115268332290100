/* eslint-disable @typescript-eslint/space-before-function-paren */
import { EventEmitter } from "@angular/core";
import { WrappedComponentCtor, WrapperBase } from "./base";
import { FILTERED_METHODS, FILTERED_PROPS } from "./definitions";

export function Wrapper<S>(component: new(...args: unknown[]) => S, rules?: {
    excludeMethods?: string[];
    includeMethods?: string[];
    excludeProp?: string[];
    includeProp?: string[];
}) {
    return <T extends WrapperBase>(ctr: new(...args: unknown[]) => T): { new (...any: unknown[]): T & S } => {
        const constructor: WrappedComponentCtor = ctr;
        constructor.rules = rules;
        constructor.wrappedComponent = component;
        if (constructor.propDecorators === undefined)
            constructor.propDecorators = {};
        Object.entries((<WrappedComponentCtor>component).propDecorators)
            .filter(([key]) => !FILTERED_PROPS.includes(key))
            .filter(([key]) => !rules?.excludeProp?.includes(key))
            .filter(([key]) => rules?.includeProp?.includes(key) ?? true)
            .forEach(([key, props]) => {
                props.forEach((prop) => {
                    if (prop.type?.prototype?.ngMetadataName === 'Input') {
                        if (!constructor.propDecorators[key])
                            constructor.propDecorators[key] = [];
                        constructor.propDecorators[key].push(prop);
                    }
                    if (prop.type?.prototype.ngMetadataName === 'Output') {
                        if (!constructor.propDecorators[key])
                            constructor.propDecorators[key] = [];
                        constructor.propDecorators[key].push(prop);
                        constructor.prototype[key] = new EventEmitter();
                    }
                });
            });
        Object.getOwnPropertyNames(component.prototype)
            .filter((key) => !FILTERED_METHODS.includes(key))
            .filter((key) => !rules?.excludeMethods?.includes(key))
            .filter((key) => rules?.includeMethods?.includes(key) ?? true)
            .filter((key) => !Object.getOwnPropertyDescriptor(component.prototype, key).get)
            .forEach((prop) => {
                const value = component.prototype[prop];
                if (typeof value === 'function') {
                    constructor.prototype[prop] = function(...args: unknown[]) {
                        if (!this.component)
                            throw new Error('@Wrapper could not locate target component, are you missing #component or FormControlDirective?');
                        return component.prototype[prop]?.apply(this.component, args);
                    };
                }
            });
        return <{ new (...any: unknown[]): T & S }>ctr;
    };
}
