import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { DepartmentType, EntityParam, IDepartmentDTO, IUser, WithFiles } from "@vierkant-software/types__api";
import { Subscription } from "rxjs";
import { SearchBaseComponent } from "src/app/components/gc-person-search/component/gc-person-search.base.component";
import { FC_G } from "src/app/modules/ngx-draft";
import { toDict } from "src/util/array";
import { sortBy } from "src/util/sort";
import { Option } from "src/util/types/global";

/**
 * TODO: things to add later (soon) but aren't required to launch
 * - limit selectable EntityType
 */
@Component({
    selector:    "v-workflow-entity-selection",
    styleUrls:   ["./workflow-entity-selection.component.sass"],
    templateUrl: "./workflow-entity-selection.component.haml",
})

export class WorkflowEntitySelectionComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[];

    @Output() public close: EventEmitter<Result | undefined> = new EventEmitter<Result>();
    @ViewChild("search") public searchComponent: SearchBaseComponent;
    public departmentType: DepartmentType;
    public departmentData: Record<string, IDepartmentDTO>;
    public departmentTypes: Option<DepartmentType>[] = [
        {label: "Ganze Abt.", value: "all"},
        {label: "Mitglieder", value: "member"},
        {label: "Leitung", value: "leader"}
    ];
    public departmentOptions: Option<string>[];
    public form = new FormGroup({
        type:           new FormControl("person"),
        departmentType: new FormControl(),
        department:     new FormGroup({
            all:    new FormControl([]),
            member: new FormControl([]),
            leader: new FormControl([]),
        }),
        person: new FormControl([]),
    }) as FC_G<FormType>;
    public users: WithFiles<IUser[]> = {
        data:    [],
        __files: [],
    };

    @Input() public set departments(value: IDepartmentDTO[]) {
        this.departmentData = toDict(value, x => x.departmentID);
        this.departmentOptions = sortBy(value.map(x => ({label: x.name, value: x.departmentID})), x => x.label);
    }

    public cancel(_event: Event) {
        this.close.emit(undefined);
        this.onClose();
    }

    public confirm(_event: Event) {
        const formValue = this.form.value;
        const type = formValue.type;
        const value = formValue[type];
        const result = {
            type,
            [type]: ["superior", "origin"].includes(type) ? true : value
        } as Result;
        this.close.emit(result);
        this.onClose();
    }

    public async fetchUsers(ids: string[]) {
        if (!ids) return;
        const existingIDs = this.users.data.map(x => x.ID);
        const newIDs = ids.filter(id => !existingIDs.includes(id));
        const newImages: string[] = [];
            const newUsers = newIDs.length > 0 ? this.searchComponent.searchResult.filter((x, i) => {
                if (!newIDs.includes(x.ID)) return false;
                newImages.push(this.searchComponent.avatars[i]);
                return true;
            }) : [];

            const images: string[] = [];
            const users = this.users.data.filter((x, i) => {
                if (!ids?.includes(x.ID)) return false;
                images.push(this.users.__files[i]);
                return true;
            });
            this.users = {
                data:    [...users, ...newUsers],
                __files: [...images, ...newImages]
            };
    }

    public isSelectable(type: EntityTypes) {
        return ["person", "department"].includes(type);
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(x => x?.unsubscribe());
    }

    public ngOnInit(): void {
        this.subscriptions = [
            this.form.get("type").valueChanges.subscribe(x => {
                this.form.reset({type: x, department: {all: [], member: [], leader: []}, person: []}, {emitEvent: false});
            }),
            this.form.get("person").valueChanges.subscribe(x => {
                this.fetchUsers(x).catch(console.error);
            })
        ];
    }

    private onClose() {
        this.form.reset({type: "person"});
    }
}


type KeysOfUnion<T> = T extends T ? keyof T : never;
export type EntityTypes = KeysOfUnion<EntityParam>;

interface FormType {
    departmentType: DepartmentType;
    department: Record<DepartmentType, string[]>;
    origin: boolean;
    person: string[];
    superior: boolean;
    type: EntityTypes;
}

export type Result =
    {type: "person",     person:     string[]} |
    {type: "department", department: string[]} |
    {type: "superior"} |
    {type: "origin"};