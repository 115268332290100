// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.side-nav {
  background: #fff;
}

.separating-line {
  border-bottom: 1px solid #C4C4C4;
}`, "",{"version":3,"sources":["webpack://./src/app/components/gc-studio-switch-sidebar/gc-studio-switch-sidebar.component.sass"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;;AAWA;EACI,gCAAA;AARJ","sourcesContent":[".side-nav\n    background: #fff\n    //TODO remove ng-deep when sidebar is needed\n    // ::ng-deep\n    //     .p-listbox .p-listbox-list .p-listbox-item.p-highlight\n    //         background: none\n    //         color: #000\n    //     .p-sidebar-header\n    //         display: none\n    //     .p-sidebar .p-sidebar-content\n    //         padding: 0.7rem\n    //         padding-top: 0        \n\n.separating-line\n    border-bottom: 1px solid #C4C4C4"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
