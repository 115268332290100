import { FormGroup } from "@angular/forms";
import { ResolveData, Router, UrlTree } from "@angular/router";
import { IDraft, RPC } from "@vierkant-software/types__api";
import { DraftService } from "../lib/draft.service";
import { DraftDefinition } from "./draft-definition";
import { ExitState } from "./exit-state";
import { IAppService } from "./IAppService";
import { PageButtonTypes } from "./pagebuttons";
import { StepStatusInfo } from "./stepstatus";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type stepComp = any; /*Partial<OnDeactivate & OnResolve & FormChanged & FormChangedImmed> & //TODO: check if IPage is still needed
    {ɵAnchor?: string, ɵinjector?: Injector, ɵDraftButtons?: PageButtons, ngAfterViewChecked?(init: boolean): void };*/

export class StepDefinition<T> {
  constructor(
    public label: string,
    public component: { new(...args: unknown[]): stepComp },
    public anchor: string,
    public config?: {
      /** step is disabled in the stepper-bar */
      disabled?: (obj: StepStatusInfo, self: StepDefinition<T>, stepper: DraftDefinition<T>) => boolean | undefined;
      /** step should be hidden from the stepper bar, it will also not be navigated to */
      hidden?: (obj: StepStatusInfo, self: StepDefinition<T>, stepper: DraftDefinition<T>) => boolean | undefined;
      /** override the steps valid status independend of its form validation status. */
      valid?: (obj: StepStatusInfo, self: StepDefinition<T>, stepper: DraftDefinition<T>) => boolean | undefined;
      /** list of resolver classes to add to the routes */
      resolver?: { [param: string]: ResolveData };
      /** additional route data */
      routeData?: { [key: string]: unknown }; // TODO @Jens define the possible keys
      /** options to pass to step during init phase */
      options?: { [key: string]: unknown };
      /** allow create of a new object at this step. closure return values will be patched into form. */
      create?: true | ((appService?: IAppService, createParams?: T) => Partial<T> | Promise<Partial<T>>);
      /** if false disallow fetch at this step. Default: true */
      canLoad?: boolean;
      /** will be called on return from a chained draft, passing the ExitStatus of the subDraft */
      returnFromChild?: (data: boolean | ExitState, form: FormGroup, self: StepDefinition<T>, stepper: DraftDefinition<T>,
        app: IAppService, draft: DraftService) => void | Promise<void>;
      /** will be called on navigation between steps before onResolve is called. NOTE: Will NOT be called when createing a new draft at this step. */
      onEnter?: (form: FormGroup, draft: IDraft, appService: IAppService, self: StepDefinition<T>, stepper: DraftDefinition<T>) => boolean | Promise<boolean>;
      /** slim resolver functions */
      resolveData?: { [param: string]: (this: RPC, options: { form: FormGroup; draft: IDraft;
        appService: IAppService; self: StepDefinition<T>; stepper: DraftDefinition<T>; draftService: DraftService; }) => unknown; };
      /** show submit button at this step */
      showSubmitButton?: boolean | ((options: { form: FormGroup, self: StepDefinition<T>, stepper: DraftDefinition<T>}) => boolean);
      /** if set a delete button will be shown. OnClick this method is called for cleanup. 
       * if it return true or an UrlTree success is assumed and the draft is destroyed.
       * if an UrlTree was provided, it will be the target of the navigation instead of /
       */
      delete?: (options: {
        router:       Router;
        form:         FormGroup;
        draft:        IDraft;
        appService:   IAppService;
        self:         StepDefinition<T>;
        stepper:      DraftDefinition<T>;
        draftService: DraftService;
        event?:       Event;
      }) => Promise<boolean | UrlTree>;
      /**
       * Override the default button labels.
       */
      buttonLabels?: { [button in PageButtonTypes]?: string } |
        ((options: {
          router:       Router;
          form:         FormGroup;
          draft:        IDraft;
          appService:   IAppService;
          self:         StepDefinition<T>;
          stepper:      DraftDefinition<T>;
          draftService: DraftService;
      }) => { [button in PageButtonTypes]?: string});
      /** default true */
      showStepperBar?: boolean | ((options: { form: FormGroup, self: StepDefinition<T>, stepper: DraftDefinition<T>}) => boolean);
    }
  ) { }
}
