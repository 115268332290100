import { ErrorHandler, Injectable, NgZone } from "@angular/core";
import { Router } from "@angular/router";
import { InternalAppService } from "./app.service";
import { DebugService } from "./debug.service";

@Injectable()
export class GenericErrorHandler implements ErrorHandler {

    constructor(
      private ngZone: NgZone,
      private router: Router,
      private app: InternalAppService,
      private debugServ: DebugService,
    ) {}

    #lastURL?: string;

    handleError(error: Error): void {

      // don't trigger on secondary errors
      if (this.router.url === '/404') return;

      const report = {
        type:   typeof error === 'object' ? error.constructor.name : typeof error,
        url:    this.router.url,
        target: this.app.__Studio,
        // error:  JSON.stringify(error),
      };
      this.debugServ.logClientError(error, report);

      // developer errors - display and ignore
      if (report.type === 'RuntimeError' && ['NG0100'].includes(error.message.split(':')[0])) {
        console.warn(error);
        return;
      }

      console.error('GenericErrorHandler:', error);
      console.log('GEH:', report);

      // TODO send stuff to Logging Sink

      if (report.type === 'HttpErrorResponse') return;

      // FIXME find a better solution
      // don't redirect twice on an error on same page
      if (report.url === this.#lastURL && report.type === 'TypeError')
        return;
      else
        this.#lastURL = report.url;

      // this.ngZone.run(() => {
      //  this.router.navigateByUrl('/404', { skipLocationChange: true }).catch(e => console.error('navigation to error page failed:', e)); // TODO replace page
      // });
    }
}
