// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `li {
  line-height: 1.5rem;
}

.label-adittion {
  color: rgba(var(--gc-text-color-rgb), 0.7);
  font-size: 14px;
}

gc-icon.info {
  color: var(--gc-color-warning);
}`, "",{"version":3,"sources":["webpack://./src/app/components/gc-li/gc-li.component.sass"],"names":[],"mappings":"AAAA;EACI,mBAAA;AACJ;;AACA;EACI,0CAAA;EACA,eAAA;AAEJ;;AACI;EACI,8BAAA;AAER","sourcesContent":["li\n    line-height: 1.5rem\n\n.label-adittion\n    color: rgba(var(--gc-text-color-rgb), 0.7)\n    font-size: 14px\n\ngc-icon\n    &.info\n        color: var(--gc-color-warning)"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
