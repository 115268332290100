import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanDeactivate, Params, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { DraftService } from "./draft.service";

@Injectable({
    providedIn: 'root',
})
export class DraftDeactivateGuard implements CanDeactivate<unknown> {

    constructor(private draftService: DraftService) { }

    canDeactivate(
        component: { canDeactivate?: boolean },
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const id = this.draftService.draftId;
        if (id) {
            console.log(nextState);
            const walk = (current: ActivatedRouteSnapshot): ActivatedRouteSnapshot | Params =>
                [ current.params, ...current.children.map(e => walk(e))].reduce((l, c) => Object.assign(l, c), {});
            const params: Params = walk(nextState.root);
            console.log('params:', params);
            if (!params.draft || params.draft !== id)
                console.log('### navigating out of draft scope ###');
        }
        return component.canDeactivate ?? true; // TODO flag for leave scope?
    }
}
