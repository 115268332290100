// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gc-info-container {
  background: var(--gc-info-background, var(--gc-color-light-tint));
  border: solid var(--gc-info-border, var(--gc-border-color));
  border-width: 1px 1px 1px 6px;
  display: grid;
  grid-template-columns: [severity-start] auto [severity-end content-start] 1fr [content-end close-start] auto [close-end];
  padding: 0.75rem 1rem;
}

.severity {
  grid-column: severity;
  margin-right: 0.5rem;
}

.content {
  grid-column: content;
}

.close-button {
  margin-left: 0.5rem;
  grid-column: close;
}

gc-icon.severity {
  color: var(--gc-info-border, --gc-text-color);
}

:host.error, :host.danger {
  --gc-info-background: var(--gc-error-background);
  --gc-info-border: var(--gc-error-border);
}
:host.warn {
  --gc-info-background: rgba(var(--gc-color-warning-rgb), 0.2);
  --gc-info-border: var(--gc-color-warning-shade);
}
:host.success {
  --gc-info-background: rgba(var(--gc-color-success-rgb), 0.2);
  --gc-info-border: var(--gc-color-success-tint);
}
:host.info {
  --gc-info-background: rgba(var(--gc-color-info-rgb), 0.2);
  --gc-info-border: var(--gc-color-info-shade);
}

:host:empty {
  display: none;
  visibility: hidden;
}`, "",{"version":3,"sources":["webpack://./src/app/components/gc-info/gc-info.component.sass"],"names":[],"mappings":"AAAA;EACI,iEAAA;EACA,2DAAA;EACA,6BAAA;EAEA,aAAA;EACA,wHAAA;EACA,qBAAA;AAAJ;;AAEA;EACI,qBAAA;EACA,oBAAA;AACJ;;AAAA;EACI,oBAAA;AAGJ;;AAFA;EACI,mBAAA;EACA,kBAAA;AAKJ;;AAHA;EACI,6CAAA;AAMJ;;AAHI;EACI,gDAAA;EACA,wCAAA;AAMR;AALI;EACI,4DAAA;EACA,+CAAA;AAOR;AANI;EACI,4DAAA;EACA,8CAAA;AAQR;AAPI;EACI,yDAAA;EACA,4CAAA;AASR;;AAPA;EACI,aAAA;EACA,kBAAA;AAUJ","sourcesContent":[".gc-info-container\n    background: var(--gc-info-background, var(--gc-color-light-tint))\n    border: solid var(--gc-info-border, var(--gc-border-color))\n    border-width: 1px 1px 1px 6px\n    \n    display: grid\n    grid-template-columns: [severity-start] auto [severity-end content-start] 1fr [content-end close-start] auto [close-end]\n    padding: 0.75rem 1rem\n\n.severity\n    grid-column: severity\n    margin-right: 0.5rem\n.content\n    grid-column: content\n.close-button\n    margin-left: 0.5rem\n    grid-column: close\n\ngc-icon.severity\n    color: var(--gc-info-border, --gc-text-color)\n    \n:host \n    &.error, &.danger\n        --gc-info-background: var(--gc-error-background)\n        --gc-info-border: var(--gc-error-border)\n    &.warn\n        --gc-info-background: rgba(var(--gc-color-warning-rgb), 0.2)\n        --gc-info-border: var(--gc-color-warning-shade)\n    &.success\n        --gc-info-background: rgba(var(--gc-color-success-rgb), 0.2)\n        --gc-info-border: var(--gc-color-success-tint)\n    &.info\n        --gc-info-background: rgba(var(--gc-color-info-rgb), 0.2)\n        --gc-info-border: var(--gc-color-info-shade)\n\n:host:empty\n    display: none\n    visibility: hidden"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
