import { Component } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { DraftForm, DraftService } from "src/app/modules/ngx-draft";
import { IProjectInfo, ProjectStatus } from "@vierkant-software/types__api";
import { Globals } from "src/app/services/globals.service";
import { ProjectDuplicateSidebarComponent } from "src/app/sidebar/components/project-duplicate/project-duplicate.sidebar.component";
import { SidebarComponent } from "src/app/sidebar/siderbar.component";
import { AppService } from "src/services/app.service";
import { Option } from "src/util/types/global";

@Component({
    templateUrl: './general.step.haml',
    styleUrls:   ['./general.step.sass'],
})
export class GeneralStep {
    @DraftForm() form: FormGroup;

    statusOptions: Option<ProjectStatus>[] = [
        {
            label: 'Aktiv',
            value: ProjectStatus.active
        },{
            label: 'Abgeschlossen',
            value: ProjectStatus.completed
        },{
            label: 'Inaktiv',
            value: ProjectStatus.inactive
        },{
            label: 'Pausiert',
            value: ProjectStatus.pause
        },{
            label: 'In Planung',
            value: ProjectStatus.planning
        },{
            label:    'Automatisch beendet',
            value:    ProjectStatus.completedAutomatically,
            disabled: true
        }
    ];

    displayCompare: boolean;
    projectA: Partial<IProjectInfo>;
    projectB: Partial<IProjectInfo>;

    get data(){
        return this.form.get('data') as FormGroup;
    }
    duplicateSidebarComponent: ProjectDuplicateSidebarComponent;

    constructor(
        private appService: AppService,
        private rightSideBar: SidebarComponent,
        public globals: Globals,
        private draftService: DraftService
        ){
        this.duplicateSidebarComponent = undefined;
    }

    async onFormChanged(data: { data: IProjectInfo }) {
        if (data.data.title === '')
            return;
        try {
            const result = await this.appService.api.ProjectWorker.findDuplicates(data.data.title, data.data.ID);
            if (result?.length > 0){
                if (!this.duplicateSidebarComponent)
					this.duplicateSidebarComponent = this.rightSideBar.addItem(ProjectDuplicateSidebarComponent, {projects: result});
                this.rightSideBar.visible = true;
            }
        } catch(e) {
			console.error('Error: findDuplicates failed', e);
		}
    }

    compareDuplicate(duplicate: Partial<IProjectInfo>){
        console.log('compareDuplicate called', duplicate, this.data.value);
        this.projectA = this.data.value;
        this.projectB = duplicate;
        this.displayCompare = true;
    }
    async setFormToDuplicate(event: Partial<IProjectInfo>) {
        this.rightSideBar.clear();
        this.rightSideBar.visible = false;
		this.draftService.recordId = event.ID;
		this.form.patchValue({
            data: this.projectB
        });
	}
}

