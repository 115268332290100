import { Component, HostBinding, Input } from "@angular/core";
import { Gap, getGap } from "../util";

/**
 * Structure elements in a row like structure.
 * This uses `display: flex` to layout the elements inside the container.
 * 
 * 
 * @name Row
 * @tags Layout, Structure
 * @remark A distinction from classic flex is that elements are systematically spaced.
 */
@Component({
    selector:  'gc-row',
    template:  `<ng-content></ng-content>`,
    styleUrls: ['row.component.sass']
})
export class GcRowContainerComponent {

    /**
     * Defines the gap between each element
     * 
     * @default "default"
     * @values  "text" | "default" | "large" | "small" | "none"
     * @remark  While possible to also provide regular css units, those are largely discouraged to enforce an uniform look.
     */
    @Input() gap: Gap | string = "default";
    /**
     * Allow element wrapping, when elements reach the end of the container
     * 
     * @default true
     */
    @HostBinding("class.flex-wrap") @Input() wrap = true;
    @HostBinding("style.--layout-row-gap") get gapVar() {
        return getGap(this.gap);
    };
}
