// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  line-height: initial;
  width: 2rem;
  aspect-ratio: 1;
}

.avatar {
  display: inline-flex;
  background: hsl(var(--color), var(--lightness));
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  border-radius: 50%;
  overflow: hidden;
  font-size: 1rem;
  position: relative;
}
.avatar .color-overlay {
  background: hsl(var(--color), var(--lightness));
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  mix-blend-mode: screen;
  -webkit-user-select: none;
          user-select: none;
  pointer-events: none;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.15);
}`, "",{"version":3,"sources":["webpack://./src/app/components/gc-avatar/gc-avatar.component.sass"],"names":[],"mappings":"AAAA;EACI,oBAAA;EACA,WAAA;EACA,eAAA;AACJ;;AAAA;EACI,oBAAA;EACA,+CAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,kBAAA;AAGJ;AAFI;EACI,+CAAA;EACA,WAAA;EACA,YAAA;EACA,cAAA;EACA,kBAAA;EACA,sBAAA;EACA,yBAAA;UAAA,iBAAA;EACA,oBAAA;EACA,kBAAA;EACA,qCAAA;AAIR","sourcesContent":[":host\n    line-height: initial\n    width: 2rem\n    aspect-ratio: 1\n.avatar\n    display: inline-flex\n    background: hsl(var(--color), var(--lightness))\n    align-items: center\n    justify-content: center\n    font-size: 1rem\n    border-radius: 50%\n    overflow: hidden\n    font-size: 1rem\n    position: relative\n    .color-overlay\n        background: hsl(var(--color), var(--lightness))\n        width: 100%\n        height: 100%\n        display: block\n        position: absolute\n        mix-blend-mode: screen\n        user-select: none\n        pointer-events: none\n        border-radius: 50%\n        border: 1px solid rgba(0, 0, 0, 15%)"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
