import { Component, ContentChild, HostBinding, Input, TemplateRef, ViewEncapsulation} from '@angular/core';
import { defaultToggleAnimation } from 'src/theme/animation';

/**
 * Component used in gc-overview. It's a modified design from the PrimeNG element p-panel
 */
@Component({
    selector:      'gc-overview-panel',
    templateUrl:   './gc-overview-panel.component.haml',
    styleUrls:     ['./gc-overview-panel.component.sass'],
    animations:    [defaultToggleAnimation],
    encapsulation: ViewEncapsulation.None,
})
export class GCOverviewPanelComponent{

    /**
     * Used for toggling the icon "chevron" up & down for the panel.
     * @default true
     */
    isCollapsed: boolean = true;
    /**
     * It's the bolded title on the panel. 
     */
    @Input() public header: unknown;
    /**
     * The content inside the panel.
     */
    @Input() public value: unknown;
    /**
     * Pass css inline styles.
     */
    @Input() public style: Record<string, unknown>;
    /**
     * Pass css style classes.
     */
    @Input() public styleClass: string;
    /**
     * @values 'icon' | 'header'
     * @default "header"
     */
    @Input() public toggler: "icon" | "header" = "header";
    /**
     * The content in the panel is hidden if there's no data to be shown.
     * @default false
     */
    @Input() public hideContent = false;
    /**
     * Adds a padding.
     * @default true
     */
    @Input() @HostBinding("class.pad-content") public padContent = true;


    @ContentChild('header', { static: true}) public headerTemplateRef: TemplateRef<unknown>;
    @ContentChild('content', { static: true}) public contentTemplateRef: TemplateRef<unknown>;

    public toggle(_event: Event, toggler: "icon" | "header"){
        if (toggler !== this.toggler) return;
        this.isCollapsed = !this.isCollapsed;
    }

    get hasContent() {
        return !!(this.value || this.contentTemplateRef) && !this.hideContent;
    }
}
