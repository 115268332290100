import { Pipe, PipeTransform } from '@angular/core';
/* 
 * Usage:
 * value | abs
 * 
 * Example:
 * {{ -1 | abs }}
 */
@Pipe({ name: 'abs' })

export class AbsPipe implements PipeTransform {
    transform(value: number): number {
        return Math.abs(value);
    }
}
