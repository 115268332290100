// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#fab-style {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 15%;
  left: 76%;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  -webkit-user-select: none;
          user-select: none;
}
#fab-style .float-btn {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #fbc02d;
  color: black;
  z-index: 1000;
  box-shadow: 0px 2px 17px -1px rgba(0, 0, 0, 0.3);
  font-size: 30px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/gc-draggable-fab/gc-draggable-fab.component.sass"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,gCAAA;EACA,QAAA;EACA,SAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,yBAAA;UAAA,iBAAA;AACJ;AAAI;EACI,kBAAA;EACA,MAAA;EACA,OAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,yBAAA;EACA,YAAA;EACA,aAAA;EACA,gDAAA;EACA,eAAA;AAER","sourcesContent":["#fab-style\n    position: absolute\n    transform: translate(-50%, -50%)\n    top: 15%\n    left: 76%\n    width: 48px\n    height: 48px\n    border-radius: 50%    \n    user-select: none\n    .float-btn\n        position: absolute\n        top: 0\n        left: 0\n        display: flex\n        justify-content: center\n        align-items: center\n        width: 100%\n        height: 100%\n        border-radius: 50%\n        background-color: #fbc02d\n        color: black\n        z-index: 1000\n        box-shadow: 0px 2px 17px -1px rgba(0,0,0,0.3)\n        font-size: 30px\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
