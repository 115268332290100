/* eslint-disable @angular-eslint/component-class-suffix */
import { Component, ViewEncapsulation } from "@angular/core";
import { Footer, Header } from "primeng/api";

@Component({
    selector:      'gc-header',
    template:      '<ng-content></ng-content>',
    encapsulation: ViewEncapsulation.None,
    providers:     [{ provide: Header, useExisting: GcHeader }]
})
export class GcHeader {}

@Component({
    selector:  'gc-footer',
    template:  '<ng-content></ng-content>',
    providers: [{ provide: Footer, useExisting: GcFooter }]
})
export class GcFooter {}
