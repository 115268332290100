/* eslint-disable @angular-eslint/directive-class-suffix */
/* eslint-disable @angular-eslint/no-host-metadata-property */
import { Directive, Input, TemplateRef } from "@angular/core";
import { PrimeTemplate } from "primeng/api";

@Directive({
    selector:  '[gcTemplate]',
    host:      {},
    providers: [
        { provide: PrimeTemplate, useExisting: GcTemplate }
    ]
})
export class GcTemplate {
    @Input() type: string;
    @Input('gcTemplate') name: string;

    constructor(public template: TemplateRef<unknown>) {}

    getType(): string {
        return this.name;
    }

}
