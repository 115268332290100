/* eslint-disable @angular-eslint/no-inputs-metadata-property */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Divider } from "primeng/divider";
import { WrapperBase } from "../base";
import { Wrapper } from "../decorators";

@Component({
    selector:      'gc-divider',
    encapsulation: ViewEncapsulation.None,
    template:      '<p-divider #component><ng-content></ng-content></p-divider>',
    providers:     [{ provide: Divider, useExisting: GcDividerComponent }],
    inputs:        ["align", "layout", "type", "style", "styleClass"],
})
@Wrapper(Divider)
export class GcDividerComponent extends WrapperBase implements OnInit {
}
export interface GcDividerComponent extends Divider {}
