import { Directive, EmbeddedViewRef, Input, TemplateRef, ViewContainerRef } from "@angular/core";
import { FrontendPermissions } from "@vierkant-software/types__api";
import { AppService } from "src/services/app.service";

@Directive({
    selector: "[gcIsPermitted]"
})
export class gcIsPermittedDirective {
    permission: FrontendPermissions[];
    elseTemplateRef: TemplateRef<unknown> | null = null;
    thenViewRef: EmbeddedViewRef<unknown> | null = null;
    elseViewRef: EmbeddedViewRef<unknown> | null = null;

    constructor(
        private viewContainer: ViewContainerRef,
        private templateRef: TemplateRef<unknown>,
        private app: AppService
    ) {}

    @Input()
    set gcIsPermitted(permission: FrontendPermissions[] | FrontendPermissions) {
        if (!Array.isArray(permission))
            permission = [permission];
        this.permission = permission;
        this.updateView();
    }

    @Input()
    set gcIsPermittedElse(templateRef: TemplateRef<unknown> | null) {
        if (templateRef && !templateRef.createEmbeddedView)
            throw new Error("gcIsFeatureElse must be a template reference variable");
        this.elseTemplateRef = templateRef;
        this.elseViewRef = null;
        this.updateView();
    }

    private updateView() {
        if (this.app.getFrontendPermissions(this.permission)) {
            if (!this.thenViewRef) {
                this.viewContainer.clear();
                this.elseViewRef = null;
                if (this.templateRef)
                    this.thenViewRef = this.viewContainer.createEmbeddedView(this.templateRef);
            }
        } else if (!this.elseViewRef) {
            this.viewContainer.clear();
            this.thenViewRef = null;
            if (this.elseTemplateRef)
                this.elseViewRef = this.viewContainer.createEmbeddedView(this.elseTemplateRef);
        }
    }

}
