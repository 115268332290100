// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.key {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.2;
  margin-top: 16px;
  margin-bottom: 10px;
}

:host.mt-0 .key {
  margin-top: 0;
}

.value.less-intensity {
  color: rgba(var(--gc-text-color-rgb), 0.7);
}

:host.container {
  padding: 0.5rem 1rem;
  padding-top: 0;
  border: 1px solid var(--gc-border-color);
  background-color: var(--gc-content-background);
}`, "",{"version":3,"sources":["webpack://./src/app/components/gc-kv/gc-kv.component.sass"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,gBAAA;EACA,mBAAA;AACJ;;AAEI;EACI,aAAA;AACR;;AACA;EACI,0CAAA;AAEJ;;AAAA;EACI,oBAAA;EACA,cAAA;EACA,wCAAA;EACA,8CAAA;AAGJ","sourcesContent":[".key // values from prime's h5\n    font-size: 18px\n    font-weight: bold\n    line-height: 1.2\n    margin-top: 16px\n    margin-bottom: 10px\n\n:host.mt-0\n    .key\n        margin-top: 0\n\n.value.less-intensity\n    color: rgba(var(--gc-text-color-rgb), 0.7)\n\n:host.container\n    padding: 0.5rem 1rem\n    padding-top: 0\n    border: 1px solid var(--gc-border-color)\n    background-color: var(--gc-content-background)\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
