// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h5 {
  font-weight: 500;
  margin-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/sidebar/siderbar.component.sass"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,mBAAA;AACJ","sourcesContent":["h5\n    font-weight: 500\n    margin-bottom: 20px\n\n// ::ng-deep\n//     gc-personshortcut-sidebar\n//         flex-grow: 1\n//         display: flex\n// .full-sidebar\n//     ::ng-deep\n//         .p-sidebar-content\n//             flex-grow: 1\n//             > * \n//                 height: 100%"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
