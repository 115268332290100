// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: grid;
  gap: 1rem;
}

.hue-slider {
  background-image: url("/assets/img/hue.png");
}

.saturation-slider {
  background: linear-gradient(90deg, hsl(var(--hue), 25%, 50%), hsl(var(--hue), 100%, 50%));
}

.hue-slider, .saturation-slider {
  width: 100%;
  height: 35px;
  background-repeat: repeat-y;
  background-size: cover;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/app/components/gc-hsl-picker/gc-hsl-picker.component.sass"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,SAAA;AACJ;;AAEA;EACI,4CAAA;AACJ;;AAAA;EACI,yFAAA;AAGJ;;AAFA;EACI,WAAA;EACA,YAAA;EACA,2BAAA;EACA,sBAAA;EACA,aAAA;EACA,0BAAA;EACA,mBAAA;AAKJ","sourcesContent":[":host\n    display: grid\n    gap: 1rem\n\n    \n.hue-slider\n    background-image: url(\"/assets/img/hue.png\")\n.saturation-slider\n    background: linear-gradient(90deg, hsl(var(--hue), 25% , 50%), hsl(var(--hue), 100% , 50%))\n.hue-slider, .saturation-slider\n    width: 100%\n    height: 35px\n    background-repeat: repeat-y\n    background-size: cover\n    display: grid\n    grid-template-columns: 1fr\n    align-items: center\n\n       "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
