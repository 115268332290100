/* eslint-disable @angular-eslint/no-host-metadata-property */
/* eslint-disable @angular-eslint/directive-class-suffix */
/* eslint-disable @typescript-eslint/ban-types */
import { Directive, HostBinding, Inject, Input, OnDestroy, OnInit, Optional, SkipSelf, ViewChild } from "@angular/core";
import { ControlContainer, ControlValueAccessor, FormControl, FormControlDirective } from "@angular/forms";
import { FILTERED_PROPS, GC_COMPONENT_CONTAINER } from "./definitions";
import { GCValidatorFn, ValidatorFlags } from "../validators";
import { randomID } from "../util";

export interface WrappedComponentCtor {
  new(...args: unknown[]): unknown;
  wrappedComponent?: {
    new(...args: unknown[]): unknown;
    propDecorators?: {
      [key: string]: {
        type: {
          prototype: {
            ngMetadataName: string;
          };
        };
      }[];
    };
  };
  rules?: {
    excludeMethods?: string[];
    includeMethods?: string[];
    excludeProp?: string[];
    includeProp?: string[];
  };
  propDecorators?: {
    [key: string]: {
      type: {
        prototype: {
          ngMetadataName: string;
        };
      };
    }[];
  };
}

@Directive()
export abstract class WrapperBase implements OnInit {
  @ViewChild('component', { static: true })
  private _component: unknown;
  public get component(): unknown {
    return this._component;
  }
  public set component(value: unknown) {
    this._component = value;
  }

  ngOnInit() {
    this.init();
  }

  init() {
    if (!this.component)
      return; //Ignore is no child component exists (this is propably a group component)
    if (!(<WrappedComponentCtor>this.constructor).wrappedComponent)
      return;
    Object.entries((<WrappedComponentCtor>this.constructor).wrappedComponent.propDecorators)
    .filter(([key]) => !FILTERED_PROPS.includes(key))
    .filter(([key]) => !(<WrappedComponentCtor>this.constructor).rules?.excludeProp?.includes(key))
    .filter(([key]) => (<WrappedComponentCtor>this.constructor).rules?.includeProp?.includes(key) ?? true)
    .forEach(([key, props]) => {
      if (props.some(prop => ['Input', 'Output'].includes(prop.type?.prototype?.ngMetadataName))) {
        if ((<Record<string, unknown>>this)[key] !== undefined)
          (<Record<string, unknown>>this.component)[key] = (<Record<string, unknown>>this)[key];
      }
      if (props.some(prop => prop.type?.prototype?.ngMetadataName === 'Input')) {
        Object.defineProperty(this, key, {
          enumerable: true,
          set(this: WrapperComponent, value: unknown) {
            (<Record<string, unknown>><unknown>this.component)[key] = value;
          },
          get(this: WrapperComponent) {
            return (<Record<string, unknown>><unknown>this.component)[key];
          }
        });
      }
    });
  }
}

@Directive({
  host: { class: "gc-component" }
})
export abstract class WrapperComponent extends WrapperBase implements ControlValueAccessor, OnDestroy {
  @HostBinding("class.gc-disabled")
  @HostBinding("attr.disabled")
  private __disabled: boolean;

  public get disabled(): boolean {
    return this.__disabled;
  }
  @Input() public set disabled(value: unknown) {
    this.__disabled = value !== undefined ? !!value : false;
    this.setDisabledState(this.__disabled);
  }
  @ViewChild(FormControlDirective, {static: true}) formControlDirective: FormControlDirective;
  @Input() formControlName: string;
  @Input() formControl: FormControl;

  public readonly __internal_ID = randomID();

  override get component() {
    return this.formControlDirective.valueAccessor;
  }

  _skipRegister = false;

  #children: WrapperComponent[] = [];

  fc = new FormControl();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  gcOnChange: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  gcOnTouched: any;

  get gcControl(): FormControl {
    return <FormControl>(
      this.formControl ||
      this.formControlName ? this.controlContainer?.control?.get(this.formControlName) : (this.parent?.gcControl ?? this.fc)
    );
  }

  @HostBinding('class.gc-required')
  get GcRequired(): boolean {
    // eslint-disable-next-line no-bitwise
    return (this.gcControl as unknown as { _rawValidators: GCValidatorFn[] })?._rawValidators?.some((v) => (v.flags & ValidatorFlags.required)) ?? false;
  }

  constructor(
    @Optional() protected controlContainer: ControlContainer,
    @Inject(GC_COMPONENT_CONTAINER) @Optional() @SkipSelf() protected parent: WrapperComponent
  ) {
    super();
    if (parent?._skipRegister)
      parent = undefined;
    if (parent)
      parent.registerChild?.(this);
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  destroy() {
    if (this.parent)
      this.parent.unregisterChild?.(this);
  }

  registerChild(self: WrapperComponent) {
    this.#children.push(self);
    if (this.gcOnChange)
      self.registerOnChange(this.gcOnChange);
    if (this.gcOnTouched)
      self.registerOnTouched(this.gcOnTouched);
    if (this.fc.value)
      self.writeValue(this.fc.value);
  }

  unregisterChild(self: WrapperComponent) {
    this.#children = this.#children.filter(x => x !== self);
  }

  broadcastValue(self: WrapperComponent, value: unknown) {
    this.#children.filter(x => x !== self).forEach(x => x.writeValue(value));
  }

  clearInput() {
    this.gcControl.setValue('');
  }

  registerOnTouched(fn: Function): void {
    this.#children.forEach((child) => child.registerOnTouched(fn));
    this.formControlDirective?.valueAccessor.registerOnTouched(fn);
    this.gcOnTouched = fn;
  }

  registerOnChange(fn: Function): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.fc.valueChanges.subscribe(fn as any);
    this.#children.forEach((child) => child.registerOnChange(fn));
    this.formControlDirective?.valueAccessor.registerOnChange(fn);
    this.gcOnChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  writeValue(obj: any): void {
    this.fc.setValue(obj, { emitEvent: false });
    this.#children.forEach((child) => child.writeValue(obj));
    this.formControlDirective?.valueAccessor.writeValue(obj);
  }

  setDisabledState(isDisabled: boolean): void {
    this.__disabled = isDisabled;
    if (isDisabled)
      this.fc.disable({ emitEvent: false });
    else
      this.fc.enable({ emitEvent: false });
    this.#children.forEach((child) => child.setDisabledState(isDisabled));
    this.formControlDirective?.valueAccessor.setDisabledState(isDisabled);
  }
}
