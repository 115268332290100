// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.business-card {
  width: 100%;
  height: auto;
  border: 1px solid #cacaca;
}
.business-card .header {
  background-color: #f2f2f2;
  padding: 0 0.5rem;
}
.business-card .content {
  background-color: #f2f2f2;
  padding: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/app/components/gc-business-card/gc-business-card.component.sass"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,yBAAA;AACJ;AAAI;EACI,yBAAA;EACA,iBAAA;AAER;AADI;EACI,yBAAA;EACA,eAAA;AAGR","sourcesContent":[".business-card\n    width: 100%\n    height: auto\n    border: 1px solid #cacaca\n    .header\n        background-color: #f2f2f2\n        padding: 0 0.5rem\n    .content\n        background-color: #f2f2f2\n        padding: 0.5rem"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
