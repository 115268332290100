export interface OnResolve {
    onResolve(data: unknown): void;
}

export interface OnDeactivate {
    onDeactivate(): void;
}

export interface FormChanged {
    onFormChanged(event: unknown): unknown;
}

export interface FormChangedImmed {
    onFormChangedImmed(event: unknown): unknown;
}
