// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table {
  display: grid;
  grid-template-columns: [name] 1fr [inheritance] auto [custom] auto;
}
.table .table-row {
  display: grid;
  grid-column: 1/-1;
  grid-template-columns: subgrid;
}
.table .table-row .table-row {
  grid-column: 1/-1;
  margin-left: 1rem;
  padding-left: 1rem;
  border-left: 1px solid var(--gc-text-color);
}
.table .table-row .column:not(.table-row) {
  padding: 0.5rem;
}
.table .table-row .column > :not(.name) {
  display: flex;
  justify-content: center;
}
.table .table-row .name {
  grid-column: name;
}
.table .table-row .inherit {
  grid-column: inheritance;
}
.table .table-row .custom {
  grid-column: custom;
}

.hide-inheritance .inherit, .hide-inheritance .custom {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/app/components/visibility-inheritance/visibility-inheritance.component.sass"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,kEAAA;AACJ;AAAI;EACI,aAAA;EACA,iBAAA;EACA,8BAAA;AAER;AADQ;EACI,iBAAA;EACA,iBAAA;EACA,kBAAA;EACA,2CAAA;AAGZ;AAFQ;EACI,eAAA;AAIZ;AAHQ;EACI,aAAA;EACA,uBAAA;AAKZ;AAJQ;EACI,iBAAA;AAMZ;AALQ;EACI,wBAAA;AAOZ;AANQ;EACI,mBAAA;AAQZ;;AALI;EACI,aAAA;AAQR","sourcesContent":[".table\n    display: grid\n    grid-template-columns: [name] 1fr [inheritance] auto [custom] auto\n    .table-row\n        display: grid\n        grid-column: 1 / -1\n        grid-template-columns: subgrid\n        .table-row\n            grid-column: 1 / -1\n            margin-left: 1rem\n            padding-left: 1rem\n            border-left: 1px solid var(--gc-text-color)\n        .column:not(.table-row)\n            padding: 0.5rem\n        .column > :not(.name) \n            display: flex\n            justify-content: center   \n        .name\n            grid-column: name\n        .inherit\n            grid-column: inheritance\n        .custom\n            grid-column: custom\n\n.hide-inheritance\n    .inherit, .custom\n        display: none"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
