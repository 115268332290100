import { Component, Input, OnDestroy, OnInit, Optional, TemplateRef, ViewChild, forwardRef } from "@angular/core";
import { InheritanceType, ParamType, WFConfig, WorkflowData, WorkflowType, categories, configurations } from "./declarations";
import { AbstractControl, ControlContainer, ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, ValidationErrors } from "@angular/forms";
import { Dict, Option } from "src/util/types/global";
import { DepartmentType, IDepartmentDTO, IUserEx, WFType } from "@vierkant-software/types__api";
import { Result, EntityTypes } from "../workflow-entity-selection/workflow-entity-selection.component";
import { Dialog } from "primeng/dialog";
import { Subscription } from "rxjs";
import { AppService } from "src/services/app.service";
import { OrgChartEntry } from "../gc-org-chart/gc-org-chart.component";
import { toDict } from "src/util/array";
import { WorkflowInheritanceComponent } from "../workflow-inheritance/workflow-inheritance.component";

type Value = Dict<WorkflowData>;
type WorkflowConfig = Omit<WorkflowType, "configs"> & {
    configs: Record<string, WFConfig>;
    options: (Option<string> & {info?: string})[];
    controls: Record<string, FormGroup>;
};
type InheritanceDetail = {origin: OrgChartEntry, workflow: WorkflowData, type?: InheritanceType};

/**
 * TODO: things to add later (soon) but aren't required to launch
 * 
 * - limit selectable EntityType
 */
@Component({
    selector:    "v-workflow",
    styleUrls:   ["./workflow.component.sass"],
    templateUrl: "./workflow.component.haml",
    providers:   [{
        provide:     NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => WorkflowComponent),
        multi:       true
    }],
})
export class WorkflowComponent implements OnInit, OnDestroy, ControlValueAccessor {

    public readonly defaultUser: IUserEx = {firstname: "Person wird geladen", lastname: ""};
    public readonly categories = categories;
    public readonly configurations = configurations;
    public readonly employeeImages: Record<string, string> = {};
    public readonly employees: Record<string, IUserEx> = {};
    public readonly workflowTypes: WorkflowType[] = this.categories.flatMap(x => x.workflowTypes);
    public readonly workflowTypeNames: string[] = this.categories.flatMap(x => x.workflowTypes.map(t => `${x.name} ${t.information?.label}`));
    public filteredWorkflowTypes: string[] = [...this.workflowTypeNames];
    public departmentNode: OrgChartEntry;
    public departments: Record<string, IDepartmentDTO> = {};
    public inheritanceDetail: Dict<InheritanceDetail> = {};
    public getDetail(name?: string) {
        return this.inheritanceDetail[name ?? "default"];
    }
    public setDetail(name: string | undefined, value: InheritanceDetail) {
        return this.inheritanceDetail[name ?? "default"] = value;
    }

    private entryDict: Dict<OrgChartEntry> = {};
    private entryList: OrgChartEntry[] = [];
    private _value: Value;
    @Input({required: true}) public defaultWorkflowData: Dict<WorkflowData> = {};
    public get entries(): OrgChartEntry[] {
        return this.entryList;
    }
    @Input()
    public set entries(value: OrgChartEntry[]) {
        this.entryList = (value ?? []).filter(x => x.type === "department");
        this.entryDict = toDict(this.entryList, x => x.ID);
    }
    private _allDepartments: IDepartmentDTO[];
    public get allDepartments(): IDepartmentDTO[] {
        return this._allDepartments;
    }
    @Input({ alias: "departments" })
    public set allDepartments(value: IDepartmentDTO[]) {
        this._allDepartments = value;
        this.departments = toDict(value, x => x.departmentID);
    }
    @Input() rootID?: string;
    @Input() public hasHierarchy: boolean;
    @ViewChild("inheritance", {static: true}) public workflowInheritance: WorkflowInheritanceComponent;
    @ViewChild("inputBoolean") public booleanTemplate: TemplateRef<unknown>;
    @ViewChild("inputDuration") public durationTemplate: TemplateRef<unknown>;
    @ViewChild("entitySelection", {static: true}) public entitySelectionDialog: Dialog;
    @ViewChild("inputEntity") public entityTemplate: TemplateRef<unknown>;
    @ViewChild("inputNumber") public numberTemplate: TemplateRef<unknown>;

    public entitySelectionContext: {typeID: string, paramID: string};
    public onModelChange: Function = (_value: Value) => {};
    public onModelTouched: Function = () => {};
    public subscriptions: Subscription[] = [];
    public workflowConfigurations: Record<string, WorkflowConfig> = (() => toDict(this.workflowTypes.map(type => ({
        name:        type.name,
        information: type.information,
        configs:     type.configs.reduce((curr, val) => {
            curr[val] = this.configurations[val];
            return curr;
        }, {} as Record<string, WFConfig>),
        options: [
            {
                label: "Für diese Abteilung nicht aktiv",
                icon:  "square-minus",
                value: null,
            },
            ...type.configs.map(x => ({
                label: configurations[x].name,
                info:  configurations[x].info,
                value: x,
            }))
        ],
        controls: {},
    })), x => x.name))();
    public inheritanceToEdit?: WFType;
    public form: FormGroup;

    private setupWorkflows(formGroup?: FormGroup, value?: Value): FormGroup {
        this.form = formGroup ??= new FormGroup({});
        const self = this;
        this.workflowTypes.forEach(type => {
            const params = (formGroup.get([type.name, "params"]) ?? new FormGroup({})) as FormGroup<{}>;
            const inheritance = formGroup.get([type.name, "inheritance"]) ?? new FormControl();
            const workflowID = formGroup.get([type.name, "workflowID"]) ?? new FormControl();
            if (!formGroup.get(type.name)) formGroup.addControl(type.name, new FormGroup({ workflowID, inheritance, params}));

            inheritance.addValidators((control) =>
                this.rootID && !this.departments[this.rootID]?.parentID && control.value === "inherit" ? {"inheritance": "root cannot inherit"} : {});
            this.workflowConfigurations[type.name].controls = params.controls;
            formGroup.get(type.name).reset(
                value?.[type.name] ??
                {inheritance: this.entryDict[this.rootID].parentID ? 'inherit' : undefined, workflowID: undefined, params: {} }
            );
            setupParameters.bind(this)();
            setupChangeListeners.bind(this)();

            function setupChangeListeners() {
                self.subscriptions.push(workflowID.valueChanges.subscribe(x => {
                    const value = self.form?.value;
                    if (!value) return;
                    value[type.name].workflowID = x;
                    self.onModelChange(value);
                }));
                self.subscriptions.push(inheritance.valueChanges.subscribe(x => {
                    self.onChangeInheritanceType(type.name, x, params);
                }));
                self.onChangeInheritanceType(type.name, inheritance.value, params);
            }

            function setupParameters() {
                type.configs.forEach(configID => {
                    const config = configurations[configID];
                    config.params.forEach(paramDefinition => {

                        const control = params.get(paramDefinition.ID) ?? new FormGroup({
                            value: new FormControl(paramDefinition.default),
                            type:  new FormControl(paramDefinition.type)
                        });
                        const value = control.get("value");
                        self.subscriptions.push(value.valueChanges.subscribe(x => {
                            const value = self.form?.value;
                            if (!value) return;
                            value[type.name].params[paramDefinition.ID].value = x;
                            self.onModelChange(value);
                        }));
                        if (paramDefinition.required)
                            value.addValidators([self.valueValidator]);

                        if (!params.get(paramDefinition.ID)) params.addControl(paramDefinition.ID, control);
                    });
                });
            }
        });
        return formGroup;
    }

    constructor(private readonly service: AppService, @Optional() private readonly controlContainer?: ControlContainer) {}

    private valueValidator(control: AbstractControl): ValidationErrors  {
        let invalid: false | string = false;
        if (!control.value) {
            invalid = "Unset";
        } else if (["number", "boolean"].includes(typeof control.value)) {
            invalid = !control.value ? "Unset" : false;
        } else if (typeof control.value === "string") {
            invalid = !control.value.length ? "Unset" : false;
        } else if (typeof control.value === "object") {
            const keys = Object.keys(control.value);
            if (keys.length){
                invalid = !keys.some(key => {
                    const val = control.value[key];
                    if (!val) return false;
                    if (Array.isArray(val)) return val.length > 0;
                    return true;
                }) ? "Keys empty" : false;
            } else {
                invalid = "No keys set";
            }
        }
        return invalid ? {defined: invalid} : {};
    }

    public ngOnInit() {
        if (this.controlContainer){
            this._value = this.controlContainer.control.value;
            this.setupWorkflows(this.controlContainer.control as FormGroup, this._value);
            this.fetchPeople();
        }
    }

    public get value(): Value {
        return this._value;
    }

    public set value(val: Value) {
        this.subscriptions.forEach(x => x.unsubscribe());
        this.subscriptions = [];
        this._value = structuredClone(val);
        if (!this._value) return;
        this.setupWorkflows(this.form, this._value);
        // update value in case inheritance changed
        this.workflowTypes.forEach(x => {
            const inheritanceType = this._value[x.name].inheritance;
            const inheritance =
                this.getInheritance(this.rootID, x.name, inheritanceType ?? (this.entryDict[this.rootID].parentID ? 'inherit' : undefined));
            this.inheritanceDetail[x.name] = inheritance;
            this._value[x.name] = {
                workflowID: this._value[x.name].workflowID ?? inheritance.workflow.workflowID,
                params:     {
                    ...inheritance.workflow.params,
                    ...this._value[x.name].params,
                },
                inheritance: inheritanceType ?? inheritance.type ?? null
            };
        });
        this.form.setValue(this._value);

        this.fetchPeople();
    }
    private fetchPeople() {
        const person = [...new Set(Object.values(
            this.form?.value ?? {}).flatMap((x: WorkflowData) => {
                const result = Object.values(x.params).filter(prop => {
                    const result = prop?.type === "entity" && "person" in (prop?.value as { person: string[] });
                    return result;
                }
                ).map((p) => (p.value as { person: string[] }).person);
                return result;
            }
            ).flat())];
        this.fetchUsers(person).catch(console.error);
    }

    public readonly filter = (term: string, values: string[]) => {
        if ([null, undefined, ""].includes(term?.trim())) return values;
        const words = term.toLowerCase().split(" ");
        return values.filter(x => words.every(word => x.toLowerCase().indexOf(word) >= 0));
    };

    public async fetchUsers(person: string[]) {
        const existing = Object.keys(this.employees);
        const ids = person.filter(x => !existing.includes(x));
        const users = await this.service.api.UserWorker.getUsers(ids);
        users.data.forEach((user, index) => {
            this.employees[user.ID] = user;
            this.employeeImages[user.ID] = users.__files[index];
        });
    }

    public getConfig(workflowType: string) {
        return this.configurations[this.form.get([workflowType, "workflowID"])?.value];
    }

    public getControl(workflowType: string, paramID: string) {
        return this.workflowConfigurations[workflowType].controls[paramID];
    }

    public getTemplate(type: ParamType){
        if (type === "number")   return this.numberTemplate;
        if (type === "duration") return this.durationTemplate;
        if (type === "boolean")  return this.booleanTemplate;
        if (type === "entity")   return this.entityTemplate;
        return undefined;
    }

    public ngOnDestroy(): void {
        this.cleanup();
    }

    public onCloseEntitySelection(result: Result) {
        try {
            if (!result) return;
            const {typeID, paramID} = this.entitySelectionContext;
            const control = this.getValueControl(typeID, paramID);
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const {type, ...values} = result;

            const value = this.merge(values, control?.value);
            control.patchValue(value);

            if (result.type === "person")this.fetchUsers(result.person).catch(console.error);
        } finally {
            this.entitySelectionContext = undefined;
        }
    }

    private merge<T>(objSource: T, objTarget: T) {
        Object.keys(objSource).forEach((k) => {
            const key = k as keyof T;
            if (typeof objSource[key] === "string") return objTarget[key] = objSource[key];
            if (Array.isArray(objSource[key])) {
                const source = objSource[key] as T[keyof T][];
                const target = objTarget[key] as T[keyof T][];
                return (objTarget[key] as T[keyof T][]) = [...(target ?? []), ...(source?.filter(x => !target?.includes(x)) ?? [])];
            };
            if (typeof objSource[key] === "object") return objTarget[key] = this.merge(objSource[key], objTarget[key] ?? {} as T[keyof T]);
            objTarget[key] = objSource[key];
        });
        return objTarget;
    }

    public onRemoveDepartment(type: WorkflowType, departmentType: DepartmentType, paramID: string, value?: string) {
        const control = this.getValueControl(type.name, paramID);
        const controlValue = control.value;
        const array = controlValue["department"][departmentType] as string[];
        const index = array.indexOf(value);
        if (index < 0) return;
        array.splice(index, 1);
        control.patchValue(controlValue);
    }

    public onRemoved(kind: EntityTypes, type: WorkflowType, paramID: string, value?: string) {
        const control = this.getValueControl(type.name, paramID);
        const controlValue = control.value;
        switch(kind) {
            case 'origin':
            case 'superior':
                delete controlValue[kind];
                break;
            case 'person':
                const array = controlValue[kind] as string[];
                const index = array.indexOf(value);
                if (index < 0) return;
                array.splice(index, 1);
                break;
            case 'department':
                throw new Error("use 'onRemoveDepartment' instead");
        }
        control.patchValue(controlValue);
    }

    public registerOnChange(fn: Function): void {
        this.onModelChange = fn;
    }

    public registerOnTouched(fn: Function): void {
        this.onModelTouched = fn;
    }

    public showEntitySelection(typeID: string, paramID: string, _event: Event) {
        this.entitySelectionContext = {typeID, paramID};
    }

    public writeValue(value: Value): void {
        this.value = value;
    }

    public editInheritance(name: WFType, _event: Event) {
        this.inheritanceToEdit = name;
        this.workflowInheritance.setup();
    }

    public onInheritanceClose(inheritanceData: Record<string, InheritanceType>) {
        try {
            if (!inheritanceData) return;
            Object.entries(inheritanceData).forEach(([key, value]) => {
                const department = this.entries.find(x => x.ID === key);
                if (!department) return;
                department.workflows[this.inheritanceToEdit] ??= {workflowID: undefined, inheritance: undefined, params: {}};
                const inheritance = this.getInheritance(key, this.inheritanceToEdit, value ?? "default");
                department.workflows[this.inheritanceToEdit] = {...inheritance.workflow, inheritance: inheritance.type};
            });
        } finally {
            this.inheritanceToEdit = undefined;
        }
    }

    public hasEntities(object: unknown) {
        return !!Object.values(object ?? {}).filter(x => x?.length ?? x !== undefined).length;
    }

    private getValueControl(typeID: string, paramID: string) {
        return this.form.get([typeID, 'params', paramID, 'value']);
    }

    private getParent(nodeID: string) {
        return this.entryDict[this.entryDict[nodeID].parentID];
    }

    public getInheritance(departmentID: string, workflowName: WFType, inheritanceType?: InheritanceType | "default"): InheritanceDetail {
        let current = this.entryDict[departmentID];
        if (inheritanceType !== null)
            (current.workflows[workflowName] ??= {} as WorkflowData).inheritance = inheritanceType === "default" ? undefined : inheritanceType;
        while (current.parentID && current.workflows[workflowName]?.inheritance === "inherit")
            current = this.getParent(current.ID);
        // root node cannot inherit, use this as failsafe in case it was somehow set. Valid values for rootnode are either `null` (default) or "custom".
        if (!current.parentID) inheritanceType = current.workflows[workflowName]?.inheritance === "custom" ? "custom" : null;
        else inheritanceType = current.workflows[workflowName]?.inheritance;

        const resultType = departmentID === current.ID ? inheritanceType : "inherit";
        if (!inheritanceType)
            return {origin: current, workflow: structuredClone(this.defaultWorkflowData[workflowName]), type: resultType};
         else
            return {origin: current, workflow: current.workflows[workflowName], type: resultType};
    }

    private onChangeInheritanceType(wfName: WFType, inheritanceType: InheritanceType, control: FormGroup) {
        if (!this.hasHierarchy) return;
        const inheritance = this.getInheritance(this.rootID, wfName, inheritanceType ?? "default");
        if (inheritance) {
            // overwrite settings with standard and inherit type, but keep on custom
            this.form?.get(wfName).patchValue({
                ...(inheritance.type === "custom" ? {} : inheritance.workflow),
                inheritance: inheritance.type
            }, {emitEvent: false});
        }

        const workflowSelection = control.parent.get("workflowID");
        if (inheritanceType === "custom") workflowSelection.enable();
        else workflowSelection.disable();

        Object.entries(control.controls).forEach(([_key, c]) => {
            if (inheritanceType === "custom") c.enable();
            else c.disable();
        });
        this.fetchPeople();
    }

    private cleanup() {
        this.subscriptions?.forEach(x => x?.unsubscribe());
    }
}