import { Component,Input, OnChanges, SimpleChanges } from '@angular/core';
import { IUser, WithFiles } from '@vierkant-software/types__api';

/**
 * Lists several users as chips.
 * 
 * @name User List
 * @tags Display, User, Data
 */
@Component({
    selector: 'gc-user-list',
    template: `
<p-chip *ngFor="let user of users" class="user-chip">
    <gc-user [value]="user" [image]="images?.[user.ID]" [forceImage]="hasImages" [ngClass]="{'pl-3': !hasImages}" class="block"></gc-user>
</p-chip>
<p-chip *ngIf="(!users || users.length === 0) && empty !== 'hidden'" class="user-chip" [ngClass]="{'chip-green': empty === 'all'}" [icon]="emptyIcon">
    {{emptyString}}
</p-chip>`,
    styleUrls: ['./gc-user-list.component.sass']
})
export class GcUserListComponent implements OnChanges{
    public images?: Record<string, string>;
    public users?: IUser[];
    public get hasImages() {
        return !!this.images;
    }
    public get emptyString() {
        return this.empty === "all" ? "Alle" : "Keine";
    }

    public get emptyIcon() {
        return this.empty === "all" ? "pi pi-check" : undefined;
    }

    /**
     * Users to display. 
     * @values WithFiles<IUser[]> | IUser[]
     */
    @Input() public value: WithFiles<IUser[]> | IUser[];
    /**
     * Determines the display if value is empty or unset.
     * 
     * @values  "hidden" | "all" | "empty"
     * @default "hidden"
     */
    @Input() public empty: "hidden" | "all" | "empty" = "hidden";

    constructor(){}
    ngOnChanges(changes: SimpleChanges): void {
        if (!changes.value) return;
        if (Array.isArray(changes.value.currentValue)){
            const value = <IUser[]>changes.value.currentValue;
            this.images = undefined;
            this.users = value;
        } else {
            const value = <WithFiles<IUser[]>> changes.value.currentValue;
            this.users = value?.data?.map((val, i) => {
                if (value.__files && value.__files.length > 0) (this.images = this.images ?? {})[val.ID] = value.__files[i];
                return val;
            });
        }
    }
}
