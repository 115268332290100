// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gc-user {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.color {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  display: block;
  flex-shrink: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/components/gc-user/gc-user.component.sass"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,WAAA;AACJ;;AAAA;EACI,WAAA;EACA,YAAA;EACA,kBAAA;EACA,cAAA;EACA,cAAA;AAGJ","sourcesContent":[".gc-user\n    display: flex\n    align-items: center\n    gap: 0.5rem\n.color\n    width: 1rem\n    height: 1rem\n    border-radius: 50%\n    display: block\n    flex-shrink: 0"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
