import { FormArray, FormGroup } from "@angular/forms";
import '../util/errors';
import { InternalAppService } from "./app.service";
import { DebugService } from "./debug.service";
import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { SettingsService } from "./settings.service";

export function applyMonkeyPatches() {
    /** disabled formField should still be visible in form.value in FormGroups*/
    // ============================================================
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (FormGroup as any).prototype._reduceValue = function _reduceValue() {
        const acc = {};
        // eslint-disable-next-line @typescript-eslint/no-shadow, @typescript-eslint/no-explicit-any
        return this._reduceChildren(acc, (acc: any, control: any, name: any) => {
            acc[name] = control.value;
            return acc;
        });
    };
    // ============================================================
    /** disabled FormField should still be visible in form.value in FormArrays */
    // ============================================================
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (FormArray as any).prototype._updateValue = function _updateValue() {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this as {value: any}).value = this.controls.map((control: any) => control.value);
    };
}

@Injectable()
export class initServicesFactory {
    constructor(
        appService: InternalAppService,
        debugService: DebugService,
        router: Router,
        settings: SettingsService,
    ) {
        return async () => {
            applyMonkeyPatches();
            await debugService.__init(router);
            await appService.__initAPI();
        };
    }
}
