import { style, trigger, transition, animate  } from '@angular/animations';
import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

export type Severity = 'info' | 'success' | 'warn' | 'danger' | 'error' | 'default';

/**
 * Component used to display some information or alerts in different stylings. It's similar to the "messages" from primeng.
 * @name Info
 * @tags Text, Warn, Error, Danger, Success, Message
 */
@Component({
    selector:    'gc-info',
    templateUrl: "./gc-info.component.haml",
    styleUrls:   ['./gc-info.component.sass'],
    /**
     * animation is currently not used, but we are keeping this for the (near) future.
     * entry transition is not playing, if the element was destroyed by removing the parent element from the DOM.
     */
    animations:  [trigger(
        "animation",
        [
                transition(
                'void => *, :enter',
                [
                    style({ transform: 'translateY(-25%)', opacity: 0}),
                    animate('400ms cubic-bezier(0.86, 0, 0.07, 1)',
                        style({ transform: 'translateY(0)', opacity: 1}))
                ]
            ),
            transition(
                "* => void",
                [
                    style({ transform: 'translateY(0)', opacity: 1}),
                    animate('400ms cubic-bezier(0.86, 0, 0.07, 1)',
                        style({ transform: 'translateY(-25%)', opacity: 0}))
                ]
            )
        ]
    )],
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: { class: "gc-component" }
})
export class GcInfoComponent{
    /**
     * A corresponding icon is shown on the left side depending on what the severity value is.
     * The icons are hard-coded. There's no icon shown for "default" severity.
     * @default true
     */
    @Input() showIcon: boolean = true;
    /**
     * When set to true a close icon "x" is displayed on top right corner. Clicking on it will result in the component being hidden away.
     * @default false
     * @example 
     * ```
     * %gc-info{[closeable]: "true", severity: "info"} Closeable example. Once you click on the "x", I'll be gone.
     * ```
     */
    @Input() closeable: boolean;
    @Output() closeableChange = new EventEmitter<boolean>();
    /**
     * Used when closeable is set to true. Its purpose is to hide the info component once the close icon "x" is clicked upon.
     * @default false
     */
    @Input() public isHidden = false;
    /**
     * Changes styling of the component. The "default" value is the only one without icon. The styling of "default" looks gray. 
     * The values "danger" and "error" do the same thing.
     * @values 'info' | 'success' | 'warn' | 'danger' | 'error' | 'default'
     * @default default
     * @example "danger"
     * ```
     * %gc-info{severity: "danger"} Danger zone!
     * ```
     */
    @Input() @HostBinding("class") severity: Severity = 'default';
}

