/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/no-outputs-metadata-property */
/* eslint-disable @angular-eslint/no-inputs-metadata-property */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { Component, Input, ViewEncapsulation } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { AutoComplete } from "primeng/autocomplete";
import { WrapperComponent } from "../base";
import { Wrapper } from "../decorators";

@Component({
    selector:      'gc-autoComplete',
    styles:        ['.p-autocomplete, .p-autocomplete-input { width: 100% }'],
    encapsulation: ViewEncapsulation.None,
    template:      `<label *ngIf="label !== undefined" [for]="__internal_ID">{{ label }}</label>
                    <p-autoComplete [formControl]="gcControl" [id]="__internal_ID"><ng-content></ng-content></p-autoComplete>`,
    providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: GcAutoCompleteComponent, multi: true }],
    inputs:    ["suggestions", "field", "scrollHeight", "dropdown", "multiple", "dropdownIcon", "minLength",
        "delay", "completeOnFocus", "style", "inputStyle", "panelStyle", "styleClass", "inputStyleClass", "panelStyleClass",
        "inputId", "name", "optionGroupLabel", "group", "optionGroupChildren", "placeholder", "readonly", "disabled", "maxlength",
        "size", "appendTo", "tabindex", "dataKey", "autoHighlight", "type", "showEmptyMessage", "emptyMessage", "immutable",
        "required", "autofocus", "forceSelection", "dropdownMode", "baseZIndex", "autoZIndex", "showTransitionOptions",
        "hideTransitionOptions", "ariaLabel", "ariaLabelledBy", "dropdownAriaLabel", "unique", "autocomplete", "showClear",
        "virtualScroll", "virtualScrollItemSize", "virtualScrollOptions", "lazy"
    ],
    outputs: ["completeMethod", "onFocus", "onBlur", "onKeyUp", "onSelect", "onUnselect", "onDropdownClick", "onClear", "onShow", "onHide", "onLazyLoad"]
})
@Wrapper(AutoComplete)
export class GcAutoCompleteComponent extends WrapperComponent {
    @Input() label: string;
}
export interface GcAutoCompleteComponent extends AutoComplete {};
