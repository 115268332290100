// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `gc-searchbar .searchbar {
  position: relative;
}
gc-searchbar .searchbar .search-icon {
  position: absolute;
  top: 12px;
  left: 14px;
}
gc-searchbar .searchbar .load-icon, gc-searchbar .searchbar .clear-icon {
  position: absolute;
  top: 12px;
  right: 14px;
}
gc-searchbar .searchbar .load-icon {
  right: 12px !important;
}
gc-searchbar .searchbar input {
  padding: 0.5rem 0.5rem 0.5rem 2.5rem !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/gc-search/gc-search.component.sass"],"names":[],"mappings":"AACI;EACI,kBAAA;AAAR;AACQ;EACI,kBAAA;EACA,SAAA;EACA,UAAA;AACZ;AAAQ;EACI,kBAAA;EACA,SAAA;EACA,WAAA;AAEZ;AADQ;EACI,sBAAA;AAGZ;AAFQ;EACI,+CAAA;AAIZ","sourcesContent":["gc-searchbar\n    .searchbar\n        position: relative\n        .search-icon\n            position: absolute\n            top: 12px\n            left: 14px\n        .load-icon, .clear-icon\n            position: absolute\n            top: 12px\n            right: 14px\n        .load-icon\n            right: 12px !important\n        input\n            padding: 0.5rem 0.5rem 0.5rem 2.5rem !important"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
