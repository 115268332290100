import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SidebarComponent } from 'src/app/sidebar/siderbar.component';
import { AppService } from 'src/services/app.service';
import { IStudio } from '@vierkant-software/types__api';
import { faAnglesRight, faLocationDot, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FormGroup, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

// FIXME backend logic - complete rework

/**
* @ignore - do not show in documentation
*/
@Component({
    selector:    'gc-studio-switch-sidebar',
    templateUrl: './gc-studio-switch-sidebar.component.haml',
    styleUrls:   ['./gc-studio-switch-sidebar.component.sass']
})
export class StudioSwitchSidebarComponent implements OnInit, OnDestroy {
    static instance: StudioSwitchSidebarComponent;

    display: boolean = false;
    studios: IStudio[] = [];
    files: string[] = [];
    studioSwitchPanel: Subscription;

    iconLocation = faLocationDot;
    iconMinimize = faAnglesRight;
    iconClose = faXmark;

    @ViewChild('sidebar') sidebar: SidebarComponent;

    form = new FormGroup({
        state:          new FormControl(),
        selectedStudio: new FormControl(),
    });

    constructor(
        private appService: AppService,
        ) {
            // this.studioSwitchPanel = appService.$studioSwitchPanel.subscribe(x => this.display = x);
            StudioSwitchSidebarComponent.instance = this;
        }

    async ngOnInit() {
        // const result = await this.appService.api.SettingsWorker.getStudioList(true);
        // this.studios = result.data;
        // this.files = result.__files;
        console.log("Studios: " + this.studios);
    }

    ngOnDestroy() {
        this.studioSwitchPanel.unsubscribe();
    }

    public toggle(){
        this.display = !this.display;
    }

    public onStudioChange(arg: {value: IStudio}){
        this.display = false;
        // this.appService.showStudioSwitchButton(this.form.value.state);
    }

    public onCloseClicked(_event: MouseEvent | PointerEvent){
        //setting false because after onMinimizeClicked it stays true
        // this.appService.showStudioSwitchButton(false);
        if (this.form.value.state){
            // make eslint happy...
            this.display = false;
            // this.appService.showStudioSwitchButton(this.form.value.state);
        }
        else {
            this.display = false;
        }
    }

    public onMinimizeClicked(){
        this.display = false;
        // this.appService.showStudioSwitchButton(true);
    }
}

