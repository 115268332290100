export type NoUndefined<T> = { [K in keyof T]: T[K] extends undefined ? never : T[K] };

export function noUndefined<T>(obj: T): NoUndefined<T> {
    if (obj === undefined)
        return obj as NoUndefined<T>;
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== undefined)) as NoUndefined<T>;
}

declare global {
    export interface ObjectConstructor {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        compare(a: unknown, b: unknown): boolean;
    }
}

// eslint-disable-next-line @typescript-eslint/space-before-function-paren
Object.compare = function(a: unknown, b: unknown) {
    if (typeof a !== typeof b)
        return false;
    if (a === b)
        return true;
    if (typeof a === 'function')
        return a.toString() === b.toString();
    if (typeof a === 'object') {
        if (a.constructor !== b.constructor)
            return false;
        const keys_a = Object.keys(a);
        const keys_b = Object.keys(b);
        if (keys_b.some(x => !keys_a.includes(x)) || keys_a.some(x => !keys_b.includes(x)))
            return false;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return keys_a.every(x => Object.compare((a as any)[x], (b as any)[x]));
    }
    return false;
};
