import { Component,Input, OnDestroy, OnInit } from '@angular/core';
import { DateTime } from 'luxon';
import { Subscription } from 'rxjs';
import { AppService } from 'src/services/app.service';
import { formatDateTime } from 'src/util/formatting/date';
import { toDateTime } from 'src/util/toDateTime';

type DateType = Date | DateTime | number | string;
type Value = DateType | [DateType, DateType];
type DateFormat = string | Intl.DateTimeFormatOptions | Record<string, string>;
/**
 * Component used to style dates and times.
 * Use this component instead of printing your own dates in the template, as 
 * this component considers the locale and culture of the browser.
 * 
 * For most cases just binding the date to the `value` property is enough.
 * The date can be formated by passing a format to the `format` property.
 * 
 * @name Date
 * @tags Display, Date, DateTime, Data
 * @example Simple
 * ```
 * %gc-date{[value]: "DateTime.now()"}
 * ```
 * @example Date range
 * ```
 * %gc-date{[value]: "[DateTime.now(), DateTime.now().plus({week: 1})]"}
 * ```
 */
@Component({
    selector: 'gc-date',
    template: '<span>{{ value }}</span>',
})
export class GcDateComponent implements OnInit, OnDestroy {
    /**
     * Accepts either a tokenized string or a `Intl.DateTimeFormatOptions`
     * @link https://github.com/moment/luxon/blob/master/docs/formatting.md#table-of-tokens
     * @remark The TS compiler and Luxon have a mismatch on the `Intl.DateTimeFormatOptions`.
     * The correct implementation is of ES5, but our compiler uses a different version, 
     * which can lead to a compiler (false-positive) error/warning when doing a production build.
     */
    @Input() public set format(value: DateFormat){
        this._internalFormat = value;
        this.updateValue();
    }
    public get format(){
        return this._internalFormat;
    }

    /**
     * Accepts a DateTime or a [DateTime, DateTime]
     * When passing an array it will automatically format it as a date range by adding an `en dash` (–).
     * 
     * @remark Note that the format applies to both values when passing an array.
     */
    @Input() public set value(value: Value) {
        this._internalValue = Array.isArray(value) ? value.map(x => toDateTime(x)).filter(x => x) as [DateTime, DateTime] : toDateTime(value);
        this.updateValue();
    }
    public get value(): string {
        return this._internvalParsedValue;
    }

    private _internalFormat: DateFormat;
    private _internalValue: DateTime | [DateTime, DateTime];
    private _internvalParsedValue: string;
    private _localeSubscription: Subscription;

    constructor(private appSerivce: AppService){}

    ngOnInit(): void {
        this._localeSubscription = this.appSerivce.$Locale.subscribe(x => this.updateValue());
    }

    ngOnDestroy(): void {
        this._localeSubscription?.unsubscribe();
    }

    private updateValue() {
        if (Array.isArray(this._internalValue))
            this._internvalParsedValue = this._internalValue.map(x => formatDateTime(x, this.format) ?? '?').join(' – ');
        else
            this._internvalParsedValue = formatDateTime(this._internalValue, this.format);

    }
}
