import { Component,Input, OnDestroy, OnInit } from '@angular/core';
import { DateTime, Duration, DurationLikeObject, ToHumanDurationOptions } from 'luxon';
import { Subscription } from 'rxjs';
import { AppService } from 'src/services/app.service';
import { formatTime } from 'src/util/formatting/time';
import { toDateTime } from 'src/util/toDateTime';
type DateLike = DateTime | Date | number;
type Value = Duration | DateLike | DurationLikeObject | [DateLike, DateLike];

/**
 * Component used to style dates and times.
 * Use this component instead of printing your own dates in the template, as 
 * this component considers the locale and culture of the browser.
 * 
 * For most cases just binding the date to the `value` property is enough.
 * The date can be formated by passing a format to the `format` property.
 * 
 * @name Time
 * @tags Display, Time, DateTime, Data
 * @example Simple
 * ```
 * %gc-time{[value]: "DateTime.now()"}
 * ```
 */
@Component({
    selector: 'gc-time',
    template: '<span>{{ _internvalParsedValue }}</span>',
})
export class GcTimeComponent implements OnInit, OnDestroy {
    private isNegative: boolean;
    /**
     * Rescales the time to the biggest unit.
     * @values boolean
     */
    @Input() set rescale(value: boolean){
        if (value && this._internalValue.valueOf() < 0) return;
        this._rescale = value;
        this.updateValue();
    }
    /**
     * Units to display.
     * @values DurationLikeObject[]
     */
    @Input() set units(value: (keyof DurationLikeObject)[]){
        this._units = value;
        this.updateValue();
    }
    /**
     * Accepts either a tokenized string or a `Intl.DateTimeFormatOptions`
     * @link https://github.com/moment/luxon/blob/master/docs/formatting.md#table-of-tokens
     * @remark The TS compiler and Luxon have a mismatch on the `Intl.DateTimeFormatOptions`.
     * The correct implementation is of ES5, but our compiler uses a different version, 
     * which can lead to a compiler (false-positive) error/warning when doing a production build.
     */
    @Input() public set format(value: string | ToHumanDurationOptions){
        this._internalFormat = value;
        this.updateValue();
    }
    public get format(){
        return this._internalFormat;
    }

    /**
     * Value of the component.
     * Accepts a luxon Duration, DateTime, Milliseconds or an array of two DateTimes to calculate the difference between them. 
     * 
     * @values Duration | DateLike | DurationLikeObject | [DateLike, DateLike]
     * 
     * @example Duration
     * ```
     * %gc-time{[value]: "demo.time"}
     * ```
     * @example DurationLikeObject
     * ```
     * %gc-time{[value]: "{hours: 13, minutes: 31}"}
     * ```
     * @example DateTime
     * ```
     * %gc-time{[value]: "demo.date"}
     * ```
     * @example Milliseconds
     * ```
     * %gc-time{[value]: "900000"}
     * ```
     * @example [DateTime, DateTime]
     * ```
     * %gc-time{[value]: "[DateTime.now(), DateTime.now().plus({hours: 2})]"}
     * ```
     */
    @Input() public set value(value: Value) {
        this.isNegative = false;
        if (Array.isArray(value)){
            value = toDateTime(value[1]).diff(toDateTime(value[0]));
        } else if (value instanceof Date || DateTime.isDateTime(value)) {
            value = toDateTime(value);
            value = Duration.fromObject({hours: value.hour, minutes: value.minute, seconds: value.second});
        } else if (typeof value === "number") {
            value = Duration.fromMillis(value);
        } else if (typeof value === "object" && !Duration.isDuration(value)) {
            value = Duration.fromDurationLike(value as DurationLikeObject);
        }

        /**
         * TODO:  check the behaviour of luxon.Duration with negative values.
         * We should see if we can adjust the formatting instead of altering the passed value.
         * - This also seems to be an open discussion on the luxon repo (since 2019).
         * - There also seems to be an issue with the luxon prop `conversionAccuracy`
         * - Check how it behaves if we normalize all units, current conversion seems to create odd result
         * - Rescaling a negative number causes issues
         * - `toFormat` also seems to change the object
         */
        if (Duration.isDuration(value) && value.valueOf() < 0) {
            value = value.negate();
            this.isNegative = true;
            this.rescale = false;
        }

        this._internalValue = value as Duration;
        this.updateValue();
    }

    _internvalParsedValue: string;
    private _internalFormat: string | ToHumanDurationOptions = "h'h' m'min'";
    private _internalValue: Duration;
    private _units: (keyof DurationLikeObject)[] = ["hours", "minutes"];
    private _rescale: boolean = true;
    private _localeSubscription: Subscription;

    constructor(private appSerivce: AppService){}

    ngOnInit(): void {
        this._localeSubscription = this.appSerivce.$Locale.subscribe(x => this.updateValue());
    }

    ngOnDestroy(): void {
        this._localeSubscription?.unsubscribe();
    }

    private updateValue() {
        this._internvalParsedValue = `${this.isNegative ? '-' : ''} ${formatTime(this._internalValue, this.format, this._units, undefined, this._rescale)}`
            .trim();
    }
}
