// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info {
  background: var(--gc-color-info);
  border: 1px solid var(--gc-color-info-shade);
  border-radius: 3px;
  width: 100%;
  max-width: 300px;
  padding: 0.45rem 0.75rem;
  display: flex;
  gap: 0.5rem;
  cursor: pointer;
  color: var(--gc-color-info-contrast);
}

@media screen and (max-width: 850px) {
  .info {
    max-width: 42px;
  }
  .info .text {
    display: none;
    color: var(--gc-color-info-contrast);
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/gc-dialog-info/gc-announcement.component.sass"],"names":[],"mappings":"AAAA;EACI,gCAAA;EACA,4CAAA;EACA,kBAAA;EACA,WAAA;EACA,gBAAA;EACA,wBAAA;EACA,aAAA;EACA,WAAA;EACA,eAAA;EACA,oCAAA;AACJ;;AAEA;EACI;IAII,eAAA;EAFN;EADM;IACI,aAAA;IACA,oCAAA;EAGV;AACF","sourcesContent":[".info\n    background: var(--gc-color-info)\n    border: 1px solid var(--gc-color-info-shade)\n    border-radius: 3px\n    width: 100%\n    max-width: 300px\n    padding: 0.45rem 0.75rem \n    display: flex\n    gap: 0.5rem\n    cursor: pointer\n    color: var(--gc-color-info-contrast)\n\n\n@media screen and (max-width: 850px)\n    .info\n        .text\n            display: none\n            color: var(--gc-color-info-contrast)\n        max-width: 42px"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
