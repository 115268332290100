/* eslint-disable max-len */
/* eslint-disable @angular-eslint/no-inputs-metadata-property */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/no-outputs-metadata-property */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { Component, Input, ViewEncapsulation } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { TreeSelect } from "primeng/treeselect";
import { WrapperComponent } from "../base";
import { Wrapper } from "../decorators";

@Component({
    selector:      'gc-treeSelect',
    styles:        ['p-treeSelect.block .p-treeSelect{ display: block }'],
    encapsulation: ViewEncapsulation.None,
    template:      `<label *ngIf="label !== undefined" [for]="__internal_ID">{{ label }}</label>
                    <p-treeSelect [ngClass]="{block: !!label}" [formControl]="gcControl" [id]="__internal_ID"><ng-content></ng-content></p-treeSelect>`,
    providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: GcTreeSelectComponent, multi: true }],
    inputs:    ["options", "scrollHeight", "placeholder", "disabled", "tabindex", "inputId", "ariaLabelledBy", "selectionMode", "panelClass", "appendTo", "emptyMessage",
        "display", "propagateSelectionUp", "propagateSelectionDown", "metaKeySelection", "filter", "filteredBy", "filterMode", "filterPlaceholder", "filterLocale",
        "resetFiterOnHide", "showClear"
    ],
    outputs: [
        "onShow", "onHide", "onFilter", "onNodeSelect", "onNodeUnselect", "onNodeExpand", "onNodeCollapse", "onClear"
    ]
})
@Wrapper(TreeSelect)
export class GcTreeSelectComponent extends WrapperComponent {
    @Input() label: string;
}
export interface GcTreeSelectComponent extends TreeSelect {};
