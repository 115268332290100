import { Component, Input } from '@angular/core';
/**
 * Component similar to the "li" element but with additional custom styling when "displayFalse" parameter is set to true.
 * @name List-element
 */
@Component({
    selector:    'gc-li',
    templateUrl: './gc-li.component.haml',
    styleUrls:   ['./gc-li.component.sass'],
})
export class GcLiComponent {
    /**
     * Value can be anything but is mostly used as a boolean.
     */
    @Input() value: unknown;
    /**
     * Regular label paramater
     */
    @Input() label: string;
    /**
     * Used when you want to use a different text from your "label" parameter when your "value" is false
     * @example
     * ```
     *  %ul
     *      %gc-li{[value]: "false", [displayFalse]: "true", label: "Text for when value is true", inactiveLabel: "Text for when value is false"}
     * ```
     */
    @Input() inactiveLabel: string;
    /**
     * If you want to use the component, it's best to always set "displayFalse" as true.
     * The purpose of "displayFalse" is to display some additional styling to your list element when that "value" is false.
     * When your "value" is false, the list element will have an additional 'x' icon and text in german saying "not active - your_label" that looks gray.
     * @default false
     */
    @Input() displayFalse = false;
    /**
     * Set "asHeader" to true when you want your label to be bolded. Label is not bolded when "value" is false.
     * @default false
     * @example 
     * ```
     *  %ul
     *      %gc-li{[value]: "true", [displayFalse]: "true", label: "Bolded list item", [asHeader]: "true"}
     * ```
    */
    @Input() asHeader = false;
    /**
     * It's an additional nested label which is displayed with an exclamation mark "!" icon in a warning way.
     * @example
     * ```
     *  %ul
     *      %gc-li{[value]: "true", [displayFalse]: "true", label: "Regular label", labelAddition: "Additional label"}
     * ```
     */
    @Input() labelAddition: string;

    constructor() { }
}
