/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Inject, Input, OnChanges, OnDestroy, Optional, SkipSelf, ViewEncapsulation } from "@angular/core";
import { WrapperComponent } from "../../base";
import { ControlValueAccessor, FormArray, FormControl, FormGroup, NG_VALUE_ACCESSOR } from "@angular/forms";
import { GC_COMPONENT_CONTAINER } from "../../definitions";
import { GcContainerComponent } from "../../container.component";

@Component({
    selector:      'gc-bitButton',
    encapsulation: ViewEncapsulation.None,
    templateUrl:   './bitbutton.html',
    styleUrls:     ['./bitbutton.sass'],
    providers:     [{ provide: NG_VALUE_ACCESSOR, useExisting: GcBitButton, multi: true }],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class GcBitButton implements ControlValueAccessor, OnChanges, OnDestroy {
    @Input() options: { label: string, onLabel?: string, value: number }[];

    fc: FormArray;
    fg: FormGroup = new FormGroup({ entries: new FormArray([]) });

    _value: Uint8Array;
    _disabled: boolean;
    _onChange: (value: Uint8Array) => void;
    _onTouched: () => void;

    _oldState: boolean[];

    constructor(
        @Inject(GC_COMPONENT_CONTAINER) @Optional() @SkipSelf() protected parent: GcContainerComponent
    ) {
        if (parent?._skipRegister)
            parent = undefined;
        if (parent)
            parent.registerChild(this as unknown as WrapperComponent);
        this.init();
    }

    ngOnChanges() {
        this.init();
    }

    init() {
        if (this.options && this.fc?.controls.length !== this.options.length) {
            this.fc = new FormArray([]);
            this.options.forEach((o) => {
                this.fc.push(new FormControl(false));
            });
            this.fc.valueChanges.subscribe((v) => {
                this.valueChanged(v);
            });
            this.fg = new FormGroup({ entries: this.fc });
        }
        if (this.options && this._value && this.fc) {
            this.options.forEach((o, i) => {
                this.fc.controls[i].setValue(this.isBitSet(o.value), { emitEvent: false });
                if (this._disabled)
                    this.fc.controls[i].disable({ emitEvent: false });
                else
                    this.fc.controls[i].enable({ emitEvent: false });
            });
        }
        this._oldState = this.fc?.value;
    }

    valueChanged(value: boolean[]) {
        this._oldState?.forEach((o, i) => {
            if (value[i] && !o) {
                for (let j = 0; j < i; j++)
                    value[j] = true;
            } else if (!value[i] && o) {
                for (let j = i + 1; j < value.length; j++)
                    value[j] = false;
            }
        });
        this.options?.forEach((o, i) => {
            if (value[i])
                this.setBit(o.value);
            else
                this.clearBit(o.value);
        });
        this.fc.patchValue(value, { emitEvent: false });
        this._oldState = value;
        this._onTouched?.();
        this._onChange?.(this._value);
    }

    isBitSet(bit: number): boolean {
        // eslint-disable-next-line no-bitwise
        return !!(this._value[Math.floor(bit / 8)] & (1 << (bit % 8)));
    }

    setBit(bit: number) {
        // eslint-disable-next-line no-bitwise
        this._value[Math.floor(bit / 8)] |= 1 << (bit % 8);
    }

    clearBit(bit: number) {
        // eslint-disable-next-line no-bitwise
        this._value[Math.floor(bit / 8)] &= ~(1 << (bit % 8));
    }

    ngOnDestroy(): void {
        if (this.parent)
            this.parent.unregisterChild(this as unknown as WrapperComponent);
    }

    writeValue(obj: any): void {
        this._value = obj;
        this.init();
    }

    registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this._disabled = isDisabled;
        this.init();
    }

    registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }
}
